import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';


@Component({
  selector: 'pager',
  templateUrl: './pager.usercontrol.component.html',
  styleUrls: ['./pager.usercontrol.component.css']
})
export class PagerUserControlComponent implements OnInit, OnChanges {

  constructor() {
  }

  @Input() public PageSize!: number;
  @Input() public PageIndex!: number;
  @Input() public TotalItemCount!: number;

  public MaxPagesLength: number = 10;
  public PageNumbers!: number[];
  public LastPageIndex!: number;
  @Output() public PageIndexChange: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit(): void {
    this.buildPagesArray();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.buildPagesArray();
    if (changes.PageIndex) {
      this.PageIndex = Number(changes.PageIndex.currentValue);
    }

    //  console.log(changes);
  }
  public SetPage(index: number) {
    this.PageIndex = index;
    this.PageIndexChange.emit(index);
  }

  private buildPagesArray() {
    if (!this.PageIndex) this.PageIndex = 0;

    this.LastPageIndex = Math.floor((this.TotalItemCount - 1) / this.PageSize);


    var startNumberIndexs = Math.max(0, this.PageIndex - (this.MaxPagesLength / 2));
    var endNumberIndexs = Math.max(this.MaxPagesLength, (this.PageIndex * 1) + (this.MaxPagesLength / 2));
    if (endNumberIndexs > this.LastPageIndex) {
      endNumberIndexs = this.LastPageIndex;
      startNumberIndexs = Math.max(0, endNumberIndexs - this.MaxPagesLength);
    }

    this.PageNumbers = [];
    if (this.TotalItemCount > 0) {

      for (var i = startNumberIndexs; i <= endNumberIndexs; i++) {
        this.PageNumbers.push(i + 1);
      }
    }

  }


}
