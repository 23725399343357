import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

declare var $: any;
import { OrderService, IOrderSearchRequest } from '../../../services/order.service';
import { IOrder, OrderState, IProductOrderItem, OrderSource, OrderErpExportStatus, SelectableERPStatus, IOrderItem, OrderCollectStatus } from '../../../models/orders.models';
import { IPageResult } from '../../../models/common.models';

import { ShipmentStatus, IShipmentTypes, ShipmentState } from '../../../models/shipments.models';
import { EnumDescriptionPipe } from '../../../pipes/enumdescription.pipe';
import { ShipmentService } from '../../../services/shipment.service';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { IExportOptions } from '../order-export/order-export.process.page.component';
import { FileType } from '../../../models/files.models';
import { IStoreDetails, StoreType } from '../../../models/stores';
import { StoreService } from '../../../services/store.service';
import { filter, first, tap } from 'rxjs/operators';
import { firstValueFrom, forkJoin, lastValueFrom } from 'rxjs';
import { SelectableList } from '../../../models/SelectableList';
import { DateSelectiontModel } from '../../usercontrols/datetimedropdownpicker/dateropdownpicker.usercontrol.component';
import { Location } from '@angular/common';
import { IMasterBasicOrderProduct } from 'src/app/models/products.models';
import { AdminService } from 'src/app/services/admin.service';
import { ISupplierConfiguration } from 'src/app/models/suppliers';
import { AuthorizationService } from 'src/app/services/authurization.service';
import { ConvertToArrayOfNumbers, ConvertToArrayOfStrings } from 'src/app/helpers/common.helper';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'src/app/deprecated-open-source-projects/angular-2-dropdown-multiselect/src/public-api';
import { ConfigStateFeature } from 'src/app/state/config.reducer';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app',
  templateUrl: './order-list.page.component.html',
  styleUrls: ['./order-list.page.component.css']
})
export class OrderListPageComponent implements OnInit {

  constructor(
    private _orderService: OrderService,
    private _shipmentService: ShipmentService,
    private _adminService: AdminService,
    private _route: ActivatedRoute,
    private _storeService: StoreService,
    private _router: Router,
    private _location: Location,
    private _authorizationService: AuthorizationService,
    private store: Store,
    private _modalService: NgbModal) { }

  private EnumDescriptionConverter = new EnumDescriptionPipe();
  private maintainView: boolean = false;
  public Mode: IOrderListMode;
  private defaultMode: IOrderListMode = {
    SplitProducts: false,
    AllowPriceListOverride: false,
  }
  orders!: IPageResult<IOrder>;
  public optionsModel: number[] = [];
  public SelectableERPStatus: OrderErpExportStatus[] = SelectableERPStatus;
  private AdditionFields: { [key: string]: boolean };
  public MouseOverOrder: IOrder;
  public FillersSettings = {
    OrderStateOptions: <IMultiSelectOption[]>[],
    ShipmentStatusOptions: <IMultiSelectOption[]>[],
    OrderERPExportStatusOptions: <IMultiSelectOption[]>[],
    OrderCollectStatusOptions: <IMultiSelectOption[]>[],
    OrderSourceOptions: <IMultiSelectOption[]>[],
    ShipmentTypeOptions: <IMultiSelectOption[]>[],
    OrderAgeOptions: <IMultiSelectOption[]>[],
    CheckboxListSetting: <IMultiSelectSettings>{
      enableSearch: true,
      checkedStyle: 'fontawesome',
      buttonClasses: 'btn btn-primary btn-sm',
      dynamicTitleMaxItems: 3,
      displayAllSelectedText: true,
      pullRight: true,
      closeOnSelect: true
    },
    RadioboxListSetting: <IMultiSelectSettings>{
      checkedStyle: 'fontawesome',
      buttonClasses: 'btn btn-primary btn-sm',
      dynamicTitleMaxItems: 1,
      displayAllSelectedText: true,
      pullRight: true,
      closeOnSelect: true,
      selectionLimit: 1,
      autoUnselect: true
    },

    GetIMultiSelectTexts: function (Title: string): IMultiSelectTexts {
      return {
        checkAll: 'בחר הכול',
        uncheckAll: 'הסר הכול',
        checked: Title + ' נבחרה',
        checkedPlural: Title + ' נבחרו',
        searchPlaceholder: 'חפש',
        searchEmptyResult: 'לא קיים',
        searchNoRenderText: 'הקלד לחיפוש',
        defaultTitle: Title,
        allSelected: 'כול ' + Title,
      };
    }
  }

  public FreeSearchText!: string;
  public FreeSearchOptions: FreeSearchOption[] = [
    { key: "FreeSearch", name: "חיפוש חופשי" },
    { key: "SupplierNumberContains", name: "חיפוש לפי מספר הזמנה" },
    { key: "ShipmentTrackingNumber", name: "חיפוש לפי מספר משלוח" },
    { key: "ShopperFullName", name: "חיפוש לפי שם לקוח" },
    { key: "Phone", name: "חיפוש לפי טלפון" },
    { key: "SKU", name: "חיפוש לפי מק\"ט" },
    { key: "ProductNameSearch", name: "חיפוש לפי שם מוצר" },
    { key: "RemarksContain", name: "חיפוש לפי הערות" },
  ];


  public SelectedFreeSearchOption: FreeSearchOption = this.FreeSearchOptions[0];

  public Selector: SelectableList<string> | null = null;

  private internalShipmentStatusStateQueryFilter: string[] | null = [];
  public IsERPOrderViewPage: boolean = false;
  public ERPUpdateStatusCandidate!: OrderErpExportStatus;
  public get ShipmentStatusStateQueryFilter() {
    var shipmentStatus = this.Query.ShipmentStatus?.map(val => "ShipmentStatus" + val);
    var shipmentState = this.Query.ShipmentState?.map(val => "ShipmentState" + val);
    var newInternalShipmentStatusStateQueryFilter = [];

    if (shipmentStatus?.length) newInternalShipmentStatusStateQueryFilter = newInternalShipmentStatusStateQueryFilter.concat(shipmentStatus);
    if (shipmentState?.length) newInternalShipmentStatusStateQueryFilter = newInternalShipmentStatusStateQueryFilter.concat(shipmentState);

    if (!this.internalShipmentStatusStateQueryFilter || (newInternalShipmentStatusStateQueryFilter.length != this.internalShipmentStatusStateQueryFilter.length) || (newInternalShipmentStatusStateQueryFilter.includes(val => !this.internalShipmentStatusStateQueryFilter.includes(val)))
    ) {
      this.internalShipmentStatusStateQueryFilter = newInternalShipmentStatusStateQueryFilter;
    }

    return this.internalShipmentStatusStateQueryFilter;
  }
  public set ShipmentStatusStateQueryFilter(values: string[]) {
    this.internalShipmentStatusStateQueryFilter = values;
    this.Query.ShipmentStatus = values.filter(val => val.startsWith("ShipmentStatus")).map(val => Number(val.substring("ShipmentStatus".length)));
    this.Query.ShipmentState = values.filter(val => val.startsWith("ShipmentState")).map(val => Number(val.substring("ShipmentState".length)));

  }

  public Query: IOrderSearchRequest = {};
  public DefaultQuery: IOrderSearchRequest = {
    PageIndex: 0,
    PageSize: 20,
    OrderByFields: ["-OperationTime"]
  }
  public Title!: string | null;
  public PrintOptions = {
    ShipmentType: "",
    ForceConvertType: false,
    CloseResult: ""
  }

  @ViewChild('contentExportOrders', { static: true })
  private contentExportOrders!: ElementRef;
  private _shipmentTypes!: IShipmentTypes;

  public ExportOptions: {
    Message: string,
    Types: string[],
    ChangeShipmentStatus?: ShipmentStatus | null,
    ChangeErpStatusToExported: boolean,
    ChangeCollectStatusToCollected: boolean,
    ExportDataFile: boolean,
    CloseResult: string,
    SelectedOrdersCount?: number,
    PopupType: string
  };


  public BuildFilerOptions() {

    this.FillersSettings.OrderStateOptions = [];
    for (const state in OrderState) {
      if (Number(state)) {
        if (!this._configuration.StockSetting?.Enable && (+state) == OrderState.OutOfStock) continue;

        this.FillersSettings.OrderStateOptions.push({
          id: +state,
          name: this.EnumDescriptionConverter.transform(+state, "OrderState")
        });
      }
    }

    this.FillersSettings.ShipmentStatusOptions = [];
    for (const status in ShipmentStatus) {
      if (Number(status)) {
        this.FillersSettings.ShipmentStatusOptions.push({
          id: "ShipmentStatus" + status,
          name: this.EnumDescriptionConverter.transform(+status, "ShipmentStatus")
        });
      }
    }
    for (const status in ShipmentState) {
      if (Number(status)) {
        if (Number(status) != ShipmentState.Active) {
          this.FillersSettings.ShipmentStatusOptions.push({
            id: "ShipmentState" + status,
            name: this.EnumDescriptionConverter.transform(+status, "ShipmentState")
          });
        }
      }
    }


    this.FillersSettings.OrderERPExportStatusOptions = [];
    for (const status in OrderErpExportStatus) {
      if (Number(status)) {
        this.FillersSettings.OrderERPExportStatusOptions.push({
          id: +status,
          name: this.EnumDescriptionConverter.transform(+status, "OrderErpExportStatus")
        });
      }
    }

    this.FillersSettings.OrderCollectStatusOptions = [];
    for (const status in OrderCollectStatus) {
      if (Number(status)) {
        this.FillersSettings.OrderCollectStatusOptions.push({
          id: +status,
          name: this.EnumDescriptionConverter.transform(+status, "OrderCollectStatus")
        });
      }
    }

    this.FillersSettings.ShipmentTypeOptions = [];
    for (const shipType in this._configuration.Shipments) {
      if (Object.prototype.hasOwnProperty.call(this._configuration.Shipments, shipType)) {
        const shipmentConfiguration = this._configuration.Shipments[shipType];
        if (shipmentConfiguration.Enabled && (this._shipmentTypes[shipType]?.Description ?? "").trim().length > 0) {
          this.FillersSettings.ShipmentTypeOptions.push({
            id: shipType,
            name: this._shipmentTypes[shipType].Description
          });
        }
      }
    }
    this.FillersSettings.ShipmentTypeOptions = this.FillersSettings.ShipmentTypeOptions.sort((a, b) => a.name.localeCompare(b.name));


    this.FillersSettings.OrderSourceOptions = [];
    for (let i = 0; i < this._stores.length; i++) {
      let store = this._stores[i];
      if (store.Enabled) {
        this.FillersSettings.OrderSourceOptions.push({
          id: store.IdName,
          name: store.Description
        });
      }
    }
    this.FillersSettings.OrderSourceOptions = this.FillersSettings.OrderSourceOptions.sort((a, b) => a.name.localeCompare(b.name));
  }
  public SortListBy(colName: string) {
    if (
      (!this.Query.OrderByFields) ||
      (this.Query.OrderByFields.length === 0) ||
      (this.Query.OrderByFields[0] !== colName)) {
      this.Query.OrderByFields = [colName];
    }
    else {
      this.Query.OrderByFields = ["-" + colName];
    }
    this.reloadPage(true, false);
  }
  private _stores!: IStoreDetails[];
  private _configuration: ISupplierConfiguration;
  public get IsFilterSelected(): boolean {
    return !!(this.Query.AgeSeconds || this.Query.OperationAgeSeconds || this.Query.ShipmentType || this.Query.OriginStore || this.Query.OrderState || this.Query.ERPExportStatus || this.Query.CollectStatus || this.Query.ShipmentStatus);
  }

  public ResetFilters() {
    delete this.Query.AgeSeconds;
    delete this.Query.OperationAgeSeconds;
    delete this.Query.ShipmentType;
    delete this.Query.OriginStore;
    delete this.Query.OrderState;
    delete this.Query.ERPExportStatus;
    delete this.Query.CollectStatus;
    delete this.Query.ShipmentStatus;
    this.reloadPage(true, true);
  }

  async ngOnInit() {
    var s = await Promise.all([
      firstValueFrom(this.store.select(ConfigStateFeature.selectShipmentTypes).pipe(filter(s => !!s))).then(s => this._shipmentTypes = s),
      lastValueFrom(this._storeService.GetStoreList()).then(s => this._stores = s),
      firstValueFrom(this.store.select(ConfigStateFeature.selectConfiguration).pipe(filter(s => !!s))).then(c => this._configuration = c)
    ]);

    this.BuildFilerOptions();
    this._route
      .queryParams
      .subscribe(params => {
        if (params.Fields) this.AdditionFields = JSON.parse(params.Fields);
        else this.AdditionFields = null;

        if (params.Mode) this.Mode = JSON.parse(params.Mode);
        else this.Mode = this.defaultMode;

        var query = $.extend(true, {}, params);
        delete query.Mode;
        delete query.Fields;
        // Defaults to 0 if no query param provided.
        if (query.ShipmentStatus) query.ShipmentStatus = ConvertToArrayOfNumbers(query.ShipmentStatus);
        if (query.ShipmentState) query.ShipmentState = ConvertToArrayOfNumbers(query.ShipmentState);
        if (query.NotShipmentState) query.NotShipmentState = ConvertToArrayOfNumbers(query.NotShipmentState);
        if (query.SupplierNumberIn) query.SupplierNumberIn = ConvertToArrayOfStrings(query.SupplierNumberIn);

        if (query.OrderState) query.OrderState = ConvertToArrayOfNumbers(query.OrderState);
        else query.NotOrderState = [OrderState.Draft];


        if (query.ShipmentType) {
          if (!Array.isArray(query.ShipmentType)) {
            query.ShipmentType = [query.ShipmentType];
          }
        }

        if (query.ERPExportStatus) query.ERPExportStatus = ConvertToArrayOfNumbers(query.ERPExportStatus);
        if (query.CollectStatus) query.CollectStatus = ConvertToArrayOfNumbers(query.CollectStatus);


        this.loadPage(query);

        if (this.maintainView) this.maintainView = false;
        else {
          this.Selector = null;
        }
      });
  }

  public get QuickButtonActionType(): string {
    return this._configuration?.UIConfiguration?.OrderPageQuickButton ?? "ExportERPFile";
  }


  public HasRole(roles: string[]): boolean {
    return this._authorizationService.DoseUserHasRole(roles);
  }

  public IsFieldEnabled(field: string, isDefaultField: boolean) {
    if (this.AdditionFields) {
      return (!!this.AdditionFields[field]);
    }
    return isDefaultField;
  }

  public reloadPage(resetPaging: boolean | undefined, resetSelection: boolean | undefined) {
    var query: IOrderSearchRequest = {};
    for (const key in this.Query) {
      if (key == "ProjectionFields") continue;
      if (key == "OrderByFields") {
        if ((this.Query.OrderByFields) && (this.DefaultQuery.OrderByFields) && (this.Query.OrderByFields[0] != this.DefaultQuery.OrderByFields[0])) query.OrderByFields = this.Query.OrderByFields;
        continue;
      };

      if (this.Query.hasOwnProperty(key)) {
        const element = this.Query[key];
        if (!element) continue;

        if (Array.isArray(element)) {
          if (element.length > 0) query[key] = element.slice();
        }
        else {
          if ((element != this.DefaultQuery[key])) query[key] = element;
        }
      }
      if (this.Query[key] instanceof Date) {
        query[key] = this.Query[key].toISOString();
      }

    }
    if (resetPaging) {
      delete query.PageIndex;
    }

    if (resetSelection) {
      this.Selector = null;
    }

    if (this.AdditionFields) query["Fields"] = JSON.stringify(this.AdditionFields);

    var mode = JSON.stringify(this.Mode);
    if (mode != JSON.stringify(this.defaultMode)) query["Mode"] = mode;

    query["timestamp"] = new Date().getTime();
    this.maintainView = true;
    this.internalShipmentStatusStateQueryFilter = null;
    if (query.NotOrderState && query.NotOrderState.length === 1 && query.NotOrderState[0] === OrderState.Draft) delete query.NotOrderState; // default value.
    this._router.navigate(["/orders"], { queryParams: query });
  }
  public loadPage(query: { [x: string]: any; OriginStore?: any; }) {

    this.Query = $.extend(true, {}, this.DefaultQuery, query);
    if (this.Query.AgeSeconds) this.Query.AgeSeconds = Number(this.Query.AgeSeconds);
    if (this.Query.OperationAgeSeconds) this.Query.OperationAgeSeconds = Number(this.Query.OperationAgeSeconds);
    if (typeof this.Query.OrderByFields === "string") this.Query.OrderByFields = [this.Query.OrderByFields];

    if (!query.OriginStore) {
      query.OriginStore = this._stores.filter(s => s.Enabled).map(s => s.IdName);
    }
    this.orders = null;
    var finalQuery = $.extend(true,
      {
        ProjectionFields: this.getQueryProjectionFields()
      },
      this.DefaultQuery,
      query) as IOrderSearchRequest;
    delete finalQuery['Fields'];

    if (finalQuery.ShipmentState && finalQuery.NotShipmentState) finalQuery.NotShipmentState = finalQuery.NotShipmentState.filter(val => !finalQuery.ShipmentState.includes(val));



    this._orderService.SearchOrders(finalQuery)
      .subscribe(r => {
        this.orders = r.Result;
        if (!this.Selector) this.Selector = new SelectableList<string>(this.orders.TotalItemsCount);
      });
    this.BindUI();

  }

  private getQueryProjectionFields() {
    var fields = ["Id", "SupplierNumber", "FailedValidations<ValidationResult>", "State", "Shipments.Id", "Shipments.Status", "Shipments.PreUnifyShipment.Id", "Items.Product.Id"];
    if (!this.AdditionFields) fields = fields.concat(["Source.Type", "Source.OriginStore", "Shopper.FullName", "Items.Product.SKU", "Items.Product.Name", "Items.Quantity", "Items.Product.Note", "Remarks", "OperationalRemarks", "Calculated_TotalPrice", "Audit.CreatedAt", "Source.OperationTime", "Shipments.Remarks", "Shipments.ItemType", "Shipments.Address.City"]);
    else {
      for (const field in this.AdditionFields) {
        if (Object.prototype.hasOwnProperty.call(this.AdditionFields, field)) {
          if (this.AdditionFields[field]) fields.push(field);
        }
      }
    }

    const priceIndex = fields.indexOf("Items.Price");
    if (priceIndex >= 0) {
      fields.splice(priceIndex, 1);
      fields.push("Items.ItemPrice");
      fields.push("Items.ItemVATPrice");
    }

    if (this.Mode.SplitProducts) {
      fields.push("Items.Product.Id");
      fields.push("Items.Product.ItemType");
    }

    return fields;
  }
  public SetOrderDateFilter(dateselection: DateSelectiontModel | null) {

    delete this.Query.AgeSeconds;
    delete this.Query.CreatedDateFrom;
    delete this.Query.CreatedDateTo;

    if (dateselection && dateselection.Age) this.Query.AgeSeconds = dateselection.Age;
    else if (dateselection && dateselection.Range) {
      this.Query.CreatedDateFrom = dateselection.Range.From;
      this.Query.CreatedDateTo = dateselection.Range.To;
    }

    this.reloadPage(true, true);
  }

  public SetOperationDateFilter(dateselection: DateSelectiontModel | null) {
    delete this.Query.OperationAgeSeconds;
    delete this.Query.OperationTimeFrom;
    delete this.Query.OperationTimeTo;

    if (dateselection && dateselection.Age) this.Query.OperationAgeSeconds = dateselection.Age;
    else if (dateselection && dateselection.Range) {
      this.Query.OperationTimeFrom = dateselection.Range.From;
      this.Query.OperationTimeTo = dateselection.Range.To;
    }

    this.reloadPage(true, true);
  }


  private BindUI() {

    const titleItemType = this.Mode.SplitProducts ? "מוצרים" : "הזמנות";
    if ((this.Query.ShipmentStatus) && (this.Query.ShipmentStatus.length === 1) && (this.Query.ShipmentStatus[0] === ShipmentStatus.New)) {
      this.Title = titleItemType + " חדשות";
    }
    else if (this.Query.SourceOperationID) {
      this.Title = `רשימת ${titleItemType} מקובץ`;
    }

    else this.Title = null;

    if ((this.Query.OrderState) && (this.Query.OrderState.length === 1)) {
      if (this.Query.OrderState[0] === OrderState.Active) this.Title = (this.Title || "הזמנות") + " פעילות";
      if (this.Query.OrderState[0] === OrderState.Draft) this.Title = (this.Title || "הזמנות") + " בהקמה";
    }

    if (this.Query?.OrderState?.length === 1 && this.Query.OrderState[0] == OrderState.Active && this.Query?.ERPExportStatus?.length === 1 && this.Query.ERPExportStatus[0] === OrderErpExportStatus.Ready) {
      this.Title = titleItemType + " פעילות מוכנות להעברה ל-ERP";
      this.IsERPOrderViewPage = true;
    }
    else this.IsERPOrderViewPage = false;

    if (this.Query?.CollectStatus?.length === 1 && this.Query.CollectStatus[0] === OrderCollectStatus.Ready) {
      this.Title = titleItemType + " מוכנות לליקוט ";
    }


    if (this.Query?.ShipmentState?.length === 1 && this.Query.ShipmentState[0] === ShipmentState.IgnoreValidation) {
      this.Title = titleItemType + " עם כתובת שגויה ";
    }


    var selectedOptions = this.FreeSearchOptions.filter(op => !!this.Query[op.key]);

    if (selectedOptions.length) {
      this.SelectedFreeSearchOption = selectedOptions[0];
      this.FreeSearchText = this.Query[this.SelectedFreeSearchOption.key];
    }
  }

  public AllowToExport(): boolean {
    if (!this.Query.OrderState) return true;
    return this.Query.OrderState.indexOf(OrderState.Draft) === -1;
  }
  public HTMLHelper = {

    IsErpSetStatusDisabled: (status: OrderErpExportStatus) => {
      if (status == OrderErpExportStatus.Exportable &&
        this.Query?.ERPExportStatus?.length &&
        this.Query.ERPExportStatus.filter(status => status != OrderErpExportStatus.Canceled && status != OrderErpExportStatus.Exported).length == 0) return true;
      else return false;
    },
    GetOrderItemTitleDescription(order: IOrder): string {
      return order.Items.map(i => {
        var product = (i as IProductOrderItem).Product;
        if (product) return product.Name;
        else return "";
      }
      ).filter(s => s && s.trim()).join("\n").trim();
    },
    GetOrderItemQuantity(order: IOrder): number {
      return order.Items.filter(item => !!item["Product"]).reduce((count, orderItem) => count + orderItem.Quantity, 0);
    },
    GetOrderItemSKUDescription(order: IOrder): string {
      return order.Items.map(i => {
        var product = (i as IProductOrderItem).Product;
        if (product) return product.SKU;
        else return "";
      }
      ).filter(s => s && s.trim()).join("\n").trim();

    },
    GetOrderProductItemTotalQuantity(order: IOrder): number {

      return order.Items
        .reduce((agg, item) => {
          let quantity = (item.Quantity && (item as IProductOrderItem).Product) ? item.Quantity : 0;
          return agg + quantity;
        }, 0);

    },
    GetOrderCombindPrice(order: IOrder): number {
      return order.Items.reduce((sum, item) => sum + this.GetOrderItemPrice(item), 0);
    },

    GetOrderItemPrice(orderItem: IOrderItem): number {
      if (orderItem.ItemPrice) return orderItem.ItemPrice + orderItem.ItemVATPrice;
      else return 0;
    },

    GetOrderCombindRecommendedPrice(order: IOrder): number {
      return order.Items.reduce((sum, item) => sum + (this.GetOrderItemRecommendedPrice(item) || 0), 0);
    },

    GetOrderItemRecommendedPrice: (orderItem: IOrderItem): number | null => {
      if (orderItem["Product"] && ((orderItem as IProductOrderItem).Product["StorePriceList"])) {
        if (((orderItem as IProductOrderItem).Product as IMasterBasicOrderProduct).StorePriceList) {
          return ((orderItem as IProductOrderItem).Product as IMasterBasicOrderProduct).StorePriceList.RecommendedPrice * (1 + this._configuration.VatRate);
        }
      }
      return null;
    },


    DateSelectiontModel: DateSelectiontModel,
    GetOrderCombindRemarks(order: IOrder): string {
      var OperationalRemarks = (order.OperationalRemarks || "").trim();
      var Remarks = (order.Remarks || "").trim();
      return (OperationalRemarks ? OperationalRemarks + "\n" : "") +
        (Remarks ? Remarks + "\n" : "") +
        order.Items.map(i => {
          var product = (i as IProductOrderItem).Product;
          if (product) return product.Note;
          else return "";
        }).filter(s => s && s.trim()).join("\n").trim() +
        order.Shipments.map(s => s.Remarks).filter(s => s && s.trim()).join("\n").trim();
    },


    GetOrderSourceTypeFontAwesomeIcon: (order: IOrder) => {

      if (order.Source.Type & OrderSource.Excel) return 'fa-file-excel';
      else if (order.Source.Type & OrderSource.CSV) return 'fa-file-alt';
      else if (order.Source.Type & OrderSource.HTML) return 'fa-file-code';
      else if (order.Source.Type & OrderSource.Text) return 'fa-file-alt';
      else if (order.Source.Type & 1) return 'far-star';
      else return "";
    },

    GetOrderState: (order: IOrder) => {
      if (order.FailedValidations.length == 0) return 0;
      return Math.max.apply(null, order.FailedValidations.map(v => v.Severity));
    },

    HasOrderRemarks: (order: IOrder) => {
      return ((!!order.Remarks) && (order.Remarks.trim().length > 0));
    },
  }
  public UpdateSelectedOrdersState(state: number) {
    this.orders = null;
    this._orderService.SetState(this.GetSelectedOrdersQuery(), state).subscribe(r => {
      this.reloadPage(!!this.Query.OrderState, !!this.Query.OrderState);
    });
  }


  public UpdateSelectedOrdersShipmentStatus(status: number) {
    this.orders = null;
    this._orderService.SetShipmentStatus(this.GetSelectedOrdersQuery(), status).subscribe(r => {
      this.reloadPage(!!this.Query.ShipmentStatus, !!this.Query.ShipmentStatus);
    });


  }
  public UpdateSelectedOrdersShipmentType(shipmentType: string) {
    this.orders = null;
    this._shipmentService.ChangeOrderShipmnetType(this.GetSelectedOrdersQuery(), shipmentType).subscribe(r => {
      this.reloadPage(!!this.Query.ShipmentType, !!this.Query.ShipmentType);
    });
  }
  public UpdateConfirmedSelectOrderERPExportStatus = () => {
    this.UpdateSelectOrderERPExportStatus(this.ERPUpdateStatusCandidate);
  }
  public UpdateSelectOrderERPExportStatus(status: number) {
    this.orders = null;
    this._orderService.SetErpStatus(this.GetSelectedOrdersQuery(), status).subscribe(r => {
      this.reloadPage(!!this.Query.ERPExportStatus, !!this.Query.ERPExportStatus);
    });
  }

  public UpdateSelectOrderCollectStatus(status: number) {
    this.orders = null;
    this._orderService.SetCollectStatus(this.GetSelectedOrdersQuery(), status).subscribe(r => {
      this.reloadPage(!!this.Query.CollectStatus, !!this.Query.CollectStatus);
    });
  }


  public RunFreeSearch() {
    delete this.Query.PageIndex;

    this.FreeSearchOptions.forEach(element => {
      delete this.Query[element.key];
    });

    this.Query[this.SelectedFreeSearchOption.key] = this.FreeSearchText.trim();


    this.reloadPage(true, true);
  }

  public ExportOrders(type: string | null) {
    var query: IOrderSearchRequest = {};


    this.ExportOptions = {
      Message: "",
      Types: [],
      ChangeErpStatusToExported: false,
      ChangeCollectStatusToCollected: false,
      ExportDataFile: false,
      CloseResult: "",
      PopupType: "Print"
    }

    if (this.Selector && this.Selector.SelectedItems.length) {
      if (!this.Selector.SelectAllItems) query.Ids = this.SelectedOrdersIds;
      else query.ExcludeIds = this.SelectedOrdersIds;
    } else {
      if (query.Ids) delete query.Ids;
      if (query.ExcludeIds) delete query.ExcludeIds;
    }

    query = $.extend(query, this.Query);

    if ((type) && (this._shipmentTypes[type])) {
      query.ShipmentType = [type];
      this.ExportOptions.Types = ["pdf"];
    }
    else if (type) {
      this.ExportOptions.Types = [type];
      if (type === "ERP") {
        if (query.ERPExportStatus && query.ERPExportStatus.length) {
          query.ERPExportStatus = query.ERPExportStatus.filter(es => es == OrderErpExportStatus.Ready || es == OrderErpExportStatus.Exportable);
          if (!query.ERPExportStatus.length) {
            this.ExportOptions.Message = "לא ניתן לייצא לפורמט ERP הזמנות שהם לא בסטטוס מוכן או העבר ל-ERP";

            // invalid query to generate zero result 
            query.NotOrderState = [OrderState.Active];
            query.OrderState = [OrderState.Active]
          }

        } else {
          query.ERPExportStatus = [OrderErpExportStatus.Ready, OrderErpExportStatus.Exportable];
        }
        this.ExportOptions.PopupType = "ERP";
      }
      else if (type == "Chita") {
        query.ShipmentType = ['ChitaShopsShipment', 'ChitaDeliveryShipment'];
      }
      else if (type == "Baldar") {
        query.ShipmentType = ['BaldarDeliveryShipment'];
      }
      else if (type == "LionWheel") {
        query.ShipmentType = ['LionWheelDeliveryShipment'];
      }
      else if (type == "SuperParmShipmentOrderTrackingReport") {
        query.OriginStore = [StoreType.SuperPharm];
        this.ExportOptions.PopupType = "ExportReport";
      }
      else if (type == "ZapShipmentOrderTrackingReport") {
        query.OriginStore = [StoreType.Zap];
        this.ExportOptions.PopupType = "ExportReport";
      }
      else if (type == "GrouponShipmentOrderTrackingReport") {
        query.OriginStore = [StoreType.Groupon];
        this.ExportOptions.PopupType = "ExportReport";
      }
      else if (type == "BehazdaaShipmentOrderTrackingReport") {
        query.OriginStore = [StoreType.Behazdaa];
        this.ExportOptions.PopupType = "ExportReport";
      }
      else if (type == "AllShipmentOrderTrackingReport") {
        query.OriginStore = [StoreType.Groupon, StoreType.SuperPharm, StoreType.Behazdaa];
        this.ExportOptions.Types = ["GrouponShipmentOrderTrackingReport", "SuperParmShipmentOrderTrackingReport", "BehazdaaShipmentOrderTrackingReport"];
        this.ExportOptions.PopupType = "ExportReport";
      }
      else if (type == "CollectPdf") {
        this.ExportOptions.PopupType = "CollectPdf";
      }
    }
    else {
      this.ExportOptions.Types = [];
    }

    query.PageSize = 0;

    this._orderService.SearchOrders(query).subscribe(r => {
      if (r.Result) {
        this.ExportOptions.SelectedOrdersCount = r.Result.TotalItemsCount;

        this.open(this.contentExportOrders, {}, null, () => {

          var options: IExportOptions = {
            FileTypes: [FileType.SchemaOriginDistributer]
          };

          if (this.ExportOptions.ChangeShipmentStatus) options.SetStatusOnSuccess = this.ExportOptions.ChangeShipmentStatus;
          if (this.ExportOptions.ChangeErpStatusToExported) options.SetErpStatusOnSuccess = OrderErpExportStatus.Exported;
          if (this.ExportOptions.ChangeCollectStatusToCollected) options.SetCollectStatusOnSuccess = OrderCollectStatus.Collected;

          if (this.ExportOptions.ExportDataFile) options.FileTypes.push(FileType.Operational);

          this._router.navigate(["/print-orders"], {

            queryParams: {
              returnUrl: this._location.path(true),
              returnDescription: "חזור ל" + (this.Title || "רשימת הזמנות"),
              query: JSON.stringify(query),
              options: JSON.stringify(options),
              types: this.ExportOptions.Types
            }
          });
        });
      }

    });
  }
  public open(content: ElementRef<any>, options: NgbModalOptions, onInit: () => void, onSave: { (): void; (): void; }) {
    if (onInit) onInit();
    this._modalService.open(content, options).result.then((result) => {
      if ((result === "save") && (onSave)) onSave();
      this.PrintOptions.CloseResult = `Closed with: ${result}`;
    }, (reason) => {
      this.PrintOptions.CloseResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  public onRunPrint = () => {

    var query: IOrderSearchRequest = this.GetSelectedOrdersQuery();
    if (!this.PrintOptions.ForceConvertType) query.ShipmentType = [this.PrintOptions.ShipmentType];

    this._router.navigate(["/print-orders", this.PrintOptions.ShipmentType], { queryParams: query });
  }

  public OrderWizard = () => {
    var searchQuery: IOrderSearchRequest = this.GetSelectedOrdersQuery();
    searchQuery.OrderState = [OrderState.Draft];
    this._router.navigate(['/orders-wizard'], { queryParams: JSON.parse(JSON.stringify(searchQuery)) });
  }

  public DeleteOrders = () => {
    var searchQuery: IOrderSearchRequest = this.GetSelectedOrdersQuery();
    this.orders = null;
    this._orderService.DeleteOrders(searchQuery, "Deleted from order list page").subscribe(r => {
      if (r.Code === 0) {
        this.reloadPage(true, true);
      }
    });
  }
  public ResetRecomamdedOrderItemsPrice = () => {

    var searchQuery: IOrderSearchRequest = this.GetSelectedOrdersQuery();
    this.orders = null;
    this._orderService.ResetOrderItemPriceToRecommendedPrice(
      searchQuery,
      this.Selector.SelectAllItems ? null : this.Selector.SelectedItems,
      this.Selector.SelectAllItems ? this.Selector.SelectedItems : null,
    ).subscribe(r => {
      if (r.Code === 0) {
        this.reloadPage(this.Query.FailedValidationsType && this.Query.FailedValidationsType.indexOf(300103) >= 0, this.Query.FailedValidationsType && this.Query.FailedValidationsType.indexOf(300103) >= 0);
      }
    });
  }

  public GetSelectedOrdersQuery = () => {
    var query: IOrderSearchRequest = $.extend(true, {}, this.Query);
    if (this.Selector && this.Selector.SelectedItems.length) {
      query[(this.Selector.SelectAllItems) ? "ExcludeIds" : "Ids"] = this.SelectedOrdersIds;
      query.PageSize = 1000;
      delete query.PageIndex;
    } else {
      delete query.ProjectionFields;
    }
    return query;
  }

  public get SelectedOrdersIds(): string[] {
    if (!this.Selector) return [];
    return this.Selector.SelectedItems
      .map(this.extractOrderFromOrderItemString)
      .filter((value, index, self) => self.indexOf(value) === index); // destinct 
  }

  private extractOrderFromOrderItemString(orderItem: string): string {
    return orderItem.substring(0, orderItem.indexOf("."));
  }

  public toggleSelectPage(): void {
    const shouldToggleOn = !this.isFullPageSelected;
    if (!this.orders) return;
    for (let i = 0; i < this.orders.Items.length; i++) {
      const order = this.orders.Items[i];
      for (let j = 0; j < order.Items.length; j++) {
        const orderItem = order.Items[j];
        if (this.Mode.SplitProducts && ((orderItem as any)?.Product) || (!this.Mode.SplitProducts && j == 0)) {
          this.Selector.SelectItem(order.Id + '.' + j, shouldToggleOn);
        }
      }
    }
  }

  public get isFullPageSelected(): boolean {
    if (!this.orders) return false;
    for (let i = 0; i < this.orders.Items.length; i++) {
      const order = this.orders.Items[i];
      for (let j = 0; j < order.Items.length; j++) {
        const orderItem = order.Items[j];
        if (this.Mode.SplitProducts && ((orderItem as any)?.Product) || (!this.Mode.SplitProducts && j == 0)) {
          if (!this.Selector.IsItemSelected(order.Id + '.' + j)) return false;
        }
      }
    }
    return true;
  }
}

export interface IOrderListMode {
  SplitProducts: boolean
  AllowPriceListOverride: boolean
}
interface FreeSearchOption { key: string, name: string };


