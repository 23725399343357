import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { filter, firstValueFrom, lastValueFrom, map } from "rxjs";
import { IProductCatalog, IProductCatalogBaseField } from "src/app/models/product.catalog";
import { IStoreDetails } from "src/app/models/stores";
import { ProductService } from "src/app/services/product.service";
import { StoreService } from "src/app/services/store.service";
import { Location } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from "src/app/deprecated-open-source-projects/angular-2-dropdown-multiselect/src";
import { DeepCopyObject, IsArrayEqual } from "src/app/helpers/common.helper";
import { Key } from "protractor";
import { Store } from "@ngrx/store";
import { CatalogStateFeature } from "src/app/state/catalog.reducer";
import { CatalogItemSaveError, CatalogItemSaveStart, CatalogItemSaveSuccess, CatalogLazyFetchAllStart } from "src/app/state/catalog.actions";
import { Actions, ofType } from "@ngrx/effects";
declare var $: any;
@Component({
  selector: 'app',
  templateUrl: './product-catalog.page.component.html',
  styleUrls: ['./product-catalog.page.component.css'],
})
export class ProductCatalogPageComponent implements OnInit, OnDestroy {
  closeResult: string;


  constructor(
    private _productService: ProductService,
    private store: Store,
    private actions$: Actions,
    private _route: ActivatedRoute,
    private _modalService: NgbModal,
    private _router: Router,
    private _location: Location,
    private _storeService: StoreService,
    public CdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder) { }



  ActiveStores: IStoreDetails[];

  //public Catalog: IProductCatalog;
  public CatalogForm: FormGroup;

  public StoreMultiSelectOptions: IMultiSelectOption[];

  public StoreMultiSelectTexts: IMultiSelectTexts = {
    checkAll: 'בחר הכול',
    uncheckAll: 'הסר הכול',
    checked: 'item selected',
    checkedPlural: 'חנויות ניבחרו',
    searchPlaceholder: 'Find',
    searchEmptyResult: 'Nothing found...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: 'בחר חנות',
    allSelected: 'כול החנויות',
  };

  public StoreMultiSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-outline-dark',
    dynamicTitleMaxItems: 3,
    displayAllSelectedText: true,
  }



  ngOnInit(): void {
    this.store.dispatch(CatalogLazyFetchAllStart());
    this._storeService.GetStoreList().subscribe(s => {
      this.ActiveStores = s.filter(s => !!s.Enabled);

      this.StoreMultiSelectOptions = [];
      for (let i = 0; i < this.ActiveStores.length; i++) {
        let store = this.ActiveStores[i];
        if (store.Enabled) {
          this.StoreMultiSelectOptions.push({
            id: store.Type,
            name: store.Description
          });
        }
      }


    });

    this._route.params.subscribe(async params => {
      let catalog: IProductCatalog;
      if (params['id'] != this.CatalogForm?.controls["id"]?.value.Id) catalog = await this.LoadProduct(params['id']);
      else catalog = this._productService.CreateNewProductCatalog();

      this.bindForm(catalog);
    });
  }

  public async LoadProduct(id: string): Promise<IProductCatalog> {
    /*
        const catalog = (await firstValueFrom(this._productService.SearchProductCatalog({
          Id: id
        }))).Result?.Items[0];
    */
    const catalog = await firstValueFrom(this.store.select(CatalogStateFeature.selectCatalog).pipe(
      filter(catalog => !!catalog), 
      map(catalog => DeepCopyObject(catalog.find(catalogItem => catalogItem.Id == id)))))

    if (catalog) window["Catalog"] = $.extend(true, {}, catalog);
    return catalog;
  }

  private bindForm(catalog: IProductCatalog) {
    this.CatalogForm = this.formBuilder.group({
      Id: [catalog?.Id ?? null],
      Stores: [catalog?.Stores ?? [], Validators.required],
      Code: [catalog?.Code ?? '', Validators.required],
      Name: [catalog?.Name ?? '', Validators.required],
      Fields: this.formBuilder.array(catalog?.Fields.map(this.generateCatalogFieldForm) ?? [])
    });

    const originalStore = catalog?.Stores.filter(() => true);
    this.CatalogForm.controls.Stores.valueChanges.subscribe(values => {
      // console.log(this.Catalog.Stores);
      if (IsArrayEqual(originalStore, values) && this.CatalogForm.controls.Stores.untouched) {
        this.StoreMultiSelectSettings.buttonClasses = 'btn btn-outline-dark';
      }
      else {
        this.StoreMultiSelectSettings.buttonClasses = 'btn';
        if (this.CatalogForm.controls.Stores.invalid) this.StoreMultiSelectSettings.buttonClasses += ' btn-outline-danger';
        else if (this.CatalogForm.controls.Stores.valid) this.StoreMultiSelectSettings.buttonClasses += ' btn-outline-success';
      }

      this.StoreMultiSelectSettings = $.extend({}, this.StoreMultiSelectSettings);
    });

  }

  public HtmlHelper = {
    getBootstrapInputValidationClass(formControl: FormControl): string {
      if (formControl.touched) {
        if (formControl.invalid) return 'is-invalid';
        if (formControl.valid) return 'is-valid'
      }

      return ''
    }
  }
  get CatalogFieldsForm() {
    return this.CatalogForm.controls["Fields"] as FormArray;
  }
  private generateCatalogFieldForm = (catalogField?: IProductCatalogBaseField) => {
    var formGroup = this.formBuilder.group({
      ItemType: [catalogField?.ItemType ?? 'ProductCatalogTextField', Validators.required],
      Name: [catalogField?.Name ?? '', Validators.required],
      Code: [catalogField?.Code ?? '', Validators.required],
    });

    this.SetCatalogType(formGroup, catalogField);

    formGroup.controls.ItemType.valueChanges.subscribe(value => {
      this.SetCatalogType(formGroup);
    });

    return formGroup;
  }
  public AddCatalogField() {
    this.CatalogFieldsForm.push(this.generateCatalogFieldForm());
  }

  public SetCatalogType(formGroup: FormGroup, catalogField?: IProductCatalogBaseField) {
    const extraFieldLists = ['ReferenceField', 'DefaultValue'];

    var ItemType = formGroup.controls['ItemType'].value;
    var ItemTypeFields: string[] = [];

    if (ItemType == 'ProductCatalogReferenceField') {
      ItemTypeFields = ['ReferenceField'];
    }

    // clean fields that are not suppose to be in the new Item type
    for (const field in formGroup.controls) {
      if (extraFieldLists.includes(field) && !ItemTypeFields.includes(field)) {
        formGroup.removeControl[field];
      }
    }

    ItemTypeFields.forEach(field => {
      if (!formGroup.controls[field]) {

        var control: FormControl;
        switch (field) {
          case 'ReferenceField':
            control = this.formBuilder.control((catalogField && catalogField[field]) ? catalogField[field] : '', Validators.required);
            break;
          default:
            break;
        }

        if (control) formGroup.addControl(field, control);
      }
    });



  }

  public DeleteCatalogField(index: number) {
    this.CatalogFieldsForm.removeAt(index);
  }
  public async SaveProductCatalog(catalog: IProductCatalog): Promise<boolean> {
    this.store.dispatch(CatalogItemSaveStart({ CatalogItem: catalog }));

    var result = await firstValueFrom(this.actions$.pipe(ofType(CatalogItemSaveSuccess, CatalogItemSaveError)));
    return result.type == CatalogItemSaveSuccess.type;

  }

  async onSubmit(): Promise<void> {
    if (await this.SaveProductCatalog(this.CatalogForm.value)) {
      this.Return();
    }
  }


  public Return() {
    this._location.back();
  }


  ImportCatalogWizardSave(catalog: IProductCatalog) {

    if (!catalog) return 'cancel'

    this.bindForm(catalog);

    return 'save';
  }

  public open(content, options, onInit, onSave) {
    if (onInit) onInit();
    this._modalService.open(content, options).result.then((result) => {
      if ((result === "save") && (onSave)) onSave();
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {

      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  ngOnDestroy(): void {
    delete window["Product"];
  }
}
