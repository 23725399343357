import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ILongRunningOperation, ILongRunningOperationItem, LongRunningOperationItemType, LongRunningOperationItemStatus } from '../../../models/common.models';
import { OperationService } from '../../../services/operation.service';


import { ProductService } from '../../../services/product.service';
@Component({
  templateUrl: './product-import.process.page.component.html',
  styleUrls: ['./product-import.process.page.component.css'],

})
export class ProductImportProcessPageComponent implements OnInit, OnDestroy {

  constructor(
    private _route: ActivatedRoute,
    private _opertionService: OperationService,
    private _productService: ProductService,
    private _router: Router) { }


  public Operation!: ILongRunningOperation;
  public CurrntStep: OrderImportStep = OrderImportStep.ServerProcessing;
  private timer: number = 0;
  ngOnInit(): void {

    this._route.params.subscribe(params => {
      // need to make rxjs timer
      this.loadOperation(params['SourceOperationID']);
    });
  }

  private loadOperation(SourceOperationID: string) {

    this._opertionService.GetOperation(SourceOperationID)
      .subscribe(r => {
        if (r.Result) {
          this.Operation = r.Result;
          if (r.Result.PresentComplete == 100) {
            this.validateOperation();
          }
          else {
            this.timer = setTimeout(() => { this.loadOperation(SourceOperationID); }, 500) as unknown as number;
          }
        }
      });
  }

  public FailedFiles!: ILongRunningOperationItem[];
  public FailedProducts!: number;
  public LoadedFiles!: number;
  public LoadedProducts!: number;
  public OverallStatus!: LongRunningOperationItemStatus;
  private validateOperation() {
    if (this.Operation.Items) {
    
      this.FailedFiles = this.Operation.Items.filter(i => i.Type == LongRunningOperationItemType.File && i.Status != LongRunningOperationItemStatus.Success);
      this.FailedProducts = this.Operation.Items.filter(i => i.Type == LongRunningOperationItemType.Product && i.Status != LongRunningOperationItemStatus.Success)
      .reduce((agg, item) => agg + (item.ObjectsCount || 0),0);

      this.LoadedFiles = this.Operation.Items.filter(i => i.Type == LongRunningOperationItemType.File && i.Status == LongRunningOperationItemStatus.Success).length;
      this.LoadedProducts = this.Operation.Items.filter(i => i.Type == LongRunningOperationItemType.Product && i.Status == LongRunningOperationItemStatus.Success)
      .reduce((agg, item) => agg + (item.ObjectsCount || 0),0);

      this.OverallStatus = Math.max.apply(null, this.Operation.Items.map(i => i.Status as number)) as LongRunningOperationItemStatus;
    }

    if (this.FailedFiles.length > 0) this.CurrntStep = OrderImportStep.FileMessages;
    else if (this.FailedProducts > 0) this.CurrntStep = OrderImportStep.SummeryResult;
    else this.CurrntStep = OrderImportStep.Complate;
  }
  public FailedFilesContinue() {
    if (this.FailedProducts > 0) this.CurrntStep = OrderImportStep.SummeryResult;
    else this.CurrntStep = OrderImportStep.Complate;
  }

  public FailedFilesBack() {
    this._router.navigate(['/import-product-upload']);
  }



  public Finish() {
    this._router.navigate(['/home']);
  }



  ngOnDestroy() {
    if (this.timer) clearTimeout(this.timer);

  }
}

enum OrderImportStep {
  ServerProcessing = 1,
  FileMessages = 2,
  SummeryResult = 3,
  Complate = 4
}

