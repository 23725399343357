import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { ISupplierConfiguration } from '../../../models/suppliers';
import { AdminService } from '../../../services/admin.service';
import { IStoreDetails, StoreType } from '../../../models/stores';
import { StoreService } from '../../../services/store.service';

import { filter, switchMap } from 'rxjs/operators';
import { IShipmentType, IShipmentTypes, UndefinedShipmentType } from '../../../models/shipments.models';
import { ShipmentService } from '../../../services/shipment.service';
import { zip } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigStateFeature } from 'src/app/state/config.reducer';




@Component({
  selector: 'app',
  templateUrl: './shipment.configuration.list.page.component.html',
  styleUrls: ['./shipment.configuration.list.page.component.css']
})
export class ShipmentConfigurationListPageComponent implements OnInit {
  private Configuration!: ISupplierConfiguration;

  constructor(
    private _adminService: AdminService,
    private _shipmentService: ShipmentService,
    private store: Store,
    private _router: Router) { }


  public ActiveShipmentTypes!: IShipmentType[];
  public InActiveShipmentTypes!: IShipmentType[];

  ngOnInit() {

    zip(
      this.store.select(ConfigStateFeature.selectConfiguration).pipe(filter(s => !!s)),
      this.store.select(ConfigStateFeature.selectShipmentTypes).pipe(filter(s => !!s))
    ).subscribe(([conf, shipmentTypes]) => {
      this.Configuration = conf;
      this.BuildUI(shipmentTypes)
    })
/*
    this._adminService.CurrentConfiguration
      .pipe(
        switchMap(conf => {
          if (conf) this.Configuration = conf;
          return this.store.select(ConfigStateFeature.selectShipmentTypes);
        })
      ).subscribe(shipmentTypes => this.BuildUI(shipmentTypes));
*/
    //  this._adminService.RefreshCurrentConfiguration().subscribe();
  }

  public BuildUI(shipmentsTypes: IShipmentTypes) {

    let shipmentsTypesFilter: IShipmentType[] =
      Object.keys(shipmentsTypes)
        .map(key => shipmentsTypes[key])
        .filter(t => !!t.Description);

    let IsActive = (ship: IShipmentType) => this.Configuration && this.Configuration.Shipments && this.Configuration.Shipments[ship.Id] && this.Configuration.Shipments[ship.Id].Enabled;

    this.ActiveShipmentTypes = shipmentsTypesFilter.filter(s => IsActive(s) && s.Id != UndefinedShipmentType);
    this.InActiveShipmentTypes = shipmentsTypesFilter.filter(s => !IsActive(s) && s.Id != UndefinedShipmentType);

  }
  public GotoShipment(ship: IShipmentType) {
    this._router.navigate(["/admin/shipment", ship.Id]);
  }
}
