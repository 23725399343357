import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';
import { ILoginUserSession } from '../models/users.models';
import { SessionStateFeature } from '../state/session.reducer';


@Injectable()
export class OnlyLoggedInUsersGuard implements CanActivate {
  private hasSession : boolean
  constructor(private router: Router,  private store: Store ) {
    
    this.store.select(SessionStateFeature.hasSession).subscribe(hasSession => {
     this.hasSession = hasSession;
    })
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.hasSession) {
      return true;
    } else {
      this.router.navigate(['/login'], {
        queryParams: {
          return: state.url 
        }
      });
      return false;
    }
  }
}