<h1>פרטי משתמש</h1>
<div class="container-fluid" *ngIf="this.User">
  <div class="row mt-2">
    <div class="col-3">
      <labeled-textbox type="text" title="שם משתמש"  [(model)]="User.UserName"></labeled-textbox>
    </div>
    <div class="col-1 mt-2">
      <span class="mr-2">פעיל</span>
      <checkbox [model]="!User.Disable" (modelChange)="User.Disable = !$event"></checkbox>
    </div>
  </div>
  <div class="row mt-2" *ngIf="!IsNewUser">
    <div class="col-4" >
      <span class="mr-2">אפס סיסמה</span>
      <checkbox [(model)]="ResetPassword"></checkbox>
    </div>
  </div>
  <div class="row mt-2" *ngIf="ResetPassword || IsNewUser">
    <div class="col-4">
      <labeled-textbox type="password" title="סיסמה ראשונית" [(model)]="FirstTimePassword"></labeled-textbox>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-4">
      
      <identity-editor [(Identity)]="User.UserIdentity" [Mode]="2" >

      </identity-editor>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-4">
      <div>פרופיל</div>
      <div *ngFor="let role of UserRoleTypes | keyvalue">
        <ng-container *ngIf="role.key != 'ERP_System'">
          <checkbox [model]="IsRoleChecked(role.key)" (modelChange)="CheckRole(role.key,$event)"></checkbox>
          <label class="ml-2">{{role.value}}</label>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div>
  <button type="button" tabindex="1000" class="btn btn-outline-success" (click)="SaveAndReturn();">
    <h4 class="d-inline-block mr-2">שמור</h4>
    <i class="fa fa-save"></i>
  </button>
  
  
  <button type="button" tabindex="1001" class="btn btn-outline-warning mx-4" (click)="Return();">
    <h4 class="d-inline-block mr-2">חזור</h4>
    <i class="fas fa-arrow-left"></i>
  </button>

   
  <button type="button" tabindex="1002" class="btn btn-outline-danger mx-4 float-left" (click)="open(contentDelete,null,null,DeleteAndReturn)">
    <h4 class="d-inline-block mr-2">מחק משתמש</h4>
    <i class="fas fa-trash-alt"></i>
  </button>

</div>



<ng-template #contentDelete let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">אישור מחיקה</h4>
    <button type="button" class="close" aria-label="Close" (click)=" c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    האם אתה בטוח שאתה רוצה למחוק את המשתמש {{User.UserName}} ?
  </div>
  <div class="modal-footer d-block">
    <button type="button" class="btn btn-outline-danger float-right" (click)="c('save')">אשר <i class="far " [ngClass]="{'fa-spinner fa-pulse' : IsOrderDeleting , 'fa-trash-alt' : !IsOrderDeleting}"></i></button>
    <button type="button" class="btn btn-outline-dark float-left" (click)="c('cancel')">בטל</button>
  </div>
</ng-template>