<div *ngIf="CurrntStep == 1" class="m-2">
  <p >
    <ngb-progressbar type="info" [striped]="true" [value]="Operation?.PresentComplete" height="50px" [striped]="true" [striped]="true" [animated]="true">
      <h2 class="position-absolute px-2">{{Operation?.Message}}</h2>
    </ngb-progressbar>
  </p> 
</div>


<div *ngIf="CurrntStep == 2">
  <div *ngIf="FailedFiles.length" class="my-3 ">
    <h2> הקבצים הבאים לא נטענו בהצלחה :</h2>
    <ul class="list-group">
      <li class="list-group-item list-group-item-danger" *ngFor="let file of FailedFiles; index as i">{{file.Message}}</li>
    </ul>
  </div>
  <div *ngIf="WarningFiles.length" class="my-3">
    <h2> הקבצים הבאים נטענו בהצלחה עם הערות:</h2>
    <ul class="list-group">
      <li class="list-group-item list-group-item-warning" *ngFor="let file of WarningFiles; index as i">{{file.Message}}</li>
    </ul>
  </div>
  <button class="float-right btn btn-secondary" (click)="FailedFilesContinue()"> המשך</button>
  <button class="float-left btn btn-danger" (click)="FailedFilesBack()"> חזור</button>
</div>

<div *ngIf="CurrntStep == 3">
  <h2>טעינה קבצים הושלמה</h2>
  <div class="mt-3 alert" [ngClass]="{'alert-success': OverallStatus ==  2, 'alert-warning': OverallStatus ==  3 , 'alert-danger': OverallStatus ==  4}" role="alert">
    <p>
      <i class="far fa-file mr-2"></i>{{FailedFiles.length + LoadedFiles}} קבצים מתוך {{LoadedFiles}} נטענו בהצלחה!
    </p>
    <p><i class="fas fa-box mr-2"></i>נטענו {{FailedOrders +  LoadedOrders}} הזמנות, מתוכן {{FailedOrders  }} דורשות השלמת פרטים.</p>
    <ng-container *ngTemplateOutlet="SkippedOrdersContent"> </ng-container>
  </div>

  <button class="float-right btn btn-success" (click)="Finish()"> צור הזמנות ועבור לרשימת ההזמנות החדשות שנטענו</button>
  <button *ngIf="OverallStatus > 2" class="float-left btn btn-primary" (click)="ShowWizard()"> צור הזמנות ועבור לאשף השלמת הפרטים</button>
</div>

<div *ngIf="CurrntStep == 4">
  <h2>טעינה קבצים הושלמה</h2>
  <div class="mt-3 alert alert-success" role="alert">
    <p>
      <i class="far fa-file mr-2"></i>{{FailedFiles.length + LoadedFiles}} קבצים מתוך {{LoadedFiles}} נטענו בהצלחה!
    </p>
    <p><i class="fas fa-box mr-2"></i>נטענו {{FailedOrders +  LoadedOrders}} הזמנות.</p>
    <ng-container *ngTemplateOutlet="SkippedOrdersContent"> </ng-container>
  </div>

  <button class="float-right btn btn-success" (click)="Finish()"> עבור לרשימת ההזמנות החדשות שנטענו</button>

</div>


<ng-template #SkippedOrdersContent>

  <div *ngIf="SkippedOrders && SkippedOrders.length" class="mt-2">
    <hr/>
    <p *ngFor="let SkippedOrder of SkippedOrders">
      <i class="fas fa-exclamation-triangle mr-2"></i>
      <ng-container *ngIf="SkippedOrder.Message == 'הזמנה קיימת' ;else skipedElse" >
        <a *ngIf="SkippedOrder.Message == 'הזמנה קיימת'" routerLink="/orders" [queryParams]="{'SupplierNumberIn':SkippedOrder.OrderNumbers}">{{SkippedOrder.OrderNumbers.length}} הזמנות</a>
      </ng-container>
      <ng-template #skipedElse><span>{{SkippedOrder.OrderNumbers.length}} הזמנות</span></ng-template>
      <span> דולגו בגלל ש{{SkippedOrder.Message}}</span>
    </p>

  </div>

</ng-template>


