<input type="text" #input [class]="inputClass" #ngbTypeahead=ngbTypeahead ngModel="text"  tabindex="{{tabindex}}" (ngModelChange)="onModelChange($event)"  [ngbTypeahead]="Search" [inputFormatter]="Format" [resultTemplate]="rt" (selectItem)="ItemSelected($event)">

<ng-template #rt let-r="result" let-t="term">
  <div class="border-bottom border-dark">
    <ngb-highlight *ngIf="this.Mode==1" [result]="r.SKU" [term]="t"></ngb-highlight>
    <div *ngIf="this.Mode!=1">{{r.SKU}}</div>
    <ngb-highlight *ngIf="this.Mode==2" [result]="r.Name" [term]="t"></ngb-highlight>
    <div *ngIf="this.Mode!=2">{{r.Name}}</div>
  </div>

  <!--  -->
</ng-template>