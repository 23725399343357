<div class="container-fluid mt-2">
  <div class="row RowEnd my-3">
    <h2 class="col-2">ערוצי מכירה</h2>
    <div class="col-10">
      זה המקום להגדיר את ערוצי המכירה איתם עובדים.<br />
      בנוסף, לכל ערוץ ניתן לקבוע כמה הגדרות פרטניות בנוגע לשיטת העבודה איתו ועם הנתונים הקשורים בו.
    </div>
  </div>
  <div class="row RowEnd border-bottom my-3" *ngIf="ActiveStores">
    <h2 class="col-12">ערוצי מכירה פעילים</h2>
    <div class="d-flex flex-wrap justify-content-start my-2">
      <div class="m-2 ActiveStore" *ngFor="let store of ActiveStores; index as i" (click)="GotoStore(store);">
        <img class="LogoImage" [src]="store.LogoURL" [alt]="store.Description" />
        <i class="fas fa-pencil-alt fa-2x  ActivePencilIcon"></i>
      </div>
      
    </div>
  </div>
  <div class="row my-3" *ngIf="InActiveStores">
    <h2 class="col-12">ערוצי מכירה נוספים</h2>
    <div class="d-flex flex-wrap justify-content-start my-2">
      <div *ngFor="let store of InActiveStores; index as i" class="m-2 InActiveStore" (click)="GotoStore(store);">

        <img class="LogoImage" [src]="store.LogoURL" [alt]="store.Description" />
        <i class="fas fa-plus fa-2x InActivePlusIcon"></i>
      </div>
      
    </div>
  </div>
</div>
