<div>
  <table class="table table-sm mb-0 RelationshipsTable" *ngIf="MasterRelationshipsIndexs && MasterRelationshipsIndexs.length">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col"><span>מק"ט</span></th>
        <th scope="col"><span>תיאור מוצר</span></th>
        <th scope="col"><span>מודל</span></th>
        <th scope="col"><span>יח'</span></th>
        <th scope="col" class="AliasColEnd text-center">אחד עם</th>
        <th scope="col" style="width:2rem"></th> <!-- Master Action-->
        <th scope="col"><span class="">מק"ט</span></th>
        <th scope="col"><span class="">תיאור קבוע</span></th>
        <!--  <th scope="col"><span class="">מודל</span></th>-->
        <th scope="col" style="width: 6rem;"><span>מחיר</span></th>
        <th scope="col" style="width: 3.5rem;"><span class="">יח'</span></th>
        <th scope="col" style="width: 6rem;" class="text-nowrap">סה"כ שורה</th>
        <!--      <th scope="col" *ngIf="IsStockEnabled"><span class="">מלאי</span></th>
      <th scope="col"><span class="">שיטת משלוח</span></th>-->
        <th scope="col" style="width: 2.75rem;"> </th>
      </tr>

    </thead>

    <tbody>
      <ng-container *ngFor="let Rel of RelationshipsTable; index as ri">
        <ng-container *ngFor="let master of Rel.Relationships.MasterMatches ; index as mi">
          <tr [ngClass]="{'bg-light': master.ItemType=='IgnoreMatch'}">
            <ng-container *ngIf="mi===0">
              <td [attr.rowspan]="(Rel.MasterRecord == Rel.RowNum ? Rel.Relationships.MasterMatches.length : 1) + 1" class="tdRownum">{{Rel.RowNum}}</td>
              <td [attr.rowspan]="(Rel.MasterRecord == Rel.RowNum ? Rel.Relationships.MasterMatches.length : 1) + 1" class="text-nowrap">
                {{(Rel.Alias.Product.SKU) ? Rel.Alias.Product.SKU : Rel.Alias.Product.UPC }}        
                <tr></tr>        
                <button *ngIf="Rel.Relationships.MasterMatches.length == 1" type="button" title="העתק" class="btn btn-link btn-sm" (click)="CopySKUAndName(Rel);">העתק <i class="fas fa-share fa-flip-horizontal"></i></button>
              </td>
              <td [attr.rowspan]="(Rel.MasterRecord == Rel.RowNum ? Rel.Relationships.MasterMatches.length : 1) + 1" style="max-width: 20rem;">{{Rel.Alias.Product.Name}}</td>
              <td [attr.rowspan]="(Rel.MasterRecord == Rel.RowNum ? Rel.Relationships.MasterMatches.length : 1) + 1">{{Rel.Alias.Product.Model}}</td>
              <td [attr.rowspan]="(Rel.MasterRecord == Rel.RowNum ? Rel.Relationships.MasterMatches.length : 1) + 1">{{Rel.Alias.Quantity}}</td>
              <td [attr.rowspan]="(Rel.MasterRecord == Rel.RowNum ? Rel.Relationships.MasterMatches.length : 1) + 1" class="AliasColEnd">
                <select class="form-control" [ngModel]="Rel.MasterRecord" (ngModelChange)="UnionAlias(Rel , $event)">
                  <option [ngValue]="Rel.RowNum">ללא</option>
                  <ng-container *ngFor="let rownum of MasterRelationshipsIndexs">
                    <option *ngIf="Rel.RowNum != rownum" [ngValue]="rownum">{{rownum}} #</option>
                  </ng-container>
                </select>
              </td>
            </ng-container>

            <ng-container *ngIf="Rel.MasterRecord == Rel.RowNum  ; else elseNoneMasterRecord">
              <td class="master_product_td" [ngClass]="{'tdAddionMaster' : mi > 0}">

                <div ngbDropdown class="d-inline-block">
                  <button class="btn btn-outline-dark btn-sm py-2" ngbDropdownToggle><i class="fas fa-bars"></i></button>
                  <div ngbDropdownMenu class="master_dropdownMenu">
                    <button ngbDropdownItem (click)="AddNewMasterProduct(Rel.Relationships,mi + 1)"><i class="fas fa-plus-circle text-success mr-1"></i>הוסף {{GetMasterName('ProductMatch')}}</button>
                    <button ngbDropdownItem (click)="AddNewMasterRemark(Rel.Relationships,mi + 1)"><i class="fas fa-plus-square text-success mr-1"></i>הוסף {{GetMasterName('RemarkMatch')}}</button>
                    <button ngbDropdownItem (click)="AddNewMasterShipMethod(Rel.Relationships,mi + 1)"><i class="fas fa-plus-square text-success mr-1"></i>הוסף {{GetMasterName('ShipMethodMatch')}}</button>

                    <button *ngIf="Rel.Relationships.MasterMatches.length > 1" ngbDropdownItem (click)="DeleteNewMaster(Rel.Relationships,mi)">
                      <i class="fas text-danger  mr-1" [ngClass]="{'fa-minus-circle' : master.ItemType=='ProductMatch' ,'fa-minus-square' : master.ItemType=='RemarkMatch' || master.ItemType=='ShipMethodMatch' }"></i>הורד {{GetMasterName(master.ItemType)}}
                    </button>
                    <div class="dropdown-divider"></div>
                    <button ngbDropdownItem (click)="ConvertToIgnoreMatch(Rel.Relationships,mi)"><i class="fas fa-eye-slash text-danger mr-1"></i>התעלם</button>
                    <button ngbDropdownItem *ngIf="master.ItemType!='RemarkMatch'" (click)="ConvertToRemarkMatch(Rel.Relationships,mi)"><i class="fas fa-sticky-note mr-1 text-primary"></i>שנה להערה בהזמנה</button>
                    <button ngbDropdownItem class="" *ngIf="master.ItemType!='ShipMethodMatch'" (click)="ConvertToShipMethodMatch(Rel.Relationships,mi)"><i class="fas fa-truck mr-1 text-primary" style="font-size: 0.80rem ;"></i>שנה שיטת משלוח בהזמנה</button>
                    <button ngbDropdownItem *ngIf="master.ItemType!='ProductMatch'" (click)="ConvertToProductMatch(Rel.Relationships,mi)"><i class="fas fa-dice-d6 mr-1 text-primary"></i>שנה למוצר</button>
                  </div>
                </div>

              </td>
              <ng-container *ngIf="master.ItemType=='ProductMatch'">
                <td class="master_product_td" [ngClass]="{'tdAddionMaster' : mi > 0}">
                  <product-autocomplete-select Mode="1" inputClass="form-control pl-1" [(text)]="master.Product.SKU" (textChange)="CheckValidation()" [model]="master.Product" (modelChange)="BindMasterProductToMatch(master,$event); CheckValidation()" [tabindex]="tabindex "></product-autocomplete-select>
                </td>
                <td class="master_product_td" [ngClass]="{'tdAddionMaster' : mi > 0}">
                  <product-autocomplete-select Mode="2" inputClass="form-control pl-1" [(text)]="master.Product.Name" (textChange)="SetMasterExtraDataDictionary(master.Product,{name : $event}); CheckValidation()" [model]="master.Product" (modelChange)="BindMasterProductToMatch(master,$event,true); CheckValidation()" [tabindex]="tabindex "></product-autocomplete-select>
                </td>
                <!--
                <td class="master_product_td master_attr" [ngClass]="{'tdAddionMaster' : mi > 0}"> מודל
                  <input type="text" class="w-100 form-control pl-1" [(ngModel)]="master.Product.Model" (ngModelChange)="CheckValidation()" [tabindex]="tabindex " />
                </td>
                -->
                <td class="master_product_td " [ngClass]="{'tdAddionMaster' : mi > 0}">
                  <div *ngIf="IsPriceListOverride && GetMasterExtraDataById(master.Product.Id)" class="d-flex justify-content-between ">
                    <div>
                      {{GetMasterExtraDataById(master.Product.Id).priceList | number:'1.2-2'}}
                    </div>
                    <div>
                      <i class="fas fa-cube text-success ml-2" style="font-size: 0.75rem;" title="מחיר מחירון"></i>
                    </div>
                  </div>

                  <div *ngIf="!(IsPriceListOverride && GetMasterExtraDataById(master.Product.Id)) && mi==0" class="d-flex justify-content-between ">
                    <div>
                      {{(GetMainMasterProductRefTotalPrice(Rel.Relationships) / master.Quantity) | number:'1.2-2'}}
                    </div>
                    <div class="mr-2 pr-1">
                      <i class="far fa-file text-danger ml-2" style="font-size: 0.75rem;" title="מחיר מקובץ"></i>
                    </div>
                  </div>
                  <input *ngIf="!(IsPriceListOverride && GetMasterExtraDataById(master.Product.Id)) && mi>0" type="number" class="form-control master_price  pl-1" [ngModel]="master.ItemPrice + master.ItemVATPrice" (ngModelChange)="SetMasterItemPrice(master,$event)" [tabindex]="tabindex " />
                </td>


                <td class="master_product_td master_qty" [ngClass]="{'tdAddionMaster' : mi > 0}">
                  <input type="number" class="form-control  pl-1" [(ngModel)]="master.Quantity" (ngModelChange)="CheckValidation()" [tabindex]="tabindex " />
                </td>

                <td class="master_product_td " [ngClass]="{'tdAddionMaster' : mi > 0}">
                  <div *ngIf="IsPriceListOverride && GetMasterExtraDataById(master.Product.Id)" class="d-flex justify-content-between ">
                    {{GetMasterExtraDataById(master.Product.Id).priceList * master.Quantity | number:'1.2-2'}}
                  </div>
                  <div *ngIf="!(IsPriceListOverride && GetMasterExtraDataById(master.Product.Id) )&& mi==0">
                    {{GetMainMasterProductRefTotalPrice(Rel.Relationships) | number:'1.2-2'}}
                  </div>
                  <div *ngIf="!(IsPriceListOverride &&  GetMasterExtraDataById(master.Product.Id)) && mi>0">
                    {{(master.ItemPrice + master.ItemVATPrice) * master.Quantity | number:'1.2-2'}}
                  </div>

                </td>
                <td>
                  <div ngbDropdown placement="bottom-start" dropdownClass="product-details" class="d-inline-block">
                    <button class="btn btn-outline-dark btn-sm p-2 expand-master-product-details" ngbDropdownToggle><i class="fas fa-plus mx-1"></i></button>
                    <div ngbDropdownMenu aria-labelledby="dropdownProductDetails" class="container px-2" style="width: 50rem; background-color: rgb(247 247 247);">
                      <div class="row">
                        <div class="col-2 font-weight-bold">
                          ברקוד
                        </div>
                        <div class="col-4">
                          <input type="text" class="w-100 form-control pl-1" [(ngModel)]="master.Product.UPC" (ngModelChange)="CheckValidation()" [tabindex]="tabindex " />
                        </div>
                        <div class="col-2 font-weight-bold">
                          מודל
                        </div>
                        <div class="col-4">
                          <input type="text" class="w-100 form-control pl-1" [(ngModel)]="master.Product.Model" (ngModelChange)="CheckValidation()" [tabindex]="tabindex " />
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-2 font-weight-bold">
                          שיטת משלוח
                        </div>
                        <div class="col-4">
                          <shipment-type-selector *ngIf="master.Product" [model]="(GetMasterExtraDataDictionary(master.Product)?.defaultShipmentMethod)" (modelChange)="master.Product.DefaultShipmentMethod = $event ; SetMasterExtraDataDictionary(master.Product, {defaultShipmentMethod: $event}); RaiseOnRelationshipsChange(); CheckValidation()" [tabindex]="tabindex" [Mode]="2" [ShowSelfDistributers]="false" [AllowUndefinedShipment]="true"></shipment-type-selector>
                        </div>

                        <div class="col-2 font-weight-bold">
                          סוג חבילה
                        </div>
                        <div class="col-4">
                        
                          <select class="form-control" [ngModel]="GetMasterExtraDataDictionary(master.Product)?.defaultShipmentPackageSize" (ngModelChange)="master.Product.DefaultShipmentPackageSize = $event ; SetMasterExtraDataDictionary(master.Product, {defaultShipmentPackageSize: $event}); RaiseOnRelationshipsChange(); CheckValidation()">
                            <option *ngFor="let size of DeliveryPackageSizes" [value]="size.id">{{size.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-2 font-weight-bold">
                          <span>מחירון</span>
                          <span class="badge badge-info ml-1">{{StoreDetails.Description}}</span>

                        </div>
                        <div class="col-4">
                          <input type="number" class="form-control  pl-1" [ngModel]="(GetMasterExtraDataDictionary(master.Product)?.priceList) ?? 0" (ngModelChange)="SetMasterExtraDataDictionary(master.Product, {priceList: $event})">
                        </div>

                        <div class="col-2 font-weight-bold">
                          מלאי
                        </div>
                        <div class="col-4">
                          <input type="number" class="form-control  pl-1" [ngModel]="(GetMasterExtraDataDictionary(master.Product)?.stockAmount) ?? 0" (ngModelChange)="SetMasterExtraDataDictionary(master.Product, {stockAmount: $event})">
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>
              <ng-container *ngIf="master.ItemType=='RemarkMatch'">
                <td colspan="7" [ngClass]="{'tdAddionMaster' : mi > 0}">
                  <span>תוספת הערות : </span>
                  <input type="text" class=" form-control w-100  pl-1" [(ngModel)]="master.Remark" (ngModelChange)="RaiseOnRelationshipsChange()" [tabindex]="tabindex " />
                </td>
              </ng-container>
              <ng-container *ngIf="master.ItemType=='ShipMethodMatch'">
                <td colspan="4" [ngClass]="{'tdAddionMaster' : mi > 0}">
                  <span>סוג משלוח </span>
                </td>
                <td>
                  <shipment-type-selector [(model)]="master.ShipMethod" (modelChange)="RaiseOnRelationshipsChange(); CheckValidation()" [tabindex]="tabindex" [Mode]="2" [ShowSelfDistributers]="false" [AllowUndefinedShipment]="true"></shipment-type-selector>
                </td>
              </ng-container>
              <ng-container *ngIf="master.ItemType=='IgnoreMatch'">
                <td colspan="5">

                </td>
              </ng-container>
            </ng-container>

            <ng-template #elseNoneMasterRecord>
              <td colspan="6" class="master_product_td"></td>
            </ng-template>
          </tr>

        </ng-container>
        <tr>
          <td colspan="6" class="master_product_td border-0 pt-0 pb-2" style="font-size: 0.9rem">
            <div *ngIf="Rel.MasterRecord == Rel.RowNum">
              <checkbox [model]="!(Rel.Relationships.OrderBind)" (modelChange)="SetOrderBind(Rel.Relationships,$event)"></checkbox>
              <span class="font-weight-light ml-1">החל בחירה זו על כול ההזמנות עם הערך הזה</span>
            </div>
          </td>
        </tr>
      </ng-container>

    </tbody>

  </table>
</div>