import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";


import { switchMap, map, first, filter } from 'rxjs/operators';
import { Observable, BehaviorSubject, firstValueFrom } from 'rxjs';
import { OrderService, IOrderSearchRequest as IOrderSearchRequest } from '../../../services/order.service';
import { IOrder, OrderState, IProductOrderItem, IShipmentOrderItem } from '../../../models/orders.models';

declare var $: any;
import { FileService } from '../../../services/file.service';
import { IExternalFile } from '../../../models/files.models';
import { IValidationResult, SeverityLevelType } from '../../../models/validation.models';
import { IProductRef, IMasterRemarkMatchResult, IAliasBasicProduct, IMasterBasicOrderProduct, IMasterAliasProductRelationship, IMasterMatchResult, IMasterProductMatchResult, IMasterShipMethodMatchResult, DeliveryPackageSize } from '../../../models/products.models';

import { MasterAliasProductBinderUserControlComponent } from '../../usercontrols/masteraliasproductbinder/masteraliasproductbinder.usercontrol.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { IShipmentTypes, IUndefinedShipment, UndefinedShipmentType } from '../../../models/shipments.models';


import { UserControlEditorMode } from '../../../models/user-controls';

import { ShipmentService } from '../../../services/shipment.service';
import { AdminService } from '../../../services/admin.service';
import { StoreService } from '../../../services/store.service';
import { ISupplierConfiguration, ShipmentMethodFindStrategyType } from 'src/app/models/suppliers';
import { StoreType } from 'src/app/models/stores';
import { ShipmentEditorUserControlComponent } from '../../usercontrols/shipment/shipment.editor.usercontrol.component';
import { ShipmentTypeSelectorUserControlComponent } from '../../usercontrols/shipment/shipment.type.selector.usercontrol.component';
import { FormControlStatus } from '@angular/forms';
import { ConfigStateFeature, WaitForConfigSave } from 'src/app/state/config.reducer';
import { Store } from '@ngrx/store';
import { ConfigAddShippingMethodDefault } from 'src/app/state/config.actions';
import { Actions } from '@ngrx/effects';
import { EnumDescriptionPipe } from 'src/app/pipes/enumdescription.pipe';


@Component({
  templateUrl: './order-wizard.page.component.html',
  styleUrls: ['./order-wizard.page.component.css'],

})
export class OrderWizardPageComponent implements OnInit, OnDestroy {
  constructor(
    private _route: ActivatedRoute,
    private _orderService: OrderService,
    private _shipmentService: ShipmentService,
    private _adminService: AdminService,
    private _fileService: FileService,
    private _storeService: StoreService,
    private _router: Router,
    private _modalService: NgbModal,
    private store: Store,
    private actions$: Actions,
    public cd: ChangeDetectorRef) { }


  public Order!: IOrder | null;
  public IsOrderLoading: boolean = true;
  public IsOrderSaving: boolean = false;
  public FileSource!: IExternalFile | null;
  public ItemsCountLeft!: number;
  public ValidationItemGroups!: {
    [key: number]: {
      State?: FormControlStatus,
      OrderValidation: IValidationResult[]
    }
  };
  public SupplierConfig!: ISupplierConfiguration;
  public ShipmentEditorMode: UserControlEditorMode = UserControlEditorMode.View;
  public ShipmentMethodEditorMode: UserControlEditorMode = UserControlEditorMode.View;
  public ShopperEditorMode: UserControlEditorMode = UserControlEditorMode.View;
  public Query!: IOrderSearchRequest;
  private getOrdersSeverityLevelTypeFrom = SeverityLevelType.Warning;

  @ViewChild('MasterAliasProductBinder', { static: false })
  private masterAliasProductBinder!: MasterAliasProductBinderUserControlComponent;

  @ViewChild('ShipmentEditor', { static: false })
  public ShipmentEditor!: ShipmentEditorUserControlComponent;


  @ViewChild('ShipmentTypeSelector', { static: false })
  public ShipmentTypeEditor!: ShipmentTypeSelectorUserControlComponent;

  private _shipmentTypes!: IShipmentTypes;
  private originalShipMethod !: string;
  private EnumDescriptionConverter = new EnumDescriptionPipe();
  public UndefineShipmentTypeOrderValue: string | null;
  public AddUndefineDefaultOrderValue: boolean = true;
  public AddUndefineDefaultOrderValueIncludePrice: boolean = false;
  private ReturnUrl: string | null = null;
  private ReturnDescription: string | null = null;
  public AvailableDeliveryPackageSizes = this.EnumDescriptionConverter.listOfValues("DeliveryPackageSize");
  ngOnInit(): void {
    this.store.select(ConfigStateFeature.selectShipmentTypes).pipe(filter(s => !!s)).subscribe(s => this._shipmentTypes = s);
    this.store.select(ConfigStateFeature.selectConfiguration).pipe(filter(s => !!s)).subscribe(conf => {
      this.SupplierConfig = conf;
    }
    );
    this._route
      .queryParams
      .subscribe(params => {
        let paramsEditable = $.extend(true, {}, params);
        if (paramsEditable.returnUrl) this.ReturnUrl = paramsEditable.returnUrl;
        delete paramsEditable.returnUrl;

        if (paramsEditable.returnDescription) this.ReturnDescription = paramsEditable.returnDescription;
        delete paramsEditable.returnDescription;

        // Defaults to 0 if no query param provided.
        if (paramsEditable.ShipmentStatus) {
          for (var i = 0; i < paramsEditable.ShipmentStatus.length; i++) paramsEditable.ShipmentStatus[i] = Number(paramsEditable.ShipmentStatus[i]);
        }
        this.Query = $.extend(true, {}, paramsEditable);

        if ((this.Query.OrderByFields) && (!Array.isArray(this.Query.OrderByFields))) this.Query.OrderByFields = [this.Query.OrderByFields];
        if ((this.Query.OrderState) && (!Array.isArray(this.Query.OrderState))) this.Query.OrderState = [this.Query.OrderState];
        this.Query.PageSize = 1;
        this.Query.SeverityFrom = this.getOrdersSeverityLevelTypeFrom;
        this.Query.ProjectionFields = ["Id"];
        this.loadNextOrder();
      });
  }
  private loadNextOrder() {
    this.IsOrderLoading = true;
    this.IsOrderSaving = false;
    var previousOrder = (this.Order);
    this._orderService.RevalidateOrders(this.Query)
      .pipe<IOrder | null>(
        map((r) => {
          if (r.Result) {
            this.ItemsCountLeft = r.Result.TotalItemsCount;
            if (r.Result.Items.length > 0) {
              return r.Result.Items[0] as IOrder;
            }
          }
          return null;
        })
      )
      .subscribe(r => {
        this.Order = r;
        if (this.Order && this.IsOrderValid(this.Order)) {
          this.loadNextOrder();
          return;
        }
        if (this.Order && this.IsOrderValidWithWarning(this.Order) && this.Order.Id == previousOrder?.Id) {

          if (!this.HasNewFaildValidationItems(this.Order.FailedValidations, previousOrder.FailedValidations)) {
            this.excludeOrderAndLoadNextOrder(this.Order.Id);
            return;
          }
        }
        if (this.Order) {
          this.resetControllers();
          this.ProcessValidationGroup();

          if (this.Order.Shipments.length && this.Order.Shipments[0].ItemType == UndefinedShipmentType) this.UndefineShipmentTypeOrderValue = (this.Order.Shipments[0] as IUndefinedShipment).SourceTypeValue;
          else this.UndefineShipmentTypeOrderValue = null;

          this._fileService.SearchFiles({ Id: this.Order.Source.DataSource, PageSize: 1 }).subscribe(r2 => {
            if (r2 && r2.Result && r2.Result.Items.length) {
              this.FileSource = r2.Result.Items[0];
            }
            this.IsOrderLoading = false;
          });
        }
        else {
          this.IsOrderLoading = false;
        }
      });
  }


  private excludeOrderAndLoadNextOrder(exludeOrderId: string) {
    // if the order is not fully valid (the order has warning validation) 
    // and the order it fixed (the order dosent have high faild validation) 
    // and there is no new failed validation 
    // then we will want to skip this order.
    if (this.Query.ExcludeIds) this.Query.ExcludeIds.push(exludeOrderId);
    else this.Query.ExcludeIds = [exludeOrderId];
    this.loadNextOrder();
  }
  public resetControllers() {
    this.ShipmentEditorMode = UserControlEditorMode.View;
    this.ShipmentMethodEditorMode = UserControlEditorMode.View;
    this.ShopperEditorMode = UserControlEditorMode.View;
    this.originalShipMethod = this.Order.Shipments[0].ItemType;
  }
  public ProcessValidationGroup() {
    this.ValidationItemGroups = {};
    if (this.Order) {
      for (var i = 0; i < this.Order.FailedValidations.length; i++) {
        let group: number = Math.floor(this.Order.FailedValidations[i].Code / 100);
        if (!this.ValidationItemGroups[group]) {
          this.ValidationItemGroups[group] =
          {
            State: 'INVALID',
            OrderValidation: this.Order.FailedValidations.filter(ov => Math.floor(ov.Code / 100) == group)
          };
        }
      }

      if (this.ValidationItemGroups[5001]) { // split group shipment validation code to method and shipment (duo to ui changes)
        this.SplitShipmentMethodValidationGroup();
      }
      if (this.ValidationItemGroups[5002]) {
        this.CurrentOrderProdutRef = this.BuildCurrentOrderProdutRef();
        this.ValidationItemGroups[5002].State = 'PENDING'; // the user control will then try to Ajax match there for we are in Checking mode.

      }
      else this.CurrentOrderProdutRef = null;


      this.FillMissingValidationGroup();
      this.SetValidationGroupEditMode();
    }

    // this.sortValidationItems();
  }

  get ProductItems(): IProductOrderItem[] | null {
    if (this.Order) return this.Order.Items.filter(item => item.ItemType === "Product") as IProductOrderItem[];
    else return null;
  }

  get ShipmentItems(): IShipmentOrderItem[] | null {
    if (this.Order) return this.Order.Items.filter(item => item.ItemType === "Shipment") as IShipmentOrderItem[];
    else return null;
  }

  public GetValidationItemGroup(group: number): {
    State?: FormControlStatus,
    OrderValidation: IValidationResult[]
  } {
    return this.ValidationItemGroups[group];
  }

  public SplitShipmentMethodValidationGroup() {
    var shipmentMethodValidation = this.ValidationItemGroups[5001].OrderValidation.filter(ov => ov.Code == 500101);
    if (shipmentMethodValidation.length) {
      this.ValidationItemGroups[5001.5] = {
        State: 'INVALID',
        OrderValidation: shipmentMethodValidation
      }
      this.ValidationItemGroups[5001].OrderValidation = this.ValidationItemGroups[5001].OrderValidation.filter(ov => ov.Code != 500101);
      if (!this.ValidationItemGroups[5001].OrderValidation.length) delete this.ValidationItemGroups[5001];
    }
  }

  public FillMissingValidationGroup() {
    var groups = [5000, 5001, 5001.5, 5002];
    for (var i = 0; i < groups.length; i++) {
      if (!this.ValidationItemGroups[groups[i]]) {
        var message: string;
        switch (groups[i]) {
          case 5000: message = "מספר הזמנה";
            break;
          case 5001: message = "משלוח";
            break;
          case 5001.5: message = "שיטת משלוח";
            break;
          case 5002: message = "מוצרים בהזמנה";
            break;
          default: message = "";
        }

        this.ValidationItemGroups[groups[i]] = {
          State: null,
          OrderValidation: [{ Code: groups[i] * 100, Message: message, Severity: SeverityLevelType.Info }]
        }
      }
    }
  }

  public async ChangeShipmentType(NewShipType: string, updateOriginal: boolean, packageSize: DeliveryPackageSize = DeliveryPackageSize.Undefined) {
    if (packageSize && this.Order.Shipments[0]["Address"]) this.Order.Shipments[0]["PackageSize"] = packageSize;
    
    if (NewShipType != this.Order.Shipments[0].ItemType) {
      this.ShipmentEditorMode = UserControlEditorMode.Edit;
      if (updateOriginal) this.originalShipMethod = NewShipType;
      await this._shipmentService.ChangeShipmentType(this.SupplierConfig, this.Order, this.Order.Shipments[0].ItemType, NewShipType);
      this.cd.detectChanges();
      this.ShipmentEditor.RefreshControls();
    }
  }

  public SetValidationGroupEditMode() {
    if (this.ValidationItemGroups[5001].State) /* update angular*/     this.ShipmentEditorMode = UserControlEditorMode.Edit;

    if (this.ValidationItemGroups[5001.5].State) /* update angular */    this.ShipmentMethodEditorMode = UserControlEditorMode.Edit;
  }


  public async SaveOrder() {
    if (!this.Order || !this.IsOrderFixed()) return;
    this.IsOrderSaving = true;
    if (this.Order.State == OrderState.Draft) this.Order.State = OrderState.Active;
    // var process: Observable<IOrder | null> = new BehaviorSubject(this.Order);
    if (this.CurrentOrderProdutRef && this.CurrentOrderProdutRef.length > 0) {
      await this.masterAliasProductBinder.Save();
    }
    if (this.UndefineShipmentTypeOrderValue && this.AddUndefineDefaultOrderValue && this.Order.Shipments[0].ItemType != UndefinedShipmentType) {
      var storeDetails = await this._storeService.GetStoreDetails(this.Order.Source.OriginStore).toPromise();
      if (storeDetails && this.Order && this.UndefineShipmentTypeOrderValue) {
        await WaitForConfigSave(this.actions$, () => {
          this.store.dispatch(ConfigAddShippingMethodDefault({
            storeId: storeDetails.IdName,
            shipmentTypeId: this.Order.Shipments[0].ItemType,
            defaultValue: this.UndefineShipmentTypeOrderValue,
            defaultPrice: (this.AddUndefineDefaultOrderValueIncludePrice && this.ShipmentItems.length) ? (this.ShipmentItems[0].ItemPrice + this.ShipmentItems[0].ItemVATPrice) : null
          }));
        })
      }
    }
    var result = await this._orderService.SaveOrder(this.Order, true).toPromise();
    this.IsOrderSaving = false;

    if (result.Code == 0) {
      if (!this.IsOrderValid(result.Result) && !this.HasNewFaildValidationItems(this.Order.FailedValidations.filter(i => i.Severity <= this.getOrdersSeverityLevelTypeFrom), result.Result.FailedValidations.filter(i => i.Severity <= this.getOrdersSeverityLevelTypeFrom))) {
        this.excludeOrderAndLoadNextOrder(result.Result.Id);
        return;
      }
      this.loadNextOrder();
    }
  }

  private IsOrderValid(order: IOrder): boolean {
    var validation = order.FailedValidations.filter(i => i.Severity >= this.getOrdersSeverityLevelTypeFrom);
    return (!validation.length);
  }

  private IsOrderValidWithWarning(order: IOrder): boolean {
    var validation = order.FailedValidations.filter(i => i.Severity > this.getOrdersSeverityLevelTypeFrom);
    return (!validation.length);
  }
  private HasNewFaildValidationItems(OriginFailedValidations: IValidationResult[], NewFailedValidations: IValidationResult[]) {

    if ((!NewFailedValidations) || !NewFailedValidations.length) return false; // empty list
    if (!OriginFailedValidations) return true;

    for (let index = 0; index < NewFailedValidations.length; index++) {
      const newFailedValidation = NewFailedValidations[index];
      if (OriginFailedValidations.filter(v => v.Code == newFailedValidation.Code && v.SubMessage == newFailedValidation.SubMessage).length == 0) return true;
    }
    return false;


  }

  public UpdateValidStateChange(ValidationItemGroupKey: number, validationResult: FormControlStatus) {
    this.ValidationItemGroups[ValidationItemGroupKey].State = validationResult;
    this.cd.detectChanges();
  }
  public IsOrderFixed(): boolean {

    if (!this.ValidationItemGroups) return false;
    // console.log(this.ValidationItemGroups);
    for (var group in this.ValidationItemGroups) {
      if (Number(group) >= (SeverityLevelType.High * 1000)
        && (this.ValidationItemGroups[group].State)        //        && this.ValidationItemGroups[group].State !=  FormControlStatus.UnChanged // upgrade angular version
        && this.ValidationItemGroups[group].State != 'VALID'

      ) return false;
    }
    return true;
  }

  public NavigateToOrdersList(): void {

    delete this.Query.SeverityFrom;
    delete this.Query.PageSize;
    delete this.Query.OrderState;
    delete this.Query.ExcludeIds;
    delete this.Query.ProjectionFields;
    this._router.navigate(['/orders'], { queryParams: this.Query });
  }

  public NavigateToReturnUrl(): void {
    this._router.navigateByUrl(this.ReturnUrl);
  }

  public CurrentOrderProdutRef: IProductRef<IAliasBasicProduct | IMasterBasicOrderProduct>[] | null = null;

  public BuildCurrentOrderProdutRef(): IProductRef<IAliasBasicProduct | IMasterBasicOrderProduct>[] {
    var result: IProductRef<IAliasBasicProduct | IMasterBasicOrderProduct>[];
    if (this.Order) result = this.Order.Items
      .filter(i => i.ItemType == "Product")
      .map(i => {
        return {
          Product: (i as IProductOrderItem).Product,
          Quantity: i.Quantity,
          ItemPrice: (i as IProductOrderItem).ItemPrice,
          ItemVATPrice: (i as IProductOrderItem).ItemVATPrice,
        }
      });
    else result = [];

    return result;
  }

  public OnRelationshipsChange(ProductRelationship: IMasterAliasProductRelationship[]) {
    const masters = ProductRelationship
      .reduce((arr: IMasterMatchResult[], rel: IMasterAliasProductRelationship) => arr.concat(rel.MasterMatches), []);// get all masters

    let winingShipmentType: { method: string, PackageSize: DeliveryPackageSize } = null;

    const shipmentItems = masters.filter(m => m.ItemType == "ShipMethodMatch");
    if (shipmentItems.length) {
      const methods = shipmentItems
        .map(m => (m as IMasterShipMethodMatchResult)?.ShipMethod)
        .filter((value, index, self) => value && value != UndefinedShipmentType && self.indexOf(value) === index);
      if (methods.length === 1) winingShipmentType = { method : methods[0], PackageSize: DeliveryPackageSize.Undefined };
    }

    if (!winingShipmentType) {
      const products = masters.filter(m => m.ItemType == "ProductMatch");
      if (products.length) {
        const storeConfig = this.SupplierConfig?.Stores[StoreType[this.Order.Source.OriginStore]];
        if (storeConfig?.ImportConfiguration?.ShipmentStrategies && storeConfig.ImportConfiguration.ShipmentStrategies[0] == ShipmentMethodFindStrategyType.MasterProduct) {
          const methods = products
            .map(m => ({method :  (m as IMasterProductMatchResult)?.Product?.DefaultShipmentMethod, PackageSize: (m as IMasterProductMatchResult)?.Product?.DefaultShipmentPackageSize}))
            .filter((value, index, self) => value && value.method != UndefinedShipmentType && self.indexOf(value) === index);
          if (methods.length === 1) winingShipmentType = methods[0];
        }
      }
    }


    if (winingShipmentType && winingShipmentType.method != UndefinedShipmentType) {
      this.UndefineShipmentTypeOrderValue = null;
      this.ChangeShipmentType(winingShipmentType.method, false, winingShipmentType.PackageSize);

    } else {
      this.UndefineShipmentTypeOrderValue = (this.Order.Shipments[0] as IUndefinedShipment).SourceTypeValue;
      this.ChangeShipmentType(this.originalShipMethod, false);
    }


  }


  public DeleteOrder = () => {
    if ((this.Order)) {
      this.IsOrderLoading = true;
      this._orderService.DeleteOrders(
        {
          Id: this.Order.Id || "000000000000000000000000"
        }, 'Deleted by user in import wizard process').subscribe(
          r => {
            this.loadNextOrder();
          });
    }
  }

  public DeleteAllOrders = () => {
    if (this.Order) {
      this.IsOrderLoading = true;
      this._orderService.DeleteOrders(this.Query, 'Deleted by user in import wizard process').subscribe(
        r => {
          this.loadNextOrder();
        });
    }
  }
  public SkipOrder = () => {
    if (this.Order) {
      if (this.Query.ExcludeIds) this.Query.ExcludeIds.push(this.Order.Id as string);
      else this.Query.ExcludeIds = [this.Order.Id as string];
      this.loadNextOrder();
    }
  }

  public SkipAllOrders = () => {
    if (this.Order) {
      this.ItemsCountLeft = 0;
      this.Order = null;
    }
  }


  public HTMLHelper = {



    GetValidationCardHeaderClass: (group: number) => {

      if (!this.ValidationItemGroups[group]) return "";
      else if (this.ValidationItemGroups[group].State == 'INVALID') return "bg-danger text-white";
      else if (this.ValidationItemGroups[group].State == 'VALID') return "bg-success text-white";

      return "";
    },

    GetOrderCombineRemarks: (order: IOrder) => {
      var remarks = "";
      if (order.Remarks) remarks += order.Remarks;
      if (this.CurrentOrderProdutRef && this.masterAliasProductBinder) {
        // adding the matchs remarks to the view only.
        for (let i = 0; i < this.masterAliasProductBinder.Relationships.length; i++) {
          if (!this.masterAliasProductBinder.Relationships[i].MasterMatches) continue;

          for (let j = 0; j < this.masterAliasProductBinder.Relationships[i].MasterMatches.length; j++) {
            if (this.masterAliasProductBinder.Relationships[i].MasterMatches[j].ItemType == "RemarkMatch") {
              remarks += "\n" + (this.masterAliasProductBinder.Relationships[i].MasterMatches[j] as IMasterRemarkMatchResult).Remark;
            }
          }

        }
      }
      if (order.Shipments && order.Shipments[0] && order.Shipments[0].Remarks) remarks += "\n" + order.Shipments[0].Remarks;
      remarks += "\n" + order.Items
        .filter(i => (i as IProductOrderItem).Product && (i as IProductOrderItem).Product.Note)
        .map(i => (i as IProductOrderItem).Product.Note)
        .join("\n");


      return remarks.trim();


    }
  }

  public open(content, options, onInit, onSave) {
    if (onInit) onInit();
    this._modalService.open(content, options || {}).result.then((result) => {
      if ((result === "save") && (onSave)) onSave();
      //   this.closeResult = `Closed with: ${result}`;
    }, (reason) => {

      //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnDestroy() {

  }
}


