import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { StatisticsUpdateDraftOrderCountStart, StatisticsUpdateDraftOrderCountSuccess, StatisticsUpdateERPErrorOrderCountStart, StatisticsUpdateERPErrorOrderCountSuccess, StatisticsUpdateInvalidShipmentOrderCountStart, StatisticsUpdateInvalidShipmentOrderCountSuccess, StatisticsUpdateOutOfStockOrderCountStart, StatisticsUpdateOutOfStockOrderCountSuccess } from "./statistics.actions";
import { exhaustMap, filter, interval, map, merge, of, switchMap, take, tap } from "rxjs";
import { OrderService } from "../services/order.service";
import { IOrder, OrderErpExportStatus, OrderState } from "../models/orders.models";
import { IApiResult } from "../models/api.model";
import { IPageResult } from "../models/common.models";
import { SessionStateFeature } from "./session.reducer";
import { ILoginUserSession } from "../models/users.models";
import { Store } from "@ngrx/store";
import { ShipmentState } from "../models/shipments.models";
import { UserSessionLoginSuccess } from "./session.actions";



@Injectable()
export class StatisticsEffects {


    constructor(
        private actions$: Actions, private _orderService: OrderService, private store: Store
    ) {


    }


    draftOrderCountUpdater$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StatisticsUpdateDraftOrderCountStart),
            exhaustMap(action => this._orderService.SearchOrders({ OrderState: [OrderState.Draft], PageSize: 0 })),
            map((result) => {
                if (result.Code == 0) {
                    return StatisticsUpdateDraftOrderCountSuccess({ draftOrderCount: result.Result.TotalItemsCount });
                }
                return StatisticsUpdateDraftOrderCountSuccess({ draftOrderCount: 0 });
            }))
    )


    erpErrorOrderCountUpdater$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StatisticsUpdateDraftOrderCountStart),
            exhaustMap(action => this._orderService.SearchOrders({ ERPExportStatus: [OrderErpExportStatus.ExportError], PageSize: 0 })),
            map((result) => {
                if (result.Code == 0) {
                    return StatisticsUpdateERPErrorOrderCountSuccess({ erpErrorOrderCount: result.Result.TotalItemsCount });
                }
                return StatisticsUpdateERPErrorOrderCountSuccess({ erpErrorOrderCount: 0 });
            })
        )
    )


    invalidShipmentOrderCountUpdater$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StatisticsUpdateDraftOrderCountStart),
            exhaustMap(action => this._orderService.SearchOrders({ ShipmentState: [ShipmentState.IgnoreValidation], PageSize: 0 })),
            map((result) => {
                if (result.Code == 0) {
                    return StatisticsUpdateInvalidShipmentOrderCountSuccess({ invalidShipmentOrderCount: result.Result.TotalItemsCount });
                }
                return StatisticsUpdateInvalidShipmentOrderCountSuccess({ invalidShipmentOrderCount: 0 });
            })
        )
    )


    outOfStockOrderCountUpdater$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StatisticsUpdateDraftOrderCountStart),
            exhaustMap(action => this._orderService.SearchOrders({ OrderState: [OrderState.OutOfStock], PageSize: 0 })),
            map((result) => {
                if (result.Code == 0) {
                    return StatisticsUpdateOutOfStockOrderCountSuccess({ outOfStockOrderCount: result.Result.TotalItemsCount });
                }
                return StatisticsUpdateOutOfStockOrderCountSuccess({ outOfStockOrderCount: 0 });
            })
        )
    )


    statisticsRefresh$ = createEffect(() => {
        var timer = interval(1000 * 60);
        return merge(
            timer,
            this.actions$.pipe(
                ofType(UserSessionLoginSuccess)
                )
        ).pipe(
            exhaustMap(() => this.store.select(SessionStateFeature.hasSession).pipe(take(1))),
            filter((hasSession) => {
                return hasSession;
            }),
            switchMap(() => of(
                StatisticsUpdateDraftOrderCountStart(),
                StatisticsUpdateERPErrorOrderCountStart(),
                StatisticsUpdateOutOfStockOrderCountStart(),
                StatisticsUpdateInvalidShipmentOrderCountStart(),
            ))
        );
    })



}