import { Component, EventEmitter, Output, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
declare var $: any;
import { ShipmentService } from '../../../services/shipment.service';
import { NgbModal, ModalDismissReasons, NgbTypeaheadSelectItemEvent, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IDropPoint } from '../../../models/shipments.models';
import { IAddress } from '../../../models/common.models';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'droppoint-shipment-selector',
  templateUrl: './droppoint.shipment.selector.usercontrol.component.html',
  styleUrls: ['./droppoint.shipment.selector.usercontrol.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropPointShipmentSelectorUserControlComponent implements OnInit, OnChanges {
  constructor(
    private _shipmentService: ShipmentService,
    private _modalService: NgbModal,
    private cd: ChangeDetectorRef) { }

  closeResult: string = "";

  @Input() validationControl!: AbstractControl;
  @Input() DropPointType !: string
  @Input() DropPoint: IDropPoint;
  @Output() DropPointChange: EventEmitter<IDropPoint> = new EventEmitter<IDropPoint>();
  @Input() Address: IAddress;
  @Input() DisplayMode: DisplayMode = DisplayMode.Default;

  public IsInFindByAddressProcessingMode = false;
  public NearestPointButtonMessage: string | null = null
  public AvailableDropPoints: IDropPoint[];

  ngOnInit(): void {
    this._shipmentService.GetDropPoints(this.DropPointType).subscribe(result => {
      if (result.Result) {
        this.AvailableDropPoints = result.Result.Items.filter(dp => (dp?.GeoCoordinate?.Latitude && dp?.GeoCoordinate?.Longitude));
        this.cd.detectChanges();
      }
    });

    if (this.validationControl) {
      this.DropPointChange.subscribe(droppoint => {
        this.validationControl.setValue(droppoint);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.Address) this.NearestPointButtonMessage = null;
  }

  SelectNearestPoint() {
    if (this.IsInFindByAddressProcessingMode) return;
    this.IsInFindByAddressProcessingMode = true;
    this._shipmentService.GetNearestDropPoints(this.DropPointType, this.Address.City, this.Address.Street).subscribe(results => {
      if (results.Code == 0 && results.Result.Items.length) {
        this.Select(results.Result.Items[0]);
      }
      else {
        this.NearestPointButtonMessage = "לא נמצא נקודה עבור כתובת נמען"
      }
      this.IsInFindByAddressProcessingMode = false;
      this.cd.detectChanges();
    });
  }

  public IsDisplayMode(mode: DisplayMode): boolean {
    return (!!(this.DisplayMode & mode));
  }
  public HtmlHelper = {
    modal: null as NgbModalRef | null,
  };

  public Select(dropPoint: IDropPoint) {
    if (this.HtmlHelper.modal) {
      this.HtmlHelper.modal.dismiss();
    }
    this.DropPoint = dropPoint;
    this.DropPointChange.emit(this.DropPoint);
    this.cd.detectChanges();

  }

  public open(content, options, onInit, onSave) {
    if (onInit) onInit();
    this.HtmlHelper.modal = this._modalService.open(content, options);
    this.HtmlHelper.modal.result.then((result) => {
      if ((result === "save") && (onSave)) onSave();
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}

export enum DisplayMode {
  ShowAutoSelectButton = 1 << 0,
  ShowMapSelectButton = 1 << 1,
  ShowTextButtons = 1 << 2,
  Default = ShowAutoSelectButton | ShowMapSelectButton | ShowTextButtons
}