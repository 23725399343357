import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ILongRunningOperation, ILongRunningOperationItem, LongRunningOperationItemType, LongRunningOperationItemStatus } from '../../../models/common.models';
import { OperationService } from '../../../services/operation.service';
declare var $: any;

import { Location } from '@angular/common';
import { FileService } from '../../../services/file.service';
import { IExternalFile, FileType } from '../../../models/files.models';

import { EnumDescriptionPipe } from '../../../pipes/enumdescription.pipe';

import { Subject, Observable } from 'rxjs';

import { IProductSearchRequest, ProductService } from 'src/app/services/product.service';
import { AdminService } from 'src/app/services/admin.service';
@Component({
  templateUrl: './product-export.process.page.component.html',
  styleUrls: ['./product-export.process.page.component.css'],

})
export class ProductExportProcessPageComponent implements OnInit, OnDestroy {
  constructor(
    private _route: ActivatedRoute,
    private _operationService: OperationService,
    private _productService: ProductService,
    private _adminService: AdminService,
    private _fileService: FileService,
    private _router: Router,
    private _location: Location) { }


  public WorkItems: WorkItem[] = [];
  private EnumDescriptionConverter = new EnumDescriptionPipe();
  private timers: { [key: string]: number } = {};
  private query!: IProductSearchRequest;
  private options!: IExportOptions;
  private types!: string[];
  private isDlg: boolean;
  public StatusDescription: string = "";

  public IsDraftOrdersInPlace: boolean = false;
  public ClientPostProcessed?: boolean
  public ReturnUrl!: string;
  public ReturnDescription!: string;

  private onAllWorkItemFinished: Subject<WorkItem>;


  ngOnInit(): void {
    this.StatusDescription = "מאתחל נתונים אנא המתן...";
    this._route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.ReturnUrl = params.returnUrl;
        this.ReturnDescription = params.returnDescription;
        this.query = JSON.parse(params.query) as IProductSearchRequest;
        this.options = JSON.parse(params.options) as IExportOptions;
        this.types = ((Array.isArray(params.types)) ? params.types : [params.types]);
        this.isDlg = (!!params.isdlg as any);
        this.loadPage();
      });
  }

  private loadPage() {

    this.startPrintProcessPage();
  }
  private startPrintProcessPage() {
    let wiCount = 0;
    for (var typeIndex = 0; typeIndex < this.options.FileTypes.length; typeIndex++) {
      var fileType = this.options.FileTypes[typeIndex] as FileType;
      if (fileType & FileType.Export) {
        wiCount++;
        if (this.types) {
          for (var i = 0; i < this.types.length; i++) {
            ((type) => {
              if (type.toLowerCase().startsWith("storeprice-")) {
                var store = type.substring("storeprice-".length);
                this._adminService.PrintStorePriceList(store, this.query)
                  .subscribe(r => {
                    if (r.Result) {
                      this.createWorkItem(r.Result, this.getWorkItemTitle(type));
                    }
                  });
              }else {
                this._productService.PrintProducts(type, this.query)
                .subscribe(r => {
                  if (r.Result) {
                    this.createWorkItem(r.Result, this.getWorkItemTitle(type));
                  }
                });
              }
            })(this.types[i]);
          }
        }
      }
      this.SetOnAllWorkItemFinishedLogic();
      this.StatusDescription = `מאתחל ${wiCount} קבצים, אנא המתן...`;
    }
  }
  private SetOnAllWorkItemFinishedLogic() {
    this.onAllWorkItemFinished = new Subject<WorkItem>();
    var finalObservable: Observable<WorkItem> = this.onAllWorkItemFinished;

    finalObservable.subscribe(wi => {
      this.StatusDescription = `הפעולה הסתיימה בהצלחה`;
      this.ClientPostProcessed = true;
    });
  }

  private getWorkItemTitle(fileType: string) {
    return "קובץ מוצרים" ;
  }

  private createWorkItem(operation: ILongRunningOperation, title: string) {
    var wi: WorkItem = {
      Operation: operation,
      Step: OrderExportStep.ServerProcessing,
      Title: title

    };
    this.WorkItems.push(wi);
    this.loadOperation(wi);
    this.refreshProcessStatusMessge();
  }
  private loadOperation(wi: WorkItem) {
    if (wi.Operation && wi.Operation.Id) {

      this._operationService.GetOperation(wi.Operation.Id)
        .subscribe(r => {
          if (r.Result) {
            wi.Operation = r.Result;
            if (r.Result.PresentComplete == 100) {
              this.timers[wi.Operation.Id as string] = 0;
              this.validateWorkItem(wi);

            }
            else {
              this.timers[wi.Operation.Id as string] = setTimeout(() => { this.loadOperation(wi); }, 500) as unknown as number;
            }
          }
        });
    }

  }

  public IsDownloadable(fileType: FileType) {
    return (fileType & FileType.Export) > 0;
  }

  private validateWorkItem(wi: WorkItem) {
    if (wi.Operation.Items) {
      var files = wi.Operation.Items.filter(i => i.Type == LongRunningOperationItemType.File && i.Status == LongRunningOperationItemStatus.Success);
      if (files.length > 0) {
        this._fileService.SearchFiles({ Id: files[0].RefId }).subscribe(r => {
          if (r.Result && r.Result.TotalItemsCount > 0) {
            wi.File = r.Result.Items[0];
          }
          wi.Step = OrderExportStep.Complate;

          if (!this.WorkItems.filter(wi => wi.Step != OrderExportStep.Complate).length) this.onAllWorkItemFinished.next(wi); // when all files finished 100 percent 
        });
      }
      var filesFailure = wi.Operation.Items.filter(i => i.Type == LongRunningOperationItemType.File && i.Status == LongRunningOperationItemStatus.Failure);
      if (filesFailure.length > 0) {
        wi.Step = OrderExportStep.Failure;
        wi.Title = "בעייה ביצירת הקובץ";
        this.ClientPostProcessed = true;
      }
      var filesFailure = wi.Operation.Items.filter(i => i.Type == LongRunningOperationItemType.File && i.Status == LongRunningOperationItemStatus.SubmitFailure);
      if (filesFailure.length > 0) {
        wi.Step = OrderExportStep.SubmitFailure;
        wi.Title = "בעייה בשידור הקובץ";
        this.ClientPostProcessed = true;
      }
    }
  }


  private refreshProcessStatusMessge() {
    let finishCount = this.WorkItems.filter(wi => wi.Step == OrderExportStep.Complate).length;
    if (!finishCount) this.StatusDescription = `מייצר ${this.WorkItems.length} קבצים לייצוא, אנא המתן...`;
    else this.StatusDescription = `${finishCount} קבצים מוכנים מתוך ${this.WorkItems.length}, אנא המתן...`;
  }



  public Finish() {
    if (this.ReturnUrl) {
      if (this.ReturnUrl == "closewindow") window.close();
      else this._router.navigateByUrl(this.ReturnUrl);
    }
    else {
      this._location.back();
    }
  }

  ngOnDestroy(): void {
    for (var id in this.timers) {
      if (this.timers[id]) clearTimeout(this.timers[id]);
    }
  }
}

enum OrderExportStep {
  ServerProcessing = 1,
  FatchingResult = 2,
  Complate = 4,
  Failure = 8,
  SubmitFailure = 16,
}

interface WorkItem {
  Operation: ILongRunningOperation,
  Step: OrderExportStep,
  Title: string,
  File?: IExternalFile
};
export interface IExportOptions {
  FileTypes: FileType[]
}
