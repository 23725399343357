import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";

import { exhaustMap, map, take, tap } from "rxjs";
import { CatalogFetchAllStart, CatalogFetchAllSuccess, CatalogItemDeleteStart, CatalogItemDeleteSuccess, CatalogItemSaveStart, CatalogItemSaveSuccess, CatalogLazyFetchAllStart } from "./catalog.actions";
import { ProductService } from "../services/product.service";



@Injectable()
export class CatalogEffects {
    constructor(
        private actions$: Actions,
        private _productService: ProductService,
        private store: Store
    ) {
        // this.actions$.subscribe(console.log)
    }

    fetchAllLazy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CatalogLazyFetchAllStart),
            take(1),
            map(() => CatalogFetchAllStart())
        )
    )

    fetchAllCatalog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CatalogFetchAllStart, CatalogItemSaveSuccess, CatalogItemDeleteSuccess),
            exhaustMap(action => this._productService.SearchProductCatalog({ PageSize: 100 })),
            map(result => CatalogFetchAllSuccess({ Catalog: result.Result.Items }))
        )
    )

    saveCatalogItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CatalogItemSaveStart),
            exhaustMap(action => this._productService.SaveProductCatalog(action.CatalogItem)),
            // tap(console.log),
            map(result => CatalogItemSaveSuccess({ CatalogItem: result.Result }))
        )
    )

    deleteCatalogItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CatalogItemDeleteStart),
            exhaustMap(action => this._productService.DeleteProductCatalog(action.DeleteQuery, action.Reason)),
            //tap(console.log),
            map(result => CatalogItemDeleteSuccess()
            )
        )
    )

}


