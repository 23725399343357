<form class="form-import">
  <input type="file" style="display:none" multiple (change)="UploadFilesInput( $event.target.files);" placeholder="Upload file" #FileInput>


  <div class="center m-2 drop-zone" (mouseenter)="Showpaperclip = true" (mouseleave)="Showpaperclip = false" style="height: 150px;">
    <ngx-file-drop
      dropZoneClassName="text-center rounded border border-dark w-100 h-100 align-self-center"
      contentClassName="h-100 text-info d-flex flex-column justify-content-center"
      (onFileDrop)="Dropped($event)" (onFileOver)="FileOver($event)" (onFileLeave)="FileLeave($event)">

      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div class="" (click)="openFileSelector()">
          <h2 *ngIf="!Files.length" >
            לחץ לבחירת קבצים או גרור קבצים לכאן
          </h2>
          <div class="rounded border border-dark m-2 d-inline-block text-right filewapper" (click)="$event.stopPropagation()" (mouseenter)="Showpaperclip = false" (mouseleave)="Showpaperclip = true" *ngFor="let file of Files; index as i">
            <div class="position-absolute"><i class="far fa-times-circle  m-1 fa-1x cancel-file" (click)="RemoveFile(file); $event.stopPropagation()"></i></div>
            <div class="text-center">
              <i *ngIf="!file.upload" class="fa fa-spinner fa-pulse fa-3x fa-fw m-2"></i>
              <i *ngIf="file.upload" class="far fa-3x m-2" [ngClass]="GetFileTypeFontAwesomeIcon(file)"></i>
              <div class="m-1">
                {{file.file.name}}
              </div>
            </div>
          </div>
        </div>
      </ng-template>

    </ngx-file-drop>

    <div class="paperclip" [hidden]="!Showpaperclip">
      <i class="fas fa-paperclip fa-3x"></i>
    </div>
  </div>

  <button type="submit" [disabled]="ReadyForImportValidationMessage" class="btn btn-primary btn-block" (click)='ImpotFiles()'>
    <span *ngIf="!ReadyForImportValidationMessage;else else_continue_invalid_content">
      המשך <i class="fas fa-download"></i>
    </span>
    <ng-template #else_continue_invalid_content>{{ReadyForImportValidationMessage}}</ng-template>
  </button>
</form>

<div class="d-flex flex-wrap justify-content-center mt-4" *ngIf="Stores">

  <img class="LogoImage mx-2" *ngFor="let store of Stores; index as i" [src]="store.LogoURL" [alt]="store.Description" />


</div>