import { Component, EventEmitter, Output, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { IProduct } from '../../../models/products.models';
import { ProductService, IProductSearchRequest } from '../../../services/product.service';
import { IOrderItem, IProductOrderItem } from '../../../models/orders.models';
import { OrderService } from '../../../services/order.service';
import { flatMap, debounceTime, distinctUntilChanged, map, switchMap, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Element } from '@angular/compiler';

@Component({
  selector: 'product-autocomplete-select',
  templateUrl: './productautocomplete.textbox.usercontrol.component.html',
  styleUrls: ['./productautocomplete.textbox.usercontrol.component.css']
})
export class ProductAutocompleteTextboxUserControlComponent implements OnInit, OnChanges {


  constructor(private _productService: ProductService, private _orderService: OrderService) { }

  @ViewChild('input', { static: true }) input!: ElementRef;
  @ViewChild('ngbTypeahead', { static: true }) ngbTypeahead!: any
  @Input()
  public Mode!: ProductSearchMode;

  @Input()
  public model!: IProduct;

  @Input()
  public tabindex: number = 0;

  @Output()
  public modelChange = new EventEmitter<IProduct>();

  @Input()
  public text!: string;

  @Input()
  public inputClass!: string;

  @Output()
  public textChange = new EventEmitter<string>();

  public SearchResults: IProduct[] | null = null;


  ngOnInit(): void {

    this.Mode = 1 * this.Mode;
    this.refreshProduct();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["model"] && changes["model"].currentValue) {
      this.refreshProduct();
    }
  }

  ngAfterViewInit() {
    var temp = this.ngbTypeahead._writeInputValue;
    this.ngbTypeahead._writeInputValue = (value) => {
      if (value === undefined) return;
      else temp(value);
    }
    this.input.nativeElement.value = this.text;
  }


  private refreshProduct() {
    if (!this.model) return;

    switch (this.Mode) {
      case ProductSearchMode.ByName:
        this.input.nativeElement.value = this.model.Name;

        this.textChange.emit(this.model.Name);
        break;
      case ProductSearchMode.BySKU:
        this.input.nativeElement.value = this.model.SKU;

        this.textChange.emit(this.model.SKU);
        break;
    }
  }

  public Search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((text => {
        var query: IProductSearchRequest = {
          Type: "MasterProduct"
        }
        text = text.trim();
        switch (this.Mode) {
          case ProductSearchMode.ByName:
            query.Name_Contains = text
            query.OrderByFields = ["StrLength.Name"];
            break;
          case ProductSearchMode.BySKU:
            query.SKU_Contains = text
            query.OrderByFields = ["StrLength.SKU"];
            break;
        }

        return this._productService.SearchProducts(query);

      })),
      filter(r => r.Code == 0),
      map(productsResult => {
        var result = productsResult.Result.Items

        return result;
      }
      ));

  public Format(value: IProduct) {
    switch (this.Mode) {
      case ProductSearchMode.ByName:
        return value.Name;
      case ProductSearchMode.BySKU:
        return value.SKU;
    }
  }

  public ItemSelected(item: NgbTypeaheadSelectItemEvent) {
    this.model = item.item;
    this.refreshProduct();
    this.modelChange.emit(item.item);
  }

  public onModelChange(value: any) {

    if (typeof value === 'string' || value instanceof String) {
      this.textChange.emit(value as string);
      if (this.model?.Id) this.modelChange.emit({ ...this.model, Id: null }); //this.model.Id = null; // reset select
    }

  }
}


export enum ProductSearchMode {
  BySKU = 1,
  ByName = 2
}
