import { Pipe, PipeTransform } from '@angular/core';
import { FileType } from '../models/files.models';
import { OrderState, OrderSource, OrderErpExportStatus, OrderCollectStatus } from '../models/orders.models';
import { ShipmentStatus, ShipmentState } from '../models/shipments.models';
declare var $: any;
import { StoreType } from '../models/stores';
import { AliasProductMatchingStrategyType, ERPDBType, ShippingPriceStrategyType, ShipmentMethodFindStrategyType, ERPType, MultiplyShipmentItemsStrategyType, TableReadOptions } from '../models/suppliers';
import { ShipmentService } from '../services/shipment.service';
import { StoreService } from '../services/store.service';
import { Store } from '@ngrx/store';
import { ConfigStateFeature } from '../state/config.reducer';
import { filter, firstValueFrom, lastValueFrom, map } from 'rxjs';
import { DeliveryPackageSize, ProductItemOrderSerialNumberControlType } from '../models/products.models';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/

function convertTypeStringToEnum(enumType: string): any {
  switch (enumType) {
    case "OrderState": return OrderState;
    case "ShipmentStatus": return ShipmentStatus;
    case "ShipmentState": return ShipmentState;
    case "OrderErpExportStatus": return OrderErpExportStatus;
    case "OrderSource": return OrderSource;
    case "ERPDBType": return ERPDBType;
    case "ERPType": return ERPType;
    case "ShipmentMethodFindStrategyType": return ShipmentMethodFindStrategyType;
    case "AliasProductMatchingStrategyType": return AliasProductMatchingStrategyType;
    case "ShippingPriceStrategyType": return ShippingPriceStrategyType;
    case "MultiplyShipmentItemsStrategyType": return MultiplyShipmentItemsStrategyType;
    case "OrderCollectStatus": return OrderCollectStatus;
    case "FileType": return FileType;
    case "TableReadOptions": return TableReadOptions;
    case "ProductItemOrderSerialNumberControlType": return ProductItemOrderSerialNumberControlType;
    case "DeliveryPackageSize": return DeliveryPackageSize;
    default: return null;
  }
}


function getEnumItemDescription(enumObj: any, id: any): string {
  if ($.isNumeric(id)) return enumObj[enumObj[id].toString() + "Description"];
  else return enumObj[id + "Description"];
}




@Pipe({ name: 'enumDescription' })
export class EnumDescriptionPipe implements PipeTransform {
  transform(id: number | string, enumString: any): string {
    var e = convertTypeStringToEnum(enumString);
    return getEnumItemDescription(e, id);
  }

  public listOfValues(enumString: string): { id: number, name: string }[] {
    var result = [] as { id: number, name: string }[];
    for (const state in convertTypeStringToEnum(enumString)) {
      if (Number(state)) {
        result.push({
          id: +state,
          name: this.transform(+state, enumString)
        });
      }
    }
    return result;
  }
}



@Pipe({ name: 'storeDescriptionAsync' })
export class EnumStoreDescriptionPipe implements PipeTransform {
  constructor(private _storeService: StoreService) { }
  transform(id: StoreType): Promise<string> {
    return this._storeService.GetStoreDetails(id).toPromise().then(store => store.Description);
  }
}


@Pipe({ name: 'shipmentDescriptionAsync' })
export class EnumShipmentDescriptionPipe implements PipeTransform {
  constructor(private store: Store) { }
  transform(itemType: string): Promise<string> {
    return firstValueFrom(this.store.select(ConfigStateFeature.selectShipmentTypes).pipe(filter(s => !!s), map(list => list[itemType].Description)));
    //return this.store.select(ConfigStateFeature.selectShipmentTypes).toPromise().then(list => list[itemType].Description);
  }
}




@Pipe({ name: 'enumList' })
export class EnumListPipe implements PipeTransform {

  transform(enumString: string): { id: number, name: string }[] {
    var e = convertTypeStringToEnum(enumString);
    var result = [] as { id: number, name: string }[];
    for (const value in e) {
      if ($.isNumeric(value)) {
        result.push({
          id: +value,
          name: getEnumItemDescription(e, value)
        });
      }
    }
    return result;
  }
}

