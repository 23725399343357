import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

declare var $: any;

import { IPageResult } from '../../../models/common.models';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


import { IProduct } from '../../../models';
import { SelectableList } from '../../../models/SelectableList';
import { ProductService, IProductSearchRequest } from 'src/app/services/product.service';
import { IExportOptions } from '../product-export/product-export.process.page.component';
import { FileType } from 'src/app/models/files.models';
import { Location } from '@angular/common';
import { StoreService } from 'src/app/services/store.service';
import { IStoreDetails, StoreType } from 'src/app/models/stores';
import { IMasterProduct, getMasterProductStoreValue } from 'src/app/models/products.models';
import { filter, first, firstValueFrom } from 'rxjs';
import { ISupplierConfiguration } from 'src/app/models/suppliers';
import { AdminService } from 'src/app/services/admin.service';
import { Store } from '@ngrx/store';
import { ConfigStateFeature } from 'src/app/state/config.reducer';



@Component({
  selector: 'app',
  templateUrl: './product-list.page.component.html',
  styleUrls: ['./product-list.page.component.css']
})


export class ProductListPageComponent implements OnInit {
  constructor(private _route: ActivatedRoute,
    private _router: Router,
    private _location: Location,
    private _productService: ProductService,
    private _storeService: StoreService,
    private _adminService: AdminService,
    private store: Store,
    private _modalService: NgbModal) { }

  public Query: IProductSearchRequest = { Type: "MasterProduct" };
  public DefaultQuery: IProductSearchRequest = {
    PageIndex: 0,
    PageSize: 20,
    OrderByFields: ["SKU"]
  }

  public products!: IPageResult<IProduct>;
  private maintainView: boolean = false;
  public SelectedProducts: SelectableList<string> | null = null;
  public FreeTextSearch: string;
  public IsProductSaving: boolean = false;
  public IncludeAllProductTypes: boolean = false;
  public ActiveStores!: IStoreDetails[];
  public exportProductOptions: { selector: "all" | "activeOnly" | "disabledOnly", type: string } = {
    selector: "all",
    type: "default"
  };

  public get SelectedStoreView(): StoreType | null {
    return this.Query.StoreContext ?? null;
  }

  public get SelectedStoreDetails(): IStoreDetails | null {

    if (!this.SelectedStoreView) return null;
    var store = this.ActiveStores?.find(s => s.Type == this.SelectedStoreView);
    return store;
  }

  private _configuration: ISupplierConfiguration;
  public set SelectedStoreView(value: StoreType | null) {
    if (value?.toString()?.toLowerCase() == "null") delete this.Query.StoreContext;
    else this.Query.StoreContext = value;
  }

  public ProductFileFormats: { name: string, exportType: string }[]

  ngOnInit(): void {
    this.store.select(ConfigStateFeature.selectConfiguration).pipe(filter(s => !!s)).subscribe(c => {
      this._configuration = c;
      if (!this._configuration?.ProductSetting?.ImportProductFormats) this.ProductFileFormats = [];
      else this.ProductFileFormats = this._configuration.ProductSetting.ImportProductFormats.map(format => ({ name: format.Name, exportType: `product_import_format:${format.Id}` }));
    });

    this._storeService.GetStoreList().subscribe(s => this.ActiveStores = s.filter(s => !!s.Enabled));
    this._route
      .queryParams
      .subscribe(params => {
        this.IncludeAllProductTypes = !!params.IncludeAllProductTypes;
        var query = $.extend(true, {}, params);
        // Defaults to 0 if no query param provided.
        delete query.IncludeAllProductTypes;

        this.loadPage(query);

        if (this.maintainView) this.maintainView = false;
        else {
          //    this.SelectedProducts = null;
        }
      });
    //this.SelectedStoreView = null;
  }

  public loadPage(query) {

    if (!this.IncludeAllProductTypes) query.Type = "MasterProduct";
    else delete query.Type;

    this.Query = $.extend(true, {}, this.DefaultQuery, query);

    if (typeof this.Query.OrderByFields === "string") this.Query.OrderByFields = [this.Query.OrderByFields];
    var runQuery = $.extend(true,
      {
        ProjectionFields: this.SelectedStoreView ? ["Id", "SKU", "Name", "Model", "ItemType", "ActiveInStore", "PriceList.RecommendedPrice", "Stock.Amount"] : ["Id", "SKU", "Name", "Model", "ItemType"]
      },
      this.DefaultQuery, query);

    this._productService.SearchProducts(runQuery)
      .subscribe(r => {
        this.products = r.Result;
        if (!this.SelectedProducts) this.SelectedProducts = new SelectableList(this.products.TotalItemsCount);
      });
    this.BindUI();

  }

  public reloadPage(resetPaging: boolean | undefined, resetSelection: boolean | undefined) {

    var query: IProductSearchRequest = {};
    for (const key in this.Query) {
      if (key == "ProjectionFields") continue;
      if (key == "Type") continue;
      if (key == "OrderByFields") {
        if ((this.Query.OrderByFields) && (this.DefaultQuery.OrderByFields) && (this.Query.OrderByFields[0] != this.DefaultQuery.OrderByFields[0])) query.OrderByFields = this.Query.OrderByFields;
        continue;
      };

      if (this.Query.hasOwnProperty(key)) {
        const element = this.Query[key];
        if (!element) continue;

        if (Array.isArray(element)) {
          if (element.length > 0) query[key] = element.slice();
        }
        else {
          if ((element != this.DefaultQuery[key])) query[key] = element;
        }

      }
    }
    if (resetPaging) {
      delete query.PageIndex;
    }

    if (resetSelection) {
      this.SelectedProducts = null;
    }

    this.maintainView = true;
    var querystringObj = $.extend(true, {}, query, { nonce: new Date().getTime() }) as any;
    delete querystringObj.Type;
    if (this.IncludeAllProductTypes) querystringObj.IncludeAllProductTypes = true;

    this._router.navigate(["/products"], { queryParams: querystringObj });
  }

  private BindUI() {

    this.FreeTextSearch = this.Query.FreeSearch;
  }

  public SortListBy(colName: string) {
    if (
      (!this.Query.OrderByFields) ||
      (this.Query.OrderByFields.length === 0) ||
      (this.Query.OrderByFields[0] !== colName)) {
      this.Query.OrderByFields = [colName];
    }
    else {
      this.Query.OrderByFields = ["-" + colName];
    }
    this.reloadPage(true, false);
  }


  public SaveInProcessProducts: string[] = []
  async ToggleActiveStoreProduct(product: IMasterProduct) {
    this.SaveInProcessProducts.push(product.Id);
    var result = await firstValueFrom(this._productService.SetProductActiveStore({
      Id: product.Id,
      Type: "MasterProduct"
    }, this.Query.StoreContext, !product.ActiveInStore));

    if (result.Result) product.ActiveInStore = !product.ActiveInStore;

    const index = this.SaveInProcessProducts.indexOf(product.Id);
    if (index >= 0) this.SaveInProcessProducts.splice(index, 1);
  }

  async SetSelectedActive(active: boolean, callback: (arg0: string) => void) {

    this.IsProductSaving = true;
    var query = this.getSelectedProductQuery();

    await firstValueFrom(this._productService.SetProductActiveStore(query, this.SelectedStoreView, active));
    this.IsProductSaving = false;
    this.reloadPage(false, false);
    callback("save");

  }

  public get IsStockEnabled(): boolean {
    return this._configuration?.StockSetting?.Enable;
  }


  public RunFreeSearch() {
    delete this.Query.PageIndex;
    this.Query.FreeSearch = this.FreeTextSearch.trim();
    this.reloadPage(true, true);
  }


  public Export = () => {
    var query = this.getSelectedProductQuery();

    if (this.exportProductOptions.selector == "activeOnly") query.ActiveInStore = true;
    else if (this.exportProductOptions.selector == "disabledOnly") query.ActiveInStore = false;

    delete query.PageIndex;

    this._router.navigate(["/print-products"], {
      queryParams: {
        returnUrl: this._location.path(true),
        returnDescription: "חזור לרשימת מוצרים",
        query: JSON.stringify(query),
        options: JSON.stringify({ FileTypes: [FileType.Export] } as IExportOptions),
        types: [this.exportProductOptions.type]
      }
    });

  }

  public ExportPrices() {

    const store = this.SelectedStoreDetails;
    if (!store) return;
    var query = this.getSelectedProductQuery();

    delete query.PageIndex;

    this._router.navigate(["/print-products"], {
      queryParams: {
        returnUrl: this._location.path(true),
        returnDescription: "חזור לרשימת מוצרים",
        query: JSON.stringify(query),
        options: JSON.stringify({ FileTypes: [FileType.Export] } as IExportOptions),
        types: ["storeprice-" + store.IdName],
      }
    });

  }

  public toggleSelectPage(): void {
    const shouldToggleOn = !this.isFullPageSelected;

    for (let i = 0; i < this.products.Items.length; i++) {
      const product = this.products.Items[i];
      this.SelectedProducts.SelectItem(product.Id, shouldToggleOn);
    }
  }

  public get isFullPageSelected(): boolean {
    for (let i = 0; i < this.products.Items.length; i++) {
      const product = this.products.Items[i];
      if (!this.SelectedProducts.IsItemSelected(product.Id)) return false;
    }

    return true;
  }

  private getSelectedProductQuery() {
    var query: IProductSearchRequest = {};

    if (this.SelectedProducts && (this.SelectedProducts.SelectedItems.length || this.SelectedProducts.SelectAllItems)) {

      if (this.SelectedProducts.SelectedItems.length) {
        if (!this.SelectedProducts.SelectAllItems) query.Ids = this.SelectedProducts.SelectedItems;
        else query.ExcludeIds = this.SelectedProducts.SelectedItems;
      } else {
        if (query.Ids) delete query.Ids;
        if (query.ExcludeIds) delete query.ExcludeIds;
      }
    }
    var result = $.extend(query, this.Query) as IProductSearchRequest;
    delete result.OrderByFields;
    return result;
  }

  public DeleteSelectedProducts = (callback) => {
    if (this.SelectedProducts && (this.SelectedProducts.SelectedItems.length || this.SelectedProducts.SelectAllItems)) {
      this.IsProductSaving = true;

      var query = this.getSelectedProductQuery();

      this._productService.DeleteProductsByQuery(query, 'Deleted by user in product list page').subscribe(
        r => {
          this.IsProductSaving = false;
          this.reloadPage(true, true);
          callback("save");
        }
      )
    }

  }

  public HTMLHelper = {
    TableColumnWidths: {
      "Default": [5, 15, 15, 65],
      "StoreSelected": [5, 5, 10, 10, 50, 10, 10]
    }
  }

  public open(content, options, onInit, onSave) {
    if (onInit) onInit();
    this._modalService.open(content, options || {}).result.then((result) => {
      if ((result === "save") && (onSave)) onSave();
      //   this.closeResult = `Closed with: ${result}`;
    }, (reason) => {

      //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }

  }
}



