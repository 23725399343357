import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ActionCreatorProps, Store } from "@ngrx/store";

import { debounceTime, distinct, exhaustMap, map, take, tap, withLatestFrom } from "rxjs";
import { ConfigAddShippingMethodDefault, ConfigFetchShipmentTypesSuccess, ConfigFetchSupplierConfigurationStart, ConfigFetchSupplierConfigurationSuccess, ConfigFetchSupplierMainSettingStart, ConfigFetchSupplierMainSettingSuccess, ConfigSaveSupplierConfigurationStart, ConfigSaveSupplierConfigurationSuccess, ConfigSaveSupplierMainSettingStart, ConfigSaveSupplierMainSettingSuccess,  ConfigSetSupplierExports, ConfigSetSupplierLocalErpConfiguration, ConfigSetSupplierProductConfiguration, ConfigSetSupplierRetentionPolicyConfiguration, ConfigSetSupplierShipmentConfiguration, ConfigSetSupplierStockConfiguration, ConfigSetSupplierStoreConfiguration, ConfigSetSupplierUIConfiguration } from "./config.actions";
import { AdminService } from "../services/admin.service";
import { SessionStateFeature } from "./session.reducer";
import { ConfigStateFeature } from "./config.reducer";
import { ISupplierStoreConfiguration } from "../models/suppliers";
import { TypedAction } from "@ngrx/store/src/models";
import { ShipmentService } from "../services/shipment.service";
import { IShipmentTypes } from "../models/shipments.models";
import { UserSessionLoginSuccess } from "./session.actions";
import { DeepCopyObject } from "../helpers/common.helper";



@Injectable()
export class ConfigEffects {
    constructor(
        private actions$: Actions,
        private _adminService: AdminService,
        private _shipmentService: ShipmentService,
        private store: Store
    ) {
        // this.actions$.subscribe(console.log)
    }

    fetchConfiguration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigFetchSupplierConfigurationStart, UserSessionLoginSuccess),
            exhaustMap(action => this._adminService.GetCurrentConfiguration()),
            map((result) => ConfigFetchSupplierConfigurationSuccess({ config: result.Result }))
        ))

    saveConfiguration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigSaveSupplierConfigurationStart),
            debounceTime(100),
            exhaustMap(action => this._adminService.SetCurrentConfiguration(action.config)),
            map((result) => {
                return ConfigSaveSupplierConfigurationSuccess({ config: result.Result });
            })
        ))


    fetchSupplierMainSetting$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigFetchSupplierMainSettingStart, UserSessionLoginSuccess),
            exhaustMap(action => this._adminService.GetSupplierMainSetting()),
            map((result) => ConfigFetchSupplierMainSettingSuccess({ mainSetting: result.Result }))
        ))

    saveSupplierMainSetting$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigSaveSupplierMainSettingStart),
            exhaustMap(action => this._adminService.SetSupplierMainSetting(action.mainSetting)),
            exhaustMap(result => {
                if (result) return this._adminService.GetSupplierMainSetting();
                else throw 'Unable to save'
            }),
            map((result) => ConfigSaveSupplierMainSettingSuccess({ mainSetting: result.Result }))
        ))


    setSupplierStoreConfiguration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigSetSupplierStoreConfiguration),
            withLatestFrom(this.store.select(ConfigStateFeature.selectConfiguration)),
            map(([action, config]) => {
                let newConfig = DeepCopyObject({ ... { Stores: {}, Shipments: {} }, ...config });
                newConfig.Stores[action.idName] = action.configuration;
                return ConfigSaveSupplierConfigurationStart({ config: newConfig });
            })
        ))
    setSupplierShipmentConfiguration = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigSetSupplierShipmentConfiguration),
            withLatestFrom(this.store.select(ConfigStateFeature.selectConfiguration)),
            map(([action, config]) => {
                let newConfig = DeepCopyObject({ ...{ Stores: {}, Shipments: {} }, ...config });
                newConfig.Shipments[action.idName] = action.configuration;
                return ConfigSaveSupplierConfigurationStart({ config: newConfig });
            })
        ))

    setSupplierExports = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigSetSupplierExports),
            withLatestFrom(this.store.select(ConfigStateFeature.selectConfiguration)),
            map(([action, config]) => {
                let newConfig = DeepCopyObject({ ... { Stores: {}, Shipments: {} }, ...config });
                newConfig.Exports = action.exportsConfiguration;
                return ConfigSaveSupplierConfigurationStart({ config: newConfig });
            })
        ))

    setSupplierLocalErpConfiguration = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigSetSupplierLocalErpConfiguration),
            withLatestFrom(this.store.select(ConfigStateFeature.selectConfiguration)),
            map(([action, config]) => {
                let newConfig = DeepCopyObject({ ... { Stores: {}, Shipments: {} }, ...config });
                newConfig.LocalERPConfiguration = action.erpConfiguration;
                return ConfigSaveSupplierConfigurationStart({ config: newConfig });
            })
        ))


    setSupplierRetentionPolicyConfiguration = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigSetSupplierRetentionPolicyConfiguration),
            withLatestFrom(this.store.select(ConfigStateFeature.selectConfiguration)),
            map(([action, config]) => {
                let newConfig = DeepCopyObject({ ... { Stores: {}, Shipments: {} }, ...config });
                newConfig.RetentionPolicy = action.retentionPolicy;
                return ConfigSaveSupplierConfigurationStart({ config: newConfig });
            })
        ))

    setSupplierStockConfiguration = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigSetSupplierStockConfiguration),
            withLatestFrom(this.store.select(ConfigStateFeature.selectConfiguration)),
            map(([action, config]) => {
                let newConfig = DeepCopyObject({ ... { Stores: {}, Shipments: {} }, ...config });
                newConfig.StockSetting = action.stockSetting;
                return ConfigSaveSupplierConfigurationStart({ config: newConfig });
            })
        ))

    setSupplierProductConfiguration = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigSetSupplierProductConfiguration),
            withLatestFrom(this.store.select(ConfigStateFeature.selectConfiguration)),
            map(([action, config]) => {
                let newConfig = DeepCopyObject({ ... { Stores: {}, Shipments: {} }, ...config });
                newConfig.ProductSetting = action.productSetting;
                return ConfigSaveSupplierConfigurationStart({ config: newConfig });
            })
        ))

    setSupplierUIConfiguration = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigSetSupplierUIConfiguration),
            withLatestFrom(this.store.select(ConfigStateFeature.selectConfiguration)),
            map(([action, config]) => {
                let newConfig = DeepCopyObject({ ... { Stores: {}, Shipments: {} }, ...config });
                newConfig.UIConfiguration = action.uiConfiguration;
                return ConfigSaveSupplierConfigurationStart({ config: newConfig });
            })
        ))

 

    addShippingMethodDefault = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigAddShippingMethodDefault),
            withLatestFrom(this.store.select(ConfigStateFeature.selectConfiguration)),
            map(([{ storeId, shipmentTypeId, defaultValue, defaultPrice }, olgConfig]) => {
                let conf = DeepCopyObject({ ... { Stores: {}, Shipments: {} }, ...olgConfig });
                var MatchDefinitionsFunction = (definition: { MatchString: string, MatchShipPrice?: number }) => definition.MatchString == defaultValue && (definition.MatchShipPrice == defaultPrice || (definition.MatchShipPrice == undefined && defaultPrice == null));


                let storeConfig: ISupplierStoreConfiguration;

                if (!conf || !conf.Stores[storeId]) storeConfig = this._adminService.CreateNewSupplierStoreConfiguration();
                else storeConfig = conf.Stores[storeId];

                // build default start up values. 
                if (!storeConfig.ImportConfiguration.ShippingMethodDefaults) storeConfig.ImportConfiguration.ShippingMethodDefaults = {};
                if (!storeConfig.ImportConfiguration.ShippingMethodDefaults[shipmentTypeId]) storeConfig.ImportConfiguration.ShippingMethodDefaults[shipmentTypeId] = [];

                // clean the defaultValue from all other shipmentTypes
                for (const key in storeConfig.ImportConfiguration.ShippingMethodDefaults) {
                    if (storeConfig.ImportConfiguration.ShippingMethodDefaults.hasOwnProperty(key)) {
                        let ShippingMethodDefaults = storeConfig.ImportConfiguration.ShippingMethodDefaults[key];
                        const index = ShippingMethodDefaults.findIndex(MatchDefinitionsFunction);
                        if (index > -1) {
                            ShippingMethodDefaults.splice(index, 1);
                        }
                    }
                }

                if (storeConfig.ImportConfiguration.ShippingMethodDefaults[shipmentTypeId].findIndex(MatchDefinitionsFunction) < 0) {
                    storeConfig.ImportConfiguration.ShippingMethodDefaults[shipmentTypeId].push({ MatchString: defaultValue, MatchShipPrice: defaultPrice });
                }

                return ConfigSetSupplierStoreConfiguration({ idName: storeId, configuration: storeConfig });
            })
        ))


    fetchShipmentTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigFetchSupplierConfigurationStart, UserSessionLoginSuccess),
            exhaustMap(action => this._shipmentService.GetShipmentTypes()),
            map((result) => {
                var answer: IShipmentTypes = {};
                if (result.Code == 0) {
                    for (var i = 0; i < result.Result.Items.length; i++) {
                        answer[result.Result.Items[i].Id] = result.Result.Items[i];
                    }
                }
                return ConfigFetchShipmentTypesSuccess({ shipmentTypes: answer });
            })
        ))

}


