import { promise } from "protractor";

export function ConvertToArrayOfNumbers(value: string | number | string[] | number[]): number[] {
    if (Array.isArray(value)) return (value as Array<any>).map(val => Number(val));
    else return [Number(value)];
}

export function ConvertToArrayOfStrings(value: string | string[]): string[] {
    if (Array.isArray(value)) return (value as Array<string>);
    else return [value];
}


export function IsArrayEqual<T>(arrOne: Array<T>, arrTwo: Array<T>) {
    return arrOne == arrTwo || (
        Array.isArray(arrOne) && Array.isArray(arrTwo) &&
        arrOne.length === arrTwo.length &&
        arrOne.every(function (element) {
            return arrTwo.includes(element);
        }));
}



export function Delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function DeepCopyObject<T>(obj: T): T { // powered by GPT-4
    let copy: any;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime((obj as Date).getTime());
        return copy;
    }

    // Handle Array or Object
    if (obj instanceof Array || obj instanceof Object) {
        copy = (obj instanceof Array) ? [] : {};
        for (let attr in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, attr)) {
                copy[attr] = DeepCopyObject(obj[attr]);
            }
        }
        return copy;
    }

    // Handle the rest (primitives and functions)
    return obj;
}