<div class="d-flex justify-content-center" *ngIf="!order">
  <i class="fa fa-spinner fa-pulse fa-5x fa-fw mt-5 text-secondary"></i>
</div>
<ngb-alert *ngIf="alert" [type]="alert.type" (close)="CloseAlert()">
  <h3><span>{{ alert.message }}</span>
    <button class='btn btn-warning ml-4' (click)="OrderWizard();">
      לאשף תיקון ההזמנה <i class="fas fa-wrench mx-2"></i>
    </button>
  </h3>
  <ul *ngIf="alert.subMessages">
    <li *ngFor="let subMsg of alert.subMessages;">
      {{subMsg}}
    </li>
  </ul>

</ngb-alert>
<div class="container-fluid mt-2" *ngIf="order">
  <div class="row">
    <div class="col-10">
      <h3 *ngIf="(!orderNumEditMode); else elseorderNumEditMode"> מספר הזמנה : <span> {{order.SupplierNumber}}</span> <i class="btn btn-outline-primary fas fa-pencil-alt" (click)="orderNumEditMode=true;"></i> </h3>

      <ng-template #elseorderNumEditMode>
        <div class="w-25 d-inline-block">
          <h3>
            <order-supplier-number-textbox Title="מספר הזמנה" [(Order)]="order" (OrderChange)="onOrderChanged();">
            </order-supplier-number-textbox>
          </h3>
        </div>
        <h3 class="d-inline-block">
          <i class="btn btn-outline-primary fas fa-check ml-2 py-2 mt-1" (click)="orderNumEditMode=false;"></i>
        </h3>
      </ng-template>
    </div>
    <div class="col-2 px-0">
      <div class="btn-group float-left" style="padding-right: 8.0rem;" ngbDropdown>
        <button *ngIf="isOrderModifyed ; else elseIsOrderModifyed" type="button" class="btn btn-outline-primary btn-sm" (click)="SaveOrder();">שמור</button>
        <ng-template #elseIsOrderModifyed>
          <button type="button" class="btn btn-outline-primary btn-sm" (click)="PrintOrder('pdf')">הדפס</button>
        </ng-template>
        <button type="button" class="btn btn-outline-primary btn-sm dropdown-toggle dropdown-toggle-split" id="dropdownTools" ngbDropdownToggle>
          <span class="sr-only">Toggle Dropdown</span>
        </button>
        <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownTools">
          <button ngbDropdownItem [disabled]="!isOrderModifyed" (click)="SaveOrder();">שמור</button>
          <button ngbDropdownItem *ngIf="HasRole(['Admin', 'OrdersOperator'])" (click)="PrintOrder('pdf')">הדפס מדבקת משלוח</button>
          <button ngbDropdownItem *ngIf="HasRole(['Admin', 'OrdersOperator'])" (click)="PrintOrder('erp')">ייצא לקובץ ERP</button>
          <button ngbDropdownItem *ngIf="HasRole(['Admin', 'OrdersOperator'])" (click)="PrintOrder('chita')">ייצא לקובץ ציטה</button>
          <button ngbDropdownItem *ngIf="HasRole(['Admin', 'OrdersOperator'])" (click)="PrintOrder('Paxxi')">ייצא לקובץ Paxxi</button>
          <button ngbDropdownItem *ngIf="HasRole(['Admin', 'OrdersOperator'])" (click)="PrintOrder('Baldar')">ייצא לקובץ בלדר</button>
          <button ngbDropdownItem *ngIf="HasRole(['Admin', 'OrdersOperator'])" (click)="PrintOrder('LionWheel')">ייצא לקובץ LionWheel</button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem (click)="open(contentDeleteOrder,null,null,DeleteOrder)"><i class="fas fa-trash-alt mr-2 ml-1"> </i>מחק הזמנה</button>
        </div>
      </div>
    </div>

  </div>
  <div class="row">
    <div class="col-6">
      <h3 *ngIf="(!orderCreateDateEditMode); else elseorderCreateDateEditMode">
        תאריך הזמנה : <span>{{order.Audit.CreatedAt | date:"dd/MM/yyyy" }}</span>
        <i class="btn btn-outline-primary fas fa-pencil-alt" (click)="orderCreateDateEditMode=true;"></i>
      </h3>

      <ng-template #elseorderCreateDateEditMode>
        <div class="h3">
          <div class="input-group col-md-3 px-0">
            <button class="btn btn-outline-secondary createDatebtn" (click)="orderDateEditor.toggle()" type="button">
              <i class="far fa-calendar-alt"></i>
            </button>

            <input class="form-control createDateBox" placeholder="dd/MM/yyyy" name="dp" [(ngModel)]="order.Audit.CreatedAt" ngbDatepicker #orderDateEditor="ngbDatepicker">
            <i class="btn btn-outline-primary fas fa-check pt-2" (click)="orderCreateDateEditMode=false;"></i>
          </div>


        </div>

      </ng-template>
    </div>

    <div class="col-6 px-0">

      <div class="d-flex justify-content-end">
        <div class="d-inline-block ">
          <div ngbDropdown class="pl-4 ">
            <button class="btn btn-outline-primary btn-sm " id="dropdownERPState" ngbDropdownToggle [disabled]="order.ValidationsSeverityLevel > 3 " title="{{(order.ValidationsSeverityLevel > 3) ? 'הזמנה לא תקינה. \n יש לתקן את ההזמנה תחילה.' : ''}}">
              <span class="pr-2 ml-2 d-inline-block" style="min-width: 8rem"> סטטוס ליקוט : {{order.CollectStatus | enumDescription : "OrderCollectStatus"}}</span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownERPState" class="">
              <button ngbDropdownItem *ngFor="let item of OrderCollectStateOptions" (click)="order.CollectStatus = item.id; onOrderChanged();">{{item.name}}</button>
            </div>

          </div>

        </div>


        <div class="d-inline-block ">
          <div ngbDropdown class="pl-4 ">
            <button class="btn btn-outline-primary btn-sm " id="dropdownERPState" ngbDropdownToggle [disabled]="order.ValidationsSeverityLevel > 3 " title="{{(order.ValidationsSeverityLevel > 3) ? 'הזמנה לא תקינה. \n יש לתקן את ההזמנה תחילה.' : ''}}">
              <span class="pr-2 ml-2 d-inline-block" style="min-width: 8rem"> סטטוס ERP : {{order.ERPExportStatus | enumDescription : "OrderErpExportStatus"}}</span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownERPState" class="">
              <div *ngFor="let item of SelectableERPStatus" placement="right" [ngbTooltip]="IsSetErpStatusDisabled(item) ? 'לא ניתן להמיר לסטטוס העבר אם ההזמנה נמצאת כבר בסטטוס עבר או בוטל' : ''">
                <button ngbDropdownItem (click)="order.ERPExportStatus = item; onOrderChanged();" [disabled]="IsSetErpStatusDisabled(item)">{{item | enumDescription : "OrderErpExportStatus"}}</button>
              </div>

            </div>

          </div>
          <div *ngIf="order.ERPExportRemarks" class="badge  my-1" [ngClass]="GetERPExportRemarks()">
            {{order.ERPExportRemarks}}
          </div>
        </div>

        <div ngbDropdown class="d-inline-block pl-4">
          <button class="btn btn-outline-primary btn-sm " id="dropdownState" ngbDropdownToggle [disabled]="order.ValidationsSeverityLevel > 3 " title="{{(order.ValidationsSeverityLevel > 3) ? 'הזמנה לא תקינה. \n יש לתקן את ההזמנה תחילה.' : ''}}">
            <span class="pr-2 ml-2"> הזמנה {{order.State | enumDescription : "OrderState"}}</span>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownState" class="">
            <button ngbDropdownItem *ngFor="let item of OrderStateOptions" (click)="order.State = item.id; onOrderChanged();">{{item.name}}</button>
          </div>
        </div>

      </div>


    </div>

  </div>
  <div class="row" *ngIf="OrderWarningValidations && OrderWarningValidations.length">
    <div class="col-12">
      <div *ngFor="let validationItem of OrderWarningValidations" class="d-block">
        <i class="fas fa-exclamation-triangle text-warning ng-star-inserted"></i><span class="">{{validationItem.Message}}</span> <span *ngIf="validationItem.SubMessage" class="font-weight-light"> - {{validationItem.SubMessage}}</span>
      </div>
    </div>

  </div>
  <div class="row">
    <div class="col-4 pr-1">
      <div class="card">
        <div class="card-body">
          <div>
            <h3 class="clearfix"> <span class="float-right">פרטי הקונה </span> <i class="btn btn-outline-primary fas fa-pencil-alt float-left mt-1" (click)="ShopperEditorMode = (ShopperEditorMode == 1) ? 2 : 1; CdRef.detectChanges();"></i></h3>
          </div>
          <identity-editor [(Identity)]="order.Shopper" [Mode]="ShopperEditorMode" (IdentityChange)="onOrderChanged();">

          </identity-editor>
          <div>
            <h3>
              פרטי המשלוח
              <i class="btn btn-outline-primary fas fa-pencil-alt float-left mt-1" (click)="ShipmentEditorMode = (ShipmentEditorMode == 1) ? 2 : 1 ; CdRef.detectChanges();"></i>
            </h3>
            <h5 class="d-flex">
              <unifyshipmenticon *ngIf="order.Shipments[0].PreUnifyShipment" [OrderId]="order.Id" [UnifyShipmentId]="order.Shipments[0].Id"></unifyshipmenticon>

              <shipment-type-selector [model]="order.Shipments[0].ItemType" (modelChange)="onShipmentTypeChange($event);" [Mode]="ShipmentEditorMode" [ngClass]="'d-inline-block'" [ShowSelfDistributers]="true"></shipment-type-selector>


              <label *ngIf="ShipmentEditorMode == 1;else EditShipmentStatus" class="ml-2 badge badge-light">{{order.Shipments[0].Status | enumDescription : "ShipmentStatus"}}</label>
              <ng-template #EditShipmentStatus>
                <select class="form-control mx-2" [(ngModel)]="order.Shipments[0].Status" (ngModelChange)="onOrderChanged($event)">
                  <option *ngFor="let item of ShipmentStatusOptions" [value]="item.id">{{item.name}}</option>
                </select>
              </ng-template>


              <i class="fas text-primary m-2 ml-auto" [ngClass]="{'fa-search-plus' : !ShowAdvanceShipmentEditor , 'fa-search-minus' : ShowAdvanceShipmentEditor }" *ngIf="ShipmentEditorMode == 2" (click)="ShowAdvanceShipmentEditor = (!ShowAdvanceShipmentEditor);" title="הצג נתוני משלוח בהזמנה"></i>
            </h5>

            <div class=" container-fluid p-0 m-0" *ngIf="ShipmentEditorMode == 2" [@collapse]="!ShowAdvanceShipmentEditor">
              <div class="row row-compact mt-1">
                <div class="col-6 pr-1">
                  <labeled-textbox title="מקט משלוח" class="compact" [(model)]="ShipmentItem.SKU" (modelChange)="onShipmentOrderItemChange();"></labeled-textbox>
                </div>
                <div class="col-6">
                  <labeled-textbox title="תיאור משלוח" class="compact" [(model)]="ShipmentItem.Name" (modelChange)="onShipmentOrderItemChange();"></labeled-textbox>
                </div>

              </div>
              <div class="row row-compact pt-1" *ngIf="ShipmentEditorMode == 2" [@collapse]="!ShowAdvanceShipmentEditor">
                <div class="col-6 pr-1">
                  <label class="form-group has-float-label text-right">
                    <select class="form-control ProductField mb-4" [(ngModel)]="order.Shipments[0].PackageSize" (ngModelChange)="onOrderChanged($event)">
                      <ng-container *ngFor="let size of AvailableDeliveryPackageSizes">
                        <option [value]="size.id">{{ size.name}}</option>
                      </ng-container>
                    </select>
                    <span class="label">סוג חבילה</span>
                  </label>

                </div>
                <div class="col-6 ">
                  <labeled-textbox title="מחיר" type="number" class="compact" [(model)]="ShipmentItem.ItemPrice" (modelChange)="onShipmentOrderItemChange();"></labeled-textbox>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <hr />
                </div>
              </div>
            </div>


          </div>
          <div *ngIf="order.Shipments[0]">
            <shipment-editor [Mode]="ShipmentEditorMode" [(Shipment)]="order.Shipments[0]" (ShipmentChange)="onOrderChanged();">

            </shipment-editor>
          </div>
        </div>
      </div>
    </div>
    <div class="col-8 card">
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="">מוצרים בהזמנה</th>
              <th scope="col" class="text-center"></th>
              <th scope="col" class="text-center">מחיר יח'</th>
              <th scope="col" class="text-center">יח' בהזמנה</th>
              <th scope="col" class="text-center">מלאי</th>
              <th scope="col" class="text-center">סה"כ</th>
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let item of ProductItems;">
              <td class="">
                <div class="delete-item">
                  <i class="fas fa-times-circle" (click)="DeleteOrderItemByIndex(item)"></i>
                </div>
                <div>
                  <b>{{item.Product.Name}}</b>
                </div>
                <div *ngIf="item.Product.SKU">
                  <a [routerLink]="['/product',item.Product.Id]">מקט: {{item.Product.SKU}}</a>
                </div>
                <div *ngIf="item.Product.Model">
                  דגם : {{item.Product.Model}}
                </div>
                <div *ngIf="item?.Product?.OrderSerialNumberControl">
                  <button class="btn btn-link m-0 p-0" [ngClass]="{'text-danger' : !HTMLHelper.validateMaterialInfo(item) , 'text-success' : HTMLHelper.validateMaterialInfo(item)}" (click)="PrepareOrderItemForMaterialInfoEdit(item);  open(contentEditProductMaterialInfos,{ size: 'sm' },null,null)">
                    <span class="serialNumbers-text">מספר טבוע: {{HTMLHelper.extractSerialNumbersTexts(item)}}</span>
                  </button>
                </div>
              </td>
              <td class="align-middle text-center">
                <div *ngIf="item.Product.Attributes">{{item.Product.Attributes.join(', ')}}</div>
              </td>
              <td class="align-middle ">
                <div class="d-flex align-items-center justify-content-center">
                  <input class="form-control form-control-sm border-0 text-center" style="width: 6rem;" type="number" [ngModel]="( item.ItemPrice +  item.ItemVATPrice).toFixed(2)" (keyup.enter)="UpdateOrderItemPrice(item);" (blur)="UpdateOrderItemPrice(item);" (ngModelChange)="item.finalPrice = $event; onOrderChanged();">
                </div>
              </td>

              <td class="text-center align-middle">
                <div><i class="fas fa-plus-square itemplus" (click)="ChangeOrderItemQuantity(item,item.Quantity+1)"></i></div>
                <div>{{item.Quantity | number:'1.0-2'}}</div>
                <div><i class="fas fa-minus-square itemminus" (click)="ChangeOrderItemQuantity(item,item.Quantity-1)"></i></div>
              </td>

              <td class="align-middle text-center">{{item.Product?.Stock?.Amount}}</td>
              <td class="align-middle colTotalPrice">{{item.Quantity * ( item.ItemPrice + item.ItemVATPrice) | number:'1.2-2' }}</td>
            </tr>
          </tbody>
        </table>
        <div class="container">
          <div class="row">
            <div class="col-8">
              <button class='btn btn-outline-success' (click)="open(contentAddProduct,{ size: 'lg' },null,null)">
                הוסף מוצר <i class="fas fa-plus"></i>
              </button>
            </div>
            <div class="col-4 container p-0">
              <div class="row">
                <div class="col-7">סה"כ מוצרים</div>
                <div class="col-5 summaryText">{{ItemsTotalPrice('Product') + ItemsTotalVATPrice('Product') | number:'1.2-2'}}</div>
              </div>
              <div class="row">
                <div class="col-7">משלוח</div>
                <div class="col-5 summaryText">{{ItemsTotalPrice('Shipment') + ItemsTotalVATPrice('Shipment') | number:'1.2-2'}}</div>
              </div>
              <div class="row border-top border-dark">
                <div class="col-7">סה"כ בהזמנה</div>
                <div class="col-5 summaryText">{{ItemsTotalPrice() + ItemsTotalVATPrice() | number:'1.2-2' }}</div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-8 pr-1 mt-1">
      <div class="card h-100">
        <div class="card-body">
          <ng-container *ngIf="(!orderRemarksEditMode); else elseorderRemarksEditMode">
            <h2> הערות <i class="btn btn-outline-primary fas fa-pencil-alt" (click)="orderRemarksEditMode=true;"></i> </h2>

            <p class="multi-line"> {{order.Remarks}}</p>
          </ng-container>
          <ng-template #elseorderRemarksEditMode>
            <h2> הערות <i class="btn btn-outline-primary fas fa-check" (click)="orderRemarksEditMode=false;"></i></h2>
            <p><textarea rows="4" [ngModel]="order.Remarks" (ngModelChange)="order.Remarks = $event; onOrderChanged();" class="w-100"></textarea></p>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="col-4 pr-0 mt-1">
      <div class="card h-100">
        <div class="card-body">
          <ng-container *ngIf="(!orderExtendDetailsEditMode); else elseorderExtendDetailsEditMode">
            <h2> פרטים נוספים <i class="btn btn-outline-primary fas fa-pencil-alt" (click)="orderExtendDetailsEditMode=true;"></i> </h2>

            <div *ngIf="SourceStoreName" class="d-flex flex-row">
              <div> מקור: </div>
              <div class="mx-1">{{SourceStoreName}}</div>
              <div class="mx-1" *ngIf="order.Source.SupplierStoreNumber">
                <span class="badge badge-secondary">{{order.Source.SupplierStoreNumber}}</span>
              </div>
            </div>

            <div *ngIf="order.ERPOrderNumber" class="d-flex flex-row">
              <div> מספר ERP :</div>
              <div class="mx-1">{{order.ERPOrderNumber}}</div>
            </div>
            <div *ngIf="order.Club" class="d-flex flex-row">
              <div>מועדון :</div>
              <div class="mx-1">{{order.Club}}</div>
            </div>


            <ng-container *ngIf="OrderCustomFieldAliases">
              <div *ngFor="let customField of OrderCustomFieldAliases | keyvalue" class="d-flex flex-row">
                <ng-container *ngIf="order[customField.key]">
                  <div> {{customField.value.Name}} :</div>
                  <div class="mx-1">{{order[customField.key]}}</div>
                </ng-container>
              </div>
            </ng-container>

          </ng-container>
          <ng-template #elseorderExtendDetailsEditMode>
            <h2> פרטים נוספים <i class="btn btn-outline-primary fas fa-check" (click)="orderExtendDetailsEditMode=false;"></i> </h2>
            <labeled-textbox type="text" title="מספר ERP" [(model)]="order.ERPOrderNumber" (modelChange)="onOrderChanged();"></labeled-textbox>
            <labeled-textbox type="text" title="מועדון" [(model)]="order.Club" (modelChange)="onOrderChanged();"></labeled-textbox>
            <ng-container *ngIf="OrderCustomFieldAliases">
              <labeled-textbox *ngFor="let customField of OrderCustomFieldAliases | keyvalue" type="text" [title]="customField.value.Name" [(model)]="order[customField.key]" (modelChange)="onOrderChanged();"></labeled-textbox>
            </ng-container>

          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 pr-0 mt-1">
      <div class="card">
        <div class="card-body">
          <ng-container *ngIf="(!orderOperationalRemarksEditMode); else elseorderOperationalRemarksEditMode">
            <h2> הערות לביצוע <i class="btn btn-outline-primary fas fa-pencil-alt" (click)="orderOperationalRemarksEditMode=true;"></i> </h2>
            <p class="multi-line"> {{order.OperationalRemarks}}</p>
          </ng-container>
          <ng-template #elseorderOperationalRemarksEditMode>
            <h2> הערות לביצוע <i class="btn btn-outline-primary fas fa-check" (click)="orderOperationalRemarksEditMode=false;"></i></h2>
            <p><textarea rows="4" [ngModel]="order.OperationalRemarks" (ngModelChange)="order.OperationalRemarks = $event; onOrderChanged();" class="w-100"></textarea></p>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>



<ng-template #contentAddProduct let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">הוספת מוצר להזמנה</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <product-select [store]="StoreType" (onResult)="AddOrderItem($event); c((($event) ? 'save' : 'cancel'));">
    </product-select>
  </div>
</ng-template>

<ng-template #contentEditProductMaterialInfos let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title"> נתוני מוצר בהזמנה</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          מספר טבוע
        </div>
      </div>

      <ng-container *ngFor="let materialInfo of SelectedOrderItem.ProductMaterialInfos;index as i">
        <div class="row">
          <div class="col-12">
            <labeled-textbox [id]="'orderpage.materialInfo.SerialNumber_'+i" class="no-margin" type="text" title="" [(model)]="materialInfo.SerialNumber" (modelChange)="onOrderChanged();" (keydown.enter)="i == SelectedOrderItem.ProductMaterialInfos.length - 1 ? c('save') : HTMLHelper.focusNext('orderpage.materialInfo.SerialNumber_'+(i+1))"></labeled-textbox>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="modal-footer d-block">
    <button type="button" class="btn btn-outline-success float-right" (click)="c('save')">אשר <i class="fas fa-check"></i></button>

  </div>
</ng-template>

<ng-template #contentDeleteOrder let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">אישור מחיקה</h4>
    <button type="button" class="close" aria-label="Close" (click)=" c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    האם אתה בטוח שאתה רוצה למחוק את הזמנה ?
  </div>
  <div class="modal-footer d-block">
    <button type="button" class="btn btn-outline-danger float-right" (click)="c('save')">אשר <i class="far " [ngClass]="{'fa-spinner fa-pulse' : IsOrderDeleting , 'fa-trash-alt' : !IsOrderDeleting}"></i></button>
    <button type="button" class="btn btn-outline-dark float-left" (click)="c('cancel')">בטל</button>
  </div>
</ng-template>