import { Component, EventEmitter, Output, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProductService } from '../../../services/product.service';


@Component({
  selector: 'product-attribute-selector',
  templateUrl: './productattributeselector.usercontrol.component.html',
  styleUrls: ['./productattributeselector.usercontrol.component.css']
})
export class ProductAttributeSelectorUserControlComponent implements OnInit, OnChanges {

  @Input()
  public model!: string[];

  @Input()
  public tabindex: number = 0;

  @Output()
  public modelChange = new EventEmitter<string[]>();

  constructor(private _productService: ProductService) { }
  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
  }

  public onChanged(input: any[]) {
    this.model = input.map(t => {
      if (typeof t === 'string' || t instanceof String) return t;
      else return t.value;
    });

    this.modelChange.emit(this.model);
  }
}

