import { Injectable } from '@angular/core';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpEventType, HttpSentEvent } from '@angular/common/http';

import { tap, map, catchError, take, exhaustMap } from 'rxjs/operators';
import { IInitLoginResult, ILoginUserSession } from '../models/users.models'
import { IApiResult } from '../models/api.model'
import { from, Subject, BehaviorSubject, throwError, Observable, lastValueFrom, firstValueFrom, of } from 'rxjs';

import { flatMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SessionStateFeature } from '../state/session.reducer';
import { UserSessionLogout, UserSessionRefreshError, UserSessionRefreshStart, UserSessionRefreshSuccess } from '../state/session.actions';
import { Actions, ofType } from '@ngrx/effects';
import { Delay } from '../helpers/common.helper';

@Injectable()
export class AuthorizationService {
  private _apiUrl = "/api/Authurization";

  session: ILoginUserSession
  constructor(private _http: HttpClient, private store: Store) {
    this.store.select(SessionStateFeature.selectSession).subscribe(session => {
      this.session = session as ILoginUserSession;
    })
  }

  private getQueryStringParameterByName(name, url = window.location.href) {
    name = name.replace(/[\\[\\]]/g, '\\\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) return null;
    return decodeURIComponent(results[2] || '');
  }

  private removeURLParameter(url: string, parameter: string) {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {

      var prefix = encodeURIComponent(parameter) + '=';
      var pars = urlparts[1].split(/[&;]/g);

      //reverse iteration as may be destructive
      for (var i = pars.length; i-- > 0;) {
        //idiom for string.startsWith
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }

      url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : "");
      return url;
    } else {
      return url;
    }
  }


  public DoseUserHasRole(roles: string[]): boolean {
    if (!this.session) return false;
    for (let index = 0; index < roles.length; index++) {
      const role = roles[index];
      if (this.session.Roles.indexOf(role) >= 0) return true;
    }
    return false;
  }


  public Login({ username, password }: { username: string; password: string }): Observable<ILoginUserSession> {
    return this._http.post<IApiResult<ILoginUserSession>>(this._apiUrl + "/Login", { Username: username, Password: password }).pipe(
      map(r => {
        if (r.Result) return r.Result;
        else throw r.Message
      })
    );
  }

  public LoginRefresh(user: ILoginUserSession, supplierId: string): Observable<ILoginUserSession | null> {
    if (user) {
      return this._http.post<IApiResult<ILoginUserSession>>(this._apiUrl + "/RefreshLogin",
        {
          RefreshToken: user.RefreshToken,
          SupplierId: supplierId ?? user.SupplierId,
          RememberMe: false
        })
        .pipe(
          map(r => (r.Result))
        );
    }
    return new BehaviorSubject(null);
  }


  public SSOInitLogin(): void {
    this._http.get<IApiResult<IInitLoginResult>>(this._apiUrl + "/SSOInitLogin/SendEB2C").subscribe(result => {
      if (result.Code == 0) {
        window.location.href = result.Result.RedirectURL;
      }
    });
  }


  public HasSSOToken(): boolean {
    var code = this.getQueryStringParameterByName("code");
    var state = this.getQueryStringParameterByName("state");
    return !!code && !!state;
  }

  public SSOLogin(): Observable<ILoginUserSession | null> {
    var code = this.getQueryStringParameterByName("code");
    var state = this.getQueryStringParameterByName("state");
    if (code && state) {

      var url = this.removeURLParameter(window.location.href, 'code');
      url = this.removeURLParameter(url, 'state');
      window.history.replaceState({}, document.title, url);

      return this._http.post<IApiResult<ILoginUserSession>>(this._apiUrl + "/SSOLogin/SendEB2C", {
        SSOCode: code,
        SSOState: state
      }).pipe(
        map(r => {
          if (r.Result) return r.Result;
          else throw r.Message
        })
      );
    }
    else {
      return of(null)
    }
  }


}



@Injectable()
export class AuthurizationHttpInterceptor implements HttpInterceptor {
  private session: ILoginUserSession
  constructor(private store: Store, private actions$: Actions) {
    this.store.select(SessionStateFeature.selectSession).subscribe(session => {
      this.session = session;
    })
  }

  private allowAnonymousUrls: RegExp[] = [/blob\.core\.windows\.net/i, /api\/Authurization\/RefreshLogin/i, /api\/Authurization\/Login/i];
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var accessToken = this.session?.AccessToken ?? null;
    for (var i = 0; i < this.allowAnonymousUrls.length; i++) {
      if (this.allowAnonymousUrls[i].test(request.url)) {
        accessToken = null;
        break;
      }

    }
    return from(this.handleAccess(request, next, accessToken, false));
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler, accessToken: string | null, isSecondRetry: boolean): Promise<HttpEvent<any>> {
    let changedRequest = request;

    // HttpHeader object immutable - copy values
    const headerSettings: { [name: string]: string | string[] } = {};

    for (const key of request.headers.keys()) {
      var value = request.headers.getAll(key);
      if (value) headerSettings[key] = value;
    }

    if (accessToken) headerSettings['Authorization'] = 'Bearer ' + accessToken;
    // headerSettings['Content-Type'] = 'application/json';
    const newHeader = new HttpHeaders(headerSettings);

    changedRequest = request.clone({
      headers: newHeader
    });


    try {
      return await lastValueFrom(next.handle(changedRequest));
    } catch (err) {
      if (!isSecondRetry && accessToken) {
        //     console.log('dispat ' + this.session.AccessToken);
        this.store.dispatch(UserSessionRefreshStart({supplierId: null}));

        try { // lets try to refresh the token and if not kill the session 
          var resultAction = await firstValueFrom(this.actions$.pipe(ofType(UserSessionRefreshSuccess, UserSessionRefreshError)));

          if (resultAction.type == UserSessionRefreshSuccess.type) {
            //            console.log('refresh success');
            return await this.handleAccess(request, next, resultAction.session.AccessToken, true);
          }
          else throw (err);
        } catch (second_err) { 
          if (second_err.status == 401) this.store.dispatch(UserSessionLogout());
          throw second_err
        }
      }
      throw (err);
    }
  }

}


/*
    return next.handle(changedRequest).pipe(
      catchError((err, caught) => {
        if (err.status == 401) {
          return this.msalService.LoginRefresh()
            .pipe(
              flatMap(success => {
                if (success) return next.handle(changedRequest);
                else return throwError(err);
              })
            )
        }
        return throwError(err);

      }),
      catchError((err, caught) => { // second try lets logout the user 
        if (err.status == 401) {
          this.msalService.Logout();
        }
        return throwError(err);
      })
    ).toPromise();
  }*/