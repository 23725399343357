import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef} from '@angular/core';


import { IExternalFile } from '../../../models/files.models';
import { IStoreDetails } from '../../../models/stores';
import { FileService } from '../../../services/file.service';


@Component({
  selector: 'uploadfile',
  templateUrl: './uploadfile.usercontrol.component.html'
})
export class UploadFileUserControlComponent implements OnInit {
  constructor(private _fileService: FileService) {
  }

  
  @Output() modelChange: EventEmitter<IExternalFile[]> = new EventEmitter<IExternalFile[]>();
  @Input() model!: IExternalFile[];
  @Input() mode!: UploadFileComponentMode;

  public IsUploading: boolean = false;
  
  @Input() accept!: string;

  @ViewChild('FileInput', { static: true })
  public FileInput!: ElementRef;

  public Stores!: IStoreDetails[];


  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {
    
  };

  ngOnInit() {
    this.modelChange.subscribe(v => {  });

  }

  public OpenFileDialog() {
    this.FileInput.nativeElement.click();
  }

  public UploadFilesInput(fileList: FileList) {
    var files: File[] = [];
    for (var i = 0; i < fileList.length; i++) {
      files.push(fileList[i]);
    }

    this.UploadFiles(files);
  }
  public UploadFiles(files: File[]) {
   


    this.IsUploading = true;
    this._fileService.UploadFiles(files)
      .subscribe(r => {
        if (r.Result) {
          var files = r.Result;
          if (this.mode == UploadFileComponentMode.MultipleConcatFiles) this.model = this.model.concat(files);
          else this.model = files;
          this.modelChange.emit(this.model);
        }
        this.IsUploading = false;
      });
  }
}


export enum UploadFileComponentMode {
  SingleFile = 1,
  MultipleFiles = 2,
  MultipleConcatFiles = 3
}