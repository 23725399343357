<div class="connecting-line"></div>
<div class="header d-flex justify-content-between mb-4">
    <button type="button" class="btn btn-info p-2 rounded-circle text-center" [ngClass]="{'active': (StepChange | async) ==3}" (click)="StepChange.emit(3)">סיום</button>
    <button type="button" class="btn btn-info p-2 rounded-circle text-center" [ngClass]="{'active': (StepChange | async)==2}" (click)="StepChange.emit(2)">בחירת<br />שדות</button>
    <button type="button" class="btn btn-info p-2 rounded-circle text-center" [ngClass]="{'active': (StepChange | async)==1}" (click)="StepChange.emit(1)">טעינת<br />קובץ</button>
</div>

<div *ngIf="(StepChange | async) ==1">
    <div>
        אנא טען קובץ 
    </div>
    <div class="">
        <uploadfile [mode]="1" [model]="UploadedFile ? [UploadedFile] : []" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xml,.csv" (modelChange)="FileUploaded($event)"> </uploadfile>
    </div>


    <div class="d-flex justify-content-between mt-4 border-top pt-2">
        <button type="button" class="btn btn-outline-success float-right" [disabled]="!UploadedFile" (click)="StepChange.emit(2)">המשך</button>
        <button type="button" class="btn btn-outline-dark float-left" (click)="Cancel()">בטל</button>
    </div>
</div>

<div *ngIf="(StepChange | async) ==2">

    <div class="d-flex justify-content-center" *ngIf="!DataTables">
        <i class="fa fa-spinner fa-pulse fa-5x fa-fw mt-5 text-secondary"></i>
    </div>
    <div *ngIf="DataTables">

        <ng-container *ngIf="DataTables.length > 1">
            אנא בחר גליון
            <select class="form-control" [ngModel]="SelectedTableIndex" (ngModelChange)="BuildCatalogFields($event)">
                <option *ngFor="let dataTable of DataTables; index as i " [value]="i">{{dataTable.Name}}</option>
            </select>
        </ng-container>

        <div class="fieldCandidateTable">
            <table class="table table-hover table-sm " *ngIf="fieldCandidates">
                <thead>
                    <tr>
                        <th scope="col">ייבא</th>
                        <th scope="col">שם </th>
                        <th scope="col">קוד </th>
                        <th scope="col">הערות</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let fieldCandidate of fieldCandidates">
                        <td>
                            <checkbox [(model)]="fieldCandidate.Enable" [disabled]="!!fieldCandidate.ErrorMessage" ></checkbox>
                        </td>
                        <td>
                            {{fieldCandidate.Name}}
                        </td>
                        <td>
                            {{fieldCandidate.Code}}
                        </td>
                        <td>
                            {{fieldCandidate.ErrorMessage}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="d-flex justify-content-between mt-4 border-top pt-2">
            <button type="button" class="btn btn-outline-success float-right" [disabled]="!UploadedFile" (click)="StepChange.emit(3)">המשך</button>
            <button type="button" class="btn btn-outline-dark float-left" (click)="Cancel()">בטל</button>
        </div>
    </div>




</div>

<div *ngIf="(StepChange | async) ==3">
    <div>
        השדות הבאים יוספו לקטלוג
    </div>

    <div class="d-flex flex-wrap">
        <ng-container *ngFor="let fieldCandidate of fieldCandidates">
            <div *ngIf="fieldCandidate.Enable" class="fieldCandidatePreview mx-1">
                <span class="badge bg-primary">{{fieldCandidate.Name}} <span class="badge badge-info">{{fieldCandidate.Code}}</span></span>

            </div>
        </ng-container>

    </div>

    <div class="d-flex justify-content-between mt-4 border-top pt-2">
        <button type="button" class="btn btn-outline-success float-right" [disabled]="!UploadedFile" (click)="Save()">המשך</button>
        <button type="button" class="btn btn-outline-dark float-left" (click)="Cancel()">בטל</button>
    </div>
</div>