<div class="">
  <h1 *ngIf="Title" class="pr-3 d-inline">{{Title}}</h1>
  <div class="row mt-2">
    <div class="col-4" *ngIf="AllowToExport() ; else NoneExportMessage">
      <div class="btn-group pr-2" *ngIf="HasRole(['Admin', 'OrdersOperator'])" ngbDropdown>
        <button type="button" class="btn btn-outline-primary btn-sm">הדפסת מדבקות הפצה</button>
        <button type="button" class="btn btn-outline-primary btn-sm dropdown-toggle dropdown-toggle-split" id="dropdownBasic1" ngbDropdownToggle>
          <span class="sr-only">Toggle Dropdown</span>
        </button>
        <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <a class="dropdown-item btn btn-link" *ngFor="let method of FillersSettings.ShipmentTypeOptions; index as i" (click)="ExportOrders(method.id)">ייצא {{method.name}}</a>
        </div>
      </div>

      <div class="btn-group  pr-2" *ngIf="HasRole(['Admin', 'OrdersOperator'])" ngbDropdown autoClose="outside" #dropdown_update="ngbDropdown">
        <button type="button" class="btn btn-outline-primary btn-sm"> ייצאו נתונים</button>
        <button type="button" class="btn btn-outline-primary btn-sm dropdown-toggle dropdown-toggle-split" id="dropdownBasic2" ngbDropdownToggle>
          <span class="sr-only">Toggle Dropdown</span>
        </button>



        <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownBasic2">
          <a class="dropdown-item btn btn-link p-2" (click)="ExportOrders('ERP')">קובץ ERP</a>
          <a class="dropdown-item btn btn-link p-2" (click)="ExportOrders('Chita')">קובץ ציטה</a>
          <a class="dropdown-item btn btn-link p-2" (click)="ExportOrders('Paxxi')">קובץ Paxxi</a>
          <a class="dropdown-item btn btn-link p-2" (click)="ExportOrders('Baldar')">קובץ בלדר</a>
          <a class="dropdown-item btn btn-link p-2" (click)="ExportOrders('LionWheel')">קובץ LionWheel</a>
          <a class="dropdown-item btn btn-link p-2" (click)="ExportOrders('CollectPdf')">דוח ליקוט</a>
          <div class="dropdown-divider"></div>


          <div ngbDropdown class="d-inline-block left-nav d-flex flex-column " display="static">
            <div class="dropdown-item btn pl-2 " id="dropdownBasic1" ngbDropdownToggle>
              קיבצי דיווח <i class="fas fa-caret-left pl-1"></i>
            </div>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="right: 100%; top: 0%;">
              <button ngbDropdownItem (click)="ExportOrders('AllShipmentOrderTrackingReport'); dropdown_update.close() ">כול החנות הנתמכות</button>
              <button ngbDropdownItem (click)="ExportOrders('SuperParmShipmentOrderTrackingReport'); dropdown_update.close() ">קובץ דיווח לסופרפארם</button>
              <button ngbDropdownItem (click)="ExportOrders('GrouponShipmentOrderTrackingReport'); dropdown_update.close() ">קובץ דיווח לגרופון</button>
              <button ngbDropdownItem (click)="ExportOrders('BehazdaaShipmentOrderTrackingReport'); dropdown_update.close() ">קובץ דיווח לבהצדעה</button>
            </div>
          </div>
        </div>
      </div>


      <div class="btn-group pr-2" ngbDropdown autoClose="outside" #dropdown_update="ngbDropdown">
        <button type="button" class="btn btn-outline-primary btn-sm"> עדכן הזמנות </button>
        <button type="button" class="btn btn-outline-primary btn-sm dropdown-toggle dropdown-toggle-split" id="dropdown_update" ngbDropdownToggle>
          <span class="sr-only">Toggle Dropdown</span>
        </button>
        <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdown_update">
          <div ngbDropdown class="d-inline-block left-nav d-flex flex-column " display="static">
            <div class="dropdown-item btn pl-2 " id="dropdownBasic4" ngbDropdownToggle>
              סטאטוס הזמנה <i class="fas fa-caret-left pl-1"></i>
            </div>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic4" style="right: 100%; top: 0%;">
              <button ngbDropdownItem *ngFor="let orderStatus of FillersSettings.OrderStateOptions" (click)="UpdateSelectedOrdersState(orderStatus.id); dropdown_update.close() ">{{orderStatus.name}}</button>
            </div>
          </div>

          <div ngbDropdown class="d-inline-block left-nav d-flex flex-column " display="static">
            <div class="dropdown-item btn pl-2 " id="dropdownBasic4" ngbDropdownToggle>
              סטאטוס משלוח <i class="fas fa-caret-left pl-1"></i>
            </div>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic4" style="right: 100%; top: 0%;">
              <ng-container *ngFor="let shipmentStatus of FillersSettings.ShipmentStatusOptions">
                <button ngbDropdownItem *ngIf="shipmentStatus.id.startsWith('ShipmentStatus')" (click)="UpdateSelectedOrdersShipmentStatus(shipmentStatus.id.substring('ShipmentStatus'.length)); dropdown_update.close() ">{{shipmentStatus.name}}</button>
              </ng-container>

            </div>
          </div>

          <div ngbDropdown class="d-inline-block left-nav d-flex flex-column " display="static">
            <div class="dropdown-item btn pl-2 " id="dropdownBasic5" ngbDropdownToggle>
              סטאטוס ERP <i class="fas fa-caret-left pl-1"></i>
            </div>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic5" style="right: 100%; top: 0%;">
              <div *ngFor="let orderERPExportStatus of SelectableERPStatus" placement="left" [ngbTooltip]="this.HTMLHelper.IsErpSetStatusDisabled(orderERPExportStatus) ? 'לא ניתן להמיר לסטטוס העבר אם ההזמנה נמצאת כבר בסטטוס עבר או בוטל' : ''">
                <button ngbDropdownItem (click)="UpdateSelectOrderERPExportStatus(orderERPExportStatus); dropdown_update.close()" [disabled]="this.HTMLHelper.IsErpSetStatusDisabled(orderERPExportStatus)">{{orderERPExportStatus | enumDescription : "OrderErpExportStatus"}}</button>
              </div>

            </div>
          </div>

          <div ngbDropdown class="d-inline-block left-nav d-flex flex-column " display="static">
            <div class="dropdown-item btn pl-2 " id="dropdownBasic5" ngbDropdownToggle>
              סטאטוס ליקוט <i class="fas fa-caret-left pl-1"></i>
            </div>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic5" style="right: 100%; top: 0%;">
              <button ngbDropdownItem *ngFor="let orderCollectStatus of FillersSettings.OrderCollectStatusOptions" (click)="UpdateSelectOrderCollectStatus(orderCollectStatus.id); dropdown_update.close() ">{{orderCollectStatus.name}}</button>
            </div>
          </div>



          <div ngbDropdown class="d-inline-block left-nav d-flex flex-column " display="static">
            <div class="dropdown-item btn pl-2 " id="dropdownBasic4" ngbDropdownToggle>
              ערוץ הפצה <i class="fas fa-caret-left pl-1"></i>
            </div>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic4" style="right: 100%; top: 0%;">
              <button ngbDropdownItem *ngFor="let shipmentType of FillersSettings.ShipmentTypeOptions" (click)="UpdateSelectedOrdersShipmentType(shipmentType.id); dropdown_update.close() ">{{shipmentType.name}}</button>
            </div>
          </div>
          <ng-container *ngIf="Mode?.AllowPriceListOverride">
            <div class="dropdown-divider"></div>
            <div [title]="(!Selector || Selector.GetSelectedItemCount() === 0)? 'לא סומן אף מוצר': ''">
              <button ngbDropdownItem class="dropdown-item btn btn-link pl-2 delete-menu-item" [disabled]="!Selector || Selector.GetSelectedItemCount() === 0" (click)="open(contentResetRecomamdedOrderItemsPrice,null,null,ResetRecomamdedOrderItemsPrice)">
                <i class="fas fa-dollar-sign mr-2 ml-1"> </i>אפס מחיר למחיר מחירון</button>
            </div>
          </ng-container>

          <div class="dropdown-divider"></div>
          <div [title]="(!Selector || Selector.GetSelectedItemCount() === 0)? 'לא סומנה אף הזמנה': ''">
            <button ngbDropdownItem class="dropdown-item btn btn-link pl-2 delete-menu-item" [disabled]="!Selector || Selector.GetSelectedItemCount() === 0" (click)="open(contentDeleteOrders,null,null,DeleteOrders)"> <i class="fas fa-trash-alt mr-2 ml-1"> </i>מחק
              הזמנות</button>
          </div>


        </div>
      </div>


    </div>
    <ng-template #NoneExportMessage>
      <div class="col-4 ">
        <span class="badge badge-light text-secondary font-italic"> לא ניתן לייצא או לשנות סטאטס הזמנות לפי חתך שמבוסס
          על הזמנות בהקמה</span>
      </div>
    </ng-template>
    <div class="col-4 text-center">
      <ng-container *ngIf="HasRole(['Admin', 'OrdersManager'])">
        <button type="button" class="btn btn-outline-primary btn-sm" (click)="OrderWizard()"> תהליך השלמת פרטים </button>
        <div class="d-inline-block ml-2" *ngIf="!AllowToExport()" [title]="(!Selector || Selector.GetSelectedItemCount() === 0)? 'לא סומנה אף הזמנה': ''">
          <button class="btn btn-outline-danger btn-sm" [disabled]="!Selector || Selector.GetSelectedItemCount() === 0" (click)="open(contentDeleteOrders,null,null,DeleteOrders)"> <i class="fas fa-trash-alt mr-2 ml-1"> </i>מחק
            הזמנות</button>
        </div>
      </ng-container>
    </div>
    <div class="col-4">
      <div class="input-group mb-3">
        <input type="text" class="form-control  border-primary" placeholder="" aria-label="" aria-describedby="basic-addon1" [(ngModel)]="FreeSearchText" (keydown.enter)="RunFreeSearch()">
        <div class="btn-group  pr-2" ngbDropdown>
          <button type="button" class="btn btn-outline-primary rounded-0" (click)="RunFreeSearch();">
            <span>{{SelectedFreeSearchOption.name}}</span>
            <i class="fas fa-search ml-2"></i>
          </button>
          <button type="button" class="btn btn-outline-primary  dropdown-toggle dropdown-toggle-split" id="dropdownSearchOptions" ngbDropdownToggle>
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownSearchOptions">
            <a class="dropdown-item btn btn-link" *ngFor="let item of FreeSearchOptions" (click)="SelectedFreeSearchOption = item">{{item.name}}</a>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-2">
      <h2 class="pt-2 d-inline">נמצאו <span class="badge badge-pill badge-light">{{orders?.TotalItemsCount}}</span></h2>
      <div class="d-inline-block ml-4 " style="line-height: 80%;" *ngIf="Selector">
        <button type="button" class="btn btn-link m-0 p-0" style="line-height: 50%;" (click)="Selector.ToggleSelectAll()">{{Selector?.SelectAllItems ? "הסר" : "סמן"}} הכול</button> <br />
        <button type="button" class="btn btn-link m-0 p-0" style="line-height: 50%;" (click)="toggleSelectPage()">{{isFullPageSelected ? "הסר" : "סמן"}} עמוד</button>
      </div>
    </div>
    <div class="col-1 d-flex justify-content-end align-items-start">
      <button *ngIf="IsERPOrderViewPage && QuickButtonActionType=='ExportERPFile'" class="btn btn-outline-success btn-sm text-nowrap" (click)="ExportOrders('ERP')"><i class="fas fa-download pr-1"></i>ייצא לקובץ ERP</button>
      <button *ngIf="IsERPOrderViewPage && QuickButtonActionType=='SetOrderStatusToExportable'" class="btn btn-outline-success btn-sm text-nowrap" (click)="ERPUpdateStatusCandidate= 32; open(confirmChangeERPStatus,null,null,UpdateConfirmedSelectOrderERPExportStatus);"><i class="fas fa-paper-plane pr-1"></i>העבר ל ERP </button>
    </div>
    <div class="col-5">
      <pager *ngIf="orders && orders.PageSize < orders.TotalItemsCount" [PageSize]="Query.PageSize" [PageIndex]="Query.PageIndex" (PageIndexChange)="Query.PageIndex = $event; reloadPage(false, false);" [TotalItemCount]="orders.TotalItemsCount"></pager>
    </div>
    <div class="col-4 d-flex align-items-stretch">
      <div class="d-inline-block">
        <button class="btn btn-sm btn-outline-secondary" [disabled]="!IsFilterSelected" (click)="ResetFilters();" title="{{IsFilterSelected ? 'בטל סינון' : ''}}">
          <span>
            <i class="fas fa-filter"></i>
            <i *ngIf="IsFilterSelected" class="fas fa-times position-relative text-danger" style="top: .25rem;left: 1rem;height: 0px;width: 0px;"></i>
          </span>
        </button>
      </div>

      <div class="d-inline-block">
        <ngx-bootstrap-multiselect *ngIf="FillersSettings.OrderStateOptions && FillersSettings.OrderStateOptions.length" class="pl-1 mb-1 d-inline-block" [settings]="FillersSettings.CheckboxListSetting" [texts]="FillersSettings.GetIMultiSelectTexts('מ. הזמנה')" [options]="FillersSettings.OrderStateOptions" [ngModel]="Query.OrderState" [ngClass]="{'selected': Query.OrderState}" (ngModelChange)="Query.OrderState = $event; reloadPage(true,true);"></ngx-bootstrap-multiselect>

        <ngx-bootstrap-multiselect *ngIf="FillersSettings.ShipmentStatusOptions && FillersSettings.ShipmentStatusOptions.length" class="pl-1 mb-1 d-inline-block" [settings]="FillersSettings.CheckboxListSetting" [texts]="FillersSettings.GetIMultiSelectTexts('ס. משלוח')" [options]="FillersSettings.ShipmentStatusOptions" [ngModel]="ShipmentStatusStateQueryFilter" [ngClass]="{'selected': Query.ShipmentStatus ||  Query.ShipmentState}" (ngModelChange)="ShipmentStatusStateQueryFilter = $event; reloadPage(true,true);"></ngx-bootstrap-multiselect>

        <ngx-bootstrap-multiselect *ngIf="FillersSettings.OrderERPExportStatusOptions && FillersSettings.OrderERPExportStatusOptions.length" class="pl-1 mb-1 d-inline-block" [settings]="FillersSettings.CheckboxListSetting" [texts]="FillersSettings.GetIMultiSelectTexts('ס. ERP')" [options]="FillersSettings.OrderERPExportStatusOptions" [ngModel]="Query.ERPExportStatus" [ngClass]="{'selected': Query.ERPExportStatus}" (ngModelChange)="Query.ERPExportStatus = $event; reloadPage(true,true);"></ngx-bootstrap-multiselect>
        <ngx-bootstrap-multiselect *ngIf="FillersSettings.OrderCollectStatusOptions && FillersSettings.OrderCollectStatusOptions.length" class="pl-1 mb-1 d-inline-block" [settings]="FillersSettings.CheckboxListSetting" [texts]="FillersSettings.GetIMultiSelectTexts('ס. ליקוט')" [options]="FillersSettings.OrderCollectStatusOptions" [ngModel]="Query.CollectStatus" [ngClass]="{'selected': Query.CollectStatus}" (ngModelChange)="Query.CollectStatus = $event; reloadPage(true,true);"></ngx-bootstrap-multiselect>

        <ngx-bootstrap-multiselect *ngIf="FillersSettings.OrderSourceOptions && FillersSettings.OrderSourceOptions.length" class="pl-1 mb-1 d-inline-block" [settings]="FillersSettings.CheckboxListSetting" [texts]="FillersSettings.GetIMultiSelectTexts('ע. מכירה')" [options]="FillersSettings.OrderSourceOptions" [ngModel]="Query.OriginStore" [ngClass]="{'selected': Query.OriginStore}" (ngModelChange)="Query.OriginStore = $event; reloadPage(true,true);"></ngx-bootstrap-multiselect>
        <ngx-bootstrap-multiselect *ngIf="FillersSettings.ShipmentTypeOptions && FillersSettings.ShipmentTypeOptions.length" class="pl-1 mb-1 d-inline-block" [settings]="FillersSettings.CheckboxListSetting" [texts]="FillersSettings.GetIMultiSelectTexts('ע. הפצה')" [options]="FillersSettings.ShipmentTypeOptions" [ngModel]="Query.ShipmentType" [ngClass]="{'selected': Query.ShipmentType}" (ngModelChange)="Query.ShipmentType = $event; reloadPage(true,true);"></ngx-bootstrap-multiselect>
        <dateropdownpicker class="pl-1 mb-1 d-inline-block" [dropButClass]="(Query.AgeSeconds) ? 'btn btn-sm btn-success' : 'btn btn-sm btn-primary'" [model]="HTMLHelper.DateSelectiontModel(Query.AgeSeconds,Query.CreatedDateFrom,Query.CreatedDateTo)" (modelChange)=" SetOrderDateFilter( $event);" title="גיל"></dateropdownpicker>
        <dateropdownpicker class="pl-1 mb-1 d-inline-block" [dropButClass]="(Query.OperationAgeSeconds) ? 'btn btn-sm btn-success' : 'btn btn-sm btn-primary'" [model]="HTMLHelper.DateSelectiontModel(Query.OperationAgeSeconds,Query.OperationTimeFrom,Query.OperationTimeTo)" (modelChange)=" SetOperationDateFilter( $event);" title="ת. יבוא"></dateropdownpicker>
      </div>
    </div>
  </div>
</div>


<div *ngIf="orders && Selector ; else waitingPanel">
  <table class="table table-hover table-sm">
    <thead>
      <tr>
        <!--0-->
        <th scope="col">סמן</th>
        <!--1-->
        <th scope="col" class="text-center">ס. הזמנה</th>
        <!--2-->
        <th scope="col" class="order-list-header" (click)="SortListBy('SupplierNumber')">מספר הזמנה <i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'SupplierNumber', 'fa-angle-down': Query?.OrderByFields[0] ==  '-SupplierNumber'}"></i>
        </th>
        <!--3-->
        <th scope="col" class="order-list-header text-nowrap" *ngIf="IsFieldEnabled('Shipments.ItemType', true)" (click)="SortListBy('ShipmentType')">ע. הפצה<i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'ShipmentType', 'fa-angle-down': Query?.OrderByFields[0] ==  '-ShipmentType'}"></i>
        </th>
        <!--4-->
        <th scope="col" class="order-list-header text-nowrap" *ngIf="IsFieldEnabled('Source.OriginStore', true)" (click)="SortListBy('OriginStore')">ע. מכירה<i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'OriginStore', 'fa-angle-down': Query?.OrderByFields[0] ==  '-OriginStore'}"></i>
        </th>
        <!--5-->
        <th scope="col" class="order-list-header text-nowrap" *ngIf="IsFieldEnabled('ERPExportRemarks', false)" (click)="SortListBy('ERPExportRemarks')">הערות ERP<i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'ERPExportRemarks', 'fa-angle-down': Query?.OrderByFields[0] ==  '-ERPExportRemarks'}"></i>
        </th>

        <!--6-->
        <th scope="col" class="order-list-header text-nowrap" *ngIf="IsFieldEnabled('Items.Product.SKU', false)" (click)="SortListBy('ItemSKU')">מק"ט<i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'ItemSKU', 'fa-angle-down': Query?.OrderByFields[0] ==  '-ItemSKU'}"></i>
        </th>
        <!--6.5-->
        <th scope="col" class="order-list-header text-nowrap" *ngIf="IsFieldEnabled('Items.Quantity', false)" (click)="SortListBy('ItemsQuantity')">כמות<i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'ItemsQuantity', 'fa-angle-down': Query?.OrderByFields[0] ==  '-ItemsQuantity'}"></i>
        </th>
        <!--7-->
        <th scope="col" class="order-list-header text-nowrap" *ngIf="IsFieldEnabled('Items.Product.Name', true)" (click)="SortListBy('ItemName')">מוצר<i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'ItemName', 'fa-angle-down': Query?.OrderByFields[0] ==  '-ItemName'}"></i>
        </th>
        <!--7.5-->
        <th scope="col" class="order-list-header text-nowrap" *ngIf="IsFieldEnabled('Items.Quantity', true)" (click)="SortListBy('Quantity')">כמות<i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'Quantity', 'fa-angle-down': Query?.OrderByFields[0] ==  '-Quantity'}"></i>
        </th>
        <!--8-->
        <th scope="col" class="order-list-header text-nowrap" *ngIf="IsFieldEnabled('Items.Price', false)" (click)="SortListBy('Price')">מחיר יח<i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'Price', 'fa-angle-down': Query?.OrderByFields[0] ==  '-Price'}"></i>
        </th>
        <!--9-->
        <th scope="col" class="order-list-header text-nowrap" *ngIf="IsFieldEnabled('Items.Product.StorePriceList.RecommendedPrice', false)" (click)="SortListBy('RecommendedPrice')">מחיר מחירון<i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'RecommendedPrice', 'fa-angle-down': Query?.OrderByFields[0] ==  '-RecommendedPrice'}"></i>
        </th>

        <!--9.5-->
        <th scope="col" class="order-list-header text-nowrap" *ngIf="IsFieldEnabled('Calculated_TotalPrice', true)" (click)="SortListBy('Calculated_TotalPrice')">סה"כ<i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'Calculated_TotalPrice', 'fa-angle-down': Query?.OrderByFields[0] ==  '-Calculated_TotalPrice'}"></i>
        </th>

        <!--10-->
        <th scope="col" class="order-list-header text-nowrap" *ngIf="IsFieldEnabled('Audit.CreatedAt', true)" (click)="SortListBy('CreatedDate')">ת. הזמנה<i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'CreatedDate', 'fa-angle-down': Query?.OrderByFields[0] ==  '-CreatedDate'}"></i>
        </th>
        <!--11-->
        <th scope="col" class="order-list-header text-nowrap" *ngIf="IsFieldEnabled('Source.OperationTime', true)" (click)="SortListBy('OperationTime')">ת. יבוא<i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'OperationTime', 'fa-angle-down': Query?.OrderByFields[0] ==  '-OperationTime'}"></i>
        </th>
        <!--12-->
        <th scope="col" class="order-list-header text-nowrap" *ngIf="IsFieldEnabled('Shopper.FullName', true)" (click)="SortListBy('ShopperName')">לקוח<i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'ShopperName', 'fa-angle-down': Query?.OrderByFields[0] ==  '-ShopperName'}"></i>
        </th>
        <!--13-->
        <th scope="col" class="order-list-header text-nowrap" *ngIf="IsFieldEnabled('Items.Product.Note', true) || IsFieldEnabled('Remarks', true) || IsFieldEnabled('OperationalRemarks', true) || IsFieldEnabled('Shipments.Remarks', true)">
          הערות</th>
        <!--14-->
        <th scope="col" class="order-list-header text-nowrap" *ngIf="IsFieldEnabled('Audit.CreatedAt', false)" (click)="SortListBy('CreatedDate')">גיל<i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'CreatedDate', 'fa-angle-down': Query?.OrderByFields[0] ==  '-CreatedDate'}"></i>
        </th>
        <!--15-->
        <th scope="col" class="order-list-header text-nowrap" *ngIf="IsFieldEnabled('Shipments.Address.City', true)" (click)="SortListBy('City')">עיר<i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'City', 'fa-angle-down': Query?.OrderByFields[0] ==  '-City'}"></i>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let order of orders.Items; index as i">
        <ng-container *ngFor="let orderItem of order.Items; index as j">
          <tr *ngIf="(Mode.SplitProducts && $any(orderItem).Product) || (!Mode.SplitProducts && j == 0)   " (mouseenter)="MouseOverOrder= order" [ngClass]="{'table-danger': order.ERPExportStatus == 128 ,'table-success': order.ERPExportStatus == 64 }">
            <!--0-->
            <td>
              <checkbox [model]="Selector.IsItemSelected(order.Id + '.' + j)" (modelChange)="Selector.SelectItem(order.Id + '.' + j,$event)"></checkbox>
            </td>
            <!--1-->
            <td class="text-center text-nowrap" placement="top" [ngbPopover]="popContentOrderState" [triggers]="(HTMLHelper.GetOrderState(order) >= 2) ? 'mouseenter:mouseleave' : ''" container="body"><i *ngIf="HTMLHelper.GetOrderState(order) >= 2" class="fas fa-exclamation-triangle text-warning"></i>{{order.Shipments[0]?.Status | enumDescription : "ShipmentStatus"}} ({{order.State | enumDescription : "OrderState"}})</td>
            <!--2-->
            <td scope="row" class="text-nowrap"><a [routerLink]="['/order', order.Id]"> {{order.SupplierNumber}}</a></td>
            <!--3-->
            <td *ngIf="IsFieldEnabled('Shipments.ItemType', true)" class="text-nowrap">

              {{order.Shipments[0].ItemType | shipmentDescriptionAsync | async}}
              <unifyshipmenticon *ngIf="order.Shipments[0].PreUnifyShipment" [OrderId]="order.Id" [UnifyShipmentId]="order.Shipments[0].Id"></unifyshipmenticon>

            </td>

            <!--4-->
            <td *ngIf="IsFieldEnabled('Source.OriginStore', true)" class="text-nowrap"><i class="far" *ngIf="IsFieldEnabled('Source.Type', true)" [ngClass]="HTMLHelper.GetOrderSourceTypeFontAwesomeIcon(order)" title="{{order.Source.Type | enumDescription : 'OrderSource'}}"></i> {{order.Source.OriginStore | storeDescriptionAsync | async}}</td>
            <!--5-->
            <td *ngIf="IsFieldEnabled('ERPExportRemarks', false)">{{order.ERPExportRemarks}}</td>

            <!--6-->
            <td *ngIf="IsFieldEnabled('Items.Product.SKU', false)" class="pre-line text-truncate prod-desc">
              <a *ngIf="Mode.SplitProducts && $any(orderItem).Product.ItemType=='MasterBasicOrderProduct';else ProductSkuRowElse" [routerLink]="['/product', $any(orderItem).Product.Id]"> {{Mode.SplitProducts? $any(orderItem).Product.SKU : HTMLHelper.GetOrderItemSKUDescription(order)}}</a>
              <ng-template #ProductSkuRowElse>
                {{Mode.SplitProducts? $any(orderItem).Product.SKU : HTMLHelper.GetOrderItemSKUDescription(order)}}
              </ng-template>
            </td>
            <!--6.5-->
            <td *ngIf="IsFieldEnabled('Items.Quantity', false)" class="pre-line text-truncate prod-desc">
              {{Mode.SplitProducts? $any(orderItem).Quantity : HTMLHelper.GetOrderProductItemTotalQuantity(order)}}
            </td>

            <!--7-->
            <td *ngIf="IsFieldEnabled('Items.Product.Name', true)" class="pre-line text-truncate prod-desc">{{
              Mode.SplitProducts ? $any(orderItem).Product.Name : HTMLHelper.GetOrderItemTitleDescription(order)}}</td>
            <!--7.5-->
            <td *ngIf="IsFieldEnabled('Items.Quantity', true)">
              <div class="ml-2">
                {{ (Mode.SplitProducts ? $any(orderItem).Quantity : HTMLHelper.GetOrderItemQuantity(order) ) | number:'1.0-2'}}
              </div>
            </td>
            <!--8-->
            <td *ngIf="IsFieldEnabled('Items.Price', false)" class="pre-line text-truncate prod-desc">{{
              (Mode.SplitProducts ? HTMLHelper.GetOrderItemPrice(orderItem) : HTMLHelper.GetOrderCombindPrice(order)) | number:'1.2-2' }}</td>
            <!--9-->
            <td *ngIf="IsFieldEnabled('Items.Product.StorePriceList.RecommendedPrice', false)" class="pre-line text-truncate prod-desc">{{ (Mode.SplitProducts ?
              HTMLHelper.GetOrderItemRecommendedPrice(orderItem) : HTMLHelper.GetOrderCombindRecommendedPrice(order)) | number:'1.2-2'}}</td>

            <!--9.5-->
            <td *ngIf="IsFieldEnabled('Calculated_TotalPrice', true)">{{order.Calculated_TotalPrice | number:'1.2-2'}}</td>

            <!--10-->
            <td *ngIf="IsFieldEnabled('Audit.CreatedAt', true)">{{order.Audit.CreatedAt | date:"dd/MM/yyyy"}}</td>
            <!--11-->
            <td *ngIf="IsFieldEnabled('Source.OperationTime', true)">{{order.Source.OperationTime | date:"dd/MM/yyyy"}}</td>
            <!--12-->
            <td *ngIf="IsFieldEnabled('Shopper.FullName', true)" class="text-nowrap">{{order.Shopper.FullName}}</td>
            <!--13-->
            <td *ngIf="IsFieldEnabled('Items.Product.Note', true) || IsFieldEnabled('Remarks', true) || IsFieldEnabled('OperationalRemarks', true) || IsFieldEnabled('Shipments.Remarks', true)" class="pre-line text-truncate prod-remark" title="{{HTMLHelper.GetOrderCombindRemarks(order)}}">{{HTMLHelper.GetOrderCombindRemarks(order)}}</td>
            <!--14-->
            <td *ngIf="IsFieldEnabled('Audit.CreatedAt', false)" class="text-nowrap">{{order.Audit.CreatedAt | toAge}}</td>
            <!--15-->
            <td *ngIf="IsFieldEnabled('Shipments.Address.City', true)" class="text-nowrap">{{order.Shipments[0]?.Address?.City}}</td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>


<ng-template #waitingPanel>
  <div class="d-flex justify-content-center">
    <i class="fa fa-spinner fa-pulse fa-5x fa-fw mt-5 text-secondary"></i>
  </div>
</ng-template>

<ng-template #popContentOrderState>
  <div *ngFor="let validation of MouseOverOrder.FailedValidations; index as i">
    {{validation.Message}} <span *ngIf="validation.SubMessage">({{validation.SubMessage}})</span>
  </div>

</ng-template>


<ng-template #contentExportOrders let-c="close" let-d="dismiss">
  <div class="modal-header">
    <div>
      <h4 class="modal-title">אפשרויות ייצוא</h4>
      <div class="ml-2">מייצא {{ExportOptions.SelectedOrdersCount}} הזמנות <span *ngIf="$any(ExportOptions).ShipmentType">ל{{
          HTMLHelper.GetShipmentTypeDescription($any(ExportOptions).ShipmentType)}}</span></div>
      <h6 class="mt-2">{{ExportOptions.Message}}</h6>
    </div>

    <button type="button" class="close" aria-label="Close" (click)="c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">

    <div class="form-row p-1" *ngIf="ExportOptions.PopupType=='Print'">
      <div class="form-group col-md-12">
        <checkbox class="mr-1" id="ChangeShipmentStatus2000" [model]="ExportOptions.ChangeShipmentStatus == 2000" (modelChange)="ExportOptions.ChangeShipmentStatus = ($event ? 2000 : null)"></checkbox>
        <label for="ChangeShipmentStatus2000">עדכן סטטוס "הודפס" להזמנות שיוצאו</label>
      </div>
    </div>
    <div class="form-row p-1" *ngIf="ExportOptions.PopupType=='ExportReport'">
      <div class="form-group col-md-12">
        <checkbox class="mr-1" id="ChangeShipmentStatus5000" [model]="ExportOptions.ChangeShipmentStatus == 5000" (modelChange)="ExportOptions.ChangeShipmentStatus = ($event ? 5000 : null)"></checkbox>
        <label for="ChangeShipmentStatus5000">עדכן סטטוס "דווח" להזמנות שיוצאו</label>
      </div>
    </div>

    <div class="form-row p-1" *ngIf="ExportOptions.PopupType=='ERP'">
      <div class="form-group col-md-12">
        <checkbox class="mr-1" id="ChangeErpStatusToExported" [(model)]="ExportOptions.ChangeErpStatusToExported"></checkbox>
        <label for="ChangeErpStatusToExported">עדכן סטטוס ייצוא ERP ל-<b>עבר ל-ERP</b></label>
      </div>
    </div>

    <div class="form-row p-1" *ngIf="ExportOptions.PopupType=='CollectPdf'">
      <div class="form-group col-md-12">
        <checkbox class="mr-1" id="ChangeCollectStatusToCollected" [(model)]="ExportOptions.ChangeCollectStatusToCollected"></checkbox>
        <label for="ChangeCollectStatusToCollected">עדכן סטטוס <b>ליקוט</b> ל- <b>עבר</b></label>
      </div>
    </div>

    <div class="form-row p-1">
      <div class="form-group col-md-12">
        <checkbox class="mr-1" id="ExportDataFile" [(model)]="ExportOptions.ExportDataFile"></checkbox>
        <label for="ExportDataFile">ייצא אקסל הזמנות לביצוע</label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" [disabled]="!ExportOptions.SelectedOrdersCount" class="btn btn-outline-dark" (click)="c('save')" [ngSwitch]="ExportOptions.PopupType">
      <ng-container *ngSwitchCase="'ExportReport'">יצא קובץ</ng-container>
      <ng-container *ngSwitchCase="'Print'">הדפס</ng-container>
      <ng-container *ngSwitchCase="'CollectPdf'">הדפס</ng-container>
      <ng-container *ngSwitchDefault>אשר</ng-container>
    </button>
    <button type="button" class="btn btn-outline-dark" (click)="c('cancel')">בטל</button>
  </div>
</ng-template>



<ng-template #contentDeleteOrders let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">אישור מחיקה</h4>
    <button type="button" class="close" aria-label="Close" (click)=" c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    האם אתה בטוח שאתה רוצה למחוק את כול ההזמנות המסומנות ?
    <h6 class="mt-2">סה"כ {{Selector?.GetSelectedItemCount()}} הזמנות</h6>
  </div>
  <div class="modal-footer d-block">
    <button type="button" class="btn btn-outline-danger float-right" (click)="c('save')">אשר
      <i class="far fa-trash-alt"></i></button>
    <button type="button" class="btn btn-outline-dark float-left" (click)="c('cancel')">בטל</button>
  </div>
</ng-template>


<ng-template #contentResetRecomamdedOrderItemsPrice let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">אישור דריסת מחירים</h4>
    <button type="button" class="close" aria-label="Close" (click)=" c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    האם אתה בטוח שאתה רוצה לדרוס את כול המחירים עבור ממוצרים המסומנים ?
    <h6 class="mt-2">אם קיים מחיר מחירון למוצר יתאפס מחירו למחיר זה</h6>
  </div>
  <div class="modal-footer d-block">
    <button type="button" class="btn btn-outline-success float-right" (click)="c('save')">אשר <i class="fas fa-check"></i></button>
    <button type="button" class="btn btn-outline-dark float-left" (click)="c('cancel')">בטל</button>
  </div>
</ng-template>

<ng-template #confirmChangeERPStatus let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">אישור עדכון</h4>
    <button type="button" class="close" aria-label="Close" (click)=" c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    מעדכן <b>{{Selector?.GetSelectedItemCount()}}</b> הזמנות לסטטוס <b *ngIf="ERPUpdateStatusCandidate == 32">העבר ל-ERP</b>
  </div>
  <div class="modal-footer d-block">
    <button type="button" class="btn btn-outline-danger float-right" (click)="c('save')">אשר
      <i class="far fa-trash-alt"></i></button>
    <button type="button" class="btn btn-outline-dark float-left" (click)="c('cancel')">דחה</button>
  </div>
</ng-template>