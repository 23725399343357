import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, Subject, BehaviorSubject } from 'rxjs';
import { CommonService } from './common.service';
declare var $: any;
import { ShipmentState, ShipmentStatus, IDeliveryShipment, IShipment, IShipmentType, IShipmentTypes, UndefinedShipmentType, IDropPoint, IBoxItShipment, IDropPointShipment, GetDropPointType } from '../models/shipments.models';
import { IBaseQuery, IGeoCoordinate, IPageResult } from '../models/common.models';
import { IApiResult } from '../models/api.model';
import { IExternalFile } from '../models/files.models';

import { map, shareReplay } from 'rxjs/operators';
import { GetShipmentItemFromOrder, IOrderSearchRequest } from './order.service';
import { IOrder, OrderErpExportStatus } from '../models/orders.models';
import { ISupplierConfiguration } from '../models/suppliers';

@Injectable()
export class ShipmentService {

  constructor(private _common: CommonService, private _http: HttpClient) { }
  private _apiUrl = "/api/Shipments";

  CreateNewDeliveryShipment(): IDeliveryShipment {
    return {
      Id: null,
      SupplierId: null,
      OrderId: null,
      Address: this._common.CreateNewIAddress(),
      ItemType: UndefinedShipmentType,
      Audit: null,
      Recipient: this._common.CreateNewIIdentity(),
      State: ShipmentState.Active,
      Status: ShipmentStatus.New,
      PackagesCount: 1
    };
  }
  CreateNewShipment(): IShipment {
    return {
      Id: null,
      SupplierId: null,
      OrderId: null,
      ItemType: "",
      Audit: null,// this._common.CreateNewItemAuditTrace(),
      Recipient: this._common.CreateNewIIdentity(),
      State: ShipmentState.Active,
      Status: ShipmentStatus.New,
      PackagesCount: 1
    };
  }

  public PrintShipments(shipmentsQuery: IShipmentSearchRequrest, exportType: string): Observable<IApiResult<IExternalFile>> {
    return this._http.post<IApiResult<IExternalFile>>(this._apiUrl + '/PrintShipments/' + exportType, shipmentsQuery);
  }

  public IsValidShipmentItemType(type: string | null): boolean {
    if (!type) return false;
    if (type.toLowerCase() == "deliveryshipment" || type.toLowerCase() == UndefinedShipmentType.toLowerCase()) return false;

    return true;
  }


  public GetShipmentTypes(): Observable<IApiResult<IPageResult<IShipmentType>>> {
    return this._http.get<IApiResult<IPageResult<IShipmentType>>>(this._apiUrl + '/GetShipmentTypes');
  }


  /*
    public get ShipmentTypes(): Observable<IShipmentTypes> {
      if (!this._shipmentTypes) {
        this._shipmentTypes = this._http.get<IApiResult<IPageResult<IShipmentType>>>(this._apiUrl + '/GetShipmentTypes').pipe(
          map(result => {
            var answer: IShipmentTypes = {};
            if (result.Code == 0) {
              for (var i = 0; i < result.Result.Items.length; i++) {
                answer[result.Result.Items[i].Id] = result.Result.Items[i];
              }
            }
            return answer;
          }),
          shareReplay(1)
        );
      }
      return this._shipmentTypes;
    }
  */
  public ChangeOrderShipmnetType(orderQuery: IOrderSearchRequest, shipmentType: string): Observable<IApiResult<boolean>> {
    return this._http.post<IApiResult<boolean>>(this._apiUrl + '/ChangeOrderShipmnetType', { Query: orderQuery, ShipmentType: shipmentType })
  }

  private cacheGetDropPoints: { [key: string]: Observable<IApiResult<IPageResult<IDropPoint>>> } = {};

  public GetDropPoints(itemType: string): Observable<IApiResult<IPageResult<IDropPoint>>> {
    if (!this.cacheGetDropPoints[itemType]) {
      this.cacheGetDropPoints[itemType] = this._http.get<IApiResult<IPageResult<IDropPoint>>>(this._apiUrl + '/GetDropPoints/' + itemType)
        .pipe(shareReplay(1));

      setTimeout(() => { delete this.cacheGetDropPoints[itemType] }, 1000 * 60 * 60);
    }
    return this.cacheGetDropPoints[itemType];
  }

  public GetDropPoint(itemType: string, dropPoint: IDropPoint): Observable<IDropPoint> {
    return (this.GetDropPoints(itemType)).pipe(
      map(result => {
        if (result.Result?.Items?.length) {
          if (dropPoint?.Code?.length) return result.Result.Items.find(dp => dp.Code == dropPoint.Code);
          else if (dropPoint?.Name?.length) return result.Result.Items.find(dp => dp.Name == dropPoint.Name);
          else return null;
        }
      }));
  }

  public GetAddressSuggest(term: string): Observable<{ Types: string[], Id: string, Name: string }[]> {
    return this._http.get<IApiResult<IPageResult<{ Types: string[], Id: string, Name: string }>>>(this._apiUrl + '/GetAddressSuggest?address=' + encodeURIComponent(term)).pipe(map(result => result.Result.Items));
  }
  public GetAddressDetails(addressId: string): Observable<{ Id: string, Name: string, GeoCoordinate: IGeoCoordinate, ViewportNortheast: IGeoCoordinate, ViewportSouthwest: IGeoCoordinate }> {
    return this._http.get<IApiResult<{ Id: string, Name: string, GeoCoordinate: IGeoCoordinate, ViewportNortheast: IGeoCoordinate, ViewportSouthwest: IGeoCoordinate }>>(this._apiUrl + '/GetAddressDetails?addressId=' + encodeURIComponent(addressId)).pipe(map(result => result.Result));
  }


  public async ChangeShipmentType(SupplierConfig: ISupplierConfiguration, order: IOrder, previousShipType: string, newShipType) {

    order.Shipments[0] = $.extend(true, {}, order.Shipments[0]);
    order.Shipments[0].ItemType = newShipType;
    if (previousShipType != newShipType) {
      // check if we need to change the default order line shipment details
      if (SupplierConfig.Shipments.hasOwnProperty(order.Shipments[0].ItemType) &&
        SupplierConfig.Shipments.hasOwnProperty(previousShipType)) {
        let shipOrderRows = GetShipmentItemFromOrder(order);
        if (shipOrderRows.length) {
          const prevShipConf = SupplierConfig.Shipments[previousShipType];
          const nextShipConf = SupplierConfig.Shipments[order.Shipments[0].ItemType];

          shipOrderRows.forEach(shipOrderRow => {
            if ((!shipOrderRow.SKU) || shipOrderRow.SKU == prevShipConf.ImportConfiguration.SKU && shipOrderRow.Name == prevShipConf.ImportConfiguration.Description) {
              shipOrderRow.SKU = nextShipConf.ImportConfiguration.SKU;
              shipOrderRow.Name = nextShipConf.ImportConfiguration.Description
            }

            if (shipOrderRow.ItemPrice == prevShipConf.ImportConfiguration.DefaultPrice) {
              shipOrderRow.ItemPrice = nextShipConf.ImportConfiguration.DefaultPrice;

              if (shipOrderRow.ItemVATPrice) shipOrderRow.ItemVATPrice = Math.round(shipOrderRow.ItemPrice * SupplierConfig.VatRate * 100) / 100;
            }
          });

        }
      }
    }
    if ((order.Shipments[0] as any as IDropPointShipment).DropPoint) {
      const ordinalDropPoint = (order.Shipments[0] as any as IDropPointShipment).DropPoint;
      delete (order.Shipments[0] as any as IDropPointShipment).DropPoint;

      var dropPoint = await this.GetDropPoint(GetDropPointType(order.Shipments[0].ItemType), ordinalDropPoint).toPromise()
      if (dropPoint) {
        (order.Shipments[0] as any as IDropPointShipment).DropPoint = dropPoint;
        order.Shipments[0] = $.extend(true, {}, order.Shipments[0]);
      }
      else {
        (order.Shipments[0] as any as IDropPointShipment).DropPoint = ordinalDropPoint;
        order.Shipments[0] = $.extend(true, {}, order.Shipments[0]);
        //   (order.Shipments[0] as any as IDropPointShipment).DropPoint.ItemType = "DropPoint";
      }
    }

  }
  public GetNearestDropPoints(itemType: string, city: string, street: string | undefined | null): Observable<IApiResult<IPageResult<IDropPoint>>> {
    var url = this._apiUrl + '/GetNearestDropPoints/' + itemType + "?city=" + encodeURIComponent(city);
    if (street) url += "&street=" + encodeURIComponent(street);
    return this._http.get<IApiResult<IPageResult<IDropPoint>>>(url);
  }

  public SubmitShipment(shipment: IShipment): Observable<IApiResult<IShipment>> {
    return this._http.post<IApiResult<IShipment>>(this._apiUrl + '/SubmitShipment', shipment);
  }

  public UpdateShipments(shipmentIds: string[], data: UpdateShipmentsData) {
    return this._http.post<IApiResult<boolean>>(this._apiUrl + '/UpdateShipments', $.extend({ ShipmentIds: shipmentIds }, data));
  }
}


export interface UpdateShipmentsData {
  Status?: ShipmentStatus,
  PackagesCount?: number
}

export interface IShipmentSearchRequrest extends IBaseQuery {
  OrderId?: string
}
