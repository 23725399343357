<ng-container *ngIf="!IsDlg; else DialogMode">
  <header-menu></header-menu>
  <div class='container-fluid h-100 se-outer-grid'>
    <div class='row  h-100'>
      <div class='col-sm-2 se-nav-menu-outer'>
        <div style="display: none;">
          <!-- diabale chrome autocomplate -->
          <input type="text" name="user"><!-- Can be type="text" -->
          <input type="password" name="password" autocomplete="new-password">
        </div>

        <nav-menu></nav-menu>
      </div>
      <div class='col-sm-10 body-content'>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

</ng-container>

<ng-template #DialogMode>
  <router-outlet></router-outlet>
</ng-template>