<nav aria-label="Page navigation example">
  <ul class="pagination justify-content-center">
    <li class="page-item" [ngClass]="{'disabled' : PageIndex == 0 }">
      <span class="page-link" aria-label="Previous" (click)="SetPage( PageIndex -1 )">
        <span aria-hidden="true">&laquo;</span>
        <span class="sr-only">Previous</span>
      </span>
    </li>

    <li class="page-item" *ngFor="let pageNum of PageNumbers" [ngClass]="{'active' : (pageNum - 1) == PageIndex}">
      <span class="page-link" (click)="SetPage(pageNum - 1)">{{pageNum}}</span>
    </li>

    <li class="page-item" [ngClass]="{'disabled' : PageIndex == LastPageIndex }">
      <span class="page-link" aria-label="Next" (click)="SetPage( PageIndex +1 )">
        <span aria-hidden="true">&raquo;</span>
        <span class="sr-only">Next</span>
      </span>
    </li>
  </ul>
</nav>