import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonService, ConvertObjectToQuery } from './common.service';

declare var $: any;
import { IApiResult } from '../models/api.model';
import { IProduct, IMasterAliasProductRelationship, IProductRef, IMasterProduct, IAliasProduct, IMasterMatchResult, IMasterBasicMatchProduct, IAliasBasicProduct, IBaseProduct, IMasterBasicOrderProduct, IProductStockDetail, getMasterProductStoreValue, IImportProductOptions, DeliveryPackageSize } from '../models/products.models';
import { IPageResult, ILongRunningOperation, IBaseQuery } from '../models/common.models';
import { StoreType } from '../models/stores';
import { UndefinedShipmentType } from '../models/shipments.models';
import { IProductCatalog, IProductCatalogQuery } from '../models/product.catalog';



@Injectable()
export class ProductService {

  constructor(private _common: CommonService, private _http: HttpClient) { }
  private _apiUrl = "/api/Products";

  public CreateNewProduct(): IMasterProduct {
    return $.extend(true, {
      UPC: "",
      Name: "",
      Model: "",
      SKU: "",
      Attributes: [],
      ItemType: "MasterProduct",
      DefaultShipmentMethod: UndefinedShipmentType,
      DefaultShipmentPackageSize :  DeliveryPackageSize.Undefined,
      Stock: {}
    }, this._common.CreateNewIDBObject());
  }

  public ConvertToMasterBasicOrderProduct(product: IMasterProduct, store: string): IMasterBasicOrderProduct {

    var priceList = getMasterProductStoreValue(product, StoreType[store], "PriceList");
    return {
      ItemType: "MasterBasicOrderProduct",
      Id: product.Id,
      Name: product.Name,
      SKU: product.SKU,
      Attributes: product.Attributes,
      Brand: product.Brand,
      Model: product.Model,

      StorePriceList: priceList ?? null,
      UPC: product.UPC

    }

  }

  public CreateNewMasterBasicMatchProduct(product?: IBaseProduct): IMasterBasicMatchProduct {
    return {
      Id: (product) ? product.Id : null,
      UPC: (product) ? product.UPC : "",
      Name: (product) ? product.Name : "",
      Model: (product) ? product.Model : "",
      SKU: (product) ? product.SKU : "",
      Attributes: (product) ? product.Attributes : [],
      ItemType: "MasterBasicMatchProduct",
      DefaultShipmentMethod: (product && product["DefaultShipmentMethod"]) ? product["DefaultShipmentMethod"] : UndefinedShipmentType,
      DefaultShipmentPackageSize: (product && product["DefaultShipmentPackageSize"]) ? product["DefaultShipmentPackageSize"] : DeliveryPackageSize.Undefined,
    };
  }


  public CreateNewMasterAliasProductRelationship(source: StoreType, masters: IMasterMatchResult[], aliass: IProductRef<IAliasProduct>[]): IMasterAliasProductRelationship {
    return $.extend(true, {
      MasterMatches: masters,
      AliasProducts: aliass,
      OriginStore: source
    }, this._common.CreateNewIDBObject());
  }


  public CreateNewProductCatalog(): IProductCatalog {
    return $.extend(true, {
      Stores : [],
      Code: "",
      Name: "",
      Fields: []
    }, this._common.CreateNewIDBObject());
  }

  public SearchProducts(search: IProductSearchRequest): Observable<IApiResult<IPageResult<IProduct>>> {
    return this._http.get<IApiResult<IPageResult<IProduct>>>(this._apiUrl + '/Search?' + ConvertObjectToQuery(search));
  }

  public SearchMasterAliasProductRelationship(search: IMasterAliasProductRelationshipQuery): Observable<IApiResult<IPageResult<IMasterAliasProductRelationship>>> {
    return this._http.get<IApiResult<IPageResult<IMasterAliasProductRelationship>>>(this._apiUrl + '/SearchMasterAliasProductRelationship?' + ConvertObjectToQuery(search));

  }

  public DeleteProductsByQuery(search: IProductSearchRequest, reason: string): Observable<IApiResult<boolean>> {
    return this._http.delete<IApiResult<boolean>>(this._apiUrl + '/Delete?Reason=' + encodeURIComponent(reason) + "&" + ConvertObjectToQuery(search));
  }
  public SaveProduct(prod: IProduct): Observable<IApiResult<IMasterProduct>> {
    return this._http.put<IApiResult<IMasterProduct>>(this._apiUrl + '/Save', prod);
  }

  public SetProductStock(productId: string, stock?: IProductStockDetail): Observable<IApiResult<boolean>> {
    return this._http.put<IApiResult<boolean>>(this._apiUrl + '/SetProductStock', { "ProductId": productId, "Stock": stock });
  }


  public SetProductActiveStore(productQuery: IProductSearchRequest, store: StoreType, active: boolean): Observable<IApiResult<boolean>> {
    return this._http.put<IApiResult<boolean>>(this._apiUrl + '/SetProductActiveStore', { "Query": productQuery, Store: store, "ActiveInStore": active });
  }


  public SaveMasterAliasProductRelationship(prodRel: IMasterAliasProductRelationship): Observable<IApiResult<IMasterAliasProductRelationship>> {
    return this._http.put<IApiResult<IMasterAliasProductRelationship>>(this._apiUrl + '/SaveMasterAliasProductRelationship', prodRel);
  }

  public DeleteMasterAliasProductRelationship(search: IMasterAliasProductRelationshipQuery, reason: string): Observable<IApiResult<boolean>> {
    return this._http.delete<IApiResult<boolean>>(this._apiUrl + '/DeleteMasterAliasProductRelationship?Reason=' + encodeURIComponent(reason) + "&" + ConvertObjectToQuery(search));
  }

  public MatchProducts(products: IProductRef<IAliasBasicProduct>[], OriginStore: StoreType): Observable<IApiResult<IMasterAliasProductRelationship[]>> {

    return this._http.post<IApiResult<IMasterAliasProductRelationship[]>>(this._apiUrl + '/MatchProducts', {
      Products: products,
      OriginStore: OriginStore
    });

  }

  public ImportProductsFromFiles(uploadFileIds: string[], options: IImportProductOptions): Observable<IApiResult<ILongRunningOperation>> {
    return this._http.post<any>(this._apiUrl + '/ImportProductsFromFiles', { FileIds: uploadFileIds, ImportProductOptions: options });
  }

  public PrintProducts(type: string, query: IProductSearchRequest): Observable<IApiResult<ILongRunningOperation>> {
    return this._http.post<any>(this._apiUrl + '/Print/' + type, query);
  }

  public GetPathValue(productId: string, store: StoreType, path: string): Observable<IApiResult<any>> {
    return this._http.get<IApiResult<any>>(this._apiUrl + '/GetPathValue?productId=' + encodeURIComponent(productId) + '&store=' + encodeURIComponent(store) + '&path=' + encodeURIComponent(path));
  }


  public SearchProductCatalog(search: IProductCatalogQuery): Observable<IApiResult<IPageResult<IProductCatalog>>> {
    return this._http.get<IApiResult<IPageResult<IProductCatalog>>>(this._apiUrl + '/SearchProductCatalog?' + ConvertObjectToQuery(search));
  }

  public SaveProductCatalog(prod: IProductCatalog): Observable<IApiResult<IProductCatalog>> {
    return this._http.put<IApiResult<IProductCatalog>>(this._apiUrl + '/SaveProductCatalog', prod);
  }
  
  
  public DeleteProductCatalog(query : IProductCatalogQuery, reason : string): Observable<IApiResult<IProductCatalog>> {
    return this._http.delete<IApiResult<IProductCatalog>>(this._apiUrl + '/DeleteProductCatalog?' + ConvertObjectToQuery({...query, Reason : reason}));
  }
}


export interface IProductSearchRequest extends IBaseQuery {
  SKU?: string,
  UPC?: string,
  Name?: string,
  Type?: string,
  SKU_Contains?: string,
  Name_Contains?: string,
  FreeSearch?: string,
  StoreContext?: StoreType,
  ActiveInStore?: boolean
}


export interface IMasterAliasProductRelationshipQuery extends IBaseQuery {
  AnyMasterProductIds?: string[],
  AnyAliasProductIds?: string[],
  OriginStore?: StoreType
}