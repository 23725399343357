import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { first, firstValueFrom } from "rxjs";
import { IPageResult } from "src/app/models/common.models";
import { IProductCatalog, IProductCatalogQuery } from "src/app/models/product.catalog";
import { ProductService } from "src/app/services/product.service";
import { StoreService } from "src/app/services/store.service";
import { CatalogItemDeleteStart, CatalogLazyFetchAllStart } from "src/app/state/catalog.actions";
import { CatalogStateFeature } from "src/app/state/catalog.reducer";
declare var $: any;

@Component({
  selector: 'product-catalog-list',
  templateUrl: './product-catalog-list.page.component.html',
  styleUrls: ['./product-catalog-list.page.component.css'],

})
export class ProductCatalogListPageComponent implements OnInit {

  public Query: IProductCatalogQuery = {};
  public closeResult: string = "";

  public catalogs$ = this.store.select(CatalogStateFeature.selectCatalog);

  public SelectedProductCatalog: IProductCatalog | null = null;


  constructor(
    private _route: ActivatedRoute,
    private _productService: ProductService,
    private _storeService: StoreService,
    private _router: Router,
    private store: Store,
    private _modalService: NgbModal,
    public CdRef: ChangeDetectorRef) { }




  ngOnInit(): void {
    this.store.dispatch(CatalogLazyFetchAllStart());

  }
  CreateNewCatalog() {
    this._router.navigate(["/product-catalog/"]);
  }

  public DeleteSelectedProductCatalog = () => {
    if (this.SelectedProductCatalog) {
      this.store.dispatch(CatalogItemDeleteStart({ DeleteQuery: { Id: this.SelectedProductCatalog.Id }, Reason: "Deleted by the user in the catalog list page" }));
    }
  }

  public open(content, options, onInit, onSave) {
    if (onInit) onInit();
    this._modalService.open(content, options).result.then((result) => {
      if ((result === "save") && (onSave)) onSave();
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {

      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


}
