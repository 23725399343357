import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthorizationService } from '../../../../services/authurization.service';
import { OrderService } from 'src/app/services/order.service';
import { ILoginUserSession } from 'src/app/models/users.models';
import { Store } from '@ngrx/store';
import { SessionStateFeature } from 'src/app/state/session.reducer';
import { UserSessionLogout, UserSessionRefreshStart } from 'src/app/state/session.actions';
import { Router } from '@angular/router';
import { statisticsStateFeature } from 'src/app/state/statistics.reducer';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'header-menu',
  templateUrl: './header.menu.usercontrol.component.html',
  styleUrls: ['./header.menu.usercontrol.component.css']
})
export class HeaderMenuUserControlComponent implements OnInit, OnDestroy {


  constructor(private store: Store, private _orderService: OrderService, private _admin: AdminService, private router: Router) { }

  public UserSession!: ILoginUserSession;
  public ERPErrorOrderCount: number = 0
  public SupplierList: {
    Name: string;
    Id: string;
  }[] = null;

  private listener$ = this.store.select(SessionStateFeature.selectSession).subscribe(session => {
    if (session) {
      this.UserSession = session;
      if (this.UserSession.IsSuperAdmin) {
        this._admin.GetSuppliersList().subscribe(suppliers => {
          this.SupplierList = suppliers;
        });
      }
    }
    else this.router.navigate(['/login']);
  })
  public logout() {
    this.store.dispatch(UserSessionLogout());
  }
  ngOnInit(): void {
    this.store.select(statisticsStateFeature.selectErpErrorOrderCount).subscribe(count => this.ERPErrorOrderCount = count);
  }

  ngOnDestroy(): void {
    this.listener$.unsubscribe();
  }
  public OnSupplierChange(supplierId: string) {
    if (supplierId == this.UserSession.SupplierId) return;

    var oldSupplierId = this.UserSession.SupplierId;

    this.store.dispatch(UserSessionRefreshStart({ supplierId: supplierId }));
    this.store.select(SessionStateFeature.selectSession).subscribe(session => {
      if (session.SupplierId == supplierId && oldSupplierId != supplierId) {
        var listpages = ["/orders", "/products", "/import-orders-upload", "/admin","/import-stock-upload","/orders-shipment-merger"];

        if (listpages.findIndex(value => this.router.url.startsWith(value)) >= 0) {
          window.location.reload();
        }
        else window.location.href = "/home";
      }
    });
  }
}
