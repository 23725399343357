import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer } from "@angular/router";

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { CommonService, CompareArray } from '../../../services/common.service';


import { AliasProductMatchingStrategyType, ISupplierStoreConfiguration, ShippingPriceStrategyType, ShipmentMethodFindStrategyType, MultiplyShipmentItemsStrategyType, ISupplierCustomExportFileTemplateConfiguration } from '../../../models/suppliers';
import { AdminService } from '../../../services/admin.service';
import { IStoreDetails, StoreType } from '../../../models/stores';
import { StoreService } from '../../../services/store.service';
import { LOVsService } from 'src/app/services/LOVs.service';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map, flatMap, filter } from 'rxjs/operators';
import { FileType, IExternalFile } from 'src/app/models/files.models';
import { OperationService } from 'src/app/services/operation.service';
import { ILongRunningOperation, ILongRunningOperationItem, LongRunningOperationItemStatus } from 'src/app/models/common.models';
import { isNumber } from '@ng-bootstrap/ng-bootstrap/util/util';
import { IExportOptions } from '../product-export/product-export.process.page.component';
import { IProductSearchRequest, ProductService } from 'src/app/services/product.service';

import { Location } from '@angular/common';
import { DeepCopyObject, Delay } from 'src/app/helpers/common.helper';
import { promise } from 'protractor';
import { Store } from '@ngrx/store';
import { ConfigStateFeature, WaitForConfigSave } from 'src/app/state/config.reducer';
import { ConfigSetSupplierStoreConfiguration } from 'src/app/state/config.actions';
import { Actions } from '@ngrx/effects';

@Component({
  selector: 'app',
  templateUrl: './store.configuration.page.component.html',
  styleUrls: ['./store.configuration.page.component.css']
})
export class StoreConfigurationPageComponent implements OnInit, OnDestroy {
  closeResult: string;


  constructor(
    private _adminService: AdminService,
    private _storeService: StoreService,
    private _operationService: OperationService,
    private _location: Location,
    private _LOVsService: LOVsService,
    private _modalService: NgbModal,
    private _route: ActivatedRoute,
    private _productService: ProductService,
    private _urlSerializer: UrlSerializer,
    private store: Store,
    private actions$: Actions,
    private _router: Router) { }


  public StoreDetails!: IStoreDetails;
  public SupplierStoreConfiguration!: ISupplierStoreConfiguration;
  public OverrideDefaultOrdernumberField!: string;
  public OverrideDefaultPriceFieldIncludeNothing: boolean = false;
  public OverrideDefaultPriceField!: string;
  public OverrideDefaultPriceFieldType!: string;
  public ImportFileFields!: { id: string, name: string }[];
  public MatchProductByModelConfiguration: boolean = false;
  public OverrideDefaultPriceFieldTypes = [
    { id: "UnitPrice", name: "המחיר כולל מע\"מ" },
    { id: "UnitPriceWithOutVAT", name: "המחיר ללא מע\"מ" },
    /* { id: "UnitPriceWithVAT", name: "המחיר כולל מע\"מ" } */
  ];

  public AliasProductMatchingStrategyOptions = [AliasProductMatchingStrategyType.Default, AliasProductMatchingStrategyType.ByName, AliasProductMatchingStrategyType.BySKU, AliasProductMatchingStrategyType.ByIdentifierAndName];
  public ShippingPriceStrategyOptions = [ShippingPriceStrategyType.IfZeroOverrideByShipmentSetting, ShippingPriceStrategyType.OverrideByShipmentSetting, ShippingPriceStrategyType.SourceOnlySetting];
  public MultiplyShipmentItemsStrategyOptions = [MultiplyShipmentItemsStrategyType.Keep, MultiplyShipmentItemsStrategyType.Max, MultiplyShipmentItemsStrategyType.Average, MultiplyShipmentItemsStrategyType.First, MultiplyShipmentItemsStrategyType.Last];

  public ExportFileTypes = [FileType.Excel, FileType.CSV, FileType.QuoteCSV, FileType.TSV];
  public OrderCustomFieldsMappingContiner!:
    {
      orderField: string,
      importColumnsId: string,
      fieldAlias: string,
      defaultValue: string,
      customFieldIndex: number
    }[];

  public ShipmentTextsToMethods: { Text: string, Price?: number | string, ShipMethod: string }[];
  public DefaultConstTextToMethod = "__DEFAULT__";

  public ShipmentStrategiesOptions: { Text: string, Key: number, ShipmentStrategies: ShipmentMethodFindStrategyType[] }[] = [
    { Key: 1, Text: "עדיפות למפוי שיטות", ShipmentStrategies: [ShipmentMethodFindStrategyType.ShopDefault, ShipmentMethodFindStrategyType.MasterProduct, ShipmentMethodFindStrategyType.ShipmentOrderItemSKU] },
    { Key: 2, Text: "עדיפות למוצר", ShipmentStrategies: [ShipmentMethodFindStrategyType.MasterProduct, ShipmentMethodFindStrategyType.ShopDefault, ShipmentMethodFindStrategyType.ShipmentOrderItemSKU] }
  ];

  public PriceListFiles: IExternalFile[] = [];
  public CatalogFiles: IExternalFile[] = [];
  public SaveInprogress: boolean = false;
  private priceListTimer: number;
  public PriceListOperation: ILongRunningOperation;
  public PriceListOperationCompleteModel!: {
    TotalFiles: ILongRunningOperationItem[],
    SuccessFiles: ILongRunningOperationItem[],
    FailedFiles: ILongRunningOperationItem[]
  };

  public PrintPriceListURLQueryParams: any;
  ngOnInit() {
    this._route.params.subscribe(params => {
      this._storeService.GetStoreDetails(params['id']).subscribe(s => {
        this.StoreDetails = s;
        this.buildSelectors();
        this.store.select(ConfigStateFeature.selectConfiguration).pipe(filter(s => !!s)).subscribe(
          conf => {
            if (conf && conf.Stores && conf.Stores[this.StoreDetails.IdName]) {
              this.SupplierStoreConfiguration = DeepCopyObject(conf.Stores[this.StoreDetails.IdName]);
              if (this.SupplierStoreConfiguration.ImportConfiguration.OverrideDefaultFields["UnitPriceWithVAT"]) this.SupplierStoreConfiguration.ImportConfiguration.OverrideDefaultFields["UnitPriceWithVAT"];  // delete depricated value
            }
            else this.SupplierStoreConfiguration = this._adminService.CreateNewSupplierStoreConfiguration();

            if (!this.SupplierStoreConfiguration.ImportConfiguration.AliasProductMatchingStrategy) this.SupplierStoreConfiguration.ImportConfiguration.AliasProductMatchingStrategy = AliasProductMatchingStrategyType.Default;
            if (!this.SupplierStoreConfiguration.ImportConfiguration.ShipingPriceStrategy) this.SupplierStoreConfiguration.ImportConfiguration.ShipingPriceStrategy = ShippingPriceStrategyType.OverrideByShipmentSetting;


            this.bindControls();
            this.BuildPrintPriceListQueryParamsURL();
          });
        //  this._adminService.RefreshCurrentConfiguration().subscribe();
      });
    });
  }

  ngOnDestroy() {
    if (this.priceListTimer) clearTimeout(this.priceListTimer);

  }

  public bindControls() {
    this.OverrideDefaultOrdernumberField = "OrderNum";
    if (this.SupplierStoreConfiguration.ImportConfiguration.OverrideDefaultFields["OrderNum"]) {
      for (let OrderTableColumns in this.StoreDetails.FileImportColumns) {
        if (CompareArray(
          this.StoreDetails.FileImportColumns[OrderTableColumns],
          this.SupplierStoreConfiguration.ImportConfiguration.OverrideDefaultFields["OrderNum"])) {
          this.OverrideDefaultOrdernumberField = OrderTableColumns;
        }
      }
    }


    if (this.StoreDetails.FileImportColumns["UnitPriceWithOutVAT"]) {
      this.OverrideDefaultPriceField = "UnitPriceWithOutVAT";
      this.OverrideDefaultPriceFieldType = "UnitPriceWithOutVAT";
      this.OverrideDefaultPriceFieldIncludeNothing = false;
    }
    else if (this.StoreDetails.FileImportColumns["UnitPrice"]) {
      this.OverrideDefaultPriceField = "UnitPrice";
      this.OverrideDefaultPriceFieldType = "UnitPrice";
      this.OverrideDefaultPriceFieldIncludeNothing = false;
    }
    else {
      this.OverrideDefaultPriceFieldIncludeNothing = true;
      this.OverrideDefaultPriceField = null;
      this.OverrideDefaultPriceFieldType = "UnitPrice";
    }


    for (let i = 0; i < this.OverrideDefaultPriceFieldTypes.length; i++) {
      if (this.SupplierStoreConfiguration.ImportConfiguration.OverrideDefaultFields[this.OverrideDefaultPriceFieldTypes[i].id]) {
        this.OverrideDefaultPriceFieldType = this.OverrideDefaultPriceFieldTypes[i].id;

        for (let OrderTableColumns in this.StoreDetails.FileImportColumns) {
          if (CompareArray(
            this.StoreDetails.FileImportColumns[OrderTableColumns],
            this.SupplierStoreConfiguration.ImportConfiguration.OverrideDefaultFields[this.OverrideDefaultPriceFieldTypes[i].id])) {
            this.OverrideDefaultPriceField = OrderTableColumns;
          }
        }

      }
    }



    this.MatchProductByModelConfiguration = ((this.SupplierStoreConfiguration.ImportConfiguration.AdditionProductIdentifiersFields || []).indexOf("ProductModel") >= 0);
  }
  public buildSelectors() {
    this.ImportFileFields = [];
    for (var OrderTableColumns in this.StoreDetails.FileImportColumns) {
      this.ImportFileFields.push(
        {
          id: OrderTableColumns,
          name: this.StoreDetails.FileImportColumns[OrderTableColumns][0]
        });
    }
  }

  public IsNumber(value): boolean {
    if (value === undefined || value === null || value === "") return false;
    return !isNaN(value);
  }

  public MappingKeySearch = (text$: Observable<string>) =>
    text$.pipe(

      switchMap((text => {
        return this._LOVsService.SearchShipmentSourceTypeValue(this.StoreDetails.IdName, text, this.ShipmentTextsToMethods.map(item => item.Text));
      })),

      map(searchResults => {
        if (!searchResults) return [];
        var result = searchResults.Result.Items;
        return result;
      }
      ));


  public onMatchProductByModelConfigurationChange(newValue: boolean) {

    if (newValue) this.SupplierStoreConfiguration.ImportConfiguration.AdditionProductIdentifiersFields = ["ProductModel"];
    else this.SupplierStoreConfiguration.ImportConfiguration.AdditionProductIdentifiersFields = null;
  }
  public OnOverrideDefaultOrdernumberFieldChanged(newValue: string) {

    this.SupplierStoreConfiguration.ImportConfiguration.OverrideDefaultFields["OrderNum"] = this.StoreDetails.FileImportColumns[newValue];
  }

  public OnOverrideDefaultPriceFieldChanged() {

    for (var i = 0; i < this.OverrideDefaultPriceFieldTypes.length; i++) {
      delete this.SupplierStoreConfiguration.ImportConfiguration.OverrideDefaultFields[this.OverrideDefaultPriceFieldTypes[i].id];
    }
    if (this.OverrideDefaultPriceField && this.OverrideDefaultPriceField != "") {
      this.SupplierStoreConfiguration.ImportConfiguration.OverrideDefaultFields[this.OverrideDefaultPriceFieldType] = this.StoreDetails.FileImportColumns[this.OverrideDefaultPriceField];
    }
  }

  public async SaveAndReturn(): Promise<void> {

    this.SaveInprogress = true;

    var success = true;

    /*
        if (success && this.CatalogFiles && this.CatalogFiles.length) {
          success = await this.LoadCatalog();
        }
    */
    if (success && this.PriceListFiles && this.PriceListFiles.length) {
      success = await this.LoadPriceList();
    }


    if (success) {
      
      success = (await WaitForConfigSave(this.actions$,()=>{this.store.dispatch(ConfigSetSupplierStoreConfiguration({idName: this.StoreDetails.IdName , configuration :this.SupplierStoreConfiguration }));}));
    }


    if (success) {
      this._router.navigate(["/admin/store"]);
    }


    this.SaveInprogress = false;

  }



  private async LoadPriceList(): Promise<boolean> {
    var response = await this._productService.ImportProductsFromFiles(this.PriceListFiles.map(f => f.Id as string),
      {
        DataChangePolicy: 'ShopUpsert',
        FileType: 'Operational',
        PriceChangePolicy: 'Only',
        StockChangePolicy: 'Ignore',
        Store: this.StoreDetails.Type
      }).toPromise();

    return await this.resolveOperation(response.Result.Id);



  };
  /*
    private async LoadCatalog(): Promise<boolean> {
      var response = await this._adminService.LoadProductCatalog(this.StoreDetails.IdName, this.CatalogFiles.map(f => f.Id as string)).toPromise();
      return await this.resolveOperation(response.Result.Id);
    }
  */
  private async resolveOperation(operationId: string): Promise<boolean> {
    while (true) {
      var r = await this._operationService.GetOperation(operationId).toPromise();
      if (r.Result) {
        this.PriceListOperation = r.Result;
        if (r.Result.PresentComplete == 100) {

          if (!r.Result.Items.find(item => item.Status != LongRunningOperationItemStatus.Success)) return true;
          else {
            this.PriceListOperationCompleteModel = {
              FailedFiles: r.Result.Items.filter(item => item.Status != LongRunningOperationItemStatus.Success),
              SuccessFiles: r.Result.Items.filter(item => item.Status == LongRunningOperationItemStatus.Success),
              TotalFiles: r.Result.Items
            };
          }
          this.PriceListOperation.Items.length;
          return false;
        }
        else {
          await Delay(1000);
        }
      }
    }

  }




  private BuildPrintPriceListQueryParamsURL() {
    var query = {
      Type: "MasterProduct"
    } as IProductSearchRequest;
    this.PrintPriceListURLQueryParams = {
      returnUrl: this._location.path(true),
      returnDescription: "חזור להגדרת " + this.StoreDetails.Description,
      query: JSON.stringify(query),
      options: JSON.stringify({ FileTypes: [FileType.Export] } as IExportOptions),
      types: ["storeprice-" + this.StoreDetails.IdName],
      // isdlg: true
    };
  }
  public get SelectedShipmentStrategiesKey(): number {
    if (this.SupplierStoreConfiguration && this.SupplierStoreConfiguration.ImportConfiguration && this.SupplierStoreConfiguration.ImportConfiguration.ShipmentStrategies) {
      var result = this.ShipmentStrategiesOptions.find(option => this.IsArrayEqaul(option.ShipmentStrategies, this.SupplierStoreConfiguration.ImportConfiguration.ShipmentStrategies));
      if (result) return result.Key;
      else {
        var newResult: { Text: string, Key: number, ShipmentStrategies: ShipmentMethodFindStrategyType[] } = {
          Key: -1,
          Text: "מותאם אישית",
          ShipmentStrategies: this.SupplierStoreConfiguration.ImportConfiguration.ShipmentStrategies
        }
        this.ShipmentStrategiesOptions.push(newResult);
        return -1;
      }
    }
    return 1;
  }
  public set SelectedShipmentStrategiesKey(key: number) {
    this.SupplierStoreConfiguration.ImportConfiguration.ShipmentStrategies = this.ShipmentStrategiesOptions.find(option => option.Key == key).ShipmentStrategies;
  }
  public get SelectedShipmentStrategies(): ShipmentMethodFindStrategyType[] {
    if (this.SupplierStoreConfiguration && this.SupplierStoreConfiguration.ImportConfiguration && this.SupplierStoreConfiguration.ImportConfiguration.ShipmentStrategies) {
      return this.SupplierStoreConfiguration.ImportConfiguration.ShipmentStrategies;
    }
    else {
      return this._adminService.GetShipmentStrategiesDefault();
    }
  }


  public CreateShipmentTextsToMethods = () => {

    this.ShipmentTextsToMethods = [{
      ShipMethod: this.DefaultConstTextToMethod,
      Text: this.DefaultConstTextToMethod,
    }];

    for (const method in this.SupplierStoreConfiguration.ImportConfiguration.ShippingMethodDefaults) {
      if (this.SupplierStoreConfiguration.ImportConfiguration.ShippingMethodDefaults.hasOwnProperty(method)) {
        const shipDefs = this.SupplierStoreConfiguration.ImportConfiguration.ShippingMethodDefaults[method];

        shipDefs.forEach(shipDef => {
          if (shipDef.MatchString === this.ShipmentTextsToMethods[0].Text) {
            this.ShipmentTextsToMethods[0].ShipMethod = method;
          }
          else {
            this.ShipmentTextsToMethods.push({
              ShipMethod: method,
              Text: shipDef.MatchString,
              Price: shipDef.MatchShipPrice
            });
          }
        });
      }
    }
  }

  public AddShipmentTextsToMethods(index: number) {
    if (index >= 0) {
      this.ShipmentTextsToMethods.splice(index, 0, {
        ShipMethod: "",
        Text: "",
      });
    }
    else {
      this.ShipmentTextsToMethods.push({
        ShipMethod: "",
        Text: "",

      });
    }

  }
  public RemoveShipmentTextsToMethods(index: number) {
    this.ShipmentTextsToMethods.splice(index, 1);
  }

  public UpdateShippingMethodDefaultsFromShipmentTextsToMethods = () => {

    this.SupplierStoreConfiguration.ImportConfiguration.ShippingMethodDefaults = {};
    this.ShipmentTextsToMethods.forEach(map => {
      if (map.ShipMethod === this.DefaultConstTextToMethod && map.Text === this.DefaultConstTextToMethod) return;

      if (this.SupplierStoreConfiguration.ImportConfiguration.ShippingMethodDefaults.hasOwnProperty(map.ShipMethod)) {
        this.SupplierStoreConfiguration.ImportConfiguration.ShippingMethodDefaults[map.ShipMethod].push({ MatchString: map.Text, MatchShipPrice: (Number(map.Price) || map.Price == "0") ? Number(map.Price) : null });
      }
      else this.SupplierStoreConfiguration.ImportConfiguration.ShippingMethodDefaults[map.ShipMethod] = [{ MatchString: map.Text, MatchShipPrice: (Number(map.Price) || map.Price == "0") ? Number(map.Price) : null }];


    });
  }


  public CreateOrderCustomFieldsMappingContiner = () => {
    this.OrderCustomFieldsMappingContiner = [];

    ["Custom", "CustomItem"].forEach(fieldPrefix => {
      for (let index = 1; index < 10; index++) {
        const fieldName = fieldPrefix + index;
        let fieldAlias = null;
        let fieldDefault = null;
        if (this.SupplierStoreConfiguration.OrderCustomFieldAliases && this.SupplierStoreConfiguration.OrderCustomFieldAliases[fieldName]) {
          fieldAlias = this.SupplierStoreConfiguration.OrderCustomFieldAliases[fieldName].Name || null;
          fieldDefault = this.SupplierStoreConfiguration.OrderCustomFieldAliases[fieldName].DefaultValue || null;
        }

        let importColumnsId = null;

        for (let OrderTableColumns in this.StoreDetails.FileImportColumns) {
          if (CompareArray(
            this.StoreDetails.FileImportColumns[OrderTableColumns],
            this.SupplierStoreConfiguration.ImportConfiguration.OverrideDefaultFields[fieldName])) {
            importColumnsId = OrderTableColumns;
          }
        }

        this.OrderCustomFieldsMappingContiner.push({
          fieldAlias: fieldAlias,
          importColumnsId: importColumnsId,
          orderField: fieldName,
          defaultValue: fieldDefault,
          customFieldIndex: index
        });
      }
    });
  }
  public OnOrderCustomFieldsMapSelected(item: { orderField: string, importColumnsId: string, fieldAlias: string }) {
    if (!item.fieldAlias || item.fieldAlias.trim() == "") {
      const findImportField = this.ImportFileFields.filter(i => i.id == item.importColumnsId);
      if (findImportField.length) item.fieldAlias = findImportField[0].name.replace("_", " ");
    }

  }
  public UpdateOrderCustomFieldsMapping = () => {
    for (let index = 0; index < this.OrderCustomFieldsMappingContiner.length; index++) {
      const element = this.OrderCustomFieldsMappingContiner[index];
      if (!element.importColumnsId || element.importColumnsId.trim() === "") delete this.SupplierStoreConfiguration.ImportConfiguration.OverrideDefaultFields[element.orderField];
      else {
        this.SupplierStoreConfiguration.ImportConfiguration.OverrideDefaultFields[element.orderField] = this.StoreDetails.FileImportColumns[element.importColumnsId];
      }
      if (!this.SupplierStoreConfiguration.OrderCustomFieldAliases) this.SupplierStoreConfiguration.OrderCustomFieldAliases = {};

      if (!element.fieldAlias || element.fieldAlias.trim() === "") delete this.SupplierStoreConfiguration.OrderCustomFieldAliases[element.orderField];
      else {
        this.SupplierStoreConfiguration.OrderCustomFieldAliases[element.orderField] = {
          Name: element.fieldAlias,
          DefaultValue: element.defaultValue
        }
      }
    }
  }

  public ProductExportTemplateFiles(): (IExternalFile | null)[] {
    if (this.SupplierStoreConfiguration?.ExportConfiguration?.ProductTemplates) return this.SupplierStoreConfiguration.ExportConfiguration.ProductTemplates
      .map(t => {
        //    window['t'] = t;
        return (t.ItemType === "ExportFileTemplate") ? (t as ISupplierCustomExportFileTemplateConfiguration).Template : null;
      });

    return [];
  }


  public OnProductExportTemplateFileUploaded(files: IExternalFile[]) {

    if (!this.SupplierStoreConfiguration?.ExportConfiguration) this.SupplierStoreConfiguration.ExportConfiguration = {ItemType : "Basic"};
    if (!this.SupplierStoreConfiguration.ExportConfiguration.ProductTemplates) this.SupplierStoreConfiguration.ExportConfiguration.ProductTemplates = [{ ItemType: "ExportFileTemplate", FileName: "", FileType: FileType.Excel, Name: "", Template: null }];

    var fileType = this.SupplierStoreConfiguration.ExportConfiguration.ProductTemplates[0]?.FileType ?? FileType.Excel;
    var fileName = this.SupplierStoreConfiguration.ExportConfiguration.ProductTemplates[0]?.FileName ?? ""
    if (files[0].Type == FileType.XML) {
      fileType = FileType.XML;
      fileName = fileName.replace(/\..*$/, ".xml");
    }
    else if (fileType == FileType.XML) fileType = files[0].Type;

    this.SupplierStoreConfiguration.ExportConfiguration.ProductTemplates[0].Template = files[0]
  }

  public ExCompareArray = CompareArray;

  public open(content, options, onInit, onSave) {
    if (onInit) onInit();
    this._modalService.open(content, options).result.then((result) => {
      if ((result === "save") && (onSave)) onSave();
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {

      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  private IsArrayEqaul(a: any[], b: any[]): boolean {
    // if length is not equal 
    if (a.length != b.length) return false;
    else {
      // comapring each element of array 
      for (var i = 0; i < a.length; i++)
        if (a[i] != b[i])
          return false;
      return true;
    }
  }
}