import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';


@Component({
  selector: 'checkbox',
  templateUrl: './checkbox.usercontrol.component.html'
})
export class CheckboxUserControlComponent implements OnInit {

  constructor() {

  }


  @Output() modelChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() model!: boolean;
  @Input() id!: string;
  @Input() disabled: boolean;
  // Function to call when the rating changes.
  onChange = (model: boolean) => {

  };

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {
  };

  ngOnInit() {
    this.modelChange.subscribe(v => { this.onChange(v); });
  }

  public Click() {
    if (this.disabled) return;
    
    this.model = !this.model;
    this.modelChange.emit(this.model);
  }
  public Keydown(event) {
    //not working
  }


}
