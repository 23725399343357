import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { IOrder, OrderState } from '../../../models/orders.models';
import { OrderService, IOrderSearchRequest } from '../../../services/order.service';


import { debounce, map } from 'rxjs/operators';


import { of, timer } from 'rxjs';
import { AbstractControl, FormControlStatus, UntypedFormControl } from '@angular/forms';


@Component({
  selector: 'order-supplier-number-textbox',
  templateUrl: './order-supplier-number.textbox.usercontrol.component.html',
  styleUrls: ['./order-supplier-number.textbox.usercontrol.component.css']
})
export class OrderSupplierNumberTextboxUserControlComponent implements OnInit {

  constructor(private _orderService: OrderService) { }

  @Output() OnValidStateChange: EventEmitter<FormControlStatus> = new EventEmitter<FormControlStatus>();

  @Output() OrderChange: EventEmitter<IOrder> = new EventEmitter<IOrder>();
  @Input() Order!: IOrder;
  @Input() Title: string = "מספר הזמנה";

  public ValidationControl!: UntypedFormControl;

  ngOnInit(): void {

    this.ValidationControl = new UntypedFormControl(this.Order.SupplierNumber, null, (control: AbstractControl) => {
      if (!control.value) return of({ existsSupplierNumber: "חסר מספר הזמנה" });

      var query: IOrderSearchRequest = {
        SupplierNumber: control.value.trim(),
        PageSize: 0,
        NotOrderState: [OrderState.Draft],
        ExcludeIds: [this.Order.Id],
        OriginStore: [this.Order.Source.OriginStore]
      };

      return this._orderService.SearchOrders(query).pipe(
        debounce(() => timer(1000)),
        map(r => { 
          if (r.Result) {
            if (r.Result.TotalItemsCount == 0) {
              return null;
            }
            return { existsSupplierNumber: "מספר הזמנה קיים במערכת" };
          }
          return { existsSupplierNumber: "שגיאת מערכת אנא נסה שוב מאוחר יותר" };
        }))

    });


    this.ValidationControl.statusChanges.subscribe(s => {
      if (s == "VALID") {
        this.Order.SupplierNumber = this.ValidationControl.value;
        this.OrderChange.emit(this.Order);
      }
      if (s != "PENDING") this.OnValidStateChange.emit(s);
    });



  }



}






