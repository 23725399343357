
import { Injectable } from "@angular/core";
import { IStoreDetails, StoreType } from "../models/stores";
declare var $: any;
import { Observable } from "rxjs";
import { CommonService } from "./common.service";
import { HttpClient } from "@angular/common/http";
import { map, shareReplay } from "rxjs/operators";
import { IApiResult } from "../models/api.model";
import { IPageResult } from "../models/common.models";

@Injectable()
export class StoreService {


  constructor(private _common: CommonService, private _http: HttpClient) {
    this.ResetStoreCache();
  }
  private _apiUrl = "/api/Stores";
  private _stores!: Observable<IStoreDetails[]>;
  private _storesDictionary!: { [key: number]: IStoreDetails };

  public ResetStoreCache() {
    this._stores = this._http.get<IApiResult<IPageResult<IStoreDetails>>>(this._apiUrl + '/GetShopsDetails').pipe(
      map(result => {
        if (result.Code !== 0) return [];
        else {
          return result.Result.Items;
        }
      }),
      shareReplay(1)
    );
    this._stores.subscribe(result => {
      this._storesDictionary = {};
      for (let index = 0; index < result.length; index++) {
        const storeDetails = result[index];
        this._storesDictionary[storeDetails.Type] = storeDetails;
      }
    });
  }
  public GetStoreDetails(type: StoreType): Observable<IStoreDetails> {
    return this._stores.pipe(
      map(stores => {
        return stores.find(s => {
          if ($.isNumeric(type)) return (s.Type == (+type));
          else return (s.IdName == type.toString());
        }) ?? null;
        
      })
    );
  }

  public GetStoreList(): Observable<IStoreDetails[]> {
    return this._stores;
  }






}