import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import { IProductCatalog } from "../models/product.catalog";
import { CatalogFetchAllStart, CatalogFetchAllSuccess, CatalogItemSaveStart, CatalogItemSaveSuccess } from "./catalog.actions";


export const initialState: ICatalogState =
{
    loading: false,
    catalog: null,
}


export interface ICatalogState {
    catalog: IProductCatalog[]
    loading: boolean,
}


export const CatalogStateFeature = createFeature({
    name: 'catalog',
    reducer: createReducer(
        initialState,
        on(CatalogFetchAllStart, CatalogItemSaveStart, (state) => ({ ...state, loading: true })),
        on(CatalogFetchAllSuccess, (state, payload) => ({ ...state, loading: false, catalog: payload.Catalog })),
        on(CatalogItemSaveSuccess, (state, payload) => {
            var result = { ...state, loading: false }
            result.catalog = result.catalog.map(i => i); // copy the array to be writable 

            var index = result.catalog.findIndex(catalogItem => catalogItem.Id == payload.CatalogItem.Id);
            if (index !== -1) result.catalog[index] = payload.CatalogItem
            else result.catalog.push(payload.CatalogItem);

            return result;
        })
    ),
    extraSelectors: ({ selectCatalog }) => ({
        fullProductFieldList: createSelector(selectCatalog, catalogs => {
            if (catalogs === null) return null;
            
            var result = [
                { name: "מק\"ט\\פריט", path: "SKU" },
                { name: "שם מוצר", path: "Name" },
                { name: "UPC\\ברקוד", path: "UPC" },
                { name: "דגם", path: "Model" },
                { name: "שיטת משלוח", path: "DefaultShipmentMethod" },
                { name: "גודל חבילה", path: "DefaultShipmentPackageSize" },
                { name: "מלאי", path: "Stock.Amount" },
                { name: "ProductUser01", path: "ProductUser01" },
                { name: "ProductUser02", path: "ProductUser02" },
                { name: "ProductUser03", path: "ProductUser03" },
                { name: "ProductUser04", path: "ProductUser04" },
                { name: "ProductUser05", path: "ProductUser05" },
                { name: "ProductUser06", path: "ProductUser06" },
                { name: "ProductUser07", path: "ProductUser07" },
                { name: "ProductUser08", path: "ProductUser08" },
                { name: "ProductUser09", path: "ProductUser09" },
                { name: "ProductUser10", path: "ProductUser10" },
                { name: "מחירון", path: "PriceList.RecommendedPrice" },
                { name: "קטגוריה", path: "Category" },
                { name: "תמונות", path: "Media" },
                { name: "תיאור", path: "Description" },
                { name: "אחריות", path: "Warranty" }
              ];
          
        //      var catalogs = await firstValueFrom(this.store.select(CatalogStateFeature.selectCatalog).pipe(filter(v => !!v)));
          
              catalogs.forEach(catalog => {
                catalog.Fields.forEach(field => {
                  result.push({
                    name: `${catalog.Name} -> ${field.Name}`,
                    path: `${catalog.Code}.${field.Code}`
                  });
                });
              });
          
              return result;
        })

    })
});

