<h1>יבוא מוצרים</h1>
<p>
  כדי לזרז ולהקל על מערכת SendE ללמוד את קטלוג המוצרים שלך, ניתן לטעון קובץ נתוני מוצרים
</p>
<div class="container-fluid" *ngIf="FileTypes">
  <div class="row">
    <h5 class="col-3">סוג קובץ</h5>
    <div class="col-3 mb-2">
      <select class="form-control flex-fill" [ngModel]="SelectedFileType" (ngModelChange)="SelectedFileType = $event">
        <option *ngFor="let option of FileTypes" [value]="option.id">{{option.name}}</option>
      </select>
    </div>
    <div class="col-6" [ngSwitch]="SelectedFileType">
      <ng-container *ngSwitchCase="0">
        כדי לזרז ולהקל על מערכת SendE ללמוד את קטלוג המוצרים שלך, ניתן לטעון קובץ נתוני מוצרים ראשוני בהתאם לפורמט <a href="/Examples/product-import.xlsx" download>שכאן</a>.
      </ng-container>
      <ng-container *ngSwitchDefault></ng-container>
    </div>
  </div>

  <div class="row ">
    <h5 class="col-3">ערוץ מכירה</h5>
    <div class="col-3">
      <select *ngIf="ActiveStores" class="form-control h3" [(ngModel)]="SelectedStore">
        <option [value]="null">ברירת מחדל</option>
        <ng-container *ngFor="let store of ActiveStores">
          <option [value]="store.Type">{{store.Description}}</option>
        </ng-container>
      </select>
    </div>
  </div>
  <div class="row my-2">
    <h5 class="col-3">דרוס נתונים קיימים</h5>
    <div class="col-3">
      <select class="form-control flex-fill" [(ngModel)]="SelectedDataOverrideOption">
        <option *ngFor="let option of DataOverrideOptions | keyvalue : originalOrder " [value]="option.key">{{option.value}}</option>
      </select>
    </div>
    <div class="col-6" [ngSwitch]="SelectedDataOverrideOption">
      <ng-container *ngSwitchCase="'ShopGeneralUpsert'">
        הטעינה תעדכן נתונים של מוצרים שכבר קיימים לאותו ערוץ מכירה (כולל ערכים כללים אבל לא את ברירת המחדל) ותוסיף נתונים של מוצרים חדשים (כברירת מחדל - כי הם חדשים).
      </ng-container>
      <ng-container *ngSwitchCase="'GeneralInsert'">
        הטעינה תוסיף נתונים של מוצרים חדשים (כברירת מחדל - כי הם חדשים).
      </ng-container>
      <ng-container *ngSwitchCase="'ShopGeneralResetUpsert'">
        הטעינה תעדכן את הנתונים הקיימים (כולל ערכים כללים), תנקה נתונים שלא קיימים ותוסיף נתונים של מוצרים חדשים (כברירת מחדל - כי הם חדשים).
      </ng-container>

      <ng-container *ngSwitchDefault></ng-container>
    </div>
  </div>

  <div class="row my-2">
    <h5 class="col-3">עדכן מחירים</h5>
    <div class="col-3">


      <div class="form-check form-check-inline ml-4" *ngFor="let option of PriceOverrideOptions | keyvalue ">
        <input class="form-check-input " type="radio" name="PriceOverrideOption" id="{{option.key}}" [value]="option.key" [ngModel]="SelectedPriceOverrideOption" (ngModelChange)="SelectedPriceOverrideOption = $event">
        <label class="form-check-label pl-1 mr-4 " for="{{option.key}}">{{option.value}}</label>
      </div>
      <!--
      <select class="form-control flex-fill" [(ngModel)]="SelectedPriceOverrideOption">
        <option *ngFor="let option of PriceOverrideOptions | keyvalue " [value]="option.key">{{option.value}}</option>
      </select>
       -->
    </div>

    <div class="col-6">
      <div [ngSwitch]="SelectedPriceOverrideOption">
        <ng-container *ngSwitchCase="'Ignore'">
          המערכת לא תתייחס למחיר שיש בקובץ הנטען.
        </ng-container>
        <ng-container *ngSwitchCase="'Include'">
          המערכת תעדכן את המחירון שיש לאותו ערוץ (ככל שיש שדה מחיר בקובץ שמייבאים).
        </ng-container>
        <ng-container *ngSwitchCase="'Only'">
          המערכת תעדכן ותתייחס רק המחירון שיש לאותו ערוץ ותתעלם משאר הנתונים (ככל שיש שדה מחיר בקובץ שמייבאים).
        </ng-container>
        <ng-container *ngSwitchDefault></ng-container>
      </div>

      <div *ngIf="SelectedStore">
        להורדת המחירון השמור במערכת <a target="_blank" routerLink="/print-products" [queryParams]="PrintPriceListQueryParamsURL">לחץ כאן</a>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <h5 class="col-3">טען קבצי מוצרים </h5>
    <div class="col-3">
      <uploadfile [mode]="2" [(model)]="SelectedFiles"> </uploadfile>
    </div>
    <div class="col-6">

    </div>
  </div>

</div>



<button type="submit" [disabled]="ReadyForImportValidationMessage" class="btn btn-primary btn-block mt-2" (click)='ImpotFiles()'>
  <span *ngIf="!ReadyForImportValidationMessage;else else_continue_invalid_content">
    המשך <i class="fas fa-download"></i>
  </span>
  <ng-template #else_continue_invalid_content>{{ReadyForImportValidationMessage}}</ng-template>
</button>


<div class="mt-4" [ngSwitch]="SelectedFileType">

  <ng-container *ngSwitchCase="'Operational'">
    <span>מבנה הקובץ:</span>
    <dl class="ml-4 mb-1">
      <dt class="d-inline-block">עמודה SKU -</dt>
      <dd class="d-inline-block ml-2 m-0"><span class="badge badge-warning mr-1">שדה חובה</span>מק"ט המוצר כפי שהוא מופיע במערכת ה-ERP שלך.</dd>
    </dl>
    <dl class="ml-4 mb-1">
      <dt class="d-inline-block">עמודה Name -</dt>
      <dd class="d-inline-block ml-2 m-0"><span class="badge badge-warning mr-1">שדה חובה</span>תיאור המוצר (שם המוצר).</dd>
    </dl>
    <dl class="ml-4 mb-1">
      <dt class="d-inline-block">עמודה Model -</dt>
      <dd class="d-inline-block ml-2 m-0"><span class="badge badge-info mr-1">שדה רשות (לא חובה)</span>דגם של המוצר.</dd>
    </dl>
    <dl class="ml-4 mb-1">
      <dt class="d-inline-block">עמודה UPC -</dt>
      <dd class="d-inline-block ml-2 m-0"><span class="badge badge-info mr-1">שדה רשות (לא חובה)</span>UPC - המק"ט שעל האריזה (הברקוד) של המוצר.</dd>
    </dl>
    <dl class="ml-4 mb-1">
      <dt class="d-inline-block">עמודה Description -</dt>
      <dd class="d-inline-block ml-2 m-0"><span class="badge badge-info mr-1">שדה רשות (לא חובה)</span>תיאור מלא של המוצר</dd>
    </dl>
    <dl class="ml-4 mb-1">
      <dt class="d-inline-block">עמודה Category -</dt>
      <dd class="d-inline-block ml-2 m-0"><span class="badge badge-info mr-1">שדה רשות (לא חובה)</span>קטגוריה של המוצר</dd>
    </dl>
    <dl class="ml-4 mb-1">
      <dt class="d-inline-block">עמודה Brand -</dt>
      <dd class="d-inline-block ml-2 m-0"><span class="badge badge-info mr-1">שדה רשות (לא חובה)</span>מותג של המוצר</dd>
    </dl>
    <dl class="ml-4 mb-1">
      <dt class="d-inline-block">עמודה Note -</dt>
      <dd class="d-inline-block ml-2 m-0"><span class="badge badge-info mr-1">שדה רשות (לא חובה)</span>הערות על המוצר</dd>
    </dl>
    <dl class="ml-4 mb-1">
      <dt class="d-inline-block">עמודה ChitaCargoType -</dt>
      <dd class="d-inline-block ml-2 m-0"><span class="badge badge-info mr-1">שדה רשות (לא חובה)</span>סוג מטען (ציטה) המשוייך למוצר</dd>
    </dl>
    <dl class="ml-4 mb-1">
      <dt class="d-inline-block align-top ">עמודה DefaultShipmentMethod -</dt>
      <dd class="d-inline-block ml-2 m-0">
        <div>
          <span class="badge badge-info mr-1">שדה רשות (לא חובה)</span>
          שיטת המשלוח שתקבע כברירת מחדל למוצר (לדוגמא - מקרר תמיד ישלח עם "שליח עצמי").
        </div>
        <ng-container *ngIf="AvailableShipmentTypesLength">
          <div class=""> להלן הערכים השונים שניתן לבחור בשיטת המשלוח:</div>
          <div class="container" *ngIf="AvailableShipmentTypes ">
            <div class="row" *ngFor="let shipmentType of AvailableShipmentTypes | keyvalue ">
              <div class="col-4 px-0 font-weight-bold">{{shipmentType.key}}</div>
              <div class="col-8 pl-1">{{shipmentType.value.Description}}</div>
            </div>
          </div>
        </ng-container>
        <div class="alert alert-warning" *ngIf="AvailableShipmentTypesLength === 0">
          טרם הוגדרו ערוצי הפצה
        </div>
        <div class="font-weight-light"><i class="fas fa-asterisk mr-1 text-secondary"></i>מציג את שיטות המשלוח בהתאם לערוצי ההפצה שהוגדרו</div>
      </dd>
    </dl>
    <dl class="ml-4 mb-1">
      <dt class="d-inline-block align-top ">עמודה DefaultShipmentPackageSize -</dt>
      <dd class="d-inline-block ml-2 m-0">
        <div>
          <span class="badge badge-info mr-1">שדה רשות (לא חובה)</span>
          סוג חבילה שתקבע כברירת מחדל למוצר (לדוגמא תנור ריצפה תמיד ישלח על גבי "משטח").
        </div>

        <div class=""> להלן הערכים השונים שניתן לבחור כסוג משלוח:</div>
        <div class="container" *ngIf="AvailableDeliveryPackageSizes ">
          <div class="row" *ngFor="let size of AvailableDeliveryPackageSizes  ">
            <div class="col-4 px-0 font-weight-bold">{{size.code}}</div>
            <div class="col-8 pl-1">{{size.name}}</div>
          </div>
        </div>
      </dd>
    </dl>
    <dl class="ml-4 mb-1">
      <dt class="d-inline-block"> כול עמודה שמתחילה ב Attribute -</dt>
      <dd class="d-inline-block ml-2 m-0"><span class="badge badge-info mr-1">שדה רשות (לא חובה)</span>תכונה של המוצר</dd>
    </dl>
    <dl class="ml-4 mb-1">
      <dt class="d-inline-block"> כול עמודה שמתחילה ב Media -</dt>
      <dd class="d-inline-block ml-2 m-0"><span class="badge badge-info mr-1">שדה רשות (לא חובה)</span>קישור לקובץ מדיה של המוצר</dd>
    </dl>
    <dl class="ml-4 mb-1">
      <dt class="d-inline-block">עמודה Warranty -</dt>
      <dd class="d-inline-block ml-2 m-0"><span class="badge badge-info mr-1">שדה רשות (לא חובה)</span>תיאור אחריות עבור המוצר</dd>
    </dl>
    <dl class="ml-4 mb-1">
      <dt class="d-inline-block">עמודה OrderSerialNumberControl -</dt>
      <dd class="d-inline-block ml-2 m-0"><span class="badge badge-info mr-1">שדה רשות (לא חובה)</span>הגדרה עבור ניהול מספר טבוע למוצר.<b> יכול להיות רק OrderOptional, OrderERPRequire או Disable (ברירת מחדל).</b></dd>
    </dl>
    <dl class="ml-4 mb-1">
      <dt class="d-inline-block">עמודות נוספות</dt>
      <dd class="d-inline-block ml-2 m-0"><span class="badge badge-info mr-1">שדות רשות (לא חובה)</span>ProductUser01 עד ProductUser10 - שדה מותאם אישית.</dd>
    </dl>

  </ng-container>
  <ng-container *ngSwitchDefault>

    <ng-container *ngIf="SelectedCustomImportProductFormat">

      <span>מבנה הקובץ:</span>

      <dl class="ml-4 mb-1" *ngFor="let field of SelectedCustomImportProductFormat?.ProductImportFieldMapping">
        <dt class="d-inline-block">עמודה {{field.SourceColumn}} </dt>
        <dd class="d-inline-block ml-2 m-0"><span class="badge badge-warning mr-1" *ngIf="field.DestinationProductField == 'SKU'">שדה חובה</span>{{GetFieldName(field.DestinationProductField)}}</dd>
      </dl>
      <!--
      <dl class="ml-4 mb-1">
        <dt class="d-inline-block">עמודה Name -</dt>
        <dd class="d-inline-block ml-2 m-0"><span class="badge badge-warning mr-1">שדה חובה</span>תיאור המוצר (שם המוצר).</dd>
      </dl>
      <dl class="ml-4 mb-1">
        <dt class="d-inline-block">עמודה Model -</dt>
        <dd class="d-inline-block ml-2 m-0"><span class="badge badge-info mr-1">שדה רשות (לא חובה)</span>דגם של המוצר.</dd>
      </dl>
      <dl class="ml-4 mb-1">
        <dt class="d-inline-block">עמודה UPC -</dt>
        <dd class="d-inline-block ml-2 m-0"><span class="badge badge-info mr-1">שדה רשות (לא חובה)</span>UPC - המק"ט שעל האריזה (הברקוד) של המוצר.</dd>
      </dl>
      <dl class="ml-4 mb-1">
        <dt class="d-inline-block">עמודה Description -</dt>
        <dd class="d-inline-block ml-2 m-0"><span class="badge badge-info mr-1">שדה רשות (לא חובה)</span>תיאור מלא של המוצר</dd>
      </dl>
      <dl class="ml-4 mb-1">
        <dt class="d-inline-block">עמודה Category -</dt>
        <dd class="d-inline-block ml-2 m-0"><span class="badge badge-info mr-1">שדה רשות (לא חובה)</span>קטגוריה של המוצר</dd>
      </dl>
-->


    </ng-container>



  </ng-container>



</div>