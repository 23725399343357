import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from "@angular/router";
declare var $: any;

import { AdminService } from '../../../services/admin.service';

import { FileType, IExternalFile } from '../../../models/files.models';
import { ISupplierCustomExportFileTemplateConfiguration, ISupplierLocalERPConfiguration, ERPType } from '../../../models/suppliers';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { EnumDescriptionPipe, EnumListPipe } from 'src/app/pipes/enumdescription.pipe';
import { Store } from '@ngrx/store';
import { ConfigStateFeature, WaitForConfigSave } from 'src/app/state/config.reducer';
import { ConfigSetSupplierExports, ConfigSetSupplierLocalErpConfiguration } from 'src/app/state/config.actions';
import { Actions } from '@ngrx/effects';
import { DeepCopyObject } from 'src/app/helpers/common.helper';

@Component({
  selector: 'app',
  templateUrl: './erp.configuration.list.page.component.html',
  styleUrls: ['./erp.configuration.list.page.component.css']
})
export class ERPConfigurationPageComponent implements OnInit {
  public Templates?: ISupplierCustomExportFileTemplateConfiguration[];
  private EnumListConverter = new EnumListPipe();
  public ERPDBTypeOptions = [];
  public ExportFileTypes = [FileType.Excel, FileType.CSV, FileType.QuoteCSV, FileType.TSV];
  public LocalERPConfiguration?: ISupplierLocalERPConfiguration;
  public ErpUserName?: string;
  constructor(
    private _adminService: AdminService,
    private _modalService: NgbModal,
    private _router: Router,
    private store: Store,
    private actions$: Actions,
    public CdRef: ChangeDetectorRef) { }
  closeResult: string = "";
  public SelectedAddSendEMappingField: string;
  public SelectedAddERPMappingField: string;
  public SendEMappingFields: { [key: string]: string } = {
    "SupplierNumber": "מספר הזמנה",
    "Source.OriginStoreERPNumber": "מספר ספק ב-ERP",
    "Audit.CreatedAt": "תאריך הזמנה",
    "Items.Product.Name": "שם מוצר",
    "Items.Product.SKU": "מק\"ט מוצר ספק",
    "Items.Quantity": "כמות",
    "Items.ItemPrice": "מחיר לפני מע\"מ",
    "Items.ItemVATPrice": "מחיר מע\"מ",
    "ADD(Items.ItemVATPrice,Items.ItemPrice)": "מחיר כולל מע\"מ",
    "Shipments.Recipient.FullName": "שם נמען",
    "Shipments.Address.FullAddress": "כתובת מלאה",
    "Shipments.Address.City": "עיר",
    "Shipments.Address.Zip": "מיקוד",
    "Shipments.Recipient.Email": "אימייל נמען",
    "Shipments.Recipient.Phone": "טלפון נמען ראשי",
    "Shipments.Recipient.Phone2": "טלפון נמען משני",
    "Shipments.ItemType": "שיטת משלוח",
    "Source.OriginStore": "שם ערוך מכירה",
    "Shipments.TrackingNumber": "מספר מעקב משלוח",
    "AllRemarks": "הערות",
    "Club": "מועדון",
    "sende-hiderow": "הסתר הזמנה",
    "sende-hiderowitem": "הסתר פריט בהזמנה"
  }
  public SendErpFields = ["NumAtCard", "important", "CardCode", "DocDate", "docduedate", "ItemCode", "Quantity", "Address2", "AddressCity", "AddressZip", "AddressEmail", "AddressPhone", "AddressPhone2", "Comments", "PicRmrk", "BpChCode"].sort();
  public HConnectFields = [
    "accountKey", "Reference", "DocumentID", "Fullname", "Address1", "Address2", "Ref2", "valueDate", "duedate", "Agent", "warehouse", "details", "warehouse_out", "Agent_out", "Price_out", "Discountprc1", "Vatprc", "copies", "currency", "Rate", "itemkey", "Quantity", "price", "Currencycode", "Discountprc", "Orate", "itemname", "Unit", "PurchaseTax", "Alterkey", "CommisionPrc", "packs", "vatexampt", "Phone", "Issuedate", "remarks", "Reference3", "costcode", "Expiredate", "BatchNum", "Localization", "Warrentydate", "Instance", "S_ExtraText1", "S_ExtraText2", "S_ExtraText3", "S_Extratext4", "S_ExtraText5", "S_ExtraSum1", "S_ExtraSum2", "S_ExtraSum3", "DistLine", "LineNum", "BaseLineNum", "SM_Extratext1", "SM_Extratext2", "SM_ExtraSum1", "SM_Extrasum2", "Osek874", "BaseMoveID", "contactMail", "Refnum", "ExFile", "SM_ExFile", "Contact", "SM_CostCode", "BurdInstItemKey", "JurnalAcc", "SM_DueDate", "SM_Details", "SM_Warehouse", "EvalCurrency", "ExpAddress", "PackingRemarks", "TransType", "VatFreeTransType", "EvalRate", "S_ExtraDate1", "S_ExtraDate2", "S_ExtraNum1", "S_ExtraNum2", "SM_ExtraDate1", "SM_ExtraDate2", "SM_ExtraNum1", "SM_ExtraNum2", "Assignmentnumber",
    "kupain.accountKey", "kupain.docNumber", "kupain.transType", "kupain.payDate", "kupain.dueDate", "kupain.details", "kupain.cheqNumber", "kupain.bankNumber", "kupain.branch", "kupain.bankAccNum", "kupain.suF", "kupain.suFDlr", "kupain.currency", "kupain.currencyDlr", "kupain.payments", "kupain.creditFirm", "kupain.cheqsCustomerID", "kupain.stockDetails", "kupain.stockAgent", "kupain.stockBranchid", "kupain.stockReference", "kupain.stockRemarks", "kupain.stockAccountName", "kupain.stockAddress", "kupain.stockCity", "kupain.stockPhone", "kupain.4Digits", "kupain.FirstPayment", "kupain.stockExtraText1", "kupain.stockExtraText2", "kupain.stockExtraText3", "kupain.stockExtraText4", "kupain.stockExtraText5", "kupain.stockExtraSum1", "kupain.stockExtraSum2", "kupain.stockExtraSum3", "kupain.stockExtraDate1", "kupain.stockExtraDate2", "kupain.stockExtraNum1", "kupain.stockExtraNum2", "kupain.contactmail", "kupain.Voucher_Number", "kupain.Ref3", "kupain.CostCode", "kupain.costCode", "kupain.exFile", "kupain.sPhone", "kupain.Website", "kupain.deductFile", "kupain.distLine", "kupain.distDoc", "kupain.ExpAddress", "kupain.SbcardNum", "kupain.Sbmonth", "kupain.Sbyear", "kupain.SBID", "kupain.Sbphone", "kupain.SBcvv", "kupain.SB4Digits", "kupain.taxRepCode", "kupain.customerID1", "kupain.customerID2", "kupain.kupaPhone1", "kupain.kupaPhone2", "kupain.mainAccountName", "kupain.balanceName", "kupain.EXPIC", "kupain.ACCEDI", "kupain.marketNetKey", "kupain.letMasavMail", "kupain.masavEmail", "kupain.Computerized", "kupain.mailReceipt", "kupain.varietyID", "kupain.fixedOrderCost", "kupain.averageSupplYPeriod", "kupain.SdsupplyPeriod", "kupain.visitPeriod", "kupain.roundType", "kupain.SBMASOFID", "kupain.SBSTRTOKEN", "kupain.SBCREDITSW", "kupain.TAXFILENUMENTITY",
  ].sort();

  public ICountFields = [
    "cash.sum", "sid", "cid", "client_id", "custom_client_id", "vat_id", "email", "client_name", "client_address", "user_id", "username", "user_email", "salesman_id", "doc_date", "paydate", "duedate", "income_type_id", "income_type_name", "expense_type_id", "currency_id", "currency_code", "rate", "tax_exempt", "vat_percent", "totalsum", "discount", "discount_incvat", "roundup", "afterdiscount", "totalwithvat", "paid", "totalwht", "totalpaid", "items.item_id", "items.inventory_item_id", "items.sku", "items.description", "items.long_description", "items.currency_id", "items.currency_rate", "items.unitprice", "items.unitprice_incvat", "items.unitprice_exempt", "items.tax_exempt", "items.quantity", "items.serial", "cash", "cheques", "banktransfer", "cc", "paypal", "barter", "deductions", "autoinvoice", "autocheque_detail", "autoinvoice_cc", "autocc_detail", "autocc_income_type_id", "autocc_exempt", "enable_paynow", "based_on", "doc_title", "doc_lang", "send_email", "send_sms", "hwc", "sanity_string", "append_preset_comments",
    "invoice.cash.sum", "invoice.sid", "invoice.cid", "invoice.client_id", "invoice.custom_client_id", "invoice.vat_id", "invoice.email", "invoice.client_name", "invoice.client_address", "invoice.user_id", "invoice.username", "invoice.user_email", "invoice.salesman_id", "invoice.doc_date", "invoice.paydate", "invoice.duedate", "invoice.income_type_id", "invoice.income_type_name", "invoice.expense_type_id", "invoice.currency_id", "invoice.currency_code", "invoice.rate", "invoice.tax_exempt", "invoice.vat_percent", "invoice.totalsum", "invoice.discount", "invoice.discount_incvat", "invoice.roundup", "invoice.afterdiscount", "invoice.totalwithvat", "invoice.paid", "invoice.totalwht", "invoice.totalpaid", "invoice.items.item_id", "invoice.items.inventory_item_id", "invoice.items.sku", "invoice.items.description", "invoice.items.long_description", "invoice.items.currency_id", "invoice.items.currency_rate", "invoice.items.unitprice", "invoice.items.unitprice_incvat", "invoice.items.unitprice_exempt", "invoice.items.tax_exempt", "invoice.items.quantity", "invoice.items.serial", "invoice.cash", "invoice.cheques", "invoice.banktransfer", "invoice.cc", "invoice.paypal", "invoice.barter", "invoice.deductions", "invoice.autoinvoice", "invoice.autocheque_detail", "invoice.autoinvoice_cc", "invoice.autocc_detail", "invoice.autocc_income_type_id", "invoice.autocc_exempt", "invoice.enable_paynow", "invoice.based_on", "invoice.doc_title", "invoice.doc_lang", "invoice.send_email", "invoice.send_sms", "invoice.hwc", "invoice.sanity_string", "invoice.append_preset_comments",
    "receipt.cash.sum", "receipt.sid", "receipt.cid", "receipt.client_id", "receipt.custom_client_id", "receipt.vat_id", "receipt.email", "receipt.client_name", "receipt.client_address", "receipt.user_id", "receipt.username", "receipt.user_email", "receipt.salesman_id", "receipt.doc_date", "receipt.paydate", "receipt.duedate", "receipt.income_type_id", "receipt.income_type_name", "receipt.expense_type_id", "receipt.currency_id", "receipt.currency_code", "receipt.rate", "receipt.tax_exempt", "receipt.vat_percent", "receipt.totalsum", "receipt.discount", "receipt.discount_incvat", "receipt.roundup", "receipt.afterdiscount", "receipt.totalwithvat", "receipt.paid", "receipt.totalwht", "receipt.totalpaid", "receipt.items.item_id", "receipt.items.inventory_item_id", "receipt.items.sku", "receipt.items.description", "receipt.items.long_description", "receipt.items.currency_id", "receipt.items.currency_rate", "receipt.items.unitprice", "receipt.items.unitprice_incvat", "receipt.items.unitprice_exempt", "receipt.items.tax_exempt", "receipt.items.quantity", "receipt.items.serial", "receipt.cash", "receipt.cheques", "receipt.banktransfer", "receipt.cc", "receipt.paypal", "receipt.barter", "receipt.deductions", "receipt.autoinvoice", "receipt.autocheque_detail", "receipt.autoinvoice_cc", "receipt.autocc_detail", "receipt.autocc_income_type_id", "receipt.autocc_exempt", "receipt.enable_paynow", "receipt.based_on", "receipt.doc_title", "receipt.doc_lang", "receipt.send_email", "receipt.send_sms", "receipt.hwc", "receipt.sanity_string", "receipt.append_preset_comments",
    "invrec.cash.sum", "invrec.sid", "invrec.cid", "invrec.client_id", "invrec.custom_client_id", "invrec.vat_id", "invrec.email", "invrec.client_name", "invrec.client_address", "invrec.user_id", "invrec.username", "invrec.user_email", "invrec.salesman_id", "invrec.doc_date", "invrec.paydate", "invrec.duedate", "invrec.income_type_id", "invrec.income_type_name", "invrec.expense_type_id", "invrec.currency_id", "invrec.currency_code", "invrec.rate", "invrec.tax_exempt", "invrec.vat_percent", "invrec.totalsum", "invrec.discount", "invrec.discount_incvat", "invrec.roundup", "invrec.afterdiscount", "invrec.totalwithvat", "invrec.paid", "invrec.totalwht", "invrec.totalpaid", "invrec.items.item_id", "invrec.items.inventory_item_id", "invrec.items.sku", "invrec.items.description", "invrec.items.long_description", "invrec.items.currency_id", "invrec.items.currency_rate", "invrec.items.unitprice", "invrec.items.unitprice_incvat", "invrec.items.unitprice_exempt", "invrec.items.tax_exempt", "invrec.items.quantity", "invrec.items.serial", "invrec.cash", "invrec.cheques", "invrec.banktransfer", "invrec.cc", "invrec.paypal", "invrec.barter", "invrec.deductions", "invrec.autoinvoice", "invrec.autocheque_detail", "invrec.autoinvoice_cc", "invrec.autocc_detail", "invrec.autocc_income_type_id", "invrec.autocc_exempt", "invrec.enable_paynow", "invrec.based_on", "invrec.doc_title", "invrec.doc_lang", "invrec.send_email", "invrec.send_sms", "invrec.hwc", "invrec.sanity_string", "invrec.append_preset_comments",
    "delcert.cash.sum", "delcert.sid", "delcert.cid", "delcert.client_id", "delcert.custom_client_id", "delcert.vat_id", "delcert.email", "delcert.client_name", "delcert.client_address", "delcert.user_id", "delcert.username", "delcert.user_email", "delcert.salesman_id", "delcert.doc_date", "delcert.paydate", "delcert.duedate", "delcert.income_type_id", "delcert.income_type_name", "delcert.expense_type_id", "delcert.currency_id", "delcert.currency_code", "delcert.rate", "delcert.tax_exempt", "delcert.vat_percent", "delcert.totalsum", "delcert.discount", "delcert.discount_incvat", "delcert.roundup", "delcert.afterdiscount", "delcert.totalwithvat", "delcert.paid", "delcert.totalwht", "delcert.totalpaid", "delcert.items.item_id", "delcert.items.inventory_item_id", "delcert.items.sku", "delcert.items.description", "delcert.items.long_description", "delcert.items.currency_id", "delcert.items.currency_rate", "delcert.items.unitprice", "delcert.items.unitprice_incvat", "delcert.items.unitprice_exempt", "delcert.items.tax_exempt", "delcert.items.quantity", "delcert.items.serial", "delcert.cash", "delcert.cheques", "delcert.banktransfer", "delcert.cc", "delcert.paypal", "delcert.barter", "delcert.deductions", "delcert.autoinvoice", "delcert.autocheque_detail", "delcert.autoinvoice_cc", "delcert.autocc_detail", "delcert.autocc_income_type_id", "delcert.autocc_exempt", "delcert.enable_paynow", "delcert.based_on", "delcert.doc_title", "delcert.doc_lang", "delcert.send_email", "delcert.send_sms", "delcert.hwc", "delcert.sanity_string", "delcert.append_preset_comments",
    "items.cheques.sum", "items.cheques.date", "items.cheques.bank", "items.cheques.branch", "items.cheques.account", "items.cheques.account", "items.banktransfer.sum", "items.banktransfer.date", "items.banktransfer.account", "items.cc.sum", "items.cc.date", "items.cc.card_number", "items.cc.num_of_payments", "items.cc.first_payment", "items.cc.token_id", "items.cc.card_number", "items.cc.card_type", "items.cc.cvv", "items.cc.exp_year", "items.cc.exp_month", "items.cc.holder_id", "items.cc.holder_name", "items.cc.confirmation_code", "items.paypal.sum", "items.paypal.date", "items.paypal.txn_id", "items.paypal.payer_name", "items.barter.sum", "items.barter.sum",
    "invoice.items.cheques.sum", "invoice.items.cheques.date", "invoice.items.cheques.bank", "invoice.items.cheques.branch", "invoice.items.cheques.account", "invoice.items.cheques.account", "invoice.items.banktransfer.sum", "invoice.items.banktransfer.date", "invoice.items.banktransfer.account", "invoice.items.cc.sum", "invoice.items.cc.date", "invoice.items.cc.card_number", "invoice.items.cc.num_of_payments", "invoice.items.cc.first_payment", "invoice.items.cc.token_id", "invoice.items.cc.card_number", "invoice.items.cc.card_type", "invoice.items.cc.cvv", "invoice.items.cc.exp_year", "invoice.items.cc.exp_month", "invoice.items.cc.holder_id", "invoice.items.cc.holder_name", "invoice.items.cc.confirmation_code", "invoice.items.paypal.sum", "invoice.items.paypal.date", "invoice.items.paypal.txn_id", "invoice.items.paypal.payer_name", "invoice.items.barter.sum", "invoice.items.barter.sum",
    "receipt.items.cheques.sum", "receipt.items.cheques.date", "receipt.items.cheques.bank", "receipt.items.cheques.branch", "receipt.items.cheques.account", "receipt.items.cheques.account", "receipt.items.banktransfer.sum", "receipt.items.banktransfer.date", "receipt.items.banktransfer.account", "receipt.items.cc.sum", "receipt.items.cc.date", "receipt.items.cc.card_number", "receipt.items.cc.num_of_payments", "receipt.items.cc.first_payment", "receipt.items.cc.token_id", "receipt.items.cc.card_number", "receipt.items.cc.card_type", "receipt.items.cc.cvv", "receipt.items.cc.exp_year", "receipt.items.cc.exp_month", "receipt.items.cc.holder_id", "receipt.items.cc.holder_name", "receipt.items.cc.confirmation_code", "receipt.items.paypal.sum", "receipt.items.paypal.date", "receipt.items.paypal.txn_id", "receipt.items.paypal.payer_name", "receipt.items.barter.sum", "receipt.items.barter.sum",
    "invrec.items.cheques.sum", "invrec.items.cheques.date", "invrec.items.cheques.bank", "invrec.items.cheques.branch", "invrec.items.cheques.account", "invrec.items.cheques.account", "invrec.items.banktransfer.sum", "invrec.items.banktransfer.date", "invrec.items.banktransfer.account", "invrec.items.cc.sum", "invrec.items.cc.date", "invrec.items.cc.card_number", "invrec.items.cc.num_of_payments", "invrec.items.cc.first_payment", "invrec.items.cc.token_id", "invrec.items.cc.card_number", "invrec.items.cc.card_type", "invrec.items.cc.cvv", "invrec.items.cc.exp_year", "invrec.items.cc.exp_month", "invrec.items.cc.holder_id", "invrec.items.cc.holder_name", "invrec.items.cc.confirmation_code", "invrec.items.paypal.sum", "invrec.items.paypal.date", "invrec.items.paypal.txn_id", "invrec.items.paypal.payer_name", "invrec.items.barter.sum", "invrec.items.barter.sum",
    "delcert.items.cheques.sum", "delcert.items.cheques.date", "delcert.items.cheques.bank", "delcert.items.cheques.branch", "delcert.items.cheques.account", "delcert.items.cheques.account", "delcert.items.banktransfer.sum", "delcert.items.banktransfer.date", "delcert.items.banktransfer.account", "delcert.items.cc.sum", "delcert.items.cc.date", "delcert.items.cc.card_number", "delcert.items.cc.num_of_payments", "delcert.items.cc.first_payment", "delcert.items.cc.token_id", "delcert.items.cc.card_number", "delcert.items.cc.card_type", "delcert.items.cc.cvv", "delcert.items.cc.exp_year", "delcert.items.cc.exp_month", "delcert.items.cc.holder_id", "delcert.items.cc.holder_name", "delcert.items.cc.confirmation_code", "delcert.items.paypal.sum", "delcert.items.paypal.date", "delcert.items.paypal.txn_id", "delcert.items.paypal.payer_name", "delcert.items.barter.sum", "delcert.items.barter.sum",
  ].sort();

  public PriorityConnectFields = [
    "CUSTNAME", "CDES", "REFERENCE", "DETAILS", 
    "SHIPTO2_SUBFORM.NAME", "SHIPTO2_SUBFORM.PHONENUM", "SHIPTO2_SUBFORM.EMAIL", "SHIPTO2_SUBFORM.CELLPHONE", "SHIPTO2_SUBFORM.ADDRESS", "SHIPTO2_SUBFORM.STATE",
    "ORDERITEMS_SUBFORM.PARTNAME", "ORDERITEMS_SUBFORM.TQUANT", "ORDERITEMS_SUBFORM.PRICE"
  ].sort();

ngOnInit() {
  this.buildOptions();

  this.store.select(ConfigStateFeature.selectConfiguration).pipe(filter(s => !!s)).subscribe(
    conf => {
      if (conf) {
        this.Templates = DeepCopyObject((conf.Exports ?? []) as ISupplierCustomExportFileTemplateConfiguration[]);
        if (this.Templates.length && this.Templates[0].FileType == FileType.UnKnown) this.Templates[0].FileType = FileType.Excel;

        this.LocalERPConfiguration = DeepCopyObject($.extend(this._adminService.CreateNewSupplierLocalERPConfiguration(), conf.LocalERPConfiguration));

      }
      if (!this.LocalERPConfiguration.Type) this.LocalERPConfiguration.Type = ERPType.SBOOnPrem;

    });
  this._adminService.GetERPServiceUsername().subscribe(r => {
    if (r.Code === 0) {
      this.ErpUserName = r.Result;
    }
  });
}

  public HTMLHelper = {
  LocalERPFieldDescription: {
    6: { //  ERPType.HConnect
      "Username": "מזהה לקוח - Station ID",
      "CustomerId": "מזהה חברה - Company",
      "Password": "מזהה מנוי - netPassportID",
      "Token": "טוקן צד ספק - Token",
    },
    7: { //  ERPType.iCount
      "Username": "מזהה לקוח - Station ID",
      "CustomerId": "מזהה חברה - Company",
      "Password": "מזהה מנוי - netPassportID",
      "Token": "טוקן API   - Token",
    },
    8: { //  ERPType.PriorityConnect
      "Username": "שם משתמש - UserName",
      "CustomerId": "מזהה חברה - Company",
      "Password": " סיסמה - Password",
    }
  },

  getFieldMapping: (type: ERPType) => {
    if (type == ERPType.HConnect) {
      return this.HConnectFields;
    }
    if (type == ERPType.ICount) {
      return this.ICountFields;
    }

    if (type == ERPType.PriorityConnect) {
      return this.PriorityConnectFields;
    }
    //defualt
    return this.SendErpFields;

  },

  GetLocalERPFieldDescription: (field: string) => {
    const ERPType = Number(this.LocalERPConfiguration.Type);
    if (this.HTMLHelper.LocalERPFieldDescription[ERPType]) {
      return this.HTMLHelper.LocalERPFieldDescription[ERPType][field];
    }
    return null;
  }
}

  private buildOptions() {
  this.ERPDBTypeOptions = this.EnumListConverter.transform("ERPDBType").sort((a, b) => (a.name > b.name) ? 1 : -1);
}
  public AddNewErpMapping() {

  // replace selected value descriptive text with relevent key (only is match)
  for (const key in this.SendEMappingFields) {
    if (this.SendEMappingFields.hasOwnProperty(key)) {
      const value = this.SendEMappingFields[key];
      if (value == this.SelectedAddSendEMappingField) {
        this.SelectedAddSendEMappingField = key;
        break;
      }
    }
  }

  this.LocalERPConfiguration.FieldMapping[this.SelectedAddERPMappingField] = this.SelectedAddSendEMappingField;
  this.SelectedAddERPMappingField = "";
  this.SelectedAddSendEMappingField = "";
  this.CdRef.detectChanges();
}

  public DeleteErpMapping(key: string) {
  delete this.LocalERPConfiguration.FieldMapping[key];
  this.CdRef.detectChanges();
}

  public OnFileUploaded(files: IExternalFile[]) {

  var fileType = this?.Templates[0]?.FileType ?? FileType.Excel;
  var fileName = this?.Templates[0]?.FileName ?? ""
  if (files[0].Type == FileType.XML) {
    fileType = FileType.XML;
    fileName = fileName.replace(/\..*$/, ".xml");
  }
  else if (fileType == FileType.XML) fileType = files[0].Type;


  this.Templates = [
    this._adminService.CreateNewSupplierExportTemplateConfiguration("ERP", fileType, fileName, files[0])
  ];
}

  public Files(): (IExternalFile | null)[] {
  if (this.Templates) return this.Templates
    .map(t => {
      //    window['t'] = t;
      return (t.ItemType === "ExportFileTemplate") ? (t as ISupplierCustomExportFileTemplateConfiguration).Template : null;
    });

  return [];
}

  public SelectedAddSendeMappingSearch = (text$: Observable<string>) =>
  text$.pipe(
    map(term => term.length < 1 ? Object.values(this.SendEMappingFields)
      : Object.values(this.SendEMappingFields).filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  );


  public SelectedAddERPMappingSearch = (text$: Observable<string>) =>
  text$.pipe(
    map(term => {
      var fields = this.HTMLHelper.getFieldMapping(this.LocalERPConfiguration.Type);
      return term.length < 1 ? fields : fields.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10);
    })
  );



  public async SaveSupplierExportsAndReturn() {
  if (!this.Templates) this._router.navigate(["/home"]);
  else {


    if (await WaitForConfigSave(this.actions$, () => { this.store.dispatch(ConfigSetSupplierExports({ exportsConfiguration: this.Templates })); })) {
      this._router.navigate(["/home"]);
    }
  }
}

  public async SaveSupplierLocalERPConfiguration() {


  if (await WaitForConfigSave(this.actions$, () => { this.store.dispatch(ConfigSetSupplierLocalErpConfiguration({ erpConfiguration: this.LocalERPConfiguration })); })) {
    this._router.navigate(["/home"]);
  }
}

  public HasSendEFieldPicked(field: string): boolean {
  return Object.values(this.LocalERPConfiguration.FieldMapping).indexOf(field) > -1;
}

  public open(content, options, onInit, onSave) {
  if (onInit) onInit();
  this._modalService.open(content, options).result.then((result) => {
    if ((result === "save") && (onSave)) onSave();
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {

    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}
  private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}

}
