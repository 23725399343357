import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import { ILoginUserSession } from "../models/users.models";
import { UserSessionLoginError, UserSessionLoginStart, UserSessionLoginSuccess, UserSessionLogout, UserSessionRefreshError, UserSessionRefreshSuccess, UserSessionSSOLoginProcess, UserSessionSSOLoginStart } from "./session.actions";

export const initialState: ISessionState =
{
    loading: false,
    session: null,
    loginError: null
}


export interface ISessionState {
    session: ILoginUserSession,
    loading: boolean,
    loginError: string
}



export const SessionStateFeature = createFeature({
    name: 'session',
    reducer: createReducer(
        ({ ...initialState, session: (localStorage['loggerUser'] && localStorage['loggerUser'] != "undefined") ? JSON.parse(localStorage["loggerUser"]) : null }) as ISessionState,
        on(UserSessionLoginStart, UserSessionSSOLoginStart, UserSessionSSOLoginProcess, (state) => ({ ...state, loading: true, loginError: null })),
        on(UserSessionLoginSuccess, UserSessionRefreshSuccess, (state, payload) => {
            localStorage["loggerUser"] = JSON.stringify(payload.session);
            return { session: payload.session, loading: false, loginError: null };

        }),
        on(UserSessionLoginError, UserSessionRefreshError, (state, payload) => ({ ...state, loading: false, loginError: payload.message })),
        on(UserSessionLogout, (state => {
            localStorage.removeItem("loggerUser");
            return initialState;
        }))
    ),
    extraSelectors: ({ selectSession }) => ({
        hasSession: createSelector(selectSession, (session) => !!session)
    }),

});



