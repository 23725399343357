<label class="form-group has-float-label text-right" [ngClass]="classes">
  <input [type]="type" [id]="id" [name]="id"
         [formControl]="bindFormControl"
         class="form-control"
         [ngClass]="{'is-invalid' : bindFormControl?.invalid ,'is-valid' : showValidIndicator && bindFormControl?.valid , 'number-text' : type == 'number'}" 
         placeholder=" "
         autofocus  tabindex="{{tabindex}}" 
         [readOnly]="readonly"
         [disabled]="disabled"
         
         />
  <span *ngIf="title" class="label">{{title}}</span>
  <span [hidden]="!bindFormControl?.invalid" class="invalid-feedback text-left">
    {{GetErrorMessage()}}
  </span>
  <i *ngIf="bindFormControl?.pending" class="fa fa-spinner fa-pulse fa-2x fa-fw float-label-loading"></i>
</label>

