<div class="container-fluid mt-2">
  <div class="row RowEnd my-3">
    <h2 class="col-2">ערוצי הפצה</h2>
    <div class="col-10">
      כאן ניתן להגדיר את ערוצי ההפצה איתם תרצו לעבוד. <br />
      ערוצי הפצה הם למעשה שיטות המשלוח שאתם מאפשאים \ משתמשים הבהן לרבות : <br />
      שרות שליחים, נקודות איסוף, דואר, שליח עצמי של העסק שלכם ואפילו איסוף עצמי. <br />
      שיטות המשלוח שתגדירו כאן יהיו זמינות לשיוך של הזמנות ומוצרים במערכת וניתן לעדכן אותן בכל עת.
    </div>
  </div>

  <div class="row RowEnd border-bottom my-3" *ngIf="ActiveShipmentTypes">
    <h2 class="col-12">ערוצי הפצה פעילים</h2>
    <div class="d-flex flex-wrap justify-content-start my-2">
      <div class="m-2 Active OutterBox" *ngFor="let ship of ActiveShipmentTypes; index as i" (click)="GotoShipment(ship);">
        <i class="fas fa-pencil-alt fa-2x m-1 ActivePencilIcon"></i>
        <div class="Box d-flex d-flex flex-column justify-content-center">
          <img *ngIf="ship.LogoURL" class="logoImg align-self-center mt-1" [src]="ship.LogoURL" [alt]="ship.Description" />
          <div class="align-self-end px-2 align-self-center text-center"  [ngClass]="{'long-text': ship.Description.length > 12 }">
            {{ship.Description}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row my-3" *ngIf="InActiveShipmentTypes">
    <h2 class="col-12">ערוצי הפצה נוספים</h2>
    <div class="d-flex flex-wrap justify-content-start my-2">
      <div *ngFor="let ship of InActiveShipmentTypes; index as i" class="m-2 InActive" (click)="GotoShipment(ship);">
        <i class="fas fa-plus fa-2x m-1 InActivePlusIcon "></i>
        <div class="Box d-flex d-flex flex-column justify-content-center">
          <img *ngIf="ship.LogoURL" class="logoImg align-self-center mt-1" [src]="ship.LogoURL" [alt]="ship.Description" />
          <div class="align-self-end px-2 align-self-center text-center" [ngClass]="{'long-text': ship.Description.length > 12 }">
            {{ship.Description}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>