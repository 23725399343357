import { Component, EventEmitter, Output, Input, OnInit, SimpleChanges, OnChanges, ViewChild, OnDestroy } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { UserControlEditorMode } from '../../../models/user-controls';
import { IAddress } from 'src/app/models/common.models';
import { AbstractControl, FormControlStatus, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable, combineLatestWith, debounceTime, distinctUntilChanged, filter, map, mergeMap, of } from 'rxjs';
import { IDropPoint, IBoxitDropPoint, BoxitLockerTypes, IUPSDropPoint, UPSDropPointTypes, ISosnaDropPoint, SpotTypes } from 'src/app/models/shipments.models';
import { ajax } from 'rxjs/ajax';
import { ShipmentService } from 'src/app/services/shipment.service';

@Component({
  selector: 'droppoint-map',
  templateUrl: './droppoint.map.usercontrol.component.html'
})
export class DropPointMapUserControlComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;

  @Input() DropPoint: IDropPoint;
  @Output() DropPointChange: EventEmitter<IDropPoint> = new EventEmitter<IDropPoint>();

  @Input() AvailableDropPoints: IDropPoint[];

  private readonly googleKey = "AIzaSyDqaMhHs9F5x7rzqmOXt7DkQDEhWnzGaHU&language";
  public SelectedDropPoint!: IDropPoint;

  public AddressPoint!: IDropPoint ;

  public MapCenter: google.maps.LatLngLiteral = {
    lat: 31.76904,
    lng: 35.21633
  }
  public MapOptions: google.maps.MapOptions = {
    /* mapTypeId: 'hybrid',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true, */
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    maxZoom: 15,
    minZoom: 8,
  };
  public MapZoom: number = 10;
  constructor(private _common: CommonService, private _shipmentService: ShipmentService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["DropPoint"]?.currentValue && !changes["DropPoint"].isFirstChange()) this.focusDropPoint(this.DropPoint);
  }
  ngOnInit(): void {
    if (this.DropPoint) this.focusDropPoint(this.DropPoint);
  }

  private focusDropPoint(dropPoint: IDropPoint) {
    this.MapCenter = {
      lat: this.DropPoint.GeoCoordinate.Latitude,
      lng: this.DropPoint.GeoCoordinate.Longitude
    }
    this.MapZoom = 15;
  }

  AutoCompleteSearchDropPoint = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? { term, result: [] } : { term, result: this.AvailableDropPoints.filter(dp => (dp.Name + " " + dp.Address?.City + " " + dp.Address?.Street + " " + dp.Address?.Company + " " + dp["FullAddress"]).indexOf(term.toLowerCase()) >= 0).slice(0, 5) }),
      mergeMap(output => {
        if (output.term.length < 2) return of(output);

        return this._shipmentService.GetAddressSuggest(output.term).pipe(
          map(result => {
            var points = result.map(item => ({
              Name: item.Name,
              Code: item.Id,
              ItemType: 'Address'
            }));
            output.result = [...output.result, ...points].slice(0, 10) 
            return output;
          })
        )
        /*
        export interface IDropPoint {
          Name: string,
          Code: string,
          Address: IAddress,
          GeoCoordinate: IGeoCoordinate,
          ItemType: string,
        }
        
        */

      }),


      map(output => output.result)
    )

  AutoCompleteFormat = (value: IDropPoint) => {
    if (value) return value.Name;
    else return null;
  }
  /*
    private concatenateObjectValues(obj: Record<string, any>): string {
      let result = '';
      for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          result += this.concatenateObjectValues(obj[key]);
        } else {
          result += obj[key];
        }
        result += ' '; // Add space as delimiter
      }
      return result.trim(); // Remove trailing space
    }
  */


  public AutoCompleteItemSelected(item: NgbTypeaheadSelectItemEvent) {

    if (item.item.ItemType == 'Address') {
      this._shipmentService.GetAddressDetails(item.item.Code).subscribe(r => {
        this.MapCenter = {
          lat: r.GeoCoordinate.Latitude,
          lng: r.GeoCoordinate.Longitude
        }
        var zoomDist = Math.sqrt(Math.pow(r.ViewportNortheast.Latitude - r.ViewportSouthwest.Latitude, 2) + Math.pow(r.ViewportNortheast.Longitude - r.ViewportSouthwest.Longitude, 2));
        this.MapZoom = 15 - (12 * zoomDist)
        this.AddressPoint = item.item;
        this.AddressPoint.GeoCoordinate = r.GeoCoordinate;
      });
    }
    else this.ShowInfoWindow(null, item.item as IDropPoint);

  }



  public GetMarkerIcon(dropPoint: IDropPoint): string | undefined {
    if (dropPoint.ItemType == "BoxitDropPoint") {
      const boxitDropPoint = dropPoint as IBoxitDropPoint;
      return (boxitDropPoint.LockerType == BoxitLockerTypes.Locker) ? "/Images/DropPoints/boxit-sm.png" : "/Images/DropPoints/shop_collect-sm.png";
    }
    else if (dropPoint.ItemType == "ChitaDropPoint") {
      return "/Images/DropPoints/chita.png";
    }
    else if (dropPoint.ItemType == "YDMDropPoint") {
      return "/Images/DropPoints/YDM_icon_store.png";
    }
    else if (dropPoint.ItemType == "CargoShopsDropPoint") {
      return "/Images/DropPoints/CargoShops_icon_store.png";
    }
    else if (dropPoint.ItemType == "OrionDropPoint") {
      return "/Images/DropPoints/Orion_icon_store.png";
    }
    else if (dropPoint.ItemType == "UPSDropPoint") {
      const upsDropPoint = dropPoint as IUPSDropPoint;
      return (upsDropPoint.DropPointType == UPSDropPointTypes.Store) ? "/Images/DropPoints/UPS_icon_store.png" : "/Images/DropPoints/UPS_icon_locker.png";
    }
    else if (dropPoint.ItemType == "SosnaDropPoint") {
      const sosnaDropPoint = dropPoint as ISosnaDropPoint;
      if (sosnaDropPoint.SpotType == SpotTypes.Shop) return "/Images/DropPoints/Sosna_icon_store.png";
      else if (sosnaDropPoint.SpotType == SpotTypes.Locker) return "/Images/DropPoints/Sosna_icon_locker.png";
      else "/Images/DropPoints/Sosna_icon_none.png";
    }
    else if (dropPoint.ItemType == "EPostDropPoint") {
      const sosnaDropPoint = dropPoint as ISosnaDropPoint;
      if (sosnaDropPoint.SpotType == SpotTypes.Shop) return "/Images/DropPoints/EPost_icon_store.png";
      else if (sosnaDropPoint.SpotType == SpotTypes.Locker) return "/Images/DropPoints/EPost_icon_locker.png";
      else "/Images/DropPoints/EPost_icon_none.png";
    }
    else if (dropPoint.ItemType == "Address")
    {
      return "/Images/DropPoints/GeneralAddress.png";
    }
    return;
  }

  private ShowInfoWindow(marker: MapMarker, droppoint: IDropPoint) {
    this.MapZoom = 15;
    this.MapCenter = {
      lat: droppoint.GeoCoordinate.Latitude,
      lng: droppoint.GeoCoordinate.Longitude
    }

    this.info.open(marker);
    this.SelectedDropPoint = droppoint;
    window["__SelectDropPoint"] = this.Select;
  }

  public Select = () => {
    this.info.close();
    delete window["__SelectDropPoint"];
    this.DropPoint = this.SelectedDropPoint;
    this.DropPointChange.emit(this.DropPoint);
  }
  ngOnDestroy(): void {
    delete window["__SelectDropPoint"];
  }
}

