import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from "@angular/router";
declare var $: any;

import { AdminService } from '../../../services/admin.service';

import { FileType, IExternalFile } from '../../../models/files.models';
import { ISupplierCustomExportFileTemplateConfiguration, ISupplierLocalERPConfiguration, ERPType } from '../../../models/suppliers';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { EnumDescriptionPipe, EnumListPipe } from 'src/app/pipes/enumdescription.pipe';
import { Store } from '@ngrx/store';
import { ConfigStateFeature, WaitForConfigSave } from 'src/app/state/config.reducer';
import { ConfigSetSupplierExports, ConfigSetSupplierLocalErpConfiguration } from 'src/app/state/config.actions';
import { Actions } from '@ngrx/effects';
import { DeepCopyObject } from 'src/app/helpers/common.helper';

@Component({
  selector: 'app',
  templateUrl: './erp.configuration.list.page.component.html',
  styleUrls: ['./erp.configuration.list.page.component.css']
})
export class ERPConfigurationPageComponent implements OnInit {
  public Templates?: ISupplierCustomExportFileTemplateConfiguration[];
  private EnumListConverter = new EnumListPipe();
  public ERPDBTypeOptions = [];
  public ExportFileTypes = [FileType.Excel, FileType.CSV, FileType.QuoteCSV, FileType.TSV];
  public LocalERPConfiguration?: ISupplierLocalERPConfiguration;
  public ErpUserName?: string;
  constructor(
    private _adminService: AdminService,
    private _modalService: NgbModal,
    private _router: Router,
    private store: Store,
    private actions$: Actions,
    public CdRef: ChangeDetectorRef) { }
  closeResult: string = "";
  public SelectedAddSendEMappingField: string;
  public SelectedAddERPMappingField: string;
  public SendEMappingFields: { [key: string]: string } = {
    "SupplierNumber": "מספר הזמנה",
    "Source.OriginStoreERPNumber": "מספר ספק ב-ERP",
    "Audit.CreatedAt": "תאריך הזמנה",
    "Items.Product.Name": "שם מוצר",
    "Items.Product.SKU": "מק\"ט מוצר ספק",
    "Items.Quantity": "כמות",
    "Items.ItemPrice": "מחיר לפני מע\"מ",
    "Items.ItemVATPrice": "מחיר מע\"מ",
    "ADD(Items.ItemVATPrice,Items.ItemPrice)": "מחיר כולל מע\"מ",
    "Shipments.Recipient.FullName": "שם נמען",
    "Shipments.Address.FullAddress": "כתובת מלאה",
    "Shipments.Address.City": "עיר",
    "Shipments.Address.Zip": "מיקוד",
    "Shipments.Recipient.Email": "אימייל נמען",
    "Shipments.Recipient.Phone": "טלפון נמען ראשי",
    "Shipments.Recipient.Phone2": "טלפון נמען משני",
    "Shipments.ItemType": "שיטת משלוח",
    "Source.OriginStore": "שם ערוך מכירה",
    "Shipments.TrackingNumber": "מספר מעקב משלוח",
    "AllRemarks": "הערות",
    "Club": "מועדון"
  }
  public SendErpFields = ["NumAtCard", "important", "CardCode", "DocDate", "docduedate", "ItemCode", "Quantity", "Address2", "AddressCity", "AddressZip", "AddressEmail", "AddressPhone", "AddressPhone2", "Comments", "PicRmrk", "BpChCode"].sort();
  public HConnectFields = [
    "accountKey", "Reference", "DocumentID", "Fullname", "Address1", "Address2", "Ref2", "valueDate", "duedate", "Agent", "warehouse", "details", "warehouse_out", "Agent_out", "Price_out", "Discountprc1", "Vatprc", "copies", "currency", "Rate", "itemkey", "Quantity", "price", "Currencycode", "Discountprc", "Orate", "itemname", "Unit", "PurchaseTax", "Alterkey", "CommisionPrc", "packs", "vatexampt", "Phone", "Issuedate", "remarks", "Reference3", "costcode", "Expiredate", "BatchNum", "Localization", "Warrentydate", "Instance", "S_ExtraText1", "S_ExtraText2", "S_ExtraText3", "S_Extratext4", "S_ExtraText5", "S_ExtraSum1", "S_ExtraSum2", "S_ExtraSum3", "DistLine", "LineNum", "BaseLineNum", "SM_Extratext1", "SM_Extratext2", "SM_ExtraSum1", "SM_Extrasum2", "Osek874", "BaseMoveID", "contactMail", "Refnum", "ExFile", "SM_ExFile", "Contact", "SM_CostCode", "BurdInstItemKey", "JurnalAcc", "SM_DueDate", "SM_Details", "SM_Warehouse", "EvalCurrency", "ExpAddress", "PackingRemarks", "TransType", "VatFreeTransType", "EvalRate", "S_ExtraDate1", "S_ExtraDate2", "S_ExtraNum1", "S_ExtraNum2", "SM_ExtraDate1", "SM_ExtraDate2", "SM_ExtraNum1", "SM_ExtraNum2", "Assignmentnumber",
    "kupain.accountKey", "kupain.docNumber", "kupain.transType", "kupain.payDate", "kupain.dueDate", "kupain.details", "kupain.cheqNumber", "kupain.bankNumber", "kupain.branch", "kupain.bankAccNum", "kupain.suF", "kupain.suFDlr", "kupain.currency", "kupain.currencyDlr", "kupain.payments", "kupain.creditFirm", "kupain.cheqsCustomerID", "kupain.stockDetails", "kupain.stockAgent", "kupain.stockBranchid", "kupain.stockReference", "kupain.stockRemarks", "kupain.stockAccountName", "kupain.stockAddress", "kupain.stockCity", "kupain.stockPhone", "kupain.4Digits", "kupain.FirstPayment", "kupain.stockExtraText1", "kupain.stockExtraText2", "kupain.stockExtraText3", "kupain.stockExtraText4", "kupain.stockExtraText5", "kupain.stockExtraSum1", "kupain.stockExtraSum2", "kupain.stockExtraSum3", "kupain.stockExtraDate1", "kupain.stockExtraDate2", "kupain.stockExtraNum1", "kupain.stockExtraNum2", "kupain.contactmail", "kupain.Voucher_Number", "kupain.Ref3", "kupain.CostCode", "kupain.costCode", "kupain.exFile", "kupain.sPhone", "kupain.Website", "kupain.deductFile", "kupain.distLine", "kupain.distDoc", "kupain.ExpAddress", "kupain.SbcardNum", "kupain.Sbmonth", "kupain.Sbyear", "kupain.SBID", "kupain.Sbphone", "kupain.SBcvv", "kupain.SB4Digits", "kupain.taxRepCode", "kupain.customerID1", "kupain.customerID2", "kupain.kupaPhone1", "kupain.kupaPhone2", "kupain.mainAccountName", "kupain.balanceName", "kupain.EXPIC", "kupain.ACCEDI", "kupain.marketNetKey", "kupain.letMasavMail", "kupain.masavEmail", "kupain.Computerized", "kupain.mailReceipt", "kupain.varietyID", "kupain.fixedOrderCost", "kupain.averageSupplYPeriod", "kupain.SdsupplyPeriod", "kupain.visitPeriod", "kupain.roundType", "kupain.SBMASOFID", "kupain.SBSTRTOKEN", "kupain.SBCREDITSW", "kupain.TAXFILENUMENTITY",

  ].sort();

  ngOnInit() {
    this.buildOptions();

    this.store.select(ConfigStateFeature.selectConfiguration).pipe(filter(s => !!s)).subscribe(
      conf => {
        if (conf) {
          this.Templates = DeepCopyObject((conf.Exports ?? []) as ISupplierCustomExportFileTemplateConfiguration[]);
          if (this.Templates.length && this.Templates[0].FileType == FileType.UnKnown) this.Templates[0].FileType = FileType.Excel;

          this.LocalERPConfiguration = DeepCopyObject($.extend(this._adminService.CreateNewSupplierLocalERPConfiguration(), conf.LocalERPConfiguration));

        }
        if (!this.LocalERPConfiguration.Type) this.LocalERPConfiguration.Type = ERPType.SBOOnPrem;

      });
    this._adminService.GetERPServiceUsername().subscribe(r => {
      if (r.Code === 0) {
        this.ErpUserName = r.Result;
      }
    });
  }

  public HTMLHelper = {
    LocalERPFieldDescription: {
      6: { //  ERPType.HConnect
        "Username": "מזהה לקוח - Station ID",
        "CustomerId": "מזהה חברה - Company",
        "Password": "מזהה מנוי - netPassportID",
        "Token": "טוקן צד ספק - Token",
      }
    },

    GetLocalERPFieldDescription: (field: string) => {
      const ERPType = Number(this.LocalERPConfiguration.Type);
      if (this.HTMLHelper.LocalERPFieldDescription[ERPType]) {
        return this.HTMLHelper.LocalERPFieldDescription[ERPType][field];
      }
      return null;
    }
  }

  private buildOptions() {
    this.ERPDBTypeOptions = this.EnumListConverter.transform("ERPDBType").sort((a, b) => (a.name > b.name) ? 1 : -1);
  }
  public AddNewErpMapping() {

    // replace selected value descriptive text with relevent key (only is match)
    for (const key in this.SendEMappingFields) {
      if (this.SendEMappingFields.hasOwnProperty(key)) {
        const value = this.SendEMappingFields[key];
        if (value == this.SelectedAddSendEMappingField) {
          this.SelectedAddSendEMappingField = key;
          break;
        }
      }
    }

    this.LocalERPConfiguration.FieldMapping[this.SelectedAddERPMappingField] = this.SelectedAddSendEMappingField;
    this.SelectedAddERPMappingField = "";
    this.SelectedAddSendEMappingField = "";
    this.CdRef.detectChanges();
  }

  public DeleteErpMapping(key: string) {
    delete this.LocalERPConfiguration.FieldMapping[key];
    this.CdRef.detectChanges();
  }

  public OnFileUploaded(files: IExternalFile[]) {

    var fileType = this?.Templates[0]?.FileType ?? FileType.Excel;
    var fileName = this?.Templates[0]?.FileName ?? ""
    if (files[0].Type == FileType.XML) {
      fileType = FileType.XML;
      fileName = fileName.replace(/\..*$/, ".xml");
    }
    else if (fileType == FileType.XML) fileType = files[0].Type;


    this.Templates = [
      this._adminService.CreateNewSupplierExportTemplateConfiguration("ERP", fileType, fileName, files[0])
    ];
  }

  public Files(): (IExternalFile | null)[] {
    if (this.Templates) return this.Templates
      .map(t => {
        //    window['t'] = t;
        return (t.ItemType === "ExportFileTemplate") ? (t as ISupplierCustomExportFileTemplateConfiguration).Template : null;
      });

    return [];
  }

  public SelectedAddSendeMappingSearch = (text$: Observable<string>) =>
    text$.pipe(
      map(term => term.length < 1 ? Object.values(this.SendEMappingFields)
        : Object.values(this.SendEMappingFields).filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );


  public SelectedAddERPMappingSearch = (text$: Observable<string>) =>
    text$.pipe(
      map(term => {
        var fields = Number(this.LocalERPConfiguration.Type) == ERPType.HConnect ? this.HConnectFields : this.SendErpFields;
        return term.length < 1 ? fields : fields.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10);
      })

    );

  public async SaveSupplierExportsAndReturn() {
    if (!this.Templates) this._router.navigate(["/home"]);
    else {


      if (await WaitForConfigSave(this.actions$, () => { this.store.dispatch(ConfigSetSupplierExports({ exportsConfiguration: this.Templates })); })) {
        this._router.navigate(["/home"]);
      }
    }
  }

  public async SaveSupplierLocalERPConfiguration() {


    if (await WaitForConfigSave(this.actions$, () => { this.store.dispatch(ConfigSetSupplierLocalErpConfiguration({ erpConfiguration: this.LocalERPConfiguration })); })) {
      this._router.navigate(["/home"]);
    }
  }

  public HasSendEFieldPicked(field: string): boolean {
    return Object.values(this.LocalERPConfiguration.FieldMapping).indexOf(field) > -1;
  }

  public open(content, options, onInit, onSave) {
    if (onInit) onInit();
    this._modalService.open(content, options).result.then((result) => {
      if ((result === "save") && (onSave)) onSave();
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {

      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
