import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserSessionAppInit, UserSessionLoginError, UserSessionLoginStart, UserSessionLoginSuccess, UserSessionRefreshError, UserSessionRefreshStart, UserSessionRefreshSuccess, UserSessionSSOLoginProcess, UserSessionSSOLoginStart } from "./session.actions";
import { EMPTY, catchError, concat, debounceTime, exhaustMap, filter, ignoreElements, interval, map, merge, of, take, tap, withLatestFrom } from "rxjs";
import { AuthorizationService } from "../services/authurization.service";
import { Store } from "@ngrx/store";
import { ILoginUserSession } from "../models/users.models";
import { SessionStateFeature } from "./session.reducer";
import { Action } from "rxjs/internal/scheduler/Action";


@Injectable()
export class SessionEffects {

    constructor(
        private actions$: Actions,
        private _authorizationService: AuthorizationService,
        private store: Store
    ) {
    }

    appInit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserSessionAppInit),
            exhaustMap(action => this.store.select(SessionStateFeature.selectSession)),
            filter(session => !!session),
            map((session) => UserSessionLoginSuccess({ session: session }))
        )
    )

    appInitSSO$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserSessionAppInit),
            exhaustMap(action => this.store.select(SessionStateFeature.selectSession)),
            filter(session => this._authorizationService.HasSSOToken()),
            map((session) => UserSessionSSOLoginProcess())
        ))

     appUserSessionSSOLoginProcess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserSessionSSOLoginProcess),
            exhaustMap(action => this._authorizationService.SSOLogin().pipe(
                map(session => UserSessionLoginSuccess({ session })),
                catchError(err => of(UserSessionLoginError({ message: err })))
            ))
        ))


    userLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserSessionLoginStart),
            debounceTime(500),
            exhaustMap(action => this._authorizationService.Login(action)),
            map(session => UserSessionLoginSuccess({ session })),
            catchError(err => of(UserSessionLoginError({ message: err })))
        )
    )

    userSSOLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserSessionSSOLoginStart),
            debounceTime(500),
            tap(action => this._authorizationService.SSOInitLogin()),
            ignoreElements(),
            map(() => null)
        )
    )

    /*
    userSessionAutoRefresh$ = createEffect(() =>
        interval(1000 * 60 * 5).pipe(
            withLatestFrom(this.store.select(SessionStateFeature.hasSession)),            
            filter(([action,hasSession]) => hasSession),
            map(() => UserSessionRefreshStart())
        ))
*/
    userSessionRefresh = createEffect(() =>
        this.actions$.pipe(
            ofType(UserSessionRefreshStart),
            debounceTime(100),
            withLatestFrom(this.store.select(SessionStateFeature.selectSession)),
            exhaustMap(([action, session]) => this._authorizationService.LoginRefresh(session,action.supplierId)),
            map(session => session ? UserSessionRefreshSuccess({ session }) : UserSessionRefreshError({ message: '' }))
        )
    )

}