import { Component, AfterViewInit, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { SessionStateFeature } from 'src/app/state/session.reducer';
import { Store } from '@ngrx/store';
import { Observable, filter } from 'rxjs';
import { UserSessionLoginStart, UserSessionSSOLoginStart } from 'src/app/state/session.actions';
import { AuthorizationService } from 'src/app/services/authurization.service';
declare var $: any;



@Component({
  selector: 'app',
  templateUrl: './login.page.component.html',
  styleUrls: ['./login.page.component.css']
})
export class LoginPageComponent implements OnInit, AfterViewInit {
  Username: string = '';
  Password: string = '';

  validationMessage$: Observable<string>;
  inProcess$: Observable<boolean>;
  constructor(private router: Router, private store: Store ) {

    this.validationMessage$ = store.select(SessionStateFeature.selectLoginError);
    this.inProcess$ = store.select(SessionStateFeature.selectLoading);
    this.store.select(SessionStateFeature.selectSession).pipe(filter(s => !!s)).subscribe(session => {

      if (session?.AccessToken) {
        this.router.navigate(['home']);
      }
    });
  }


  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    $('body').click();
  }


  Login(): void {
    this.store.dispatch(UserSessionLoginStart({
      username: this.Username,
      password: this.Password
    }));
    
  };


  SSOLogin(): void {
    this.store.dispatch(UserSessionSSOLoginStart());
  }
}
