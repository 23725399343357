import { Component, EventEmitter, Output, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';


import { CommonService } from '../../../services/common.service';
import { UserControlEditorMode } from '../../../models/user-controls';
import { IAddress } from 'src/app/models/common.models';
import { AbstractControl, FormControlStatus, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'address-editor',
  templateUrl: './address.editor.usercontrol.component.html'
})
export class AddressEditorUserControlComponent implements OnInit, OnChanges {


  constructor(private _common: CommonService) { }


  @Output() AddressChange: EventEmitter<IAddress> = new EventEmitter<IAddress>();
  @Input() Address!: IAddress;
  @Input() Mode!: UserControlEditorMode;


  @Output() OnValidChange: EventEmitter<FormControlStatus> = new EventEmitter<FormControlStatus>();
  public ValidState!: FormControlStatus;
  public ValidationMessage!: string;
  public ValidationFrom: UntypedFormGroup;

  @Output() public ValidationFromChange: EventEmitter<AbstractControl> = new EventEmitter<AbstractControl>();

  ngOnInit(): void {
    if (!this.Mode) this.Mode = UserControlEditorMode.View;
    if (!this.Address) this.Address = this._common.CreateNewIAddress();

    this.bindControls();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.Address && !changes.Address.currentValue) this.Address = this._common.CreateNewIAddress();
    if (changes.Mode && !changes.Mode.currentValue) this.Mode = UserControlEditorMode.View;
  }


  private bindControls() {

    if ((Number(this.Mode) & UserControlEditorMode.IgnoreValidation) === 0) {
      this.ValidationFrom = new UntypedFormGroup({
        AddressCity: new UntypedFormControl(this.Address.City, Validators.required)
      });

      this.ValidationFrom.statusChanges.subscribe(
        r => {
          this.OnValidChange.emit(r);
        });

      this.ValidationFromChange.emit(this.ValidationFrom);
    }

  }



  RaiseChange(changes: SimpleChanges): void {
    this.AddressChange.emit(this.Address);
  }
}

