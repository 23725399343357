import { Component, EventEmitter, Output, Input, OnInit, OnChanges, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { IOrder, OrderState } from '../../../models/orders.models';
import { OrderService, IOrderSearchRequest } from '../../../services/order.service';

import { IShipment, IDeliveryShipment, IgnoreAddressValidationShipmentTypes } from '../../../models/shipments.models';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, FormControlStatus } from '@angular/forms';

import { ShipmentService } from '../../../services/shipment.service';
import { CommonService } from '../../../services/common.service';
import { UserControlEditorMode } from '../../../models/user-controls';
import { LOVsService } from '../../../services/LOVs.service';

@Component({
  selector: 'delivery-shipment-editor',
  templateUrl: './delivery.shipment.editor.usercontrol.component.html',
  styleUrls: ['./delivery.shipment.editor.usercontrol.component.css']
})
export class DeliveryShipmentEditorUserControlComponent implements OnInit  {


  constructor(private _orderService: OrderService,
    private _common: CommonService,
    private _LOVsService: LOVsService,
    private _shipmentService: ShipmentService) { }


  @Output() OnValidChange: EventEmitter<FormControlStatus> = new EventEmitter<FormControlStatus>();
  @Output() ShipmentChange: EventEmitter<IShipment> = new EventEmitter<IShipment>();
  @Input() Shipment!: IDeliveryShipment;
  @Input() Mode!: UserControlEditorMode;

  public ValidState!: FormControlStatus;
  public ValidationMessage!: string;
  public ValidationFrom : UntypedFormGroup;
  @Output() public ValidationFromChange: EventEmitter<AbstractControl> = new EventEmitter<AbstractControl>();

  public ShipmentItemType: string = "";
  @Output() ShipmentItemTypeChange: EventEmitter<string> = new EventEmitter<string>();

  

  ngOnInit(): void {
    if (!this.Mode) this.Mode = UserControlEditorMode.View;

    if (!this.Shipment) this.Shipment = this._shipmentService.CreateNewDeliveryShipment();
    if (!this.Shipment.Address) this.Shipment.Address = this._common.CreateNewIAddress();

    this.bindControls();

  }
  public get AddressMode(): UserControlEditorMode {
    var mode = this.Mode;
    if (IgnoreAddressValidationShipmentTypes.indexOf(this.Shipment.ItemType) >= 0) mode = mode | UserControlEditorMode.IgnoreValidation;
    return mode;
  }

  BindAddressFromControl(ValidationFrom : AbstractControl): void {
   if ( this.ValidationFrom?.controls?.Address) this.ValidationFrom.removeControl("Address");
   this.ValidationFrom.addControl("Address",ValidationFrom);
  }


  private bindControls() {
         
    this.ValidationFrom = new UntypedFormGroup({
      RecipientFullName : new UntypedFormControl(this.Shipment.Recipient.FullName,Validators.required),
      PackagesCount: new UntypedFormControl(this.Shipment.Recipient.FullName,[Validators.required,Validators.min(0)]),
      RecipientEmail: new UntypedFormControl(this.Shipment.Recipient.Email,Validators.email),
     
    });

    this.ValidationFromChange.emit(this.ValidationFrom);


    this.ValidationFrom.statusChanges.subscribe(
      r => {
        this.OnValidChange.emit(r);
      });

  }





  RaiseChange(changes: SimpleChanges): void {
    this.ShipmentChange.emit(this.Shipment);
  }



}

