<nav class="se-header navbar navbar-expand-md navbar-dark bg-dark d-flex justify-content-between">
  <div>
    <a href="/" tabindex="-1"> <img src="/Images/AnonymousUser.png" class="rounded-circle se-avatar" /></a>
    <div class="navbar-brand">שלום {{UserSession.FullName}} <a href="#" (click)="logout()" tabindex="-1">(התנתק)</a></div>
  </div>

  <div *ngIf="!SupplierList" class="navbar-brand">
    {{UserSession.SupplierName}}
  </div>

  <div *ngIf="SupplierList" class="navbar-brand">
    <select  class="form-control text-light bg-dark" [ngModel]="UserSession.SupplierId" (ngModelChange)="OnSupplierChange($event)">
      <option *ngFor="let supplier of SupplierList" [value]="supplier.Id">{{supplier.Name}}</option>
    </select>
  </div>
  <div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" tabindex="-1">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="" id="navbarCollapse">
      <ul class="navbar-nav mlll-auto">
        <li class="nav-item active">
          <button class="btn btn-outline-success my-2 my-sm-0" [disabled]="true" tabindex="-1">הזמנות ממתינות</button>
        </li>
        <li class="nav-item">
          <button class="btn btn-outline-success my-2 my-sm-0" [disabled]="!ERPErrorOrderCount" routerLink="/orders" [queryParams]="{'ERPExportStatus': [128] ,Fields :'{&quot;ERPExportStatus&quot;:true,&quot;ERPExportRemarks&quot;:true}'}" tabindex="-1" [ngClass]="{'btn-outline-success': ERPErrorOrderCount == 0 ,'btn-outline-danger': ERPErrorOrderCount > 0 }">הודעות שגיאה {{ERPErrorOrderCount}}</button>
        </li>
        <!-- 
      <li class="nav-item">
        <button class="btn btn-outline-success my-2 my-sm-0" type="submit" tabindex="-1">עזרה</button>
      </li>
    -->
      </ul>
    </div>
  </div>
</nav>