import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import { ISupplierConfiguration, ISupplierMainSetting } from "../models/suppliers"
import { ConfigFetchShipmentTypesSuccess, ConfigFetchSupplierConfigurationStart, ConfigFetchSupplierConfigurationSuccess, ConfigFetchSupplierMainSettingStart, ConfigFetchSupplierMainSettingSuccess, ConfigSaveSupplierConfigurationError, ConfigSaveSupplierConfigurationStart, ConfigSaveSupplierConfigurationSuccess, ConfigSaveSupplierMainSettingSuccess } from "./config.actions";
import { state } from "@angular/animations";
import { IShipmentType, IShipmentTypes, UndefinedShipmentType } from "../models/shipments.models";
import { Action } from "rxjs/internal/scheduler/Action";
import { Actions, ofType } from "@ngrx/effects";
import { filter, firstValueFrom, pipe, tap } from "rxjs";


export const initialState: IConfigState =
{
    loading: false,
    configuration: null,
    supplierMainSetting: null,
    shipmentTypes: null
}


export interface IConfigState {
    configuration: ISupplierConfiguration,
    supplierMainSetting: ISupplierMainSetting,
    shipmentTypes: { [key: string]: IShipmentType },
    loading: boolean
}




export const ConfigStateFeature = createFeature({
    name: 'config',
    reducer: createReducer(
        initialState,
        on(ConfigFetchSupplierConfigurationStart, (state) => ({ ...state, loading: true })),
        on(ConfigFetchSupplierConfigurationSuccess, ConfigSaveSupplierConfigurationSuccess, (state, payload) => ({ ...state, configuration: payload.config, loading: false })),
        on(ConfigFetchSupplierMainSettingStart, (state) => ({ ...state, loading: true })),
        on(ConfigFetchSupplierMainSettingSuccess, ConfigSaveSupplierMainSettingSuccess, (state, payload) => ({ ...state, supplierMainSetting: payload.mainSetting, loading: false })),
        on(ConfigFetchShipmentTypesSuccess, (state, payload) => ({ ...state, shipmentTypes: payload.shipmentTypes })),
        on(ConfigFetchSupplierMainSettingSuccess, (state, payload) => ({ ...state, supplierMainSetting: payload.mainSetting })),
    ),
    extraSelectors: ({ selectConfiguration, selectShipmentTypes }) => ({
        availableShipmentTypes: createSelector(selectConfiguration, selectShipmentTypes,
            (config, shipmentTypes) => {
                let answer: IShipmentTypes = {};
                if (!config) return null;

                for (const shipType in shipmentTypes) {
                    if (shipmentTypes[shipType].Description) {
                        let show = config.Shipments[shipType] && config.Shipments[shipType].Enabled;
                        if (shipType == UndefinedShipmentType) show = true;
                        if (show) answer[shipType] = shipmentTypes[shipType];
                    }
                }
                return answer;
            }),
        stockModuleEnable: createSelector(selectConfiguration, (config) => config ? (config.StockSetting?.Enable ?? false) : null),
        productSetting: createSelector(selectConfiguration, (config) => config ? config.ProductSetting : null),
    }),



});

export function ConfigAvailableShipmentTypesFilter(shipmentTypes: IShipmentTypes, showSelfDistributers: boolean, allowUndefinedShipment: boolean): IShipmentTypes {
    let output: IShipmentTypes = {}
    for (var shipType in shipmentTypes) {
        if (!showSelfDistributers && shipmentTypes[shipType].IsSelfDistributer) continue;
        if (!allowUndefinedShipment && shipType == UndefinedShipmentType) continue;
        output[shipType] = shipmentTypes[shipType];
    }
    return output;

}
export async function WaitForConfigSave(actions$: Actions, action: Function): Promise<boolean> {
    var resultAction = firstValueFrom(actions$.pipe(
        ofType(ConfigSaveSupplierConfigurationSuccess, ConfigSaveSupplierConfigurationError))
    );
    action();

    const result = (await resultAction);
    return result.type == ConfigSaveSupplierConfigurationSuccess.type;

}