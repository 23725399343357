export class SelectableList<T>{

    constructor(private totalItemsCount: number) {
        this.SelectedItems = [];
        this.SelectAllItems = false;
    }
    public SelectedItems: T[]
    public SelectAllItems: boolean



    public FindSelectedItemIndex(id: T): number {
        return this.SelectedItems.indexOf(id);
    }

    public IsItemSelected(id: T): boolean {
        var isSelected = this.FindSelectedItemIndex(id) >= 0;
        return isSelected != this.SelectAllItems;
    }

    public ToggleSelectAll() {
        this.ClearSelection();
        this.SelectAllItems = !this.SelectAllItems;
    }
    public ClearSelection() {
        this.SelectedItems = [];

    }
    public GetSelectedItemCount(): number {
        if (this.SelectAllItems) return this.totalItemsCount - this.SelectedItems.length;
        else return this.SelectedItems.length;
    }

    public SelectItem(id: T, select: boolean): void {
        if (this.SelectAllItems) select = !select;
        if (!select) { // remove
            var index = this.FindSelectedItemIndex(id);
            if (index >= 0) {
                this.SelectedItems.splice(index, 1);
            }
        }
        else { // add
            var index = this.FindSelectedItemIndex(id); // check if already exists
            if (index < 0) this.SelectedItems.push(id);
        }
    }
}