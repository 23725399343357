export interface IItemAuditTrace {
  CreatedAt: Date,
  ModifiedAt: Date,
  DeletedAt?: Date,
  ChangeTrace?: string[],
  CreatedById: string,
  ModifiedById: string,
  DeletedById?: string;

  CreatedByName: string,
  ModifiedByName: string,
  DeletedByName?: string,
  DeletedReason?: string
}


export interface IIdentity {
  FullName: string,
  Phone?: string,
  Email?: string,
  SecondaryPhone?: string,
}



export interface IDBObject {
  Id: string | null,
  Audit?: IItemAuditTrace,
  SupplierId?: string | null
}

export interface IAddress {
  City: string,
  Neighborhood?: string,
  Street?: string,
  HouseNum?: string,
  Floor?: string,
  Entrance?: string,
  Apartment?: string,
  Zip?: string,
  POBox?: string,
  Remarks?: string,
  Company?: string
}


export interface IGeoCoordinate {
  Latitude: number,
  Longitude: number
}

export interface IPageResult<Type> {
  TotalItemsCount: number,
  PageIndex: number,
  PageSize: number,
  Items: Type[],
  HasNextPage: boolean,
  HasPreviousPage: boolean,
}

export interface IBaseQuery {

  OrderByFields?: string[]
  PageIndex?: number,
  PageSize?: number,
  Id?: string,
  Ids?: string[],
  ExcludeIds?: string[],
  ProjectionFields?: string[]
}


export interface ILongRunningOperation extends IDBObject {

  PresentComplete: number,
  Type: OperationItemType,
  Message: string,
  Items: ILongRunningOperationItem[] | null
}

export interface ILongRunningOperationItem {

  Type: LongRunningOperationItemType,
  RefId?: string,
  Message?: string,
  Status: LongRunningOperationItemStatus,
  ObjectsCount : number,
  ObjectsCodes?: string[]
}
export enum LongRunningOperationItemType {
  File = 1,
  Order = 2,
  Product = 3
}

export enum LongRunningOperationItemStatus {
  New = 0,
  InProgress = 1,
  Success = 2,
  Warning = 3,
  Failure = 4,
  SubmitFailure = 8,
  Skipped = -1,
}

export enum OperationItemType {
  ImportOrders = 1
}

export enum DBOperationResult {
  Success = 0,
  ObjectNotValid = 1 << 3,
  Faild = 1 << 6
}
