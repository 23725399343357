import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';




export interface IValidationResult {
  Code: number,
  Message: string,
  MessageEN?: string,
  SubMessage?: string,
  SubMessageEN?: string,
  Severity: SeverityLevelType
}

export enum SeverityLevelType {
  None = 0,
  Info = 2,
  Warning = 3,
  Medium = 4,
  High = 5
}



export function TriggerValidation(control: AbstractControl) {
  if (control instanceof UntypedFormGroup) {
    const group = (control as UntypedFormGroup);

    for (const field in group.controls) {
      const c = group.controls[field];
      TriggerValidation(c);
    }
  }
  else if (control instanceof UntypedFormArray) {
    const group = (control as UntypedFormArray);

    for (const field in group.controls) {
      const c = group.controls[field];
      TriggerValidation(c);
    }
  }

  control.updateValueAndValidity({ onlySelf: false });
}