import { Component, Input, Output, EventEmitter, OnInit, AfterViewChecked, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { promise } from 'protractor';
import { BehaviorSubject, Observable, Subscription, filter, firstValueFrom } from 'rxjs';
import { DeepCopyObject } from 'src/app/helpers/common.helper';
import { IDataTable, IExternalFile } from 'src/app/models/files.models';
import { IProductCatalog, IProductCatalogReferenceField, IProductCatalogTextField } from 'src/app/models/product.catalog';
import { IImportProductFormat, IProductImportMappingDefinition } from 'src/app/models/suppliers';
import { FileService } from 'src/app/services/file.service';
import { CatalogLazyFetchAllStart } from 'src/app/state/catalog.actions';
import { CatalogStateFeature } from 'src/app/state/catalog.reducer';


@Component({
  selector: 'import-product-format-editor',
  templateUrl: './importproductformateditor.usercontrol.component.html',
  styleUrls: ['./importproductformateditor.usercontrol.component.css'],

})
export class ImportProductFormatEditor implements OnInit, OnChanges {

  constructor(private formBuilder: FormBuilder, private store: Store) {

  }


  @Input()
  public ProductImportMappingDefinition: IImportProductFormat;

  @Output()
  public ProductImportMappingDefinitionChange: EventEmitter<IImportProductFormat> = new EventEmitter<IImportProductFormat>();


  @Output()
  public IsValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public MappingForm: FormGroup
  private onFormChangesSubscription: Subscription
  private onFormValidChangesSubscription: Subscription
  public ProductCatalogs: IProductCatalog[];
  public productFields: { name: string, path: string }[];
  async ngOnInit() {
    this.store.dispatch(CatalogLazyFetchAllStart());
    this.buildForm();
    this.buildProductDestFieldList();

  }

  public async buildProductDestFieldList(): Promise<void> {
    this.productFields = await firstValueFrom(this.store.select(CatalogStateFeature.fullProductFieldList).pipe(filter(v => !!v)));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ProductImportMappingDefinition && changes.ProductImportMappingDefinition.currentValue != this.ProductImportMappingDefinition) {
      this.buildForm();
    }
  }
 

  private buildForm() {

    if (this.onFormChangesSubscription) this.onFormChangesSubscription.unsubscribe();
    if (this.onFormValidChangesSubscription) this.onFormValidChangesSubscription.unsubscribe();

    this.MappingForm = this.formBuilder.group({
      Id: [this.ProductImportMappingDefinition.Id ?? ''],
      Name: [this.ProductImportMappingDefinition.Name ?? '', Validators.required],
      TableReadOption: [this.ProductImportMappingDefinition.TableReadOption ?? '', Validators.required]
    })

    this.MappingForm.addControl(
      "ProductImportFieldMapping", this.formBuilder.array(this.ProductImportMappingDefinition.ProductImportFieldMapping.map(this.generateCatalogFieldForm))
    )

    this.onFormChangesSubscription = this.MappingForm.valueChanges.subscribe(value => {
      if (this.MappingForm.valid) {
        this.ProductImportMappingDefinition = this.MappingForm.value;
        this.ProductImportMappingDefinitionChange.emit(this.ProductImportMappingDefinition);
      }
    })

    this.onFormValidChangesSubscription = this.MappingForm.statusChanges.subscribe(status => {
      this.IsValidChange.emit(status == 'VALID');
    })
  }



  private generateCatalogFieldForm = (mapping?: IProductImportMappingDefinition) => {
    var formGroup = this.formBuilder.group({
      SourceColumn: [mapping?.SourceColumn, Validators.required],
      DestinationProductField: [mapping?.DestinationProductField, Validators.required],
    });

    return formGroup;
  }

  public AddNewMapping() {
    (this.MappingForm.controls["ProductImportFieldMapping"] as FormArray).push(this.generateCatalogFieldForm());
  }

  public DeleteMapping(index: number) {
    (this.MappingForm.controls["ProductImportFieldMapping"] as FormArray).removeAt(index);
  }

}
