<h1>איתור איחודי משלוחים</h1>
<div *ngIf="OrderShipments ">
    <h5>נמצאו <b>{{OrderShipments.TotalItemsCount}}</b> איחודי משלוחים פוטנציאליים</h5>

    <div class="d-flex justify-content-between">
        <div></div>
        <div>
            <pager *ngIf="OrderShipments && OrderShipments.PageSize < OrderShipments.TotalItemsCount" [PageSize]="Query.PageSize" [PageIndex]="Query.PageIndex" (PageIndexChange)="Query.PageIndex = $event; reloadPage();" [TotalItemCount]="OrderShipments.TotalItemsCount"></pager>
        </div>

        <div style="width: 20rem;">
            <dateropdownpicker class="pl-1 mb-1 d-inline-block" [dropButClass]="(Query.AgeSeconds) ? 'btn btn-sm btn-success' : 'btn btn-sm btn-primary'" [model]="HTMLHelper.DateSelectiontModel(Query.AgeSeconds,Query.CreatedDateFrom,Query.CreatedDateTo)" (modelChange)=" SetOrderDateFilter( $event);" title="גיל">
            </dateropdownpicker>
            <ngx-bootstrap-multiselect
             *ngIf="FillersSettings.UnifyOptions && FillersSettings.UnifyOptions.length"
              class="pl-1 mb-1 d-inline-block" 
              [settings]="FillersSettings.CheckboxListSetting" 
              [texts]="FillersSettings.GetIMultiSelectTexts('מצב איחוד')" 
              [options]="FillersSettings.UnifyOptions"
              [ngModel]="UnifyFilter" 
              [ngClass]="{'selected': IsUnifyFilterSelected}" (ngModelChange)="UnifyFilter = $event; UpdateUnifyFilter();"></ngx-bootstrap-multiselect>
   
        </div>
    </div>
 
    <div class="mt-4">
        <table class="table table-hover table-sm">
            <thead>
                <tr>
                    <th scope="col" style="width: 2%">#</th>
                    <th scope="col" style="width: 7%" class="item-list-header">ס.הזמנה </th>
                    <th scope="col" style="width: 7%" class="item-list-header">מספר הזמנה </th>
                    <th scope="col" style="width: 7%" class="item-list-header">ע. הפצה </th>
                    <th scope="col" style="width: 8%" class="item-list-header">ע. מכירה </th>
                    <th scope="col" style="width: 27%" class="item-list-header">מק"ט מוצר</th>
                    <th scope="col" style="width: 3%" class="item-list-header">יח'</th>
                    <th scope="col" style="width: 6%" class="item-list-header">ת.הזמנה </th>
                    <th scope="col" style="width: 6%" class="item-list-header">לקוח</th>
                    <th scope="col" style="width: 8%" class="item-list-header">טלפון משלוח</th>
                    <th scope="col" style="width: 8%" class="item-list-header">עיר </th>
                    <th scope="col" style="width: 6%" class="item-list-header">אחד עם </th>
                    <th scope="col" style="width: 3%;text-align: center;" class="item-list-header">מדבקות (חבילות)</th>
                    <th scope="col" style="width: 2%;text-align: center;" class="item-list-header"></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let ordersGroup of OrderShipments.Items ; index as orderGroupIndex ">
                    <tr *ngFor="let order of ordersGroup.Items; index as orderIndex" [ngClass]="{'NextStartGroup' : orderGroupIndex && !orderIndex }">
                        <th scope="row">{{(1 + orderIndex)}}</th>
                        <td>
                            {{order.Shipments[0]?.Status | enumDescription : "ShipmentStatus"}} ({{order.State | enumDescription : "OrderState"}})
                        </td>

                        <td scope="row"><a [routerLink]="['/order', order.Id]"> {{order.SupplierNumber}}</a></td>
                        <td>{{order.Shipments[0].ItemType | shipmentDescriptionAsync | async}} </td>
                        <!--4-->
                        <td><i class="far" [ngClass]="HTMLHelper.GetOrderSourceTypeFontAwesomeIcon(order)" title="{{order.Source.Type | enumDescription : 'OrderSource'}}"></i> {{order.Source.OriginStore | storeDescriptionAsync | async}}</td>

                        <td class="multi-line text-truncate prod-desc">
                            {{HTMLHelper.GetOrderItemSKUDescription(order)}}
                        </td>
                        <td class="multi-line text-truncate prod-desc">
                            {{HTMLHelper.GetOrderItemQuantity(order)}}
                        </td>
                        <td>{{order.Audit.CreatedAt | date:"dd/MM/yyyy"}}</td>
                        <td>{{order.Shopper.FullName}}</td>
                        <td class="ltr">{{order.Shipments[0].Recipient.Phone}}</td>
                        <td>{{order.Shipments[0].Address.City}}</td>
                        <td>
                            <select class="form-control form-control-sm" [disabled]="HTMLHelper.IsLeadingShipment(order)" [ngModel]="HTMLHelper.GetUnifyIndex(ordersGroup,order)" (ngModelChange)="Unify(ordersGroup,order , $event ,  $event == orderIndex )">
                                <option [ngValue]="orderIndex">ללא</option>
                                <ng-container *ngFor="let rowNum of HTMLHelper.GenerateOrderGroupNumbersToUnify(ordersGroup,order) ">
                                    <option *ngIf="orderIndex != rowNum" [ngValue]="rowNum">{{rowNum + 1}} #</option>
                                </ng-container>
                            </select>

                        </td>
                        <td style="text-align: center;" class="border-left">
                            <input style="width: 2.5rem;" class="form-control form-control-sm" min="1" *ngIf="orderIndex == HTMLHelper.GetUnifyIndex(ordersGroup,order)" type="number" [(ngModel)]="order.Shipments[0].PackagesCount" (ngModelChange)="OnPackageCountChange(ordersGroup,order)" />

                        </td>

                        <td *ngIf="!orderIndex" [rowSpan]="ordersGroup.Items.length" class="saveCell">
                            <button type="button" class="btn  btn-sm" [ngClass]="{'btn-primary': ordersGroup.HasUnification ,'btn-success': !ordersGroup.HasUnification }" [disabled]="ordersGroup.Saving || !ordersGroup.HasChanges" [style.height.rem]=" HTMLHelper.GetGroupSaveButHeight(ordersGroup) * 2" (click)="SaveGroup(ordersGroup)">
                                שמור<br />
                                <i class="fa" [ngClass]="{'fa-spinner fa-pulse' : ordersGroup.Saving , 'fa-save' : !ordersGroup.Saving}"></i>
                            </button>

                        </td>

                    </tr>

                </ng-container>


            </tbody>
        </table>

    </div>
</div>