import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

declare var $: any;


import { AllProductImportFileTypes, IImportProductFormat, IProductConfiguration, IRetentionPolicyConfiguration, IStockConfiguration, ISupplierConfiguration, ISupplierMainSetting, ISupplierUser, IUIConfiguration, SupplierUserRolesTypes, TableReadOptions } from '../../../models/suppliers';
import { AdminService } from '../../../services/admin.service';
import { IStoreDetails, StoreType } from '../../../models/stores';

import { firstValueFrom, forkJoin, zip } from 'rxjs';
import { filter, map, mergeMap, switchMap, zipAll } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ConfigStateFeature, WaitForConfigSave } from 'src/app/state/config.reducer';
import { ConfigSetSupplierProductConfiguration, ConfigSetSupplierRetentionPolicyConfiguration, ConfigSetSupplierStockConfiguration, ConfigSetSupplierStoreConfiguration, ConfigSetSupplierUIConfiguration } from 'src/app/state/config.actions';
import { Actions, ofType } from '@ngrx/effects';

import { DeepCopyObject } from 'src/app/helpers/common.helper';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ISessionState, SessionStateFeature } from 'src/app/state/session.reducer';
import { ILoginUserSession } from 'src/app/models/users.models';



@Component({
  selector: 'app',
  templateUrl: './supplier.configuration.page.component.html',
  styleUrls: ['./supplier.configuration.page.component.css']
})
export class SupplierConfigurationPageComponent implements OnInit {
  public RetentionPolicyConfiguration!: IRetentionPolicyConfiguration | null;
  public StockSetting!: IStockConfiguration | null;
  public ProductSetting!: IProductConfiguration | null;
  public Setting!: ISupplierMainSetting;
  public Users!: ISupplierUser[];
  public UserRoleTypes = SupplierUserRolesTypes;
  public ValidationFrom: UntypedFormGroup;
  public FileTypes = AllProductImportFileTypes;
  public UIConfiguration!: IUIConfiguration;
  public CurrentUserSession!: ILoginUserSession;
  closeResult: string;

  constructor(
    private _adminService: AdminService,
    private store: Store,
    private actions$: Actions,
    private _modalService: NgbModal,
    private _router: Router,) { }



  public ActiveStores!: IStoreDetails[];
  public InActiveStores!: IStoreDetails[];
  ngOnInit() {


    zip(
      this.store.select(ConfigStateFeature.selectConfiguration).pipe(filter(s => !!s), map(DeepCopyObject)),
      this.store.select(ConfigStateFeature.selectSupplierMainSetting).pipe(filter(s => !!s), map(DeepCopyObject)),
      this._adminService.GetSupplierUsers({ ExcludeRoles: ["ERP_System"] }),
      this.store.select(SessionStateFeature.selectSession)
    )
      .subscribe(([config, mainSetting, users, session]) => {

        this.RetentionPolicyConfiguration = config.RetentionPolicy;
        this.StockSetting = config.StockSetting;
        this.ProductSetting = config.ProductSetting;
        this.UIConfiguration = config.UIConfiguration;
        this.CurrentUserSession = session; 
        
        if (!this.RetentionPolicyConfiguration) this.RetentionPolicyConfiguration = this._adminService.CreateNewSupplierRetentionPolicyConfiguration();
        if (!this.StockSetting) this.StockSetting = this._adminService.CreateNewSupplierStockConfiguration();
        if (!this.ProductSetting) this.ProductSetting = this._adminService.CreateNewSupplierProductConfiguration();
        if (!this.ProductSetting.PullSource) this.ProductSetting.PullSource = {};
        if (!this.ProductSetting.PullPostProcessCopy) this.ProductSetting.PullPostProcessCopy = {};
        if (!this.UIConfiguration) this.UIConfiguration = { OrderPageQuickButton: "ExportERPFile" };


        this.Setting = mainSetting;
        if (users.Code == 0) this.Users = users.Result.Items;
        this.bindControls();
      });
  }

  public async SaveAndReturn() {

    if (!(await firstValueFrom(this._adminService.SetSupplierMainSetting(this.Setting)))) return;
    console.log(this.RetentionPolicyConfiguration);
    if (!(await WaitForConfigSave(this.actions$, () => { this.store.dispatch(ConfigSetSupplierRetentionPolicyConfiguration({ retentionPolicy: this.RetentionPolicyConfiguration })); }))) return;
    if (!(await WaitForConfigSave(this.actions$, () => { this.store.dispatch(ConfigSetSupplierStockConfiguration({ stockSetting: this.StockSetting })); }))) return;
    if (!(await WaitForConfigSave(this.actions$, () => { this.store.dispatch(ConfigSetSupplierProductConfiguration({ productSetting: this.ProductSetting })); }))) return;
    if (!(await WaitForConfigSave(this.actions$, () => { this.store.dispatch(ConfigSetSupplierUIConfiguration({ uiConfiguration: this.UIConfiguration })); }))) return;



    this._router.navigate(['/home']);
  }


  private bindControls() {

    this.ValidationFrom = new UntypedFormGroup({
      PullSourceUrl: new UntypedFormControl(this.ProductSetting.PullSource.Url, {
        validators: Validators.pattern(/^(ht|f)tp(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%\$#_]*)?$/i),
        updateOn: 'blur'
      }),
      PullPostProcessCopyUrl: new UntypedFormControl(this.ProductSetting.PullPostProcessCopy.Url, {
        validators: Validators.pattern(/^(ht|f)tp(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%\$#_]*)?$/i),
        updateOn: 'blur'
      })
    });


    this.ValidationFrom.statusChanges.subscribe(
      r => {

      });

  }

  public selectedImportProductFormat: IImportProductFormat;
  public selectedImportProductFormatValid: boolean;
  public CreateNewImportProductFormat = () => {
    this.selectedImportProductFormat = {
      Id: null,
      Name: "",
      TableReadOption: TableReadOptions.SingleHeader,
      ProductImportFieldMapping: []
    }
    this.selectedImportProductFormatValid = false;
  }

  public SaveNewImportProductFormat = () => {
    if (!this.ProductSetting.ImportProductFormats) this.ProductSetting.ImportProductFormats = [];

    var index = this.ProductSetting.ImportProductFormats.findIndex(format => format.Name == this.selectedImportProductFormat.Name);
    if (index < 0) this.ProductSetting.ImportProductFormats.push(this.selectedImportProductFormat);
    else this.ProductSetting.ImportProductFormats[index] = this.selectedImportProductFormat;
  }
  public DeleteImportProductFormatByIndex(index: number) {
    this.ProductSetting.ImportProductFormats.splice(index, 1);
  }

  public open(content, options, onInit, onSave) {
    if (onInit) onInit();
    this._modalService.open(content, options).result.then((result) => {
      if ((result === "save") && (onSave)) onSave();
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {

      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
