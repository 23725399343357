import { Component, OnInit, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";


declare var $: any;

import { FileService } from '../../../services/file.service';

import { FileType, IExternalFile } from '../../../models/files.models';

import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { IProductSearchRequest, ProductService } from '../../../services/product.service';
import { IShipmentTypes } from 'src/app/models/shipments.models';
import { AdminService } from 'src/app/services/admin.service';
import { StoreService } from 'src/app/services/store.service';
import { IStoreDetails, StoreType } from 'src/app/models/stores';
import { IExportOptions } from '../product-export/product-export.process.page.component';
import { KeyValue } from '@angular/common';
import { AllProductImportFileTypes, IImportProductFormat } from 'src/app/models/suppliers';
import { Store } from '@ngrx/store';
import { ConfigAvailableShipmentTypesFilter, ConfigStateFeature } from 'src/app/state/config.reducer';
import { filter, firstValueFrom, map } from 'rxjs';
import { CatalogStateFeature } from 'src/app/state/catalog.reducer';
import { CatalogLazyFetchAllStart } from 'src/app/state/catalog.actions';
import { EnumDescriptionPipe } from 'src/app/pipes/enumdescription.pipe';
import { DeliveryPackageSize } from 'src/app/models/products.models';

@Component({
  templateUrl: './product-import.upload.page.component.html',
  styleUrls: ['./product-import.upload.page.component.css'],

})
export class ProductImportUploadPageComponent implements OnInit {

  public AvailableShipmentTypes!: IShipmentTypes;
  public AvailableShipmentTypesLength!: number;
  public FileTypes: { id: string, name: string }[];
  public DataOverrideOptions = {

    "GeneralInsert": "הוסף חדשים בלבד",
    "ShopGeneralUpsert": "עדכן והוסף",
    "ShopGeneralResetUpsert": "עדכן, הוסף ונקה"
  };
  public ActiveStores: IStoreDetails[];
  public PriceOverrideOptions = {
    "Ignore": "התעלם",
    "Include": "עדכן",
    //"PriceOnlyShopDefault": "עדכן רק מחיר",
  };

  public SelectedFileType = "Operational";
  public SelectedFiles: IExternalFile[] = [];
  public SelectedStore: string | null = null;
  public SelectedDataOverrideOption: string = "ShopGeneralUpsert";
  public SelectedPriceOverrideOption: string = "Ignore"
  public CustomImportProductFormats: IImportProductFormat[];
  public productFields: { name: string, path: string }[];
  private EnumDescriptionConverter = new EnumDescriptionPipe();

  constructor(
    private _productService: ProductService,
    private _adminService: AdminService,
    private _storeService: StoreService,
    private _route: ActivatedRoute,
    private store: Store,
    private _router: Router) { }


  public AvailableDeliveryPackageSizes = [
    { code: "Letter", name: this.EnumDescriptionConverter.transform(DeliveryPackageSize.Letter, "DeliveryPackageSize") },
    { code: "Normal", name: this.EnumDescriptionConverter.transform(DeliveryPackageSize.Normal, "DeliveryPackageSize") },
    { code: "OverSize", name: this.EnumDescriptionConverter.transform(DeliveryPackageSize.OverSize, "DeliveryPackageSize") },
    { code: "Stand", name: this.EnumDescriptionConverter.transform(DeliveryPackageSize.Stand, "DeliveryPackageSize") },
    { code: "Platform", name: this.EnumDescriptionConverter.transform(DeliveryPackageSize.Platform, "DeliveryPackageSize") }
  ];

  ngOnInit() {
    this.store.dispatch(CatalogLazyFetchAllStart());
    this.store.select(ConfigStateFeature.availableShipmentTypes).pipe(filter(s => !!s), map(types => ConfigAvailableShipmentTypesFilter(types, false, false))).subscribe(result => {
      this.AvailableShipmentTypes = result;
      this.AvailableShipmentTypesLength = Object.keys(this.AvailableShipmentTypes).length;
    });
    this._storeService.GetStoreList().subscribe(s => this.ActiveStores = s.filter(s => !!s.Enabled));
    this.store.select(ConfigStateFeature.productSetting)
      .pipe(map(productSetting => productSetting?.ImportProductFormats ?? []))
      .subscribe(importProductFormats => {
        this.CustomImportProductFormats = importProductFormats;
        this.FileTypes = [...AllProductImportFileTypes, ...importProductFormats.map(format => ({ id: format.Id, name: format.Name }))];
      });
    this.store.select(CatalogStateFeature.fullProductFieldList).pipe(filter(v => !!v)).subscribe(fields => {
      this.productFields = fields
    });
  }



  public get SelectedCustomImportProductFormat(): IImportProductFormat | null {
    return this.CustomImportProductFormats.find(f => f.Id == this.SelectedFileType) ?? null;
  }

  public get ReadyForImportValidationMessage(): string {
    // should convert to veriable ad this is called meny times
    if (!this.SelectedFiles.length) return "אנא בחר קבצים להעלות";
    return "";
  }

  public GetFieldName(fileCode) {
    if (!this.productFields) return "";
    var field = this.productFields.find(f => f.path == fileCode);
    return field?.name ?? "";
  }

  public ImpotFiles() {
    this._productService.ImportProductsFromFiles(
      this.SelectedFiles.map(f => f.Id as string),
      {
        FileType: this.SelectedFileType as any,
        Store: this.SelectedStore as unknown as StoreType,
        DataChangePolicy: this.SelectedDataOverrideOption as any,
        PriceChangePolicy: this.SelectedPriceOverrideOption as any,
        StockChangePolicy: 'Include'
      }
    ).subscribe(r => {
      if (r.Result) {
        this._router.navigate(['/import-products-process', r.Result.Id]);
      }
    });
  }

  public get PrintPriceListQueryParamsURL() {
    var query = {
      Type: "MasterProduct"
    } as IProductSearchRequest;
    return {
      returnUrl: "closewindow",
      returnDescription: "סגור",
      query: JSON.stringify(query),
      options: JSON.stringify({ FileTypes: [FileType.Export] } as IExportOptions),
      types: ["storeprice-" + this.SelectedStore],
      isdlg: true
    };
  }
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }
}

