import { NgModule, forwardRef } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbDateAdapter, NgbDateParserFormatter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';

import { OrderModule } from 'ngx-order-pipe';
//import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';


import { NgxFileDropModule } from 'ngx-file-drop';
//import { TagInputModule } from 'ngx-chips';
import { BrowserModule } from '@angular/platform-browser';
import { AuthurizationHttpInterceptor, AuthorizationService } from './services/authurization.service';
import { AppComponent } from './components/app/app.component';
import { MainLayoutComponent } from './components/layouts/main/main.layout.component';
import { ERPConfigurationPageComponent } from './components/pages/admin/erp.configuration.list.page.component';
import { ShipmentConfigurationListPageComponent } from './components/pages/admin/shipment.configuration.list.page.component';
import { ShipmentConfigurationPageComponent } from './components/pages/admin/shipment.configuration.page.component';
import { StoreConfigurationListPageComponent } from './components/pages/admin/store.configuration.list.page.component';
import { StoreConfigurationPageComponent } from './components/pages/admin/store.configuration.page.component';
import { HomePageComponent } from './components/pages/home/home.page.component';
import { LoginPageComponent } from './components/pages/login/login.page.component';
import { OrderExportProcessPageComponent } from './components/pages/order-export/order-export.process.page.component';
import { OrderImportProcessPageComponent } from './components/pages/order-import/order-import.process.page.component';
import { OrderImportUploadPageComponent } from './components/pages/order-import/order-import.upload.page.component';
import { OrderListPageComponent } from './components/pages/order-list/order-list.page.component';
import { OrderWizardPageComponent } from './components/pages/order-wizard/order-wizard.page.component';
import { OrderPageComponent } from './components/pages/order/order.page.component';
import { ProductImportProcessPageComponent } from './components/pages/product-import/product-import.process.page.component';
import { ProductImportUploadPageComponent } from './components/pages/product-import/product-import.upload.page.component';
import { ProductListPageComponent } from './components/pages/product-list/product-list.page.component';
import { ProductPageComponent } from './components/pages/product/product.page.component';
import { ShipmentSubmitterPageComponent } from './components/pages/shipment-submitter/shipment-submitter.page.component';
import { CheckboxUserControlComponent } from './components/usercontrols/checkbox/checkbox.usercontrol.component';
import { DateDropdownPickerUserControlComponent } from './components/usercontrols/datetimedropdownpicker/dateropdownpicker.usercontrol.component';
import { IdentityEditorUserControlComponent } from './components/usercontrols/identity/identity.editor.usercontrol.component';
import { LabeledAutoComplateTextBoxUserControlComponent } from './components/usercontrols/labeledtextbox/labeledautocompletextbox.usercontrol.component';
import { LabeledTextBoxUserControlComponent } from './components/usercontrols/labeledtextbox/labeledtextbox.usercontrol.component';
import { MasterAliasProductBinderUserControlComponent } from './components/usercontrols/masteraliasproductbinder/masteraliasproductbinder.usercontrol.component';
import { HeaderMenuUserControlComponent } from './components/usercontrols/menus/header/header.menu.usercontrol.component';
import { RightNavMenuUserControlComponent } from './components/usercontrols/menus/rightnav/rightnav.menu.usercontrol.component';
import { OrderSupplierNumberTextboxUserControlComponent } from './components/usercontrols/order/order-supplier-number.textbox.usercontrol.component';
import { PagerUserControlComponent } from './components/usercontrols/pager/pager.usercontrol.component';
import { ProductAttributeSelectorUserControlComponent } from './components/usercontrols/product/productattributeselector.usercontrol.component';
import { ProductAutocompleteTextboxUserControlComponent } from './components/usercontrols/product/productautocomplete.textbox.usercontrol.component';
import { ProductSelectUserControlComponent } from './components/usercontrols/product/productselect.usercontrol.component';
import { AddressEditorUserControlComponent } from './components/usercontrols/shipment/address.editor.usercontrol.component';
import { CityAutoComplateInpotUserControlComponent } from './components/usercontrols/shipment/city.autocomplate.input.usercontrol.component';
import { DeliveryShipmentEditorUserControlComponent } from './components/usercontrols/shipment/delivery.shipment.editor.usercontrol.component';
import { DropPointShipmentEditorUserControlComponent } from './components/usercontrols/shipment/droppoint.shipment.editor.usercontrol.component';
import { DropPointShipmentSelectorUserControlComponent } from './components/usercontrols/shipment/droppoint.shipment.selector.usercontrol.component';
import { ShipmentEditorUserControlComponent } from './components/usercontrols/shipment/shipment.editor.usercontrol.component';
import { ShipmentTypeSelectorUserControlComponent } from './components/usercontrols/shipment/shipment.type.selector.usercontrol.component';
import { StreetAutoComplateInpotUserControlComponent } from './components/usercontrols/shipment/street.autocomplate.input.usercontrol.component';
import { UploadFileUserControlComponent } from './components/usercontrols/uploadfile/uploadfile.usercontrol.component';
import { EnumDescriptionPipe, EnumListPipe, EnumShipmentDescriptionPipe, EnumStoreDescriptionPipe } from './pipes/enumdescription.pipe';
import { ToAge } from './pipes/toage.pipe';
import { NgbParserNativeAdapter } from './components/usercontrols/datenativeadapter/datenativeadapter.usercontrol.component';
import { OnlyLoggedInUsersGuard } from './guards/only.loggedin.users';
import { AdminService } from './services/admin.service';
import { CommonService } from './services/common.service';
import { FileService } from './services/file.service';
import { LOVsService } from './services/LOVs.service';
import { OperationService } from './services/operation.service';
import { OrderService } from './services/order.service';
import { ProductService } from './services/product.service';
import { ShipmentService } from './services/shipment.service';
import { StoreService } from './services/store.service';
import { SupplierConfigurationPageComponent } from './components/pages/admin/supplier.configuration.page.component';
import { SupplierUserPageComponent } from './components/pages/admin/supplier.user.page.component';
import { RoleGuard } from './guards/role.guard.users';
import { OrderShipmentMergerPageComponent } from './components/pages/order-shipment-merger/order-shipment-merger.page.component';
import { ProductExportProcessPageComponent } from './components/pages/product-export/product-export.process.page.component';
import { UnifyShipmentIconUserControlComponent } from './components/usercontrols/unifyshipmenticon/unifyshipmenticon.usercontrol.component';
import { UserService } from './services/user.service';
import { StockImportUploadPageComponent } from './components/pages/stock/stock-import.upload.page.component';
import { AutofocusDirective } from './components/directives/autofocus';

import { NgxBootstrapMultiselectModule } from './deprecated-open-source-projects/angular-2-dropdown-multiselect/src/public-api';
import { TagInputModule } from './deprecated-open-source-projects/ngx-chips/tag-input.module';
import { GoogleMapsModule } from '@angular/google-maps'
import { DropPointMapUserControlComponent } from './components/usercontrols/shipment/droppoint.map.usercontrol.component';
import { ProductCatalogListPageComponent } from './components/pages/product-catalog-list/product-catalog-list.page.component';
import { ProductCatalogPageComponent } from './components/pages/product-catalog/product-catalog.page.component';
import { StoreModule } from '@ngrx/store';


import { EffectsModule } from '@ngrx/effects';

import { statisticsStateFeature } from './state/statistics.reducer';
import { StatisticsEffects } from './state/statistics.effects';
import { SessionEffects } from './state/session.effects';
import { SessionStateFeature } from './state/session.reducer';
import { ConfigStateFeature } from './state/config.reducer';
import { ConfigEffects } from './state/config.effects';
import { ProductCatalogImportWizard } from './components/usercontrols/catalog/importcatalogwizard.usercontrol.component';
import { CatalogStateFeature } from './state/catalog.reducer';
import { CatalogEffects } from './state/catalog.effects';
import { ImportProductFormatEditor } from './components/usercontrols/productimportmappingdefinitioneditor/importproductformateditor.usercontrol.component';
//import { AgmCoreModule } from './deprecated-open-source-projects/angular-google-maps/core';


@NgModule({
  declarations: [
    AppComponent,
    RightNavMenuUserControlComponent,
    HeaderMenuUserControlComponent,
    ProductSelectUserControlComponent,
    ProductAutocompleteTextboxUserControlComponent,
    ProductAttributeSelectorUserControlComponent,
    MasterAliasProductBinderUserControlComponent,
    DeliveryShipmentEditorUserControlComponent,
    LabeledTextBoxUserControlComponent,
    CheckboxUserControlComponent,
    DateDropdownPickerUserControlComponent,
    UploadFileUserControlComponent,
    PagerUserControlComponent,
    OrderSupplierNumberTextboxUserControlComponent,
    ShipmentTypeSelectorUserControlComponent,
    MainLayoutComponent,
    HomePageComponent,
    LoginPageComponent,
    OrderPageComponent,
    OrderListPageComponent,
    ProductListPageComponent,
    ProductExportProcessPageComponent,
    ProductPageComponent,
    ProductCatalogListPageComponent,
    ProductCatalogPageComponent,
    ProductCatalogImportWizard,
    ShipmentSubmitterPageComponent,
    IdentityEditorUserControlComponent,
    EnumDescriptionPipe,
    EnumStoreDescriptionPipe,
    EnumShipmentDescriptionPipe,
    EnumListPipe,
    ToAge,
    AutofocusDirective,
    OrderImportUploadPageComponent,
    OrderImportProcessPageComponent,
    OrderWizardPageComponent,
    OrderExportProcessPageComponent,
    OrderShipmentMergerPageComponent,
    ShipmentEditorUserControlComponent,
    AddressEditorUserControlComponent,
    CityAutoComplateInpotUserControlComponent,
    StreetAutoComplateInpotUserControlComponent,
    ProductImportUploadPageComponent,
    ProductImportProcessPageComponent,
    StoreConfigurationListPageComponent,
    StoreConfigurationPageComponent,
    ShipmentConfigurationListPageComponent,
    ShipmentConfigurationPageComponent,
    SupplierUserPageComponent,
    ERPConfigurationPageComponent,
    SupplierConfigurationPageComponent,
    DropPointShipmentSelectorUserControlComponent,
    DropPointShipmentEditorUserControlComponent,
    DropPointMapUserControlComponent,
    LabeledAutoComplateTextBoxUserControlComponent,
    UnifyShipmentIconUserControlComponent,
    StockImportUploadPageComponent,
    ImportProductFormatEditor
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    OrderModule,
    NgxBootstrapMultiselectModule,
    NgbModule,
    NgxFileDropModule,
    ReactiveFormsModule,
    TagInputModule,
    RouterModule.forRoot([
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginPageComponent },
      {
        path: '',
        component: MainLayoutComponent,
        canActivate: [OnlyLoggedInUsersGuard],
        children: [
          { path: 'home', component: HomePageComponent },
          { path: 'import-products-upload', component: ProductImportUploadPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin'] } },
          { path: 'import-products-process/:SourceOperationID', component: ProductImportProcessPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin'] } },
          { path: 'import-stock-upload', component: StockImportUploadPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin'] } },

          { path: 'orders', component: OrderListPageComponent },
          { path: 'order/:id', component: OrderPageComponent },
          { path: 'import-orders-upload', component: OrderImportUploadPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin', 'OrdersManager'] } },
          { path: 'import-orders-process/:SourceOperationID', component: OrderImportProcessPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin', 'OrdersManager'] } },
          { path: 'orders-wizard', component: OrderWizardPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin', 'OrdersManager'] } },
          { path: 'orders-shipment-merger', component: OrderShipmentMergerPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin', 'OrdersManager'] } },
          { path: 'products', component: ProductListPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin'] } },
          { path: 'product/:id', component: ProductPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin'] } },
          { path: 'product-catalogs', component: ProductCatalogListPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin'] } },
          { path: 'product-catalog', component: ProductCatalogPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin'] } },
          { path: 'product-catalog/:id', component: ProductCatalogPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin'] } },
          { path: 'shipments-submitter', component: ShipmentSubmitterPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin', 'OrdersOperator'] } },
          { path: 'print-orders', component: OrderExportProcessPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin', 'OrdersOperator'] } },
          { path: 'print-products', component: ProductExportProcessPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin'] } },
          { path: 'admin/store', component: StoreConfigurationListPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin'] } },
          { path: 'admin/store/:id', component: StoreConfigurationPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin'] } },
          { path: 'admin/shipment', component: ShipmentConfigurationListPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin'] } },
          { path: 'admin/shipment/:id', component: ShipmentConfigurationPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin'] } },
          { path: 'admin/custom-export', component: ERPConfigurationPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin'] } },
          { path: 'admin/supplier', component: SupplierConfigurationPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin'] } },
          { path: 'admin/supplier/user', component: SupplierUserPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin'] } },
          { path: 'admin/supplier/user/:id', component: SupplierUserPageComponent, canActivate: [RoleGuard], data: { roles: ['Admin'] } },
        ]
      },
      {
        path: '**', redirectTo: 'home'
      }
    ]
      , {}),
    GoogleMapsModule,
    StoreModule.forRoot({}),
    StoreModule.forFeature(SessionStateFeature.name, SessionStateFeature.reducer),
    StoreModule.forFeature(statisticsStateFeature.name, statisticsStateFeature.reducer),
    StoreModule.forFeature(ConfigStateFeature.name,ConfigStateFeature.reducer),
    StoreModule.forFeature(CatalogStateFeature.name,CatalogStateFeature.reducer),
    EffectsModule.forRoot([SessionEffects, StatisticsEffects, ConfigEffects , CatalogEffects])

  ],
  providers: [
    CommonService,
    AuthorizationService,
    AdminService,
    UserService,
    OnlyLoggedInUsersGuard,
    RoleGuard,
    ShipmentService,
    OrderService,
    StoreService,
    FileService,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbParserNativeAdapter },
    ProductService,
    LOVsService,
    OperationService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LabeledTextBoxUserControlComponent),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthurizationHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
