import { Component, EventEmitter, Output, Input } from '@angular/core';
import { IMasterProduct, IProduct } from '../../../models/products.models';
import { ProductService, IProductSearchRequest } from '../../../services/product.service';
import { IOrderItem, IProductOrderItem } from '../../../models/orders.models';
import { OrderService } from '../../../services/order.service';
import { flatMap } from 'rxjs/operators';

@Component({
  selector: 'product-select',
  templateUrl: './productselect.usercontrol.component.html',
  styleUrls: ['./productselect.usercontrol.component.css']
})
export class ProductSelectUserControlComponent {

  constructor(private _productService: ProductService) { }

  Mode: ProductSelectMode = ProductSelectMode.Search;
  SelectedProduct!: IMasterProduct;
  SelectedProductattributes: string = "";

  ProcessingMessage!: string;
  ProductSearchQuery: IProductSearchRequest = { FreeSearch: "", Type: "MasterProduct", OrderByFields: ["StrLength.SKU"] };

  @Input()
  public tabindex: number = 0;

  @Input()
  public store: string;

  @Output()
  onResult = new EventEmitter<IMasterProduct>();


  public SearchResults: IMasterProduct[] | null = null;
  public SearchProduct() {
    this._productService.SearchProducts(this.ProductSearchQuery).subscribe(SearchResult => {
      this.SearchResults = SearchResult.Result.Items as IMasterProduct[];
    });
  }
  public SwitchMode(newMode: ProductSelectMode) {
    if (this.Mode !== newMode) {
      if (newMode === ProductSelectMode.NewProduct) {
        if (!this.SelectedProduct) this.CreateNewProduct();
        else if (this.SelectedProduct.Id) newMode = ProductSelectMode.Search;
      }
      if (this.Mode === ProductSelectMode.NewProduct) { // if we are moving from new product mode
        this.SetNewProduct();
      }

      this.Mode = newMode;
    }
  }

  public CreateNewProduct() {
    this.SelectedProduct = this._productService.CreateNewProduct();
    if (this.SelectedProduct.Attributes) this.SelectedProductattributes = this.SelectedProduct.Attributes.join(",");
  }
  public SetNewProduct() {
    if (this.SelectedProduct) this.SelectedProduct.Attributes = this.SelectedProductattributes.split(",");
  }

  public SelectProduct(product) {
    if (product) {
      this.SelectedProduct = product;
      this.FinishExistingProduct();
    }

  }
  public async FinishNewProduct() {
    this.SwitchMode(ProductSelectMode.Processing);
    this.ProcessingMessage = "שומר מוצר במערכת...";

    if (this.SelectedProduct.Stock && !Object.prototype.hasOwnProperty.call(this.SelectedProduct.Stock, "Amount")) delete this.SelectedProduct.Stock;

    var r = await this._productService.SaveProduct(this.SelectedProduct).toPromise();
    if (r.Code === 0) {
      this.SelectedProduct = r.Result;
      this.FinishExistingProduct();
    }

  }

  public FinishExistingProduct() {
    this.onResult.emit(this.SelectedProduct);
  }
}


enum ProductSelectMode {
  Search = 1,
  NewProduct = 2,
  Processing = 9999,
}
