<div class="container-fluid">
  <div class="row">
    <div class="col-6">
      <h1>הגדרות כלליות</h1>
    </div>
  </div>
  <div class="row" *ngIf="Setting">
    <div class="col-6 d-flex flex-row align-items-center">
      <h6 class="mr-2">שם העסק</h6>
      <labeled-textbox type="text" class="no-margin" [(model)]="Setting.Name"> </labeled-textbox>
    </div>

    <div class="col-6 d-flex flex-row-reverse align-items-center" *ngIf="CurrentUserSession">
      <span class="badge badge-info">{{CurrentUserSession.SupplierId ?? CurrentUserSession.SupplierID }}</span>      
      <h6 class="mt-1 mr-2">מזהה לקוח</h6>
      
    </div>

  </div>
  <div class="row mt-4">
    <div class="col-12">
      <h4>מודולים</h4>
    </div>
  </div>
  <div class="row ml-2">
    <div class="col-6 " *ngIf="ProductSetting">
      <h5 class="mb-0"> ניהול מוצרים </h5>
      <div class="mb-2">
        <div>פורמטים ליבוא מוצרים <button class="btn btn-outline-success btn-sm ml-4" (click)="open(editProductImportMapping,{ size: 'xl' },CreateNewImportProductFormat,SaveNewImportProductFormat)"> הוסף פורמט <i class="fas fa-plus-circle"></i></button> </div>

        <div class="list-group mt-2">
          <div class="list-group-item list-group-item py-0 d-flex justify-content-between" *ngFor="let format of ProductSetting.ImportProductFormats ; i as index">
            <button class="btn btn-link" (click)="selectedImportProductFormat = format; open(editProductImportMapping,{ size: 'xl' },null,SaveNewImportProductFormat)"> {{format.Name}}</button>
            <i class="fas fa-times-circle mt-2 pt-1 delete-item" (click)="DeleteImportProductFormatByIndex(i)"></i>
          </div>
        </div>
      </div>

      <div class="mb-2 mt-4">
        <h5>עדכון מוצרים אוטמטית</h5>

        <div>
          קובץ בפורמט
          <div class="d-inline-block" style="width: 12rem;">
            <select class="form-control flex-fill" [(ngModel)]="ProductSetting.PullSourceFileType">
              <option *ngFor="let option of FileTypes" [value]="option.id">{{option.name}}</option>
            </select>
          </div>
        </div>

        <div class="d-flex flex-row align-items-center mt-1">

          <div class="mx-2" style="width: 30em;height: 2em;">
            <labeled-textbox type="text" title="כתובת" [(model)]="ProductSetting.PullSource.Url" [bindFormControl]="ValidationFrom.controls.PullSourceUrl" [validationMessages]=" {pattern:'יש להכניס כתובת חוקית למשל : ftp://server/folder '}"> </labeled-textbox>
          </div>
          <div class="mx-1" style="width: 10em;height: 2em;">
            <labeled-textbox type="text" title="משתמש" [(model)]="ProductSetting.PullSource.User"> </labeled-textbox>
          </div>
          <div class="mx-1" style="width: 10em;height: 2em;">
            <labeled-textbox type="password" title="סיסמה" [(model)]="ProductSetting.PullSource.Password"> </labeled-textbox>
          </div>
        </div>

        <ng-container *ngIf="ProductSetting.PullSource.Url && ValidationFrom.controls.PullSourceUrl.valid">
          <div class="mt-2">גיבוי קבצים שנטענו </div>
          <div class="d-flex flex-row align-items-center mt-1">

            <div class="mx-2" style="width: 30em;height: 2em;">
              <labeled-textbox type="text" title="כתובת" [(model)]="ProductSetting.PullPostProcessCopy.Url" [bindFormControl]="ValidationFrom.controls.PullPostProcessCopyUrl" [validationMessages]=" {pattern:'יש להכניס כתובת חוקית למשל : ftp://server/folder '}"> </labeled-textbox>
            </div>
            <div class="mx-1" style="width: 10em;height: 2em;">
              <labeled-textbox type="text" title="משתמש" [(model)]="ProductSetting.PullPostProcessCopy.User"> </labeled-textbox>
            </div>
            <div class="mx-1" style="width: 10em;height: 2em;">
              <labeled-textbox type="password" title="סיסמה" [(model)]="ProductSetting.PullPostProcessCopy.Password"> </labeled-textbox>
            </div>
          </div>

        </ng-container>

      </div>

    </div>
  </div>

  <div class="row ml-2 mt-4" *ngIf="StockSetting">
    <div class="col-6 ">
      <h5 class="mb-0"> ניהול מלאי
        <checkbox class="mx-2" [(model)]="StockSetting.Enable"></checkbox>
      </h5>
      <ng-container *ngIf="StockSetting?.Enable">
        <div class="d-flex flex-row align-items-center mt-0">
          התראה כאשר המלאי למוצר יורד מתחת ל
          <div class="mx-2" style="width: 4em;">
            <labeled-textbox type="number" class="no-margin" [(model)]="StockSetting.MinimumStockLevel"> </labeled-textbox>
          </div>
        </div>


      </ng-container>

    </div>
  </div>

  <ng-container *ngIf="RetentionPolicyConfiguration">
    <div class="row mt-4">
      <div class="col-12">
        <h4>מדיניות שמירת נתונים</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-6 d-flex flex-row align-items-center">
        מחיקת פרטי לקוחות
        <checkbox class="mx-2" [(model)]="RetentionPolicyConfiguration.ShoppersPIIDataExpirationEnabled"></checkbox>
        לאחר
        <div class="mx-2" style="width: 4em;">
          <labeled-textbox type="number" class="no-margin" [(model)]="RetentionPolicyConfiguration.ShoppersPIIDataExpirationDays"> </labeled-textbox>
        </div>
        ימים
      </div>
      <div class="col-6">
        פרטי הלקוחות ימחקו (ימוסכו) מהמערכת לאחר מספר הימים שיקבע כאן. <br />
        גם לאחר מחיקת פרטי הלקוחות, פרטי ההזמנות ישארו במערכת לשמירה על הלימוד שלהן.
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-6">
        הפעל ארכיון הזמנות
        <checkbox class="mx-2" [(model)]="RetentionPolicyConfiguration.OrderAuditEnabled"></checkbox>
      </div>
      <div class="col-6">
        כאשר מופעל כול הזמנה שמיוצאת ל-ERP תדווח לארכיון ההזמנות <span class="font-weight-light">(לצורך דוחות BI)</span>

      </div>

    </div>
  </ng-container>

  <div class="row mt-4">
    <div class="col-12">
      <h4>אפשרויות תצוגה</h4>
    </div>
  </div>
  <div class="row" *ngIf="UIConfiguration">
    <div class="col-6 text-nowrap d-flex flex-row align-items-center">
      כפתור קיצור דרך מסך הזמנות
      <select class="form-control w-25 ml-2" [(ngModel)]="UIConfiguration.OrderPageQuickButton">
        <option [value]="'ExportERPFile'">ייצוא לקובץ ERP</option>
        <option [value]="'SetOrderStatusToExportable'">העבר ל ERP</option>
      </select>
    </div>
  </div>


  <div class="row my-2 ">
    <div class="col-12">
      <button type="button" tabindex="1000" class="btn btn-lg btn-outline-success" [disabled]="ValidationFrom && !ValidationFrom.valid" (click)="SaveAndReturn();">
        <h4 class="d-inline-block mr-2">שמור</h4>
        <i class="fa fa-save"></i>
      </button>
    </div>
  </div>

  <ng-container *ngIf="Users">
    <div class="row border-top border-dark mt-4 pt-4">
      <div class="col-12">
        <h4> ניהול משתמשים</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <table class="table table-bordered table-sm">
          <thead>
            <th>משתמש</th>
            <th>שם מלא</th>
            <th>פרופיל</th>
            <th>סטטוס </th>

          </thead>
          <tbody>
            <tr *ngFor="let user of Users">
              <td>
                <a [routerLink]="['/admin/supplier/user', user.Id]">{{user.UserName}}</a>
              </td>
              <td>{{user.UserIdentity.FullName}}</td>
              <td>
                <span *ngFor="let role of user.Roles">{{UserRoleTypes[role]}}</span>
              </td>
              <td>{{user.Disable ? "לא פעיל" : "פעיל"}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <a [routerLink]="['/admin/supplier/user']" class="btn btn-sm btn-outline-success">
          <h4 class="d-inline-block mr-2">הוסף</h4>
          <i class="fas fa-user-plus"></i>
        </a>
      </div>
    </div>

  </ng-container>

</div>


<ng-template #editProductImportMapping let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">עריכה של שדות</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body px-0">
    <import-product-format-editor [(ProductImportMappingDefinition)]="selectedImportProductFormat" (IsValidChange)="selectedImportProductFormatValid = $event"> </import-product-format-editor>

    <div class="modal-footer">
      <button type="button" class="btn btn-outline-success" (click)="c('save')" [disabled]="!selectedImportProductFormatValid">שמור</button>
      <button type="button" class="btn btn-outline-dark" (click)="c('cancel')">בטל</button>

    </div>
  </div>


</ng-template>