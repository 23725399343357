import { EnumDescriptionPipe } from "../pipes/enumdescription.pipe";


// If the Enum type is the same as the Enum Description you should add a space (BUG in the enum convertion)
export enum StoreType {
  Manual = 1,                //ManualDescription = <any>"ידני",
  Wallashops = 2,            //WallashopsDescription = <any>"וואלה שופס",
  Groupon = 3,               //GrouponDescription = <any>"גרופון",
  Konimbo = 4,               //KonimboDescription = <any>"קונימבו",
  Behazdaa = 5,              //BehazdaaDescription = <any>"בהצדעה",
  GetIt = 6,                 //GetItDescription = <any>" GetIt",
  Olsale = 7,                //OlsaleDescription = <any>"אולסייל",
  P1000 = 8,                 //P1000Description = <any>" P1000",
  Marmelada = 9,             //MarmeladaDescription = <any>"מרמלדה",
  BaBeTov = 10,              //BaBeTovDescription = <any>"בא בטוב",
  LastPrice = 11,            //LastPriceDescription = <any>"לאסטפרייס",
  Mamaguro = 12,             //MamaguroDescription = <any>"מאמאגורו",
  Azrieli = 13,              //AzrieliDescription = <any>"עזריאלי",
  BaLiGam = 14,              //BaLiGamDescription = <any>"בא לי גם‎",
  Shufersal = 15,            //ShufersalDescription = <any>"שופרסל‎",
  TV21 = 16,                 //TV21Description = <any>"ערוץ הקניות‎",
  BigDeal = 17,              //BigDealDescription = <any>"ביג דיל‎",
  HighTechZone = 18,         //HighTechZoneDescription = <any>"הייטקזון‎",
  DolceVita = 19,            //DolceVitaDescription = <any>"דולצ'ה ויטה‎",
  KSP = 20,                  //KSPDescription = <any>" KSP",
  Segment = 21,              //SegmentDescription = <any>"סגמנט",
  KUPI = 22,                 //KUPIDescription = <any>" KUPI",
  WooCommerce = 23,          //WooCommerceDescription = <any>"Woo Commerce",
  ACE = 24,                  //ACEDescription = <any>" ACE",
  ICE = 25,                  //ICEDescription = <any>"חברת החשמל",
  Simhim = 26,               //SimhimDescription = <any>"שמחי ובניו",
  UNIQ = 27,                 //UNIQDescription = <any>" UNIQ",
  Hot = 28,                  //HotDescription = <any>"הוט",
  DreamCard = 29,            //DreamCardDescription = <any>"Dream Card",
  SuperPharm = 30,           //SuperPharmDescription = <any>"סופר פארם",
  EShop = 31,                //EShopDescription = <any>" EShop",
  Wobily = 32,               //WobilyDescription = <any>" Wobily",
  DreamTime = 33,            //DreamTimeDescription = <any>" DreamTime",
  ClickAndBuy = 34,          //ClickAndBuyDescription = <any>" ClickAndBuy",
  ElectricWarehouse = 35,    //ElectricWarehouseDescription = <any>"מחסני חשמל",
  BetterShop = 36,           //BetterShopDescription = <any>" Better Shop",
  ConsumerClub1824 = 38,     //ConsumerClub1824Description = <any>"מועדון 1824",
  VaadCellcom = 39,          //VaadCellcomDescription = <any>"וועד סלקום",
  GaloGroup = 40,            //GaloGroupDescription = <any>"גלו גרופ",
  Dynamica = 41,             //DynamicaDescription = <any>"דינמיקה",
  Cuponofesh = 42,           //CuponofeshDescription = <any>"קופונופש",
  SaleMaster = 43,           //SaleMasterDescription = <any>"סטייל מאסטר",
  LowCostElectric = 44,      //LowCostElectricDescription = <any>"Low Cost Electric",
  ElectricShop = 45,         //ElectricShopDescription = <any>" ElectricShop",
  BabyCoupon = 46,           //BabyCouponDescription = <any>" בייבי קופון",
  Wix = 47,                  //WixDescription = <any>" Wix",
  Bezeq = 48,                //BezeqDescription = <any>" בזק",
  VisaCal = 49,              //VisaCalDescription = <any>" ויזה כאל",
  IGift = 50,                //IGiftDescription = <any>" איי גיפט",
  HighBiz = 51,              //HighBizDescription = <any>" היי ביז",
  Splendor = 52,             //SplendorDescription = <any>" Splendor",
  Fox = 53,                  //FoxDescription = <any>" פוקס",
  ShayGift = 54,             //ShayGiftDescription = <any>" ש.מתנות",
  HomeCenter = 55,           //HomeCenterDescription = <any>" ש.מתנות",
  NHS = 56,                  //NHSDescription = <any>"  נכס ניהול כספים סטודנטיאליים בע"\מ",
  Steimatzky = 57,           //SteimatzkyDescription = <any>"סטימצקי",
  Foody = 58,                //FoodyDescription = <any>"פודי",
  MultiShops = 59,           //MultiShopsDescription = <any>"Multi Shops",
  Zap = 60,                  //ZapDescription = <any>"זאפ",
  Switchy = 61,              //SwitchyDescription = <any>" Switchy",
  GeneralWorkersCommittee = 62,              //SwitchyDescription = <any>"ועדי עובדים כללי",
  FasterChef = 63,           //FasterChefDescription = <any>"FASTER CHEF",
  Kranot = 64,               //KranotDescription = <any>"קרנות",
  Ashmoret = 65,             //AshmoretDescription = <any>"אשמורת",
  MegaLean = 66,             //MegaLeanDescription = <any>"מגה לאן",
  Alam = 67,                 //AlamDescription = <any>"א.ל.מ.",
  Hever = 68,                //HeverDescription = <any>"חבר",
  BoomBuy = 69,              //BoomBuyDescription = <any>" BoomBuy",
  HighTechZoneWorkersCommittee = 70,              //HighTechZoneWorkersCommitteeDescription = <any>" הייטקזון‎ וועדים"
  Concept = 71,              //ConceptDescription = <any>"קונספטים"
  Xtra = 73,                 //XtraDescription = <any>"אקסטרא"  
  Mikesef = 74,              //MikesefDescription = <any>"מכסף"
  GMKeshet = 75,              //GMKeshetDescription = <any>"ג.מ. קשת"
  FlyStore = 76,              //FlyStoreDescription = <any>"FLYSTORE "
}


export interface IStoreDetails {
  Type: StoreType,
  IdName: string,
  Description: string,
  LogoURL?: string,
  UIPriority: number,
  FileImportColumns: { [key: string]: string[] },
  Enabled: boolean
}
