import { createAction, props } from "@ngrx/store";

export const StatisticsUpdateDraftOrderCountStart = createAction('[Statistics DraftOrderCount] Update Start');
export const StatisticsUpdateDraftOrderCountSuccess = createAction('[Statistics DraftOrderCount] Update Success', props<{ draftOrderCount : number }>());

export const StatisticsUpdateERPErrorOrderCountStart = createAction('[Statistics ERPErrorOrderCount] Update Start');
export const StatisticsUpdateERPErrorOrderCountSuccess = createAction('[Statistics ERPErrorOrderCount] Update Success', props<{ erpErrorOrderCount : number }>());

export const StatisticsUpdateInvalidShipmentOrderCountStart = createAction('[Statistics InvalidShipmentOrderCount] Update Start');
export const StatisticsUpdateInvalidShipmentOrderCountSuccess = createAction('[Statistics InvalidShipmentOrderCount] Update Success', props<{ invalidShipmentOrderCount : number }>());

export const StatisticsUpdateOutOfStockOrderCountStart = createAction('[Statistics outOfStockOrderCount] Update Start');
export const StatisticsUpdateOutOfStockOrderCountSuccess = createAction('[Statistics outOfStockOrderCount] Update Success', props<{ outOfStockOrderCount : number }>());







