<form *ngIf="MappingForm" [formGroup]="MappingForm" class="container-fluid mt-4">

    <div class="row">
        <div class="col-6">
            <labeled-textbox type="text" title="שם פורמט" [bindFormControl]="MappingForm.controls.Name" [showValidIndicator]="MappingForm.controls.Name.touched" [validationMessages]="{'required': 'שדה חובה'}"></labeled-textbox>
        </div>

        <div class="col-6">
            <select class="form-control" formControlName="TableReadOption" [ngClass]="{'is-invalid' : MappingForm.controls.TableReadOption?.invalid ,'is-valid' : MappingForm.controls.TableReadOption?.valid }">
                <option value="1">{{1 | enumDescription : "TableReadOptions"}}</option>
                <option value="2">{{2 | enumDescription : "TableReadOptions"}}</option>
                <option value="4">{{4 | enumDescription : "TableReadOptions"}}</option>
            </select>

        </div>

    </div>

    <button class="btn btn-sm btn-outline-success" (click)="AddNewMapping()" >
        הוסף מיפוי
    </button>
    <div class="row">
        <div class="col">
            <ng-container *ngIf="MappingForm.controls.ProductImportFieldMapping" formArrayName="ProductImportFieldMapping">
                <table class="table table-hover table-sm">
                    <thead>
                        <tr>
                            <th scope="col">עמודה בקובץ</th>
                            <th scope="col">שדה במוצר</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let mappingFieldForm of MappingForm.controls.ProductImportFieldMapping.controls; let i = index">
                            <tr [formGroup]="mappingFieldForm">
                                <td>
                                    <labeled-textbox type="text" classes="mb-0" [bindFormControl]="mappingFieldForm.controls.SourceColumn" [showValidIndicator]="mappingFieldForm.controls?.SourceColumn?.touched" [validationMessages]="{'required': 'שדה חובה'}"></labeled-textbox>
                                </td>

                                <td>
                                    <select *ngIf="productFields" class="form-control" formControlName="DestinationProductField" [ngClass]="{'is-invalid' : mappingFieldForm.controls.DestinationProductField?.invalid ,'is-valid' : mappingFieldForm.controls.DestinationProductField?.valid }">
                                        <option  [value]="null">בחר שדה מוצר</option>
                                        <option *ngFor="let field of productFields" [value]="field.path">{{field.name}}</option>
                                    </select>                                    
                                </td>
                                <td>
                                    
                                    <button class="btn btn-danger" (click)="DeleteMapping(i)" ><span class="mr-2">מחק</span> <i class="far fa-trash-alt"></i></button>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </ng-container>

            
        </div>
    </div>
</form>