import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { Session } from 'protractor';
import { filter } from 'rxjs/operators';
import { UserSessionAppInit } from 'src/app/state/session.actions';
import { SessionStateFeature } from 'src/app/state/session.reducer';
declare var gtag;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit{
    private pagerUrlIdRegex = /\/[0-9a-f]{24}$/gm;
    constructor(public router: Router , private store: Store) {

        const navEndEvent$ = router.events.pipe(
            filter(e => e instanceof NavigationEnd)
        );
        navEndEvent$.subscribe((e: NavigationEnd) => {
            var url = e.urlAfterRedirects;
            var query = "";
            if (url.indexOf("?") > 0) {
                var query = url.substring(url.indexOf("?") + 1);;
                url = url.substring(0, url.indexOf("?"));
            }
            url = url.replace(this.pagerUrlIdRegex,"");

            gtag('config', 'UA-168032716-1', { 'page_path': url });
        });


    }
    ngOnInit(): void {
       this.store.dispatch(UserSessionAppInit());
    }

}
