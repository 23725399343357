<div class="d-flex justify-content-center" *ngIf="!Product">
    <i class="fa fa-spinner fa-pulse fa-5x fa-fw  mt-5 text-secondary"></i>
</div>

<div class="container-fluid mt-2 px-0" *ngIf="Product">

    <div class="row no-gutters">
        <div class="col-3">

            <h3>
                <ng-container *ngIf="!IsMasterProduct">
                    <i class="fas fa-project-diagram" title="מוצר מדומה"></i>
                </ng-container>
                <ng-container *ngIf="IsMasterProduct">
                    <i class="fas fa-cube" title="מוצר מסטר"></i>
                </ng-container>
                <label class="ml-2">פרטי מוצר</label>
            </h3>
        </div>
        <div class="col-2">

        </div>
        <div class="col-4"></div>
        <div class="col-3">
            <div class="float-left">
                <button class="btn btn-danger btn-sm mr-2 " (click)="open(contentDeleteProduct,null,null,null)">
                    מחק מוצר
                    <i class="far fa-trash-alt mx-2"></i>
                </button>

                <button *ngIf="!EditMode;else editButton" class="btn btn-primary btn-sm mr-2" (click)="SwitchToEditMode()">
                    עריכה
                    <i class="fas fa-pencil-alt mx-2"></i>
                </button>
                <button type="button" class="btn btn-warning btn-sm" (click)="Return();">
                    חזור
                    <i class="fas fa-arrow-left"></i>
                </button>
                <ng-template #editButton>
                    <button class="btn btn-primary btn-sm" (click)="SaveProduct(); EditMode=false;">
                        שמור
                        <i class="fas fa-save mx-2"></i>
                    </button>
                </ng-template>

            </div>

        </div>
    </div>
    <div class="row no-gutters mb-0">
        <div class="col-1">
            <h4 class="mb-0">ERP</h4>
        </div>
        <div class="col-1 " [ngClass]="{'pt-2' : EditMode}" title="עמודת SKU בקובץ הטעינה הסטנדרטי">
            מק"ט\פריט :
        </div>
        <div class="col-3">
            <ng-container *ngIf="!EditMode">
                {{Product.SKU}}
            </ng-container>
            <ng-container *ngIf="EditMode">
                <labeled-textbox type="text" class="ProductField" [(model)]="Product.SKU"></labeled-textbox>
            </ng-container>
        </div>
        <div class="col-1 " [ngClass]="{'pt-2' : EditMode}" title="עמודת Name בקובץ הטעינה הסטנדרטי">
            תיאור:
        </div>
        <div class="col-6">
            <ng-container *ngIf="!EditMode">
                {{Product.Name}}
            </ng-container>
            <ng-container *ngIf="EditMode">
                <labeled-textbox type="text" class="ProductField" [(model)]="Product.Name"></labeled-textbox>
            </ng-container>
        </div>
    </div>
    <div class="row no-gutters mt-0">

        <div class="col-1 offset-1" [ngClass]="{'pt-2' : EditMode}" title="עמודת UPC בקובץ הטעינה הסטנדרטי">
            UPC\ברקוד :
        </div>
        <div class="col-3">
            <ng-container *ngIf="!EditMode">
                {{Product.UPC}}
            </ng-container>
            <ng-container *ngIf="EditMode">
                <labeled-textbox type="text" class="ProductField" [(model)]="Product.UPC"></labeled-textbox>
            </ng-container>
        </div>
        <div class="col-1 " [ngClass]="{'pt-2' : EditMode}" title="עמודת Model בקובץ הטעינה הסטנדרטי">
            דגם:
        </div>
        <div class="col-3">
            <ng-container *ngIf="!EditMode">
                {{Product.Model}}
            </ng-container>
            <ng-container *ngIf="EditMode">
                <labeled-textbox type="text" class="ProductField" [(model)]="Product.Model"></labeled-textbox>
            </ng-container>
        </div>
    </div>
    <div class="row no-gutters mt-0">
        <div class="col-1 offset-1" [ngClass]="{'pt-2' : EditMode}" title="עמודת DefaultShipmentMethod בקובץ הטעינה הסטנדרטי">
            שיטת משלוח:
        </div>
        <div class="col-3">
            <shipment-type-selector [(model)]="Product.DefaultShipmentMethod" class="ProductField" [Mode]="EditMode ? 2 : 1" [ShowSelfDistributers]="false" [AllowUndefinedShipment]="true"></shipment-type-selector>
        </div>

        <div class="col-1 " [ngClass]="{'pt-2' : EditMode}">
            מותג:
        </div>
        <div class="col-3">
            <ng-container *ngIf="!EditMode">
                {{Product.Brand}}
            </ng-container>
            <ng-container *ngIf="EditMode">
                <labeled-textbox type="text" class="ProductField" [(model)]="Product.Brand"></labeled-textbox>
            </ng-container>
        </div>

    </div>

    <div class="row no-gutters mt-0">
        <div class="col-1 offset-1" [ngClass]="{'pt-2' : EditMode}" title="">

        </div>
        <div class="col-3">

        </div>

    </div>


    <div class="row no-gutters mt-0">
        <ng-container *ngIf="Product.DefaultShipmentPackageSize || EditMode ;else ShipmentPackageSizeElseTmp">
            <div class="col-1 offset-1" [ngClass]="{'pt-2' : EditMode}" title="">
                סוג חבילה:
            </div>
            <div class="col-3">
                <ng-container *ngIf="!EditMode">
                    {{ Product.DefaultShipmentPackageSize | enumDescription : "DeliveryPackageSize"}}
                </ng-container>
                <ng-container *ngIf="EditMode">
                    <select class="form-control ProductField mb-4" [(ngModel)]="Product.DefaultShipmentPackageSize">
                        <ng-container *ngFor="let size of AvailableDeliveryPackageSizes">
                            <option [value]="size.id">{{size.name}}</option>
                        </ng-container>
                    </select>
                </ng-container>
            </div>
        </ng-container>
        <ng-template #ShipmentPackageSizeElseTmp>
            <div class="col-4 offset-1" [ngClass]="{'pt-2' : EditMode}" title="">
            </div>
        </ng-template>

        <ng-container *ngIf="Product.ChitaCargoType || EditMode ;else ChitaCargoTypeElseTmp">
            <div class="col-1" [ngClass]="{'pt-2' : EditMode}" title="">
                סוג מטען (ציטה):
            </div>
            <div class="col-3">
                <ng-container *ngIf="!EditMode">
                    {{ ChitaCargoTypes[Product.ChitaCargoType]}}
                </ng-container>
                <ng-container *ngIf="EditMode">
                    <select class="form-control ProductField mb-4" [(ngModel)]="Product.ChitaCargoType">
                        <ng-container *ngFor="let map of ChitaCargoTypes | keyvalue">
                            <option *ngIf="map.key != '110'" [value]="map.key">{{map.value}}</option>
                        </ng-container>

                    </select>
                </ng-container>
            </div>
        </ng-container>
        <ng-template #ChitaCargoTypeElseTmp>
            <div class="col-4 offset-1" [ngClass]="{'pt-2' : EditMode}" title="">
            </div>
        </ng-template>
    </div>

    <div class="row no-gutters mt-0" *ngIf="IsMasterProduct"> 
        <ng-container *ngIf="Product.OrderSerialNumberControl || EditMode ;else OrderSerialNumberControlElseTmp">
            <div class="col-1 offset-1" [ngClass]="{'pt-2' : EditMode}" title="">
                ניהול מספר טבוע:
            </div>
            <div class="col-3">
                <ng-container *ngIf="!EditMode">
                    {{ Product.OrderSerialNumberControl | enumDescription : "ProductItemOrderSerialNumberControlType"}}
                </ng-container>
                <ng-container *ngIf="EditMode">
                    <select class="form-control ProductField mb-4" [(ngModel)]="Product.OrderSerialNumberControl">
                        <ng-container *ngFor="let value of ProductItemOrderSerialNumberControlTypes ">
                            <option  [value]="value">{{value | enumDescription : "ProductItemOrderSerialNumberControlType"}}</option>
                        </ng-container>
                    </select>
                </ng-container>
            </div>
        </ng-container>
        <ng-template #OrderSerialNumberControlElseTmp>
            <div class="col-4 offset-1" [ngClass]="{'pt-2' : EditMode}" title="">
            </div>
        </ng-template>

        <ng-container *ngIf="IsStockEnable">
            <div class="col-1 " [ngClass]="{'pt-2' : EditMode}" title="עמודת StockAmount בקובץ הטעינה הסטנדרטי">
                מלאי:
            </div>
            <div class="col-3" style="direction: ltr;            text-align: right;">
                <ng-container *ngIf="!EditMode">
                    {{Product?.Stock?.Amount}}
                </ng-container>
                <ng-container *ngIf="EditMode">
                    <labeled-textbox type="number" class="ProductField" [(model)]="Product.Stock.Amount"></labeled-textbox>
                </ng-container>
            </div>
        </ng-container>

    </div>
    <div class="row no-gutters mt-0" *ngIf="IsMasterProduct">

        <ng-container *ngFor="let productUserField of ProductActiveUserFields; index as i">
            <ng-container *ngIf="i % 2 == 0">

                <div class="col-1 offset-1" [ngClass]="{'pt-2' : EditMode}" [title]="'עמודת '+ productUserField +' בקובץ הטעינה הסטנדרטי'">
                    {{productUserField}}
                </div>
                <div class="col-3">
                    <ng-container *ngIf="!EditMode">
                        {{Product[productUserField]}}
                    </ng-container>
                    <ng-container *ngIf="EditMode">
                        <labeled-textbox type="text" class="ProductField" [(model)]="Product[productUserField]"></labeled-textbox>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="i % 2 == 1">
                <div class="col-1" [ngClass]="{'pt-2' : EditMode}">
                    {{productUserField}}
                </div>
                <div class="col-3">
                    <ng-container *ngIf="!EditMode">
                        {{Product[productUserField]}}
                    </ng-container>
                    <ng-container *ngIf="EditMode">
                        <labeled-textbox type="text" class="ProductField" [(model)]="Product[productUserField]"></labeled-textbox>
                    </ng-container>
                </div>
                <div class="col-3"> </div>
            </ng-container>
        </ng-container>
    </div>




    <!-------------------------------------------------------->









    <div class="row no-gutters border-top border-dark pt-2 mt-4 mb-2" *ngIf="IsMasterProduct">
        <div class="col-2">
            <select *ngIf="ActiveStores" [autofocus] class="form-control font-weight-bold" [ngModel]="SelectedStoreView" (ngModelChange)="ChangeStoreView($event);" tabindex="0">
                <option [ngClass]="{'h5 font-weight-bold' : !SelectedStoreView  , 'h5 font-italic text-secondary' : SelectedStoreView}" [value]="null">ברירת מחדל</option>
                <option [ngClass]="{'h5' : SelectedStoreView == store.Type , 'h6 text-secondary' : SelectedStoreView !=  store.Type}" [value]="store.Type" *ngFor="let store of ActiveStores">{{store.Description}}</option>
            </select>
        </div>
    </div>


    <div class="row no-gutters">
        <div class="col-4">
            <div class="container-fluid pl-1">
                <div class="row no-gutters">
                    <div class="col-3" [ngClass]="{'pt-2' : EditMode}" title="עמודת Name בקובץ הטעינה הסטנדרטי">
                        שם:
                        <i class="fas fa-magic ml-1 but-multi-value" *ngIf="HasProductCustomStoreValue('Name')" title="לשדה זה יש ערכים שונים בחניות אחרות. לחץ כאן לראות את הערכים" [ngbPopover]="popContentMultiValues" [popoverTitle]="popTitleMultiValues" [autoClose]="'outside'" popoverClass="popoverW-fit-content" (shown)="popContentMultiValuesContext = {name : 'שם' , field : 'Name'}"></i>
                    </div>
                    <div class="col-9 align-self-center">
                        <ng-container *ngIf="!EditMode">
                            {{GetProductValue('Name')}}
                        </ng-container>
                        <ng-container *ngIf="EditMode">
                            <labeled-textbox type="text" class="ProductField" updateOn="blur" [model]="GetProductValue('Name')" (modelChange)="SetProductValue('Name',$event);"></labeled-textbox>
                        </ng-container>

                    </div>
                </div>

                <div class="row no-gutters">
                    <div class="col-3" [ngClass]="{'pt-2' : EditMode}" title="עמודת SKU בקובץ הטעינה הסטנדרטי">
                        מק"ט בערוץ:
                        <i class="fas fa-magic ml-1 but-multi-value" *ngIf="HasProductCustomStoreValue('SKU')" title="לשדה זה יש ערכים שונים בחניות אחרות. לחץ כאן לראות את הערכים" [ngbPopover]="popContentMultiValues" [popoverTitle]="popTitleMultiValues" [autoClose]="'outside'" popoverClass="popoverW-fit-content" (shown)="popContentMultiValuesContext = {name : 'מק&quot;ט' , field : 'SKU'}"></i>
                    </div>
                    <div class="col-9 align-self-center">
                        <ng-container *ngIf="!EditMode">
                            {{GetProductValue('SKU')}}
                        </ng-container>
                        <ng-container *ngIf="EditMode">
                            <labeled-textbox type="text" class="ProductField" updateOn="blur" [model]="GetProductValue('SKU')" (modelChange)="SetProductValue('SKU',$event);"></labeled-textbox>
                        </ng-container>
                    </div>
                </div>


                <div class="row no-gutters">
                    <div class="col-3" [ngClass]="{'pt-2' : EditMode}" title="עמודת Model בקובץ הטעינה הסטנדרטי">
                        דגם בערוץ:
                        <i class="fas fa-magic ml-1 but-multi-value" *ngIf="HasProductCustomStoreValue('Model')" title="לשדה זה יש ערכים שונים בחניות אחרות. לחץ כאן לראות את הערכים" [ngbPopover]="popContentMultiValues" [popoverTitle]="popTitleMultiValues" [autoClose]="'outside'" popoverClass="popoverW-fit-content" (shown)="popContentMultiValuesContext = {name : 'מודל' , field : 'Model'}"></i>
                    </div>
                    <div class="col-9 align-self-center">
                        <ng-container *ngIf="!EditMode">
                            {{GetProductValue('Model')}}
                        </ng-container>
                        <ng-container *ngIf="EditMode">
                            <labeled-textbox type="text" class="ProductField" updateOn="blur" [model]="GetProductValue('Model')" (modelChange)="SetProductValue('Model',$event);"></labeled-textbox>
                        </ng-container>
                    </div>
                </div>

                <div class="row no-gutters">
                    <div class="col-3" [ngClass]="{'pt-2' : EditMode}" title="כול העמודות ששמם מתחיל ב Attributes בקובץ הטעינה הסטנדרטי">
                        מאפיינים:
                        <i class="fas fa-magic ml-1 but-multi-value" *ngIf="HasProductCustomStoreValue('Attributes')" title="לשדה זה יש ערכים שונים בחניות אחרות. לחץ כאן לראות את הערכים" [ngbPopover]="popContentMultiValues" [popoverTitle]="popTitleMultiValues" [autoClose]="'outside'" popoverClass="popoverW-fit-content" (shown)="popContentMultiValuesContext = {name : 'מאפיינים' , field : 'Attributes'}"></i>
                    </div>
                    <div class="col-9 align-self-center">
                        <ng-container *ngIf="!EditMode">
                            {{GetProductValue('Attributes')?.join(', ')}}
                        </ng-container>
                        <div *ngIf="EditMode" class="mb-2">
                            <product-attribute-selector [model]="GetProductValue('Attributes')" (modelChange)="SetProductValue('Attributes',$event);" class="tag-input-padding-0 ProductField"></product-attribute-selector>
                        </div>
                    </div>
                </div>



                <!--
                <div class="row no-gutters">
                    <div class="col-3" [ngClass]="{'pt-2' : EditMode}">
                        UPC:
                        <i class="fas fa-magic ml-1 but-multi-value"  *ngIf="HasProductCustomStoreValue('UPC')" title="לשדה זה יש ערכים שונים בחניות אחרות. לחץ כאן לראות את הערכים" 
                        [ngbPopover]="popContentMultiValues" [popoverTitle]="popTitleMultiValues" [autoClose]="'outside'"  popoverClass="popoverW-fit-content"
                        (shown)="popContentMultiValuesContext = {name : 'UPC' , field : 'UPC'}"></i>
                    </div>
                    <div class="col-9 align-self-center">
                        <ng-container *ngIf="!EditMode">
                            {{GetProductValue('UPC')}}

                        </ng-container>
                        <ng-container *ngIf="EditMode">
                            <labeled-textbox type="text" updateOn="blur" class="ProductField" [model]="GetProductValue('UPC')" (modelChange)="SetProductValue('UPC',$event);"></labeled-textbox>
                        </ng-container>

                    </div>
                </div>
-->

            </div>
        </div>

        <div class="col-8 border-3 border-right">
            <div class="container-fluid pl-1">
                <div class="row no-gutters ">
                    <div class="col-2 ">
                        מחירון 
                        <span class="font-weight-light badge badge-info">ללא מע"מ</span>
                        <i class="fas fa-magic ml-1 but-multi-value" *ngIf="HasProductCustomStoreValue('PriceList')" title="לשדה זה יש ערכים שונים בחניות אחרות. לחץ כאן לראות את הערכים" [ngbPopover]="popContentMultiValues" [popoverTitle]="popTitleMultiValues" [autoClose]="'outside'" popoverClass="popoverW-fit-content" (shown)="popContentMultiValuesContext = {name : 'מחירון' , field : 'PriceList'}"></i>
                        <br />
                        <span class="text-secondary  text-truncate ">{{HtmlHelper.GetSelectedStoreViewName()}}</span>

                    </div>
                    <div class="col-10 px-0 align-self-center">
                        <ng-container *ngIf="!EditMode">
                            {{GetProductValue('PriceList') | number:'1.2-2'}}
                        </ng-container>
                        <ng-container *ngIf="EditMode">
                            <labeled-textbox type="number" min="0" updateOn="blur" class="ProductField" [model]="GetProductValue('PriceList')" (modelChange)="SetProductValue('PriceList',$event);"></labeled-textbox>
                        </ng-container>

                    </div>
                </div>

                <div class="row no-gutters">
                    <div class="col-2" [ngClass]="{'pt-2' : EditMode}" title="עמודת Category בקובץ הטעינה הסטנדרטי">
                        קטגוריה
                        <i class="fas fa-magic ml-1 but-multi-value" *ngIf="HasProductCustomStoreValue('Category')" title="לשדה זה יש ערכים שונים בחניות אחרות. לחץ כאן לראות את הערכים" [ngbPopover]="popContentMultiValues" [popoverTitle]="popTitleMultiValues" [autoClose]="'outside'" popoverClass="popoverW-fit-content" (shown)="popContentMultiValuesContext = {name : 'קטגוריה' , field : 'Category'}"></i>
                    </div>
                    <div class="col-10 px-0 ">
                        <ng-container *ngIf="!EditMode">
                            {{GetProductValue('Category')}}
                        </ng-container>

                        <div *ngIf="EditMode" class="d-flex flex-row">
                            <labeled-textbox type="text" updateOn="blur" class="ProductField" [model]="GetProductValue('Category')" (modelChange)="SetProductValue('Category',$event);"></labeled-textbox>
                        </div>
                    </div>
                </div>

                <div class="row no-gutters">
                    <div class="col-2" [ngClass]="{'pt-2' : EditMode}" title="כול העמודות ששמם מתחיל ב Media בקובץ הטעינה הסטנדרטי">
                        תמונות
                        <i class="fas fa-magic ml-1 but-multi-value" *ngIf="HasProductCustomStoreValue('Media')" title="לשדה זה יש ערכים שונים בחניות אחרות. לחץ כאן לראות את הערכים" [ngbPopover]="popContentMultiValues" [popoverTitle]="popTitleMultiValues" [autoClose]="'outside'" popoverClass="popoverW-fit-content" (shown)="popContentMultiValuesContext = {name : 'תמונות' , field : 'Media'}"></i>
                    </div>
                    <div class="col-10 px-0 align-self-center">
                        <ng-container *ngIf="!EditMode">
                            <div *ngFor="let media of GetProductValue('Media') ;let i = index" class="text-truncate">
                                <span class="badge badge-pill badge-info mr-2">{{i +1 }}</span><a [href]="media">{{media}}</a>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="EditMode">
                            <div *ngFor="let media of GetProductValue('Media'); let i = index" class="d-flex flex-row ProductField">

                                <div class="badge badge-pill badge-info mr-2 h-100 mt-2">{{i +1 }}</div>


                                <labeled-textbox type="text" class="flex-grow-1" updateOn="blur" [model]="media" (modelChange)="SetProductValueArray('Media',i,$event);"></labeled-textbox>
                                <div class="">
                                    <button type="button" class="btn btn-outline-danger" (click)="SetProductValueArray('Media',i,null);">
                                        <span class=""></span> <i class="far fa-trash-alt"></i>
                                    </button>
                                </div>
                            </div>
                            <button class='btn btn-outline-success' (click)="SetProductValueArray('Media',GetProductValue('Media').length,'');">
                                הוסף תמונה <i class="fas fa-plus"></i>
                            </button>


                        </ng-container>

                    </div>
                </div>


                <div class="row no-gutters">
                    <div class="col-2" [ngClass]="{'pt-2' : EditMode}" title="עמודת Description בקובץ הטעינה הסטנדרטי">
                        תיאור
                        <i class="fas fa-magic ml-1 but-multi-value" *ngIf="HasProductCustomStoreValue('Description')" title="לשדה זה יש ערכים שונים בחניות אחרות. לחץ כאן לראות את הערכים" [ngbPopover]="popContentMultiValues" [popoverTitle]="popTitleMultiValues" [autoClose]="'outside'" popoverClass="popoverW-fit-content" (shown)="popContentMultiValuesContext = {name : 'תיאור' , field : 'Description'}"></i>
                    </div>
                    <div class="col-10 px-0 ">
                        <pre *ngIf="!EditMode">{{ GetProductValue('Description')}}</pre>
                        <ng-container *ngIf="EditMode">
                            <textarea rows="5" class="w-100 form-control" [ngModel]="GetProductValue('Description')" (ngModelChange)="SetProductValue('Description',$event);" [ngModelOptions]="{updateOn: 'blur'}"></textarea>
                        </ng-container>
                    </div>
                </div>

                <div class="row no-gutters">
                    <div class="col-2" [ngClass]="{'pt-2' : EditMode}" title="עמודת Warranty בקובץ הטעינה הסטנדרטי">
                        אחריות
                        <i class="fas fa-magic ml-1 but-multi-value" *ngIf="HasProductCustomStoreValue('Warranty')" title="לשדה זה יש ערכים שונים בחניות אחרות. לחץ כאן לראות את הערכים" [ngbPopover]="popContentMultiValues" [popoverTitle]="popTitleMultiValues" [autoClose]="'outside'" popoverClass="popoverW-fit-content" (shown)="popContentMultiValuesContext = {name : 'אחריות' , field : 'Warranty'}"></i>
                    </div>
                    <div class="col-10 px-0 ">
                        <pre *ngIf="!EditMode">{{ GetProductValue('Warranty')}}</pre>
                        <ng-container *ngIf="EditMode">
                            <textarea rows="2" class="mt-1 w-100 form-control" [ngModel]="GetProductValue('Warranty')" (ngModelChange)="SetProductValue('Warranty',$event);" [ngModelOptions]="{updateOn: 'blur'}"></textarea>
                        </ng-container>
                    </div>
                </div>

            </div>
        </div>
    </div>




    <ng-container *ngFor="let productCatalog of ActiveProductCatalogs">

        <div class="row border-top">
            <div class="col-12">
                <h5>קטלוג :<span data-toggle="tooltip" data-placement="top" [ngbTooltip]="productCatalog.Code"> {{productCatalog.Name}}</span></h5>
            </div>
        </div>
        <div class="row">
            <ng-container *ngIf="!EditMode">
                <ng-container *ngFor="let productCatalogField of productCatalog.Fields; index as productCatalogFieldindex">
                    <div class="col-2"> <span [ngbTooltip]="productCatalogField.Code">{{productCatalogField.Name}}</span>:</div>
                    <div class="col-4" [ngClass]="{'border-left' : productCatalogFieldindex % 2 === 0}">
                        <pre>{{GetProductValue(productCatalog.Code + '.' + productCatalogField.Code )}}</pre>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="EditMode">
                <ng-container *ngFor="let productCatalogField of productCatalog.Fields; index as productCatalogFieldindex">
                    <div class="col-6" [ngClass]="{'border-left' : productCatalogFieldindex % 2 === 0}">
                        <labeled-textbox [readonly]="productCatalogField.ItemType=='ProductCatalogReferenceField'" updateOn="blur" class="ProductField" [title]="productCatalogField.Name + '   ('+productCatalogField.Code + ')'" [model]="GetProductValue(productCatalog.Code + '.' + productCatalogField.Code )" (modelChange)="SetProductValue(productCatalog.Code + '.' + productCatalogField.Code,$event);"></labeled-textbox>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>


    <!------------------------------------------------------->
    <div class="row border-top border-3 border-dark">
        <div class="col-12 mt-1">
            <diV class="d-flex justify-content-between">
                <div class="">
                    <h3 class="d-inline-block" *ngIf="IsMasterProduct">מק"ט ערוץ המכירה</h3>
                    <h3 class="d-inline-block" *ngIf="!IsMasterProduct">קישורי למידה</h3>
                    <button *ngIf="(MasterAliasProductRelationships && SelectedMasterAliasProductRelationships )" type="button" class="btn btn-link " (click)="SelectedMasterAliasProductRelationships.ToggleSelectAll()">{{SelectedMasterAliasProductRelationships.SelectAllItems ? "הסר" : "סמן"}} הכול</button>
                </div>
                <div>
                    <pager *ngIf="(MasterAliasProductRelationships && SelectedMasterAliasProductRelationships && MasterAliasProductRelationships.TotalItemsCount > 15)" [PageSize]="15" [PageIndex]="MasterAliasProductRelationships.PageIndex" (PageIndexChange)="LoadMasterAliasProductRelationship($event);" [TotalItemCount]="MasterAliasProductRelationships.TotalItemsCount"></pager>
                </div>
                <div>
                    <button type="button" *ngIf="(MasterAliasProductRelationships && SelectedMasterAliasProductRelationships)" [disabled]="SelectedMasterAliasProductRelationships.GetSelectedItemCount() == 0" class="btn btn-outline-danger btn-sm mt-2 " (click)="open(contentDeleteMasterAliasRel,null,null,null)">
                        <span>
                            <ng-container *ngIf="SelectedMasterAliasProductRelationships && SelectedMasterAliasProductRelationships.GetSelectedItemCount() <= 1;else contentDeleteProductGetSelectedProductsCountelse">
                                מחק קישור מסומן
                            </ng-container>
                            <ng-template #contentDeleteProductGetSelectedProductsCountelse>
                                מחק <span class="badge badge-pill badge-light">{{SelectedMasterAliasProductRelationships?.GetSelectedItemCount()}}</span> קישורים מסומנים
                            </ng-template>
                        </span>

                        <i class="far fa-trash-alt ml-1"></i>
                    </button>

                </div>
            </diV>



            <div class="d-flex justify-content-center" *ngIf="!(MasterAliasProductRelationships && SelectedMasterAliasProductRelationships)">
                <i class="fa fa-spinner fa-pulse fa-5x fa-fw row w-100 mt-5 text-secondary"></i>
            </div>
            <div *ngIf="(MasterAliasProductRelationships && SelectedMasterAliasProductRelationships)">
                <table class="table table-hover table-sm">
                    <thead>
                        <th scope="col" style="width: 5%">סמן</th>
                        <th scope="col" style="width: 15%" class="product-list-header" (click)="ToggleMasterAliasProductRelationshipsOrderBy('OriginStore')"> ערוץ מכירה <i class="fas" [ngClass]="{'fa-angle-up': MasterAliasProductRelationshipsOrderBy ==  'OriginStore', 'fa-angle-down': MasterAliasProductRelationshipsOrderBy ==  '-OriginStore'}"></i></th>

                        <ng-container *ngIf="IsMasterProduct">
                            <th scope="col" style="width: 20%" class="product-list-header" (click)="ToggleMasterAliasProductRelationshipsOrderBy('AliasProducts.Product.SKU')">מק"ט בערוץ מכירה <i class="fas" [ngClass]="{'fa-angle-up': MasterAliasProductRelationshipsOrderBy ==  'AliasProducts.Product.SKU', 'fa-angle-down': MasterAliasProductRelationshipsOrderBy ==  '-AliasProducts.Product.SKU'}"></i></th>
                            <th scope="col" style="width: 45%" class="product-list-header" (click)="ToggleMasterAliasProductRelationshipsOrderBy('AliasProducts.Product.Name')">שם בערוץ מכירה <i class="fas" [ngClass]="{'fa-angle-up': MasterAliasProductRelationshipsOrderBy ==  'AliasProducts.Product.Name', 'fa-angle-down': MasterAliasProductRelationshipsOrderBy ==  '-AliasProducts.Product.Name'}"></i></th>
                            <th scope="col" style="width: 5%" class="product-list-header"> כמות </th>
                            <th scope="col" style="width: 10%" class="product-list-header" (click)="ToggleMasterAliasProductRelationshipsOrderBy('AliasProducts.Product.Model')">דגם בערוץ מכירה <i class="fas" [ngClass]="{'fa-angle-up': MasterAliasProductRelationshipsOrderBy ==  'AliasProducts.Product.Model', 'fa-angle-down': MasterAliasProductRelationshipsOrderBy ==  '-AliasProducts.Product.Model'}"></i></th>
                        </ng-container>

                        <ng-container *ngIf="!IsMasterProduct">
                            <th scope="col" style="width: 20%" class="product-list-header" (click)="ToggleMasterAliasProductRelationshipsOrderBy('MasterMatches.Product.SKU')">מק"ט <i class="fas" [ngClass]="{'fa-angle-up':  MasterAliasProductRelationshipsOrderBy ==  'MasterMatches.Product.SKU', 'fa-angle-down':   MasterAliasProductRelationshipsOrderBy ==  '-MasterMatches.Product.SKU'}"></i></th>
                            <th scope="col" style="width: 45%" class="product-list-header" (click)="ToggleMasterAliasProductRelationshipsOrderBy('MasterMatches.Product.Name')">שם <i class="fas" [ngClass]="{'fa-angle-up':   MasterAliasProductRelationshipsOrderBy ==  'MasterMatches.Product.Name', 'fa-angle-down':  MasterAliasProductRelationshipsOrderBy ==  '-MasterMatches.Product.Name'}"></i></th>
                            <th scope="col" style="width: 5%" class="product-list-header"> כמות </th>
                            <th scope="col" style="width: 10%" class="product-list-header" (click)="ToggleMasterAliasProductRelationshipsOrderBy('MasterMatches.Product.Model')">דגם <i class="fas" [ngClass]="{'fa-angle-up': MasterAliasProductRelationshipsOrderBy ==  'MasterMatches.Product.Model', 'fa-angle-down': MasterAliasProductRelationshipsOrderBy ==  '-MasterMatches.Product.Model'}"></i></th>
                        </ng-container>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let mar of MasterAliasProductRelationships.Items; index as im">

                            <tr *ngFor="let productRel of HtmlHelper.GetRelevantProducts(mar); index as ia">
                                <td *ngIf="!ia" [attr.rowspan]="HtmlHelper.GetRelevantProducts(mar).length" class="align-middle">
                                    <checkbox [model]="SelectedMasterAliasProductRelationships.IsItemSelected(mar.Id)" (modelChange)="SelectedMasterAliasProductRelationships.SelectItem(mar.Id,$event)"></checkbox>
                                </td>
                                <td *ngIf="!ia" [attr.rowspan]="HtmlHelper.GetRelevantProducts(mar).length" class="align-middle">
                                    {{mar.OriginStore | storeDescriptionAsync | async}}
                                </td>

                                <ng-container [ngSwitch]="productRel.ItemType">

                                    <td *ngSwitchCase="'IgnoreMatch'" class=" " colspan="3">
                                        <span class="mx-4 px-4 badge badge-warning"><i class="fas fa-eraser"></i> מחיקת פריט מההזמנה </span>
                                    </td>

                                    <td *ngSwitchCase="'RemarkMatch'" colspan="3">
                                        <div class="mx-4 px-4 ">
                                            <span><i class="fas fa-sticky-note"></i> המר להערה </span>
                                            <span class="badge badge-info px-1"> {{productRel.Remark}} </span>
                                        </div>
                                    </td>


                                    <ng-container *ngSwitchDefault>
                                        <!-- aliash or master product-->

                                        <td>
                                            <a [routerLink]="['/product', productRel.Product.Id]">
                                                {{productRel.Product.SKU}}
                                            </a>
                                        </td>
                                        <td>
                                            <a [routerLink]="['/product', productRel.Product.Id]">
                                                {{productRel.Product.Name}}
                                            </a>
                                        </td>
                                        <td>
                                            {{productRel.Quantity}}<i class="fas fa-long-arrow-alt-left mx-1"></i>{{HtmlHelper.GerRelevantProductsCrossTotalQuantity(mar)}}
                                        </td>
                                        <td>
                                            {{productRel.Product.Model}}
                                        </td>

                                    </ng-container>
                                </ng-container>


                            </tr>
                        </ng-container>

                    </tbody>
                </table>
            </div>

        </div>
    </div>

</div>




<ng-template #contentDeleteMasterAliasRel let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">אישור מחיקה</h4>
        <button type="button" class="close" aria-label="Close" (click)=" c('cross')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="SelectedMasterAliasProductRelationships && SelectedMasterAliasProductRelationships.GetSelectedItemCount() <= 1;else contentDeleteProductGetSelectedProductsCountelse">
            האם אתה בטוח שאתה רוצה למחוק את הקישור ?
        </ng-container>
        <ng-template #contentDeleteProductGetSelectedProductsCountelse>
            האם אתה בטוח שאתה רוצה למחוק <span class="badge badge-pill badge-light">{{SelectedMasterAliasProductRelationships?.GetSelectedItemCount()}}</span> קישורים ?
        </ng-template>
    </div>

    <div class="modal-footer d-block">
        <button type="button" class="btn btn-outline-danger float-right" (click)="DeleteMasterAliasProductRelationships(c)">אשר <i class="fa" [ngClass]="{'fa-spinner fa-pulse' : IsProcessing , 'fa-trash-alt' : !IsProcessing}"></i></button>
        <button type="button" class="btn btn-outline-dark float-left" (click)="c('cancel')">בטל</button>
    </div>
</ng-template>


<ng-template #contentDeleteProduct let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">אישור מחיקה</h4>
        <button type="button" class="close" aria-label="Close" (click)=" c('cross')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        האם אתה בטוח שאתה רוצה למחוק את המוצר ?
    </div>

    <div class="modal-footer d-block">
        <button type="button" class="btn btn-outline-danger float-right" (click)="DeleteProduct(c)">אשר <i class="fa" [ngClass]="{'fa-spinner fa-pulse' : IsProcessing , 'fa-trash-alt' : !IsProcessing}"></i></button>
        <button type="button" class="btn btn-outline-dark float-left" (click)="c('cancel')">בטל</button>
    </div>
</ng-template>



<ng-template #popContentMultiValues>

    <div style="width: 25rem;">
        <table class="table table-hover table-sm " *ngIf="popContentMultiValuesContext?.field">
            <!--  <thead>
                <tr>
                    <th scope="col">ערוץ</th>
                    <th scope="col">ערך</th>
                </tr>
            </thead>
            -->
            <tbody>
                <tr>
                    <td style="width: 7rem;"><b> {{HtmlHelper.GetStoreName(0)}}</b></td>

                    <td *ngIf="Product[popContentMultiValuesContext.field]" [ngSwitch]="popContentMultiValuesContext.field">
                        <ng-container *ngSwitchCase="'PriceList'">{{Product[popContentMultiValuesContext.field].RecommendedPrice | number:'1.2-2'}}</ng-container>
                        <ng-container *ngSwitchCase="'Media'">
                            <div *ngFor="let media of Product[popContentMultiValuesContext.field] ;let i = index" class="text-truncate">
                                <span class="badge badge-pill badge-info mr-2">{{i +1 }}</span><a [href]="media">{{media}}</a>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'Attributes'"> {{Product[popContentMultiValuesContext.field]?.join(', ')}} </ng-container>
                        <ng-container *ngSwitchDefault>{{Product[popContentMultiValuesContext.field] }}</ng-container>
                    </td>
                    <td *ngIf="!Product[popContentMultiValuesContext.field]">אין ערך</td>
                </tr>
                <tr *ngFor="let storeOverride of GetFieldCustomValues(popContentMultiValuesContext.field)">
                    <td style="width: 7rem;"><b>{{HtmlHelper.GetStoreName(storeOverride.Store)}}</b></td>
                    <td [ngSwitch]="popContentMultiValuesContext.field">
                        <ng-container *ngSwitchCase="'PriceList'">{{storeOverride?.Value?.RecommendedPrice | number:'1.2-2'}}</ng-container>
                        <ng-container *ngSwitchCase="'Media'">
                            <div *ngFor="let media of storeOverride?.Value ;let i = index" class="text-truncate">
                                <span class="badge badge-pill badge-info mr-2">{{i +1 }}</span><a [href]="media">{{media}}</a>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'Attributes'"> {{storeOverride.Value?.join(', ')}} </ng-container>
                        <ng-container *ngSwitchDefault>{{storeOverride.Value}} </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>
<ng-template #popTitleMultiValues> ערכי שדה "{{popContentMultiValuesContext?.name}}"</ng-template>