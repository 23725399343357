import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
import { OrderService, IOrderSearchRequest } from '../../../services/order.service';
import { ShipmentService } from '../../../services/shipment.service';
import { CommonService } from '../../../services/common.service';

import { IShipment, IShipmentTypes } from '../../../models/shipments.models';
import { DBOperationResult, IPageResult } from '../../../models/common.models';
import { EnumDescriptionPipe } from '../../../pipes/enumdescription.pipe';
import { UserControlEditorMode } from '../../../models/user-controls';
import { Location } from '@angular/common';
import { collapseAnimation, fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, collapseOnLeaveAnimation } from 'angular-animations';
import { AdminService } from '../../../services/admin.service';

import { ProductService, IMasterAliasProductRelationshipQuery } from '../../../services/product.service';
import { IMasterProduct, IMasterAliasProductRelationship } from '../../../models';
import { SelectableList } from '../../../models/SelectableList';

import { IStoreDetails, StoreType } from '../../../models/stores';
import { StoreService } from 'src/app/services/store.service';
import { getMasterProductStoreValue, HasMasterProductCustomStoreValue, IPriceListItem, IProduct, ProductItemOrderSerialNumberControlType, setMasterProductStoreValue, StoreOverride, StoreOverrideMasterProductFieldName } from 'src/app/models/products.models';
import { IProductCatalog } from 'src/app/models/product.catalog';
import { Store } from '@ngrx/store';
import { ConfigStateFeature } from 'src/app/state/config.reducer';
import { filter } from 'rxjs';
import { CatalogLazyFetchAllStart } from 'src/app/state/catalog.actions';
import { CatalogStateFeature } from 'src/app/state/catalog.reducer';



@Component({
  selector: 'app',
  templateUrl: './product.page.component.html',
  styleUrls: ['./product.page.component.css'],
  animations: [
    collapseAnimation({ duration: 500 })

  ]
})
export class ProductPageComponent implements OnInit, OnDestroy {

  private EnumDescriptionConverter = new EnumDescriptionPipe();
  private _shipmentTypes!: IShipmentTypes;
  public ActiveStores!: IStoreDetails[];
  public SelectedStoreView: StoreType | null;
  constructor(
    private _productService: ProductService,
    private _shipmentService: ShipmentService,

    private _route: ActivatedRoute,
    private _modalService: NgbModal,
    private _router: Router,
    private _location: Location,
    private _admin: AdminService,
    private _storeService: StoreService,
    private store: Store,
    public CdRef: ChangeDetectorRef) { }




  closeResult: string = "";
  public Product: IMasterProduct | null;
  public MasterAliasProductRelationships: IPageResult<IMasterAliasProductRelationship> | null;
  public SelectedMasterAliasProductRelationships: SelectableList<string> | null;
  public EditMode: boolean;
  public MasterAliasProductRelationshipsOrderBy: string = "-Audit.CreatedAt"
  public MasterAliasProductRelationshipsPageIndex: number = 0
  public IsProcessing: boolean = false;
  public ProductUserFields = [
    "ProductUser01",
    "ProductUser02",
    "ProductUser03",
    "ProductUser04",
    "ProductUser05",
    "ProductUser06",
    "ProductUser07",
    "ProductUser08",
    "ProductUser09",
    "ProductUser10",
  ];

  public ProductCatalogs: IProductCatalog[];
  public get ActiveProductCatalogs(): IProductCatalog[] {
    if (!this.Product || !this.IsMasterProduct) return [];
    return this.ProductCatalogs?.filter(pCatalog => pCatalog.Stores.includes(Number(this.SelectedStoreView)) && pCatalog.Fields?.length) ?? [];
  }
  public popContentMultiValuesContext!: { name: string, field: string };
  public ChitaCargoTypes = {
    null: "חבילה רגילה",
    //"" : "חבילה רגילה",
    "100": "תעודה חתומה",
    "110": "חבילה רגילה",
    "145": "מדבקות ללקוח",
    "150": "חב משקל חריג",
    "155": "חבילה נפחית",
    "160": "חבילת גובה",
    "170": "משטח",
    "190": "גוביינא הובל",
    "192": "חב' עד 3 ק\"ג",
    "999": "מטען מעורב",
  }

 public AvailableDeliveryPackageSizes = this.EnumDescriptionConverter.listOfValues("DeliveryPackageSize");
  public ProductItemOrderSerialNumberControlTypes = [ProductItemOrderSerialNumberControlType.Disable, ProductItemOrderSerialNumberControlType.OrderOptional, ProductItemOrderSerialNumberControlType.OrderERPRequire];
  public IsStockEnable: boolean = false;
  ngOnInit(): void {

    this.store.dispatch(CatalogLazyFetchAllStart());
    this._storeService.GetStoreList().subscribe(s => this.ActiveStores = s.filter(s => !!s.Enabled));
    this.store.select(ConfigStateFeature.stockModuleEnable).pipe(filter(b => typeof b === "boolean")).subscribe(isStockEnable => this.IsStockEnable = isStockEnable);
    this.store.select(CatalogStateFeature.selectCatalog).subscribe(result => this.ProductCatalogs = result);


    this._route.params.subscribe(params => {
      if (params['id'] != this.Product?.Id) this.LoadProduct(params['id']);

    });
    this._route.queryParams.subscribe(params => {
      this.SelectedStoreView = params['store'] ?? null;
    })

  }

  public LoadProduct(id: string) {

    var action = this._productService.SearchProducts({
      Id: id
    });

    this.Product = null;

    action.subscribe(result => {
      if (result.Code === 0 && result.Result.Items.length) {
        this.Product = result.Result.Items[0] as IProduct;

        window["Product"] = $.extend(true, {}, this.Product);
        window["GetMapValue"] = (path?: string) => {
          if (path) {
            this._productService.GetPathValue(this.Product.Id, this.SelectedStoreView, path).subscribe(r => console.log(r.Result));
          }
        }


        if (this.IsMasterProduct) if (!this.Product.StoreDetails) this.Product.StoreDetails = {};
        this.LoadMasterAliasProductRelationship();
      }
    });

  }
  public ToggleMasterAliasProductRelationshipsOrderBy(orderBy: string) {
    if (this.MasterAliasProductRelationshipsOrderBy == orderBy) this.MasterAliasProductRelationshipsOrderBy = "-" + orderBy;
    else this.MasterAliasProductRelationshipsOrderBy = orderBy;
    this.LoadMasterAliasProductRelationship();

  }
  public LoadMasterAliasProductRelationship(pageIndex: number = 0) {

    var query: IMasterAliasProductRelationshipQuery = {
      OrderByFields: [this.MasterAliasProductRelationshipsOrderBy],
      PageIndex: pageIndex,
      PageSize: 15
    }

    if (this.IsMasterProduct) query.AnyMasterProductIds = [this.Product.Id];
    else query.AnyAliasProductIds = [this.Product.Id];

    var action = this._productService.SearchMasterAliasProductRelationship(query);
    this.MasterAliasProductRelationships = null;
    this.SelectedMasterAliasProductRelationships = null;

    action.subscribe(result => {
      if (result.Code === 0) {
        this.MasterAliasProductRelationships = result.Result;
        this.SelectedMasterAliasProductRelationships = new SelectableList(this.MasterAliasProductRelationships.TotalItemsCount);
      }
    });

  }

  public get ProductActiveUserFields(): string[] {
    return this.ProductUserFields.filter(productUserField => this.EditMode || this.Product[productUserField] && this.Product[productUserField] != '');
  }

  public GetProductValue(field: StoreOverrideMasterProductFieldName): any {

    var result;
    if (this.SelectedStoreView && this.IsMasterProduct) {
      result = getMasterProductStoreValue(this.Product, this.SelectedStoreView, field);
    }
    else result = this.Product[field];

    if (field == 'PriceList' && result) return result.RecommendedPrice;
    if ((field == "Media" || field == "Attributes") && !result) {
      this.Product[field] = [];
      result = this.Product[field];
    }
    return result;
  }

  public SetProductValue(field: StoreOverrideMasterProductFieldName, value: any) {

    if (field == 'PriceList' && value) value = { RecommendedPrice: value };
    if (this.SelectedStoreView && this.IsMasterProduct) {
      setMasterProductStoreValue(this.Product, this.SelectedStoreView, field, value);
    }
    else this.Product[field] = value;
  }

  public SetProductValueArray(field: StoreOverrideMasterProductFieldName, index: number, value: any) {
    var array = (this.GetProductValue(field) as any[]).map(i => i);
    array[index] = value;
    this.SetProductValue(field, array.filter(i => i != null));
  }


  public HasProductCustomStoreValue(field: StoreOverrideMasterProductFieldName) {
    // if ((this.SelectedStoreView as any) == "null") this.SelectedStoreView = null; // bug fix
    return HasMasterProductCustomStoreValue(this.Product, null, field);
  }

  public GetFieldCustomValues(field: StoreOverrideMasterProductFieldName): StoreOverride[] {
    return (this.Product as IMasterProduct).StoreOverrides?.filter((o: StoreOverride) => o.Field == field);
  }

  public SaveProduct() {

    this._productService.SaveProduct(this.Product)
      .subscribe(result => {
        if (result.Code === 0 && result.Result) {
          this.Product = result.Result as IMasterProduct;
        }

      });
    this.Product = null;
  }

  public get IsMasterProduct(): boolean {
    return this.Product.ItemType == "MasterProduct";
  }

  public DeleteProduct = (callback) => {
    this.IsProcessing = true;
    this._productService.DeleteProductsByQuery({ Id: this.Product.Id }, "Deleted by user").subscribe(r => {
      this.IsProcessing = false;
      callback("save");
      this._location.back();

    });
  }


  public DeleteMasterAliasProductRelationships = (callback) => {

    if (this.SelectedMasterAliasProductRelationships && (this.SelectedMasterAliasProductRelationships.SelectedItems.length || this.SelectedMasterAliasProductRelationships.SelectAllItems)) {
      this.IsProcessing = true;

      var query: IMasterAliasProductRelationshipQuery = { AnyMasterProductIds: [this.Product.Id], };

      if (this.SelectedMasterAliasProductRelationships.SelectedItems.length) {
        if (!this.SelectedMasterAliasProductRelationships.SelectAllItems) query.Ids = this.SelectedMasterAliasProductRelationships.SelectedItems;
        else query.ExcludeIds = this.SelectedMasterAliasProductRelationships.SelectedItems;
      } else {
        if (query.Ids) delete query.Ids;
        if (query.ExcludeIds) delete query.ExcludeIds;
      }

      this._productService.DeleteMasterAliasProductRelationship(query, 'Deleted by user in product page').subscribe(
        r => {
          this.IsProcessing = false;
          this.LoadMasterAliasProductRelationship();
          callback("save");
        }
      )
    }
  }

  public HtmlHelper = {
    GetRelevantProducts: (mar: IMasterAliasProductRelationship) => (this.IsMasterProduct) ? mar.AliasProducts : mar.MasterMatches,
    GerRelevantProductsCrossTotalQuantity: (mar: IMasterAliasProductRelationship) => {
      if (this.IsMasterProduct) {
        return mar.MasterMatches.reduce((prev, cur) => prev + cur.Quantity, 0);
      } else {
        return mar.AliasProducts.reduce((prev, cur) => prev + cur.Quantity, 0);
      }
    },
    GetSelectedStoreViewName: () => {
      return this.HtmlHelper.GetStoreName(this.SelectedStoreView);
    },
    GetStoreName: (storeType: StoreType) => {
      var store = this.ActiveStores?.find(store => store.Type == storeType);
      return store?.Description ?? "(ברירת מחדל)";
    }
  }

  public ChangeStoreView(store: StoreType) {
    this._router.navigate([], { queryParams: { store }, replaceUrl: true });
  }


  public Return() {
    this._location.back();
  }


  public SwitchToEditMode() {
    this.EditMode = true;
    if (this.IsStockEnable && !this.Product.Stock) this.Product.Stock = { Amount: 0 };
  }

  public open(content, options, onInit, onSave) {
    if (onInit) onInit();
    this._modalService.open(content, options).result.then((result) => {
      if ((result === "save") && (onSave)) onSave();
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {

      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  ngOnDestroy(): void {
    delete window["Product"];
    delete window["GetMapValue"];
  }
}
