<div class="container-fluid">
  <div class="row mt-2">
    <div class="col-12">
      <p class="">
        <b class="mr-4 h4">סדר פעולות יומי</b>
        סדר פעולות לעבודה מסודרת
      </p>
    </div>
  </div>
  <div class="row ">
    <div class="col-3 d-flex align-items-center border-bottom">
      <div>
        <div class="font-weight-bold"> קציר הזמנות מהאתרים</div>
        <div>נכנסים לכל האתרים ומורידים הזמנות לקובץ.</div>
      </div>
    </div>
    <div class="col-1 border">
    </div>

    <div class="col-1 border d-flex align-items-center justify-content-center font-weight-bold stats-row" *ngFor="let dateKey of DateTypes">
      <ng-container [ngSwitch]="dateKey">
        <ng-container *ngSwitchCase="'today'">היום</ng-container>
        <ng-container *ngSwitchCase="'yesterday'">אתמול</ng-container>
        <ng-container *ngSwitchCase="'weekAGo'">עד שבוע</ng-container>
        <ng-container *ngSwitchCase="'total'">הכול</ng-container>
      </ng-container>
    </div>

    <div class="col-1 d-flex align-items-center justify-content-center font-weight-bold">
      <div class="text-center eye-toggle" *ngIf="HasStatsHidden ; else HideTitle" role="button" (click)="ResetViewStats()">
        הצג <br />
        <i class="fas fa-eye"></i>
      </div>
      <ng-template #HideTitle>
        <div class="text-center">
          הסתר
        </div>
      </ng-template>

    </div>
  </div>


  <div class="row stats-row " *ngIf="orderStatistics?.inDraft" >
    <div class="col-3 d-flex align-items-center border-bottom">
      <div>
        <div class="font-weight-bold"><a style="color: #212529 ;" routerLink="/import-orders-upload" *ngIf="HasRole(['Admin', 'OrdersManager'])">טעינת ההזמנות למערכת</a></div>
        <div>טוענים את ההזמנות למערכת ומשלימים פרטים</div>
      </div>
    </div>
    <div class="col-1 border  d-flex align-items-center text-center ">
      בהשלמת פרטים
    </div>

    <div class="col-1 border d-flex align-items-center justify-content-center stats-number font-weight-bold" *ngFor="let dateKey of DateTypes" role="button" (click)="GoToWizard(orderStatistics.inDraft,dateKey)" [ngClass]="{'stat-no-items text-success' : orderStatistics.inDraft[dateKey]===0 , 'stat-items text-danger' : orderStatistics.inDraft[dateKey]>0 }">
      <ng-container *ngIf="orderStatistics.hasOwnProperty('inDraft') && orderStatistics.inDraft.hasOwnProperty(dateKey) ;else LoadingIcon">{{orderStatistics.inDraft[dateKey]}}</ng-container>
    </div>

    <div class="col-1 ">
    </div>
  </div>

  <div class="row stats-row" *ngIf="orderStatistics?.shipmentError">
    <div class="col-3 d-flex align-items-center border-bottom">
      <div>
        <div class="font-weight-bold">טיפול בכתובות שגויות</div>
        <div>תיקון כתובות משלוח שגויות ואיתור נקודות איסוף מתאימות.</div>
      </div>
    </div>
    <div class="col-1 border d-flex align-items-center  text-center ">
      כתובת משלוח שגויה
    </div>

    <div class="col-1 border d-flex align-items-center justify-content-center font-weight-bold" *ngFor="let dateKey of DateTypes" role="button" (click)="GoToOrders(orderStatistics.shipmentError,dateKey)" [ngClass]="{'stat-no-items text-success' : orderStatistics.shipmentError[dateKey]===0 , 'stat-items text-danger' : orderStatistics.shipmentError[dateKey]>0 }">
      <ng-container *ngIf="orderStatistics.hasOwnProperty('shipmentError') && orderStatistics.shipmentError.hasOwnProperty(dateKey) ;else LoadingIcon">{{orderStatistics.shipmentError[dateKey]}}</ng-container>
    </div>

    <div class="col-1 ">
    </div>
  </div>


  <div class="row stats-row" *ngIf="orderStatistics?.exportOrderMissingRequirements" [@collapse]="!orderStatistics.exportOrderMissingRequirements.Enabled">
    <div class="col-3 d-flex align-items-center border-bottom">
      <div>
        <div class="font-weight-bold">שיוך מספר טבוע</div>
        <div>הזנת מספרים טבועים (סיריאלים) בהזמנות עם מוצרים שדורשים זאת</div>
      </div>
    </div>
    <div class="col-1 border d-flex align-items-center  text-center ">
      שיוך מספר טבוע
    </div>

    <div class="col-1 border d-flex align-items-center justify-content-center font-weight-bold" *ngFor="let dateKey of DateTypes" role="button" (click)="GoToOrders(orderStatistics.exportOrderMissingRequirements,dateKey)" [ngClass]="{'stat-no-items text-success' : orderStatistics.exportOrderMissingRequirements[dateKey]===0 , 'stat-items text-danger' : orderStatistics.exportOrderMissingRequirements[dateKey]>0 }">
      <ng-container *ngIf="orderStatistics.hasOwnProperty('exportOrderMissingRequirements') && orderStatistics.exportOrderMissingRequirements.hasOwnProperty(dateKey) ;else LoadingIcon">{{orderStatistics.exportOrderMissingRequirements[dateKey]}}</ng-container>
    </div>

    <div class="col-1 d-flex align-items-center justify-content-center font-weight-bold">
      <h5 (click)="ToggleViewStats('exportOrderMissingRequirements')" role="button" class="eye-toggle">
        <i class="fas " [ngClass]="{'fa-eye' : !orderStatistics.exportOrderMissingRequirements.Enabled , 'fa-eye-slash' : orderStatistics.exportOrderMissingRequirements.Enabled }"></i>
      </h5>
    </div>
  </div>


  <div class="row stats-row" *ngIf="IsStockEnabled && orderStatistics?.outOfStock" [@collapse]="!orderStatistics.outOfStock.Enabled">
    <div class="col-3 d-flex align-items-center border-bottom">
      <div>
        <div class="font-weight-bold">חסר במלאי</div>
        <div>הזמנות עם מוצר חסר במלאי</div>
      </div>
    </div>
    <div class="col-1 border  d-flex align-items-center text-center ">
      חסר במלאי
    </div>

    <div class="col-1 border d-flex align-items-center justify-content-center font-weight-bold" *ngFor="let dateKey of DateTypes" role="button" (click)="GoToOrders(orderStatistics.outOfStock,dateKey)" [ngClass]="{'stat-no-items text-success' : orderStatistics.outOfStock[dateKey]===0 , 'stat-items text-danger' : orderStatistics.outOfStock[dateKey]>0 }">
      <ng-container *ngIf="orderStatistics.hasOwnProperty('outOfStock') && orderStatistics.outOfStock.hasOwnProperty(dateKey) ;else LoadingIcon"> {{orderStatistics.outOfStock[dateKey]}}</ng-container>
    </div>

    <div class="col-1 d-flex align-items-center justify-content-center font-weight-bold">
      <h5 (click)="ToggleViewStats('outOfStock')" role="button" class="eye-toggle">
        <i class="fas " [ngClass]="{'fa-eye' : !orderStatistics.outOfStock.Enabled , 'fa-eye-slash' : orderStatistics.outOfStock.Enabled }"></i>
      </h5>
    </div>
  </div>


  <div class="row stats-row" *ngIf="orderStatistics?.readyForPrint" [@collapse]="!orderStatistics.readyForPrint.Enabled">

    <div class="col-3 d-flex align-items-center border-bottom">
      <div>
        <div class="font-weight-bold">הדפסת מדבקות משלוח</div>
        <div>מדפיסים את מדבקות המשלוחים כדי שיהיו במערכת מספרי מעקב.</div>
      </div>
    </div>

    <div class="col-1 border  d-flex align-items-center text-center ">
      ממתינות להדפסה
    </div>

    <div class="col-1 border d-flex align-items-center justify-content-center font-weight-bold" *ngFor="let dateKey of DateTypes" role="button" (click)="GoToOrders(orderStatistics.readyForPrint,dateKey)" [ngClass]="{'stat-no-items text-success' : orderStatistics.readyForPrint[dateKey]===0 , 'stat-items text-danger' : orderStatistics.readyForPrint[dateKey]>0 }">
      <ng-container *ngIf="orderStatistics.hasOwnProperty('readyForPrint') && orderStatistics.readyForPrint.hasOwnProperty(dateKey) ;else LoadingIcon"> {{orderStatistics.readyForPrint[dateKey]}}</ng-container>
    </div>

    <div class="col-1 d-flex align-items-center justify-content-center font-weight-bold">
      <h5 (click)="ToggleViewStats('readyForPrint')" role="button" class="eye-toggle">
        <i class="fas " [ngClass]="{'fa-eye' : !orderStatistics.readyForPrint.Enabled , 'fa-eye-slash' : orderStatistics.readyForPrint.Enabled }"></i>
      </h5>
    </div>
  </div>


  <div class="row stats-row"  *ngIf="orderStatistics?.readyForERPExport" [@collapse]="!orderStatistics.readyForERPExport.Enabled">

    <div class="col-3 d-flex align-items-center border-bottom">
      <div>
        <div class="font-weight-bold">ייצוא נתונים ל-ERP</div>
        <div>מייצאים את ההזמנות ל-ERP</div>
      </div>
    </div>

    <div class="col-1 border  d-flex align-items-center text-center ">
      ממתינות לייצוא <br /> ל-ERP
    </div>


    <div class="col-1 border d-flex align-items-center justify-content-center font-weight-bold" *ngFor="let dateKey of DateTypes" role="button" (click)="GoToOrders(orderStatistics.readyForERPExport,dateKey)" [ngClass]="{'stat-no-items text-success' : orderStatistics.readyForERPExport[dateKey]===0 , 'stat-items text-danger' : orderStatistics.readyForERPExport[dateKey]>0 }">
      <ng-container *ngIf="orderStatistics.hasOwnProperty('readyForERPExport') && orderStatistics.readyForERPExport.hasOwnProperty(dateKey) ;else LoadingIcon">{{orderStatistics.readyForERPExport[dateKey]}}</ng-container>
    </div>

    <div class="col-1 d-flex align-items-center justify-content-center font-weight-bold">
      <h5 (click)="ToggleViewStats('readyForERPExport')" role="button" class="eye-toggle">
        <i class="fas " [ngClass]="{'fa-eye' : !orderStatistics.readyForERPExport.Enabled , 'fa-eye-slash' : orderStatistics.readyForERPExport.Enabled }"></i>
      </h5>
    </div>
  </div>

  <div class="row stats-row" *ngIf="orderStatistics?.reportedToSeller" [@collapse]="!orderStatistics.reportedToSeller.Enabled">

    <div class="col-3 d-flex align-items-center border-bottom">
      <div>
        <div class="font-weight-bold">דיווח של ההזמנות שטופלו לערוץ המכירה</div>
       
      </div>
    </div>

    <div class="col-1 border  d-flex align-items-center text-center ">
      מוכנות לדיווח לערוץ המכירה
    </div>

    <div class="col-1 border d-flex align-items-center justify-content-center font-weight-bold" *ngFor="let dateKey of DateTypes" role="button" (click)="GoToOrders(orderStatistics.reportedToSeller,dateKey)" [ngClass]="{'stat-no-items text-success' : orderStatistics.reportedToSeller[dateKey]===0 , 'stat-items text-danger' : orderStatistics.reportedToSeller[dateKey]>0 }">
      <ng-container *ngIf="orderStatistics.hasOwnProperty('reportedToSeller') && orderStatistics.reportedToSeller.hasOwnProperty(dateKey) ;else LoadingIcon"> {{orderStatistics.reportedToSeller[dateKey]}}</ng-container>
    </div>

    <div class="col-1 d-flex align-items-center justify-content-center font-weight-bold">
      <h5 (click)="ToggleViewStats('reportedToSeller')" role="button" class="eye-toggle">
        <i class="fas " [ngClass]="{'fa-eye' : !orderStatistics.reportedToSeller.Enabled , 'fa-eye-slash' : orderStatistics.reportedToSeller.Enabled }"></i>
      </h5>
    </div>
  </div>

  <div class="row stats-row"  *ngIf="orderStatistics?.readyToCollect" [@collapse]="!orderStatistics.readyToCollect.Enabled">
    <div class="col-3 d-flex align-items-center border-bottom">
      <div>
        <div class="font-weight-bold">העברת ההזמנות לביצוע במחסן</div>
        <!--<div>תיקון כתובות משלוח שגויות ואיתור נקודות איסוף מתאימות.</div>-->
      </div>
    </div>
    <div class="col-1 border  d-flex align-items-center text-center ">
      ממתינות לליקוט
    </div>

    <div class="col-1 border d-flex align-items-center justify-content-center font-weight-bold" *ngFor="let dateKey of DateTypes" role="button" (click)="GoToOrders(orderStatistics.readyToCollect,dateKey)" [ngClass]="{'stat-no-items text-success' : orderStatistics.readyToCollect[dateKey]===0 , 'stat-items text-danger' : orderStatistics.readyToCollect[dateKey]>0 }">
      <ng-container *ngIf="orderStatistics.hasOwnProperty('readyToCollect') && orderStatistics.readyToCollect.hasOwnProperty(dateKey) ;else LoadingIcon"> {{orderStatistics.readyToCollect[dateKey]}}</ng-container>
    </div>

    <div class="col-1 d-flex align-items-center justify-content-center font-weight-bold">
      <h5 (click)="ToggleViewStats('readyToCollect')" role="button" class="eye-toggle">
        <i class="fas " [ngClass]="{'fa-eye' : !orderStatistics.readyToCollect.Enabled , 'fa-eye-slash' : orderStatistics.readyToCollect.Enabled }"></i>
      </h5>
    </div>
  </div>



</div>

<ng-template #LoadingIcon>
  <i class="fa fa-spinner fa-pulse  text-secondary"></i>
</ng-template>