import { type } from 'os';
import { IDBObject } from './common.models';
import { StoreType } from './stores';


export interface IBaseProduct {
  Id: string | null,
  UPC?: string,
  Name: string,
  Model?: string,
  SKU: string,
  Attributes?: string[],
  ItemType: string,
  Brand?: string,

}

export interface IBasicProduct extends IBaseProduct {
  Note?: string,
}

export interface IMasterBasicProduct extends IBasicProduct {
  ProductUser01?: string,
  ProductUser02?: string,
  ProductUser03?: string,
  ProductUser04?: string,
  ProductUser05?: string,
  ProductUser06?: string,
  ProductUser07?: string,
  ProductUser08?: string,
  ProductUser09?: string,
  ProductUser10?: string,
  OrderSerialNumberControl?: ProductItemOrderSerialNumberControlType;
}

export interface IMasterBasicOrderProduct extends IBasicProduct, IMasterBasicProduct {
  StorePriceList?: IPriceListItem,

}


export interface IMasterBasicMatchProduct extends IBasicProduct {
  DefaultShipmentMethod?: string,
  DefaultShipmentPackageSize?: DeliveryPackageSize,
}

export interface IAliasBasicProduct extends IBasicProduct {
  AdditionIdentifiers: string[] | null
}


export interface IProduct extends IDBObject, IBaseProduct {

  Note?: string,
}

export interface IStoreProductDetails {
  PriceList?: IPriceListItem
}
export interface IPriceListItem {
  CeilingPrice?: number,
  FloorPrice?: number,
  RecommendedPrice?: number,
}

export interface IMasterProduct extends IProduct, IMasterBasicProduct {
  DefaultShipmentMethod: string | null,
  DefaultShipmentPackageSize: DeliveryPackageSize | null,
  Stock?: IProductStockDetail,
  Description?: string,
  Warranty?: string,
  PriceList?: IPriceListItem,
  Media?: string[];
  Category?: string;
  ActiveInStore?: boolean;
  StoreOverrides?: StoreOverride[];
  ChitaCargoType?: string | null;

}




export enum ProductItemOrderSerialNumberControlType {
  Disable = 0, DisableDescription = <any>"מבוטל", // Disable SN control
  OrderOptional = 1, OrderOptionalDescription = <any>"פעיל - אופציונלי",// Optional to fill SN before Order ERP submission 
  OrderERPRequire = 2, OrderERPRequireDescription = <any>"פעיל - נדרש לייצוא ERP" // Require to fill SN before Order ERP submission 
}

export enum DeliveryPackageSize {
  Undefined = 0, UndefinedDescription = <any>" ", 
  Letter = 1000, LetterDescription = <any>"מעטפה", 
  Normal = 2000, NormalDescription = <any>"חבילה רגילה", 
  OverSize = 3000, OverSizeDescription = <any>"חבילה חריגה נפח / משקל", 
  Stand = 4000, StandDescription = <any>"חצי משטח / סטנד", 
  Platform = 5000, PlatformDescription = <any>"משטח", 
}

export type StoreOverrideMasterProductFieldName = "UPC" | "SKU" | "PriceList" | "Name" | "Media" | "Category" | "Description" | "Warranty" | "Model" | "Attributes" | "ActiveInStore";

export interface StoreOverride {
  Field: StoreOverrideMasterProductFieldName,
  Store: StoreType,
  Value: any
}

export function setMasterProductStoreValue(product: IMasterProduct, store: StoreType, field: StoreOverrideMasterProductFieldName, value: any) {

  store = Number(store); // bug fix
  if (!store && !field.includes('.')) {
    (product[field] as unknown) = value;
    return;
  }
  var storeOverride = product.StoreOverrides?.find(o => o.Store == store && o.Field == field);
  var masterValue = product[field];

  if (JSON.stringify(masterValue) === JSON.stringify(value) || !value) {
    if (storeOverride) product.StoreOverrides = product.StoreOverrides.filter(o => !(o.Store == store && o.Field == field));
  }
  else {
    if (storeOverride) storeOverride.Value = value;
    else {
      if (!product.StoreOverrides) product.StoreOverrides = [];
      product.StoreOverrides.push({ Store: store, Field: field, Value: value });
    }
  }
}

export function getMasterProductStoreValue(product: IMasterProduct, store: StoreType, field: StoreOverrideMasterProductFieldName): any {
  if (!product) return undefined;
  var storeOverride = product.StoreOverrides?.find(o => o.Store == store && o.Field == field);
  if (storeOverride && storeOverride.Value) return storeOverride.Value;
  else return product[field];
}

export function HasMasterProductCustomStoreValue(product: IMasterProduct, store: StoreType, field: StoreOverrideMasterProductFieldName): boolean {
  if (!product) return false;
  var storeOverride = product.StoreOverrides?.find(o => (o.Store == store || !store) && o.Field == field);
  if (storeOverride && storeOverride.Value) return true;
}




export interface IAliasProduct extends IProduct {
  AdditionIdentifiers: string[] | null
}

export interface IProductRef<T> {
  Product: T,
  Quantity: number,
  OrderRowIndex?: number,
  ItemPrice?: number,
  ItemVATPrice?: number
}

export interface IMasterMatchResult {
  ItemType: string
}

export interface IMasterProductMatchResult extends IProductRef<IMasterBasicMatchProduct>, IMasterMatchResult { }
export interface IMasterRemarkMatchResult extends IMasterMatchResult {
  Remark: string
}
export interface IMasterShipMethodMatchResult extends IMasterMatchResult {
  ShipMethod: string
}
export interface IMasterIgnoreMatchResult extends IMasterMatchResult {

}

export interface IMasterAliasProductRelationship extends IDBObject {
  MasterMatches: IMasterMatchResult[],
  AliasProducts: IProductRef<IAliasBasicProduct>[],
  OriginStore: StoreType,
  OrderBind?: string | null

}


export interface IProductStockDetail {
  Amount: number
}


export interface IImportProductOptions {
  FileType: "Operational" | "Miracle",
  DataChangePolicy: "ShopUpsert" | "ShopGeneralUpsert" | "Reset" | "ShopGeneralResetUpsert" | "ShopResetUpsert" | "GeneralInsert",
  PriceChangePolicy: FieldChangePolicyType
  StockChangePolicy: FieldChangePolicyType
  Store: StoreType | 0
}

export type FieldChangePolicyType = "Only" | "Ignore" | "Include"

