<section class="h-100 text-center se-login">
  <div class="container h-100">
    <div class="row justify-content-md-center h-100">
      <div class="card-wrapper">
        <div class="brand">
          <svg width="100" height="100" class="octicon octicon-package" viewBox="0 0 16 16" version="1.1" aria-hidden="true">
            <path fill-rule="evenodd" d="M1 4.732v7.47c0 .45.3.84.75.97l6.5 1.73c.16.05.34.05.5 0l6.5-1.73c.45-.13.75-.52.75-.97v-7.47c0-.45-.3-.84-.75-.97l-6.5-1.74a1.4 1.4 0 0 0-.5 0l-6.5 1.74c-.45.13-.75.52-.75.97zm7 9.09l-6-1.59v-6.77l6 1.61v6.75zm-6-9.36l2.5-.67 6.5 1.73-2.5.67L2 4.463zm13 7.77l-6 1.59v-6.75l2-.55v2.44l2-.53v-2.44l2-.53v6.77zm-2-7.24l-6.5-1.73 2-.53 6.5 1.73-2 .53z"></path>
          </svg>
        </div>
        <div class="card fat">
          <div class="card-body">
            <h4 class="card-title">מסך כניסה</h4>

            <form class="form-signin">

              <labeled-textbox title="שם משתמש" id="user-name" [(model)]="Username" [readonly]="(inProcess$ | async)"></labeled-textbox>

              <labeled-textbox type="password" title="סיסמה" id="password" [(model)]="Password" [readonly]="(inProcess$ | async) "></labeled-textbox>


              <!--
                <div class="checkbox mb-3">
                  <label>
                    <input type="checkbox" value="remember-me"> Remember me
                  </label>
                </div>
  -->
              <div class="form-group no-margin">
                <button type="submit" class="btn btn-primary btn-block" (click)='Login()' [disabled]="inProcess$ | async ">
                  כניסה
                </button>
                <hr>
                <button type="submit" class="btn btn-primary btn-block" (click)='SSOLogin()' [disabled]="inProcess$ | async ">
                  כניסה עם SSO
                </button>
              </div>
              <div>
                <i *ngIf="inProcess$ | async" class="fa fa-spinner fa-pulse fa-2x fa-fw text-secondary"></i>
                <pre class="text-danger">{{validationMessage$ | async}}</pre>
              </div>
            </form>
          </div>
        </div>
        <div class="footer">
          Copyright &copy; 2017 &mdash; Send-E
        </div>
      </div>
    </div>
  </div>


</section>