<div class="container" *ngIf="Mode==1">
  <div class="row mt-4 mb-4">
    <div class="input-group col-6 ">


      <input [(ngModel)]="ProductSearchQuery.FreeSearch" (keydown.enter)="SearchProduct()" [class]="'form-control right-radius-only'" tabindex="{{tabindex}}" /> 

      <!--
    <input type="text" class="form-control sukSearchBox" tabindex="{{tabindex}}" [(ngModel)]="ProductSearchQuery.SKU_Contains" placeholder='חיפוש לפי מק"ט'>
   -->
      <span class="input-group-btn">
        <button class="btn btn-outline-secondary left-radius-only" type="button" tabindex="{{tabindex}}" (click)="SearchProduct();"><i class="fas fa-search"></i></button>
      </span>
    </div>
    <div class="input-group col-2 ">

    </div>
    <div class="col-4 text-left">
      <button class="btn btn-outline-success" type="button" (click)="SwitchMode(2);"><i class="far fa-star"></i> צור מוצר חדש</button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <table class="table table-hover" *ngIf="SearchResults">
        <thead>
          <tr>
            <th scope="col">מק"ט</th>
            <th scope="col">תיאור</th>
            <th scope="col">מלאי</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of SearchResults; index as i" >
            <th scope="row">{{item.SKU}}</th>
            <td>{{item.Name}}</td>
            <td>{{item?.Amount?.Amount}}</td>
            <td> <button class="btn btn-outline-success btn-sm" (click)="SelectProduct(item);">בחר</button> </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="container" *ngIf="Mode==2">
  <div class="form-row">
    <div class="form-group col-md-12">
      <labeled-textbox title="תיאור" [(model)]="SelectedProduct.Name"></labeled-textbox>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-6">
      <labeled-textbox title='מק"ט' [(model)]="SelectedProduct.SKU"></labeled-textbox>
    </div>
    <div class="form-group col-md-6">
      <labeled-textbox title="מודל" [(model)]="SelectedProduct.Model"></labeled-textbox>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-6">
      <labeled-textbox title='מק"ט יצרן' [(model)]="SelectedProduct.UPC"></labeled-textbox>
    </div>
    <div class="form-group col-md-6">
      <labeled-textbox type="number" title='כמות מלאי' [(model)]="SelectedProduct.Stock.Amount"></labeled-textbox>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-6">
      <labeled-textbox title="מאפיינים" [(model)]="SelectedProductattributes"></labeled-textbox>
    </div>
  </div>
  <div class="row">
    <div class="col-4 text-right">
      <button class="btn btn-outline-success" type="button" (click)="SwitchMode((SelectedProduct.id)? 2 : 1 );"><i class="fas fa-chevron-right"></i>  חזור</button>
    </div>
    <div class="col-4">
    </div>
    <div class="col-4 text-left">
      <button class="btn btn-outline-success" type="button" (click)="FinishNewProduct();"><i class="fas fa-cart-plus"></i>צור מוצר והכנס מוצר להזמנה</button>
    </div>
  </div>
</div>


<div class="container" *ngIf="Mode==9999">

  <div _ngcontent-c6="" class="container text-center">
    <h1 _ngcontent-c6="" class="pt-4">
      {{ProcessingMessage}}
    </h1>

    <div class="text-right pt-2 pb-4 w-75 d-inline-block">
      <i class="fas fa-spinner fa-3x fa-pulse"></i>
    </div>
  </div>

</div>