import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ILongRunningOperation, ILongRunningOperationItem, LongRunningOperationItemType, LongRunningOperationItemStatus } from '../../../models/common.models';
import { OperationService } from '../../../services/operation.service';
declare var $: any;
import { OrderService, IOrderSearchRequest } from '../../../services/order.service';
import { Location } from '@angular/common';
import { FileService } from '../../../services/file.service';
import { IExternalFile, FileType } from '../../../models/files.models';
import { ShipmentStatus, IShipmentTypes } from '../../../models/shipments.models';
import { EnumDescriptionPipe } from '../../../pipes/enumdescription.pipe';
import { ShipmentService } from '../../../services/shipment.service';
import { OrderState, OrderErpExportStatus, OrderCollectStatus } from 'src/app/models/orders.models';
import { Subject, Observable } from 'rxjs';
import { filter, flatMap, map } from 'rxjs/operators';
import { ConfigStateFeature } from 'src/app/state/config.reducer';
import { Store } from '@ngrx/store';
@Component({
  templateUrl: './order-export.process.page.component.html',
  styleUrls: ['./order-export.process.page.component.css'],

})
export class OrderExportProcessPageComponent implements OnInit, OnDestroy {
  constructor(
    private _route: ActivatedRoute,
    private _opertionService: OperationService,
    private _orderService: OrderService,
    private _fileService: FileService,
    private _shipmentService: ShipmentService,
    private _router: Router,
    private store: Store,
    private _location: Location) { }


  public WorkItems: WorkItem[] = [];
  private EnumDescriptionConverter = new EnumDescriptionPipe();
  private timers: { [key: string]: number } = {};
  private query!: IOrderSearchRequest;
  private options!: IExportOptions;
  private types!: string[];
  public StatusDescription: string = "";
  private _shipmentTypes!: IShipmentTypes;
  public IsDraftOrdersInPlace: boolean = false;
  public ClientPostProcessed?: boolean
  public ReturnUrl!: string;
  public ReturnDescription!: string;

  private onAllWorkItemFinished: Subject<WorkItem>;


  ngOnInit(): void {
    this.store.select(ConfigStateFeature.selectShipmentTypes).pipe(filter(s => !!s)).subscribe(s => this._shipmentTypes = s);
    this.StatusDescription = "מאתחל נתונים אנא המתן...";
    this._route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.ReturnUrl = params.returnUrl;
        this.ReturnDescription = params.returnDescription;
        this.query = JSON.parse(params.query) as IOrderSearchRequest;
        this.options = JSON.parse(params.options) as IExportOptions;
        this.types = ((Array.isArray(params.types)) ? params.types : [params.types]);
        this.loadPage();
      });
  }

  private loadPage() {
    if (this.query.OrderState && this.query.OrderState.indexOf(OrderState.Draft) === -1) {
      // no need to chack for draft orders as we already filtering it
      this.IsDraftOrdersInPlace = false;
      this.startPrintProcessPage();
      return;
    }

    var query = $.extend(true, {}, this.query);
    query.OrderState = [OrderState.Draft];
    this._orderService.SearchOrders(query)
      .subscribe(r => {

        if (r.Code === 0 && !r.Result.TotalItemsCount) {
          this.IsDraftOrdersInPlace = false;
          this.startPrintProcessPage();
        }
        else {
          this.IsDraftOrdersInPlace = true;
          this.StatusDescription = "קיימות הזמנות שדורשות תיקון או השלמת פרטים";
        }
      });
  }
  private startPrintProcessPage() {
    let wiCount = 0;
    for (var typeIndex = 0; typeIndex < this.options.FileTypes.length; typeIndex++) {
      var fileType = this.options.FileTypes[typeIndex] as FileType;
      if (fileType & FileType.SchemaOriginDistributer) {
        wiCount++;
        if (this.types) {
          for (var i = 0; i < this.types.length; i++) {
            ((index) => {
              this._orderService.PrintOrders(this.types[index], this.query)
                .subscribe(r => {
                  if (r.Result) {

                    this.createWorkItem(r.Result, this.getWorkItemTitle(this.types[index]));
                  }
                });
            })(i);
          }

        }
        //  var shipmentType = this.query.ShipmentType[i];


      }

      if (fileType & FileType.Operational) {
        wiCount++;
        this._orderService.PrintOrders("SendEOperational", this.query)
          .subscribe(r => {
            if (r.Result) {
              this.createWorkItem(r.Result, this.getWorkItemTitle("sendeoperational"));
            }
          });

      }

      this.SetOnAllWorkItemFinishedLogic();

      this.StatusDescription = `מאתחל ${wiCount} קבצים, אנא המתן...`;
    }
  }
  private SetOnAllWorkItemFinishedLogic() {

    this.onAllWorkItemFinished = new Subject<WorkItem>();
    var finalObservable: Observable<WorkItem> = this.onAllWorkItemFinished;
    if (this.options.SetStatusOnSuccess) {
      finalObservable = finalObservable.pipe(flatMap(lastWorkitem => {
        this.StatusDescription = `מעדכן סטטוס משלוחים, אנא המתן...`;
        return this._orderService.SetShipmentStatus(
          {
            OperationID: lastWorkitem.Operation.Id as string
          }, this.options.SetStatusOnSuccess).pipe(map(result => lastWorkitem));
      }));
    }

    if (this.options.SetErpStatusOnSuccess) {
      finalObservable = finalObservable.pipe(flatMap(lastWorkitem => {
        this.StatusDescription = `מעדכן סטטוס ERP, אנא המתן...`;
        return this._orderService.SetErpStatus(
          {
            OperationID: lastWorkitem.Operation.Id as string
          }, this.options.SetErpStatusOnSuccess).pipe(map(result => lastWorkitem));
      }));
    }

    if (this.options.SetCollectStatusOnSuccess) {
      finalObservable = finalObservable.pipe(flatMap(lastWorkitem => {
        this.StatusDescription = `מעדכן סטטוס ליקוט , אנא המתן...`;
        return this._orderService.SetCollectStatus(
          {
            OperationID: lastWorkitem.Operation.Id as string
          }, this.options.SetCollectStatusOnSuccess).pipe(map(result => lastWorkitem));
      }));
    }


    finalObservable.subscribe(wi => {
      this.StatusDescription = `הפעולה הסתיימה בהצלחה`;
      this.ClientPostProcessed = true;
    });
  }

  public GoToOrderWizard(wi: WorkItem | undefined) {
    if (wi) this.query.OperationID = wi.Operation.Id;
    const searchQuery = $.extend(true, {}, this.query) as any;
    searchQuery.OrderState = [OrderState.Draft];

    searchQuery.returnUrl = this._location.path(true);
    searchQuery.returnDescription = " חזור לתהליך יצוא";
    this._router.navigate(['/orders-wizard'], {
      queryParams: searchQuery
    });
  }

  public GoToOrderShipmentsSubbmiter(wi: WorkItem | undefined) {
    let searchQuery: IOrderSearchRequest = {};
    if (wi) {
      searchQuery.OperationID = wi.Operation.Id;
    } else {
      searchQuery = $.extend(true, searchQuery, this.query);
    }

    var returnUrl = this._location.path(true);
    var returnDescription = "המשך להפקת מדבקות";
    this._router.navigate(['/shipments-submitter'], {
      queryParams: {
        returnUrl: returnUrl,
        returnDescription: returnDescription,
        query: JSON.stringify(searchQuery)
      }
    });
  }


  private getWorkItemTitle(fileType: string) {
    var result = "";
    if (fileType.toLowerCase() == "hasavsevet") {
      result = "חשבשבת";
    }
    else if (fileType.toLowerCase() == "sendeoperational") {
      result = "הזמנות לביצוע";
    }
    else if (fileType.toLowerCase() == "erp") {
      result = "ERP";
    }
    else if (fileType.toLowerCase() == "SuperParmShipmentOrderTrackingReport".toLowerCase()) {
      result = "דיווח לסופר פארם";
    }
    else if (fileType.toLowerCase() == "GrouponShipmentOrderTrackingReport".toLowerCase()) {
      result = "דיווח לגרופון";
    }
    else if (fileType.toLowerCase() == "BehazdaaShipmentOrderTrackingReport".toLowerCase()) {
      result = "דיווח לבהצדעה";
    }
    else if (fileType.toLowerCase() == "ZapShipmentOrderTrackingReport".toLowerCase()) {
      result = "דיווח לזאפ";
    }
    else {
      var shipmentDesc = this._shipmentTypes[fileType] ? this._shipmentTypes[fileType].Description : "";
      if (shipmentDesc) result = shipmentDesc;
    }

    return "קובץ " + result;
  }

  private createWorkItem(operation: ILongRunningOperation, title: string) {
    var wi: WorkItem = {
      Operation: operation,
      Step: OrderExportStep.ServerProcessing,
      Title: title

    };
    this.WorkItems.push(wi);
    this.loadOperation(wi);
    this.refreshProcessStatusMessge();
  }
  private loadOperation(wi: WorkItem) {
    if (wi.Operation && wi.Operation.Id) {

      this._opertionService.GetOperation(wi.Operation.Id)
        .subscribe(r => {
          if (r.Result) {
            wi.Operation = r.Result;
            if (r.Result.PresentComplete == 100) {
              this.timers[wi.Operation.Id as string] = 0;
              this.validateWorkItem(wi);

            }
            else {
              this.timers[wi.Operation.Id as string] = setTimeout(() => { this.loadOperation(wi); }, 500) as unknown as number;
            }
          }
        });
    }

  }

  public IsDownloadable(fileType: FileType) {
    return (fileType & FileType.Export) > 0;
  }

  private processSuccussWorkItem(wi: WorkItem) {
    wi.Step = OrderExportStep.Complate;

    if (!this.WorkItems.filter(wi => wi.Step != OrderExportStep.Complate).length) this.onAllWorkItemFinished.next(wi); // when all files finished 100 percent 

  }
  private validateWorkItem(wi: WorkItem) {
    if (wi.Operation.Items) {
      var files = wi.Operation.Items.filter(i => i.Type == LongRunningOperationItemType.File && (i.Status == LongRunningOperationItemStatus.Success || i.Status == LongRunningOperationItemStatus.Warning));
      if (files.length > 0) {
        if (!files[0].RefId) this.processSuccussWorkItem(wi);
        else {
          this._fileService.SearchFiles({ Id: files[0].RefId }).subscribe(r => {
            if (r.Result && r.Result.TotalItemsCount > 0) {
              wi.File = r.Result.Items[0];
            }
            this.processSuccussWorkItem(wi);
          });
        }
      }
      var filesFailure = wi.Operation.Items.filter(i => i.Type == LongRunningOperationItemType.File && i.Status == LongRunningOperationItemStatus.Failure);
      if (filesFailure.length > 0) {
        wi.Step = OrderExportStep.Failure;
        wi.Title = "בעייה ביצירת הקובץ";
        this.ClientPostProcessed = true;
      }
      var filesFailure = wi.Operation.Items.filter(i => i.Type == LongRunningOperationItemType.File && i.Status == LongRunningOperationItemStatus.SubmitFailure);
      if (filesFailure.length > 0) {
        wi.Step = OrderExportStep.SubmitFailure;
        wi.Title = "בעייה בשידור הקובץ";
        this.ClientPostProcessed = true;
      }
    }
  }


  private refreshProcessStatusMessge() {
    let finishCount = this.WorkItems.filter(wi => wi.Step == OrderExportStep.Complate).length;
    if (!finishCount) this.StatusDescription = `מייצר ${this.WorkItems.length} קבצים לייצוא, אנא המתן...`;
    else this.StatusDescription = `${finishCount} קבצים מוכנים מתוך ${this.WorkItems.length}, אנא המתן...`;
  }



  public Finish() {
    if (this.ReturnUrl) {
      this._router.navigateByUrl(this.ReturnUrl);
    }
    else {
      this._location.back();
    }
  }

  ngOnDestroy(): void {
    for (var id in this.timers) {
      if (this.timers[id]) clearTimeout(this.timers[id]);
    }
  }
}

enum OrderExportStep {
  ServerProcessing = 1,
  FatchingResult = 2,
  Complate = 4,
  Failure = 8,
  SubmitFailure = 16,
}

interface WorkItem {
  Operation: ILongRunningOperation,
  Step: OrderExportStep,
  Title: string,
  File?: IExternalFile
};
export interface IExportOptions {
  SetStatusOnSuccess?: ShipmentStatus,
  SetErpStatusOnSuccess?: OrderErpExportStatus,
  SetCollectStatusOnSuccess?: OrderCollectStatus,
  FileTypes: FileType[]
}
