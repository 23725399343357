import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
import { OrderService, IOrderSearchRequest, GetShipmentItemFromOrder, GetProductItemsFromOrder } from '../../../services/order.service';
import { ShipmentService } from '../../../services/shipment.service';
import { CommonService, RoundNumber } from '../../../services/common.service';
import { IOrderItem, IOrder, IProductOrderItem, IShipmentOrderItem, OrderState, OrderErpExportStatus, SelectableERPStatus, OrderCollectStatus } from '../../../models/orders.models';
import { IDropPointShipment, IShipment, IShipmentTypes, ShipmentStatus } from '../../../models/shipments.models';
import { DBOperationResult } from '../../../models/common.models';
import { EnumDescriptionPipe } from '../../../pipes/enumdescription.pipe';
import { UserControlEditorMode } from '../../../models/user-controls';
import { Location } from '@angular/common';
import { collapseAnimation, fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, collapseOnLeaveAnimation } from 'angular-animations';
import { AdminService } from '../../../services/admin.service';
import { ISupplierConfiguration, ISupplierStoreConfigurationOrderCustomFieldAlias } from '../../../models/suppliers';
import { IStoreDetails, StoreType } from 'src/app/models/stores';
import { IExportOptions } from '../order-export/order-export.process.page.component';
import { FileType } from 'src/app/models/files.models';
import { IValidationResult, SeverityLevelType } from 'src/app/models/validation.models';
import { AuthorizationService } from 'src/app/services/authurization.service';
import { IMasterProduct, IProduct } from 'src/app/models/products.models';
import { ProductService } from 'src/app/services/product.service';
import { StoreService } from 'src/app/services/store.service';
import { filter, lastValueFrom } from 'rxjs';
import { ConfigStateFeature } from 'src/app/state/config.reducer';
import { Store } from '@ngrx/store';
import { trim } from 'jquery';

@Component({
  selector: 'app',
  templateUrl: './order.page.component.html',
  styleUrls: ['./order.page.component.css'],
  animations: [
    collapseAnimation({ duration: 500 })
  ]
})

export class OrderPageComponent implements OnInit, OnDestroy {
  private EnumDescriptionConverter = new EnumDescriptionPipe();
  private _shipmentTypes!: IShipmentTypes;

  constructor(
    private _orderService: OrderService,
    private _shipmentService: ShipmentService,
    private _commonService: CommonService,
    private _productService: ProductService,
    private _storeService: StoreService,
    private _route: ActivatedRoute,
    private _modalService: NgbModal,
    private _router: Router,
    private _location: Location,
    private _admin: AdminService,
    private _authorizationService: AuthorizationService,
    private store: Store,
    public CdRef: ChangeDetectorRef) { }




  closeResult: string = "";
  isOrderModifyed: boolean = false;
  order!: IOrder
  alert: { type: string, message: string, subMessages?: string[] } | null = null;
  public OrderStateOptions = [];
  public ShipmentStatusOptions = [];
  public OrderCollectStateOptions = [];
  public ShipmentEditorMode: UserControlEditorMode = UserControlEditorMode.View;
  public ShowAdvanceShipmentEditor: boolean = false;
  public ShopperEditorMode: UserControlEditorMode = UserControlEditorMode.View;
  public SupplierConfig!: ISupplierConfiguration;
  public OrderCustomFieldAliases!: { [key: string]: ISupplierStoreConfigurationOrderCustomFieldAlias };
  private preShipmentType!: string;
  public SelectableERPStatus: OrderErpExportStatus[] = SelectableERPStatus;
  public OrderWarningValidations!: IValidationResult[];
  public SourceStoreName!: string;
  public SelectedOrderItem: IOrderItem | null = null;

  public AvailableDeliveryPackageSizes = this.EnumDescriptionConverter.listOfValues("DeliveryPackageSize");

  ngOnInit() {
    this.store.select(ConfigStateFeature.selectShipmentTypes).pipe(filter(s => !!s)).subscribe(s => this._shipmentTypes = s);

    this._route.params.subscribe(params => {
      this.loadOrder(params['id']);
    });

  }

  ngOnDestroy(): void {
    delete window["Order"];
    delete window["GetOrderErpMap"];
  }

  public HasRole(roles: string[]): boolean {
    return this._authorizationService.DoseUserHasRole(roles);
  }

  private buildOptions() {
    this.OrderStateOptions = [];
    this.ShipmentStatusOptions = [];
    this.OrderCollectStateOptions = [];

    this.OrderStateOptions = this.EnumDescriptionConverter.listOfValues("OrderState").filter(state => ((+state) != OrderState.OutOfStock) || this.SupplierConfig.StockSetting?.Enable);
    this.ShipmentStatusOptions = this.EnumDescriptionConverter.listOfValues("ShipmentStatus");
    this.OrderCollectStateOptions = this.EnumDescriptionConverter.listOfValues("OrderCollectStatus");

  }

  private loadOrder(orderId: string): void {
    if ((orderId) && (orderId != "0") && (orderId != "new")) {
      this._orderService.GetOrder(orderId).subscribe((result: IOrder) => {
        this.order = result;
        window["Order"] = $.extend(true, {}, this.order);
        window["GetOrderErpMap"] = (path?: string) => {
          if (path) {
            this._orderService.GetPathValue(this.order.Id, path).subscribe(r => console.log(r.Result));
          }
          else this._orderService.ERPSearch({ Id: this.order.Id }).subscribe(r => console.log(r.Result.Items[0]));
        }
        if (this.order.Shipments.length) this.preShipmentType = this.order.Shipments[0].ItemType;
        if (this.order.State == OrderState.Draft) {
          this.alert = {
            type: "warning",
            message: "הזמנה לא תקינה",
            subMessages: this.order.FailedValidations.map(v => v.Message)
          }
        } else {
          this.OrderWarningValidations = this.order.FailedValidations.filter(v => v.Severity <= SeverityLevelType.Warning);
        }
        this.buildSourceName();
        this.loadSupplierConfig();
      });
    }
    else {
      this.order = this._orderService.CreateNewOrder();
      if (this.order.Shipments.length) this.preShipmentType = this.order.Shipments[0].ItemType;
      this.loadSupplierConfig();
    }

  };

  public get StoreType(): string {
    return StoreType[this.order.Source.OriginStore];
  }

  private async buildSourceName() {
    const storeDetails = await lastValueFrom(this._storeService.GetStoreDetails(this.order.Source.OriginStore));
    this.SourceStoreName = storeDetails.Description;
  }
  private loadSupplierConfig() {
    this.store.select(ConfigStateFeature.selectConfiguration).pipe(filter(s => !!s)).subscribe(conf => {
      if (conf) {
        this.SupplierConfig = conf;
        if (this.SupplierConfig.Stores[this.StoreType]) this.OrderCustomFieldAliases = this.SupplierConfig.Stores[this.StoreType].OrderCustomFieldAliases;
      }
      this.buildOptions();
    });
  }

  public DeleteOrder = () => {
    this._orderService.DeleteOrders(this.GetOrderSearchQuery(), "Deleted from order page").subscribe(r => {
      if (r.Code === 0) this._location.back();
    });
  }

  public PrintOrder(type: string) {
    this._router.navigate(["/print-orders"], {
      queryParams: {
        returnUrl: this._location.path(true),
        returnDescription: "חזור להזמנה מספר " + this.order.SupplierNumber,
        query: JSON.stringify(this.GetOrderSearchQuery()),
        options: JSON.stringify({
          FileTypes: [FileType.SchemaOriginDistributer]
        } as IExportOptions),
        types: [type]
      }
    });
  }


  public onShipmentTypeChange(newType: string) {
    this._shipmentService.ChangeShipmentType(this.SupplierConfig, this.order, this.order.Shipments[0].ItemType, newType);
    this.onOrderChanged();
    this.CdRef.detectChanges();
  }

  public onShipmentOrderItemChange() {
    if (this.SupplierConfig.Shipments.hasOwnProperty(this.order.Shipments[0].ItemType)) {
      let shipOrderRow = this.ShipmentItem;
      if (shipOrderRow) {
        const ShipConf = this.SupplierConfig.Shipments[this.order.Shipments[0].ItemType];
        shipOrderRow.IsOrigin = (
          shipOrderRow.SKU == ShipConf.ImportConfiguration.SKU &&
          shipOrderRow.Name == ShipConf.ImportConfiguration.Description &&
          shipOrderRow.ItemPrice == ShipConf.ImportConfiguration.DefaultPrice);

        if (shipOrderRow.ItemVATPrice) shipOrderRow.ItemVATPrice = Math.round(shipOrderRow.ItemPrice * this.SupplierConfig.VatRate * 100) / 100;
      }
    }
  }
  public onOrderChanged() {
    this.isOrderModifyed = true;
    window["Order"] = $.extend(true, {}, this.order);
  }

  public UpdateOrderItemPrice(item: IOrderItem) {
    if (item.hasOwnProperty("finalPrice")) {
      const newPrice = Number(item["finalPrice"]);
      if (!newPrice) {
        item.ItemPrice = 0;
        item.ItemVATPrice = 0;
      } else {
        const VatRate = (!item.ItemPrice) ? this.SupplierConfig.VatRate : RoundNumber(item.ItemVATPrice / item.ItemPrice, 4);
        item.ItemPrice = RoundNumber(newPrice / (1 + VatRate), 2);
        item.ItemVATPrice = RoundNumber(newPrice - item.ItemPrice, 2);
      }
      delete item["finalPrice"];
    }
  }

  ItemsTotalPrice(itemTypeFilter: string): number {
    var result = 0;
    for (var i = 0; i < this.order.Items.length; i++) {
      if (!itemTypeFilter || this.order.Items[i].ItemType === itemTypeFilter) result += (this.order.Items[i].Quantity || 1) * this.order.Items[i].ItemPrice;
    }
    return result;
  }

  ItemsTotalVATPrice(itemTypeFilter: string): number {
    var result = 0;
    for (var i = 0; i < this.order.Items.length; i++) {
      if (!itemTypeFilter || this.order.Items[i].ItemType === itemTypeFilter) result += (this.order.Items[i].Quantity || 1) * this.order.Items[i].ItemVATPrice;
    }
    return result;
  }

  PrepareOrderItemForMaterialInfoEdit(item: IProductOrderItem) {
    // check the array of serial numbers and fill it up to the length of the quantity or reduce it based on the quantity
    if (!item.ProductMaterialInfos) item.ProductMaterialInfos = [];
    if (item.ProductMaterialInfos.length < item.Quantity) {
      for (let i = item.ProductMaterialInfos.length; i < item.Quantity; i++) {
        item.ProductMaterialInfos.push({ SerialNumber: "" });
      }
    }
    else if (item.ProductMaterialInfos.length > item.Quantity) {
      item.ProductMaterialInfos = item.ProductMaterialInfos.slice(0, item.Quantity);
    }
    this.SelectedOrderItem = item;
  }

  public HTMLHelper = {
    validateMaterialInfo: (item: IProductOrderItem) => {
      return (item.ProductMaterialInfos?.filter(m => trim(m.SerialNumber)).length ?? 0) === item.Quantity;

    },
    extractSerialNumbersTexts: (item: IProductOrderItem) => {
      var serialNumbers = item?.ProductMaterialInfos?.map(m => trim(m.SerialNumber)).filter(s => s);
      if (serialNumbers?.length > 0) return serialNumbers.join(', ');
      else return "לא נמצאו מספרי סידור";
    },

    focusNext: (id) => {
      document.getElementById(id)?.focus();
    }
  }

  get ProductItems(): IProductOrderItem[] {
    return GetProductItemsFromOrder(this.order);
  }

  get ShipmentItem(): IShipmentOrderItem | null {
    var shipItems = GetShipmentItemFromOrder(this.order);
    return (shipItems.length > 0) ? shipItems[0] : null;
  }

  public DeleteOrderItemByIndex = (item: IOrderItem) => {

    var index = this.order.Items.indexOf(item);
    if (index >= 0) {
      this.order.Items.splice(index, 1);
      this.onOrderChanged();
    }
  }
  public ChangeOrderItemQuantity = (item: IOrderItem, Quantity: number) => {
    if (Quantity >= 0) {
      item.Quantity = Quantity;
      this.onOrderChanged();
    }
  }





  public AddOrderItem(item: IMasterProduct) {

    var orderItem = this._orderService.CreateNewOrderItem(this._productService.ConvertToMasterBasicOrderProduct(item, this.StoreType));

    this.order.Items.push(orderItem);
    this.onOrderChanged();
  }
  public CloseAlert() {
    this.alert = null;
  }
  public SaveOrder() {

    for (let index = 0; index < this.order.Items.length; index++) { // just as a double check we want to make sure all the items price were updated
      const item = this.order.Items[index];
      this.UpdateOrderItemPrice(item);
    }

    this._orderService.SaveOrder(this.order, true).subscribe(result => {
      if (result.Code === DBOperationResult.Success) {
        this._location.back();

      }
      else if (result.Code == DBOperationResult.ObjectNotValid) {
        this.alert = {
          type: "warning",
          message: "הזמנה נשמרה אך היא עדיין לא תקינה",
          subMessages: result.Result.FailedValidations.map(v => v.Message)
        }
      }
      else {
        this.alert = {
          type: "danger",
          message: "שגיאה בעת שמירת הזמנה - " + result.Message
        }
      }
    });
  }


  public OrderWizard = () => {
    var searchQuery: any = this.GetOrderSearchQuery();

    searchQuery.returnUrl = this._location.path(true);
    searchQuery.returnDescription = " חזור להזמנה";

    this._router.navigate(['/orders-wizard'], { queryParams: searchQuery });
  }

  public GetERPExportRemarks() {
    switch (this.order.ERPExportStatus) {
      case OrderErpExportStatus.ExportError:
        return "badge-danger";
      case OrderErpExportStatus.Exported:
        return "badge-success";
      default:
        return "badge-light";
    }
  }

  public IsSetErpStatusDisabled(status: OrderErpExportStatus) {
    return (status == OrderErpExportStatus.Exportable && (this.order.ERPExportStatus == OrderErpExportStatus.Canceled || this.order.ERPExportStatus == OrderErpExportStatus.Exported));
  }

  private GetOrderSearchQuery(): IOrderSearchRequest | null {
    if (this.order.Id) {
      return {
        Id: this.order.Id
      }
    }
    else return null;

  }
  public open(content, options, onInit, onSave) {
    if (onInit) onInit();
    this._modalService.open(content, options).result.then((result) => {
      if ((result === "save") && (onSave)) onSave();
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {

      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
