    <i class="mr-1 mt-1 text-info button fa-fw" [ngClass]="{'fa fa-spinner fa-pulse': LoadingOrders , 'fas fa-share-alt' : !LoadingOrders}" [ngbTooltip]="tipContent" triggers="manual" (click)="OpenOrdersToolTip(t);" #t="ngbTooltip" [autoClose]="true" tooltipClass="tooltip-text-info"></i>


<ng-template #tipContent>
    <div class="text-left tipContent">
        <span class="font-weight-bold"></span> משלוח מאוחד עם ההזמנות
        <ul *ngIf="Orders">
            <li *ngFor="let order of Orders"><a [routerLink]="['/order', order.Id]"> {{order.SupplierNumber}}</a></li>
        </ul>
    </div>
</ng-template>