import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";



import { IRetentionPolicyConfiguration, ISupplierConfiguration, ISupplierMainSetting, ISupplierUser, SupplierUserRolesTypes } from '../../../models/suppliers';
import { AdminService } from '../../../services/admin.service';
import { IStoreDetails, StoreType } from '../../../models/stores';

import { forkJoin, zip } from 'rxjs';
import { mergeMap, switchMap, zipAll } from 'rxjs/operators';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app',
  templateUrl: './supplier.user.page.component.html'
})
export class SupplierUserPageComponent implements OnInit {
  public User: ISupplierUser;
  public UserRoleTypes = SupplierUserRolesTypes;
  public IsNewUser: boolean = false;
  public ResetPassword: boolean = false;
  public FirstTimePassword: string;
  public closeResult: string = "";
  constructor(
    private _adminService: AdminService,
    private _route: ActivatedRoute,
    private _modalService: NgbModal,
    private _router: Router) { }


  ngOnInit() {
    this._route.params.subscribe(params => {
      if (params['id']) {
        this._adminService.GetSupplierUsers({ Id: params['id'] }).subscribe(result => {
          if (result.Code == 0 && result.Result.Items.length) this.User = result.Result.Items[0];
        });
      }
      else {
        this.User = this._adminService.CreateNewSupplierUser();
        this.IsNewUser = true;
      }
    });
  }

  public IsRoleChecked(role: string): boolean {
    return this.User.Roles.indexOf(role) >= 0;
  }

  public CheckRole(role: string, check: boolean) {
    if (check && !this.IsRoleChecked(role)) 
    {
      this.User.Roles.push(role);
      return;
    }
    if (!check && this.IsRoleChecked(role)) {
      var index = this.User.Roles.indexOf(role);
      if (index !== -1) {
        this.User.Roles.splice(index, 1);
      }
      return;
    }
  
  }

  public SaveAndReturn() {
    this._adminService.SaveSupplierUser(this.User, (this.IsNewUser || this.ResetPassword) ? this.FirstTimePassword : null).subscribe(result => {
     this.Return();
    });
  }

  
  public DeleteAndReturn = () => {
    this._adminService.DeleteSupplierUser(this.User.Id, "Deleted by UI").subscribe(result => {
     this.Return();
    });
  }

  public Return() {
    this._router.navigate(['/admin/supplier']);
  }


  public open(content, options, onInit, onSave) {
    if (onInit) onInit();
    this._modalService.open(content, options).result.then((result) => {
      if ((result === "save") && (onSave)) onSave();
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {

      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
