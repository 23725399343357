import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbDate } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'dateropdownpicker',
  templateUrl: './dateropdownpicker.usercontrol.component.html',
  styleUrls: ['./dateropdownpicker.usercontrol.component.css']
})
export class DateDropdownPickerUserControlComponent implements OnInit {

  constructor(private _modalService: NgbModal) {

  }

  @Input() title!: string;

  @Output() modelChange: EventEmitter<DateSelectiontModel | null> = new EventEmitter<DateSelectiontModel | null>();
  @Input() model!: DateSelectiontModel | null;

  @Input() dropButClass!: string;
  public Options: DateSelectiontModel[];

  @ViewChild('contentCustomRange', { static: true })
  private contentCustomRange!: ElementRef;

  // Function to call when the rating changes.
  onChange = (model: string) => {

  };

  ngOnInit() {
    var dayInSeconds = 60 * 60 * 24;


    this.Options = [
      DateSelectiontModel(dayInSeconds),
      DateSelectiontModel(dayInSeconds * 7),
      DateSelectiontModel(dayInSeconds * 14),
      DateSelectiontModel(dayInSeconds * 30), // need to convert to be more accurate to full month 
      DateSelectiontModel(dayInSeconds * 60), // need to convert to be more accurate to full month 
      DateSelectiontModel(dayInSeconds * 90), // need to convert to be more accurate to full month 
      DateSelectiontModel(dayInSeconds * 365),
    ].filter(o => o) as DateSelectiontModel[];
    this.modelChange.subscribe(m => {
      if (m != this.model) this.model = m;
    });
  }
  public Select(option: DateSelectiontModel) {
    this.modelChange.emit(option);
  }



  public CustomDatePicker = {
    hoveredDate: null as NgbDate | null,

    fromDate: null as NgbDate | null,
    toDate: null as NgbDate | null,
    OpenCustomDate: () => {

      this._modalService.open(this.contentCustomRange).result.then(
        (result) => {
          if (result === "save" && this.CustomDatePicker.fromDate && this.CustomDatePicker.toDate) {
            this.modelChange.emit(
              DateSelectiontModel(null,
                new Date(this.CustomDatePicker.fromDate.year, this.CustomDatePicker.fromDate.month - 1, this.CustomDatePicker.fromDate.day, 0, 0, 0, 0),
                new Date(this.CustomDatePicker.toDate.year, this.CustomDatePicker.toDate.month - 1, this.CustomDatePicker.toDate.day, 0, 0, 0, 0))
            );
          }

        }, (reason) => {

        });
    },
    onCustomDateSelection: (date: NgbDate) => {
      if (!this.CustomDatePicker.fromDate && !this.CustomDatePicker.toDate) {
        this.CustomDatePicker.fromDate = date;
      } else if (this.CustomDatePicker.fromDate && !this.CustomDatePicker.toDate && date.after(this.CustomDatePicker.fromDate)) {
        this.CustomDatePicker.toDate = date;
      } else {
        this.CustomDatePicker.toDate = null;
        this.CustomDatePicker.fromDate = date;
      }
    },

    isHovered: (date: NgbDate) => {
      return this.CustomDatePicker.fromDate && !this.CustomDatePicker.toDate && this.CustomDatePicker.hoveredDate && date.after(this.CustomDatePicker.fromDate) && date.before(this.CustomDatePicker.hoveredDate);
    },

    isInside: (date: NgbDate) => {
      return date.after(this.CustomDatePicker.fromDate as NgbDate) && date.before(this.CustomDatePicker.toDate as NgbDate);
    },

    isRange: (date: NgbDate) => {
      return date.equals(this.CustomDatePicker.fromDate as NgbDate) || date.equals(this.CustomDatePicker.toDate as NgbDate) || this.CustomDatePicker.isInside(date as NgbDate) || this.CustomDatePicker.isHovered(date as NgbDate);
    }
  }

}

export interface DateSelectiontModel {
  Title: string,
  Age?: number | null,
  Range?: { From?: Date, To?: Date } | null
}

export function DateSelectiontModel(age: number | undefined | null, from?: Date | string, to?: Date | string): DateSelectiontModel | null {
  if (age) {
    const dayInSeconds = 60 * 60 * 24;

    if (dayInSeconds == age) return { Age: dayInSeconds, Title: "יום" };
    else if (dayInSeconds * 7 == age) return { Age: dayInSeconds * 7, Title: "שבוע" };
    else if (dayInSeconds * 14 == age) return { Age: dayInSeconds * 14, Title: "שבועיים" };
    else if (dayInSeconds * 30 == age) return { Age: dayInSeconds * 30, Title: "30 יום" }; // need to convert to be more accurate to full month 
    else if (dayInSeconds * 60 == age) return { Age: dayInSeconds * 60, Title: "60 יום" }; // need to convert to be more accurate to full month 
    else if (dayInSeconds * 90 == age) return { Age: dayInSeconds * 90, Title: "90 יום" }; // need to convert to be more accurate to full month 
    else if (dayInSeconds * 365 == age) return { Age: dayInSeconds * 365, Title: "שנה" };
    else return null;
  }
  else if (from && to) {
    from = new Date(from);
    to = new Date(to);
    return {
      Title: (from as Date).getDate() + '/' + ((from as Date).getMonth() + 1) + '/' + (from as Date).getFullYear() + " - " +
        (to as Date).getDate() + '/' + ((to as Date).getMonth() + 1) + '/' + (to as Date).getFullYear(),
      Range: {
        From: from,
        To: to
      }
    }
  }
  else return null;

}