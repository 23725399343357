import { Component, OnInit, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
import { OrderService } from '../../../services/order.service';

import { FileService } from '../../../services/file.service';



import { IExternalFile } from '../../../models/files.models';

import {  FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { IStoreDetails, StoreType } from '../../../models/stores';
import { StoreService } from '../../../services/store.service';

@Component({
  templateUrl: './order-import.upload.page.component.html',
  styleUrls: ['./order-import.upload.page.component.css'],


})
export class OrderImportUploadPageComponent {


  constructor(
    private _fileService: FileService,
    private _orderService: OrderService,
    private _storeService: StoreService,
    private _route: ActivatedRoute,
    private _router: Router) {

    _storeService.GetStoreList().subscribe(
      stores => {
        this.Stores = stores.filter(s => s.Type != StoreType.Manual)
      })

  }



  public Stores!: IStoreDetails[];

  public Files: {
    file: File,
    upload: IExternalFile | null
  }[] = [];

  public Showpaperclip: boolean = false;
  public UploadFilesInput(fileList: FileList) {
    var files: File[] = [];
    for (var i = 0; i < fileList.length; i++) {
      files.push(fileList[i]);
    }

    this.UploadFiles(files);
  }
  public UploadFiles(files: File[]) {
    var uploadFiles: { file: File, upload: IExternalFile | null }[] = files.map(f => { return { file: f, upload: null } });

    this.Files = this.Files.concat(uploadFiles);

    this._fileService.UploadFiles(files)
      .subscribe(r => {
        if (r.Result) {
          var files = r.Result;
          for (var i = 0; i < files.length; i++) {
            uploadFiles[i].upload = files[i];
          }
        }
      });
  }


  public Dropped(files: NgxFileDropEntry[]) {
    // https://www.npmjs.com/package/ngx-file-drop 
    var dragFiles: File[] = [];

    var eventFiles = files.filter(droppedFile => droppedFile.fileEntry.isFile); // only files
    for (const droppedFile of eventFiles) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {

        dragFiles.push(file);
        if (dragFiles.length == eventFiles.length) this.UploadFiles(dragFiles); // if this if the last file callback

      });
    }



  }

  public RemoveFile(file: { file: File, upload: IExternalFile | null }) {
    var index = this.Files.indexOf(file);
    if (index >= 0) {
      this.Files.splice(index, 1);
    }
  }

  public FileOver(event) {
    // console.log(event);
  }

  public FileLeave(event) {
    // console.log(event);
  }

  public GetFileTypeFontAwesomeIcon(file: { file: File, upload: IExternalFile | null }) {
    if (file.file.name.toLowerCase().endsWith(".xlsx")) return 'fa-file-excel';
    else if (file.file.name.toLowerCase().endsWith(".xls")) return 'fa-file-excel';
    else if (file.file.name.toLowerCase().endsWith(".html") || file.file.name.toLowerCase().endsWith(".htm")) return 'fa-file-code';
    else return 'fa-file-alt';
  }

  public get ReadyForImportValidationMessage(): string {
    // should convert to veriable ad this is called meny times
    if (!this.Files.length) return "אנא בחר קבצים להעלות";
    if (this.Files.filter(f => !f.upload).length) return "מעלה קבצים אנא המתן...";
    return "";
  }

  public ImpotFiles() {
    this._orderService.ImportOrdersFromFiles(
      this.Files.map(f => (f.upload as IExternalFile).Id as string)
    ).subscribe(r => {
      if (r.Result) {
        this._router.navigate(['/import-orders-process', r.Result.Id]);
      }
    });
  }

}

