<div class="container-fluid mt-2" *ngIf="SupplierShipmentConfiguration && ShipmentType">
  <div class="row mb-3">
    <div class="col-8">
      <h2>הגדרת ערוץ הפצה</h2>
      <h3>{{ShipmentType.Description}}</h3>
    </div>
    <div class="col-4" *ngIf="ShipmentType.LogoURL">
      <img class="LogoImage position-absolute" [src]="ShipmentType.LogoURL" [alt]="ShipmentType.Description" />
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12 multi-line">
      {{HTMLTexts.LongDescription}}
    </div>
  </div>

  <div class="row my-2">
    <h5 class="col-3">ערוץ הפצה פעיל</h5>
    <div class="col-3">
      <checkbox [(model)]="SupplierShipmentConfiguration.Enabled" tabindex="10"></checkbox>
    </div>
    <div class="col-6"></div>
  </div>

  <div class="row my-2">
    <h5 class="col-3">התעלם אוטומטית משגיאות</h5>
    <div class="col-3">
      <checkbox [(model)]="SupplierShipmentConfiguration.AutoIgnoreErrors" tabindex="15"></checkbox>
    </div>
    <div class="col-6">תהליכי ההזמנות לא יעצרו כאשר המשלוח מכיל שגיאות ומשלוח לא תקין יסומן כ"התעלם משגיאות" עד לתיקון השגיאה</div>
  </div>

  <div class="row my-2" >
    <h5 class="col-3">שתול מספר מעקב ייעודי</h5>
    <div class="col-3">
      <checkbox [(model)]="SupplierShipmentConfiguration.AutoTrackingNumber" tabindex="17"></checkbox>
    </div>
    <div class="col-6">המערכת תיצור מספר משלוח ייחודי שאותו ניתן לשנות על ידי המשלח (בתהליך שידור המשלוח( או ידני במסך עריכת הזמנה.</div>
  </div>

  <ng-container *ngIf="SupplierShipmentConfiguration.hasOwnProperty('ExportConfiguration') ">

    <div class="row my-2">
      <h5 class="col-3">הוסף מק"ט משלוח בהזמנה</h5>
      <div class="col-3">
        <checkbox [(model)]="SupplierShipmentConfiguration.ExportConfiguration.EnableAddionLineInERPExport" (modelChange)="OnEnableAddionLineInERPExportChange()" tabindex="20"></checkbox>
      </div>
      <div class="col-6"></div>
    </div>

    <div class="row my-1" *ngIf="SupplierShipmentConfiguration.ExportConfiguration.EnableAddionLineInERPExport">
      <div class="col-3">
        <h5 class="d-inline">מק"ט משלוח בהזמנה</h5>
      </div>
      <div class="col-3">
        <labeled-textbox type="text" [(model)]="SupplierShipmentConfiguration.ImportConfiguration.SKU" [bindFormControl]="ValidationFrom.controls.SKU" [validationMessages]="{'required':'חובה להכניס מק&quot;ט משלוח בהזמנה'}" tabindex="22"> </labeled-textbox>
      </div>
      <div class="col-6"></div>
    </div>

    <div class="row my-1" *ngIf="SupplierShipmentConfiguration.ExportConfiguration.EnableAddionLineInERPExport">
      <div class="col-3">
        <h5 class="d-inline">תיאור המוצר</h5>
      </div>
      <div class="col-3">
        <labeled-textbox type="text" [(model)]="SupplierShipmentConfiguration.ImportConfiguration.Description" [bindFormControl]="ValidationFrom.controls.Description" [validationMessages]="{'required':'חובה להכניס תיאור משלוח בהזמנה'}" tabindex="24"></labeled-textbox>
      </div>
      <div class="col-6"></div>
    </div>

    <div class="row my-1" *ngIf="SupplierShipmentConfiguration.ExportConfiguration.EnableAddionLineInERPExport">
      <div class="col-3">
        <h5 class="d-inline">מחיר</h5>
      </div>
      <div class="col-3">
        <input type="number" class="form-control" [(ngModel)]="SupplierShipmentConfiguration.ImportConfiguration.DefaultPrice" tabindex="26" />
      </div>
      <div class="col-6"></div>
    </div>
  </ng-container>

  <div class="row my-1">
    <div class="col-3">
      <h5 class="d-inline">הוסף הערות משלוח מחושבות</h5>
    </div>
    <div class="col-3">
      <input type="text" class="form-control" [(ngModel)]="SupplierShipmentConfiguration.ComputedRemarksTemplate" tabindex="30" />
    </div>
    <div class="col-6">

    </div>
  </div>

  <ng-container *ngIf="SupplierShipmentConfiguration.hasOwnProperty('CustomerID') || SupplierShipmentConfiguration.hasOwnProperty('UserName') || SupplierShipmentConfiguration.hasOwnProperty('Password') || SupplierShipmentConfiguration.hasOwnProperty('AuthenticationToken') ">
    <div class="row my-2">
      <div class="col-12">
        <h5 class="d-inline">פרטי הלקוח שלך ב{{ShipmentType.Description}}</h5> (לצורך התחברות ישירה - התממשקות)
      </div>
    </div>

    <div class="row my-2" *ngIf="SupplierShipmentConfiguration.hasOwnProperty('CustomerID')">
      <div class="col-3">
        <h5 class="d-inline">מזהה לקוח</h5> (שלך ב{{ShipmentType.Description}}):
      </div>
      <div class="col-3">
        <input type="text" class="form-control" [(ngModel)]="SupplierShipmentConfiguration.CustomerID" tabindex="50" />
      </div>
      <div class="col-6"></div>
    </div>
    <div class="row my-2" *ngIf="SupplierShipmentConfiguration.hasOwnProperty('CustomerName')">
      <div class="col-3">
        <h5 class="d-inline">שם לקוח</h5> (שלך ב{{ShipmentType.Description}}):
      </div>
      <div class="col-3">
        <input type="text" class="form-control" [(ngModel)]="SupplierShipmentConfiguration.CustomerName" tabindex="51" />
      </div>
      <div class="col-6"></div>
    </div>
    <div class="row my-2" *ngIf="SupplierShipmentConfiguration.hasOwnProperty('AuthenticationToken')">
      <div class="col-3">
        <h5 class="d-inline">מפתח אותנטיקציה לממשק</h5><br /> ( Authentication Token שלך ב{{ShipmentType.Description}}):
      </div>
      <div class="col-3">
        <input type="text" class="form-control" [(ngModel)]="SupplierShipmentConfiguration.AuthenticationToken" tabindex="52" />
      </div>
      <div class="col-6"></div>
    </div>

    <div class="row my-2" *ngIf="SupplierShipmentConfiguration.hasOwnProperty('Phone')">
      <div class="col-3">
        <h5 class="d-inline"> טלפון הלקוח  </h5>
      </div>
      <div class="col-3">
        <input type="text" class="form-control" [(ngModel)]="SupplierShipmentConfiguration.Phone" tabindex="52" />
      </div>
      <div class="col-6"></div>
    </div>

    <div class="row my-2" *ngIf="SupplierShipmentConfiguration.hasOwnProperty('Email')">
      <div class="col-3">
        <h5 class="d-inline"> אמייל הלקוח  </h5>
      </div>
      <div class="col-3">
        <input type="text" class="form-control" [(ngModel)]="SupplierShipmentConfiguration.Email" tabindex="52" />
      </div>
      <div class="col-6"></div>
    </div>

   
    
    <div class="row my-2" *ngIf="SupplierShipmentConfiguration.hasOwnProperty('UserName')">
      <div class="col-3">
        <h5 class="d-inline">שם משתמש</h5> (שלך ב{{ShipmentType.Description}}):
      </div>
      <div class="col-3">
        <input type="text" class="form-control" [(ngModel)]="SupplierShipmentConfiguration.UserName" tabindex="55" />
      </div>
      <div class="col-6"></div>
    </div>

    <div class="row my-2" *ngIf="SupplierShipmentConfiguration.hasOwnProperty('Password')">
      <div class="col-3">
        <h5 class="d-inline">סיסמה</h5> (שלך ב{{ShipmentType.Description}}):
      </div>
      <div class="col-3">
        <input type="password" class="form-control" autocomplete="new-password" [(ngModel)]="SupplierShipmentConfiguration.Password" tabindex="60" />
      </div>
      <div class="col-6"></div>
    </div>

  </ng-container>


  <div class="row my-2" *ngIf="SupplierShipmentConfiguration.hasOwnProperty('AliasName')">
    <div class="col-3">
      <h5 class="d-inline">שם ערוץ</h5> (כינוי עבור ערוץ שליח עצמי):
    </div>
    <div class="col-3">
      <input type="text" class="form-control" [(ngModel)]="SupplierShipmentConfiguration.AliasName" tabindex="100" />
    </div>
    <div class="col-6"></div>
  </div>


  <div class="row my-2" *ngIf="SupplierShipmentConfiguration.hasOwnProperty('LocationSearchDistance')">
    <div class="col-3">
      <h5>מרחק מקסימלי למציאת נק' איסוף (ק"מ): </h5>
    </div>
    <div class="col-3">
      <input type="number" class="form-control" [(ngModel)]="SupplierShipmentConfiguration.LocationSearchDistance" tabindex="200" />
    </div>
    <div class="col-6"></div>
  </div>

  <div class="row my-2">
    <h5 class="col-3"> המנע מיצוא הערות בהזמנה </h5>
    <div class="col-3">
      <checkbox [(model)]="SupplierShipmentConfiguration.ExportConfiguration.IgnoreOrderRemarks" tabindex="300"></checkbox>
    </div>
    <div class="col-6">בעת ייצוא או הדפסה שדה ההערות בהזמנה לא ישלח </div>
  </div>

  <div class="row my-2">
    <h5 class="col-3"> המנע מיצוא הערות במשלוח </h5>
    <div class="col-3">
      <checkbox [(model)]="SupplierShipmentConfiguration.ExportConfiguration.IgnoreShipmentRemarks" tabindex="310"></checkbox>
    </div>
    <div class="col-6">בעת ייצוא או הדפסה שדה ההערות במשלוח לא ישלח </div>
  </div>

  <div class="row my-2" *ngIf="SupplierShipmentConfiguration.hasOwnProperty('PickupContact')">
    <h5 class="col-3"> איש קשר לאיסוף </h5>
    <div class="col-3 border rounded pt-4">
      <identity-editor [(Identity)]="SupplierShipmentConfiguration.PickupContact" [Mode]="2"> </identity-editor>
    </div>
    <div class="col-6"></div>
  </div>

  <div class="row my-2" *ngIf="SupplierShipmentConfiguration.hasOwnProperty('PickupAddress')">
    <h5 class="col-3"> כתובת לאיסוף </h5>
    <div class="col-3 border rounded pt-4">
      <address-editor [(Address)]="SupplierShipmentConfiguration.PickupAddress" [Mode]="6"></address-editor>
    </div>
    <div class="col-6"></div>
  </div>
  <div class="row my-2">
    <div class="col-12">
      <button type="button" tabindex="1000" class="btn btn-lg btn-outline-secondary" [ngClass]="{'btn-outline-success': ValidationFrom.valid }" (click)="SaveAndReturn();">
        <h4 class="d-inline-block mr-2">שמור</h4>
        <i class="fa fa-save"></i>
      </button>

    </div>
  </div>
</div>