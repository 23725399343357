import { createAction, props } from '@ngrx/store';
import { IProductConfiguration, IRetentionPolicyConfiguration, IStockConfiguration, ISupplierConfiguration, ISupplierExportConfiguration, ISupplierLocalERPConfiguration, ISupplierMainSetting, ISupplierShipmentConfiguration, ISupplierStoreConfiguration, IUIConfiguration } from '../models/suppliers';
import { IShipmentType } from '../models/shipments.models';


export const ConfigFetchSupplierConfigurationStart = createAction('[Config Supplier] Fetch configuration Start');
export const ConfigFetchSupplierConfigurationSuccess = createAction('[Config Supplier] Fetch configuration Success', props<{ config: ISupplierConfiguration }>());
export const ConfigFetchSupplierConfigurationError = createAction('[Config Supplier] Fetch configuration Error', props<{ error: string }>());


export const ConfigSaveSupplierConfigurationStart = createAction('[Config Supplier] Save configuration Start', props<{ config: ISupplierConfiguration }>());
export const ConfigSaveSupplierConfigurationSuccess = createAction('[Config Supplier] Save configuration Success', props<{ config: ISupplierConfiguration }>());
export const ConfigSaveSupplierConfigurationError = createAction('[Config Supplier] Save configuration Error', props<{ error: string }>());

export const ConfigFetchSupplierMainSettingStart = createAction('[Config Supplier] Fetch MainSetting Start');
export const ConfigFetchSupplierMainSettingSuccess = createAction('[Config Supplier] Fetch MainSetting Success', props<{ mainSetting: ISupplierMainSetting }>());
export const ConfigFetchSupplierMainSettingError = createAction('[Config Supplier] Fetch MainSetting Error', props<{ error: string }>());

export const ConfigSaveSupplierMainSettingStart = createAction('[Config Supplier] Save MainSetting Start', props<{ mainSetting: ISupplierMainSetting }>());
export const ConfigSaveSupplierMainSettingSuccess = createAction('[Config Supplier] Save MainSetting Success', props<{ mainSetting: ISupplierMainSetting }>());
export const ConfigSaveSupplierMainSettingError = createAction('[Config Supplier] Save MainSetting Error', props<{ error: string }>());


export const ConfigSetSupplierStoreConfiguration = createAction('[Config Supplier] Set Store Configuration', props<{ idName: string, configuration: ISupplierStoreConfiguration }>());
export const ConfigSetSupplierShipmentConfiguration = createAction('[Config Supplier] Set Shipment Configuration', props<{ idName: string, configuration: ISupplierShipmentConfiguration }>());
export const ConfigSetSupplierExports = createAction('[Config Supplier] Set Supplier Exports Configuration', props<{ exportsConfiguration: ISupplierExportConfiguration[] }>());

export const ConfigFetchShipmentTypesStart = createAction('[Config Supplier] Fetch ShipmentTypes Start');
export const ConfigFetchShipmentTypesSuccess = createAction('[Config Supplier] Fetch ShipmentTypes Success', props<{ shipmentTypes: { [key: string]: IShipmentType } }>());
export const ConfigFetchShipmentTypesError = createAction('[Config Supplier] Fetch ShipmentTypes Error', props<{ error: string }>());


export const ConfigSetSupplierLocalErpConfiguration = createAction('[Config Supplier] Set SupplierLocalErp Configuration', props<{ erpConfiguration: ISupplierLocalERPConfiguration }>());
export const ConfigSetSupplierRetentionPolicyConfiguration = createAction('[Config Supplier] Set SupplierRetentionPolicyConfiguration Configuration', props<{ retentionPolicy: IRetentionPolicyConfiguration }>());
export const ConfigSetSupplierStockConfiguration = createAction('[Config Supplier] Set SupplierStockConfiguration Configuration', props<{ stockSetting: IStockConfiguration }>());
export const ConfigSetSupplierProductConfiguration = createAction('[Config Supplier] Set SupplierProductConfiguration Configuration', props<{ productSetting: IProductConfiguration }>());
export const ConfigSetSupplierUIConfiguration = createAction('[Config Supplier] Set SupplierUIConfiguration Configuration', props<{ uiConfiguration: IUIConfiguration }>());
export const ConfigAddShippingMethodDefault = createAction('[Config Supplier] Add ShippingMethodDefault Configuration', props<{ storeId: string, shipmentTypeId: string, defaultValue: string, defaultPrice: number | null }>());


/*
GetShipmentTypes
*/