<div class="">
  <div class="row mt-2">
    <div class="col-6">
      <div class="btn-group pr-2" ngbDropdown>
        <button type="button" class="btn btn-outline-primary btn-sm dropdown-toggle dropdown-toggle-split " id="dropdownBasic1" ngbDropdownToggle>
          פעולות לביצוע <span class="sr-only">Toggle Dropdown</span>
        </button>
        <div class="dropdown-menu" style="width: 18rem;" ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button class="dropdown-item btn btn-sm btn-block rounded-0" (click)=" open(contentExportOptions,null,null,Export)">
            <i class="fas fa-file-excel mr-1"></i>
            ייצא מוצרים {{(!SelectedProducts || SelectedProducts.GetSelectedItemCount() == 0)? "" : "מסומנים"}}
          </button>
          <!-- 
          <button class="dropdown-item btn btn-sm btn-block rounded-0" (click)="Export({activeOnly : true})">
            <i class="fas fa-file-excel mr-1"></i>
             ייצא מוצרים {{(!SelectedProducts || SelectedProducts.GetSelectedItemCount() == 0)? "" : "מסומנים"}} פעילים בלבד
          </button>
          <button class="dropdown-item btn btn-sm btn-block rounded-0" (click)="Export({disabledOnly : true})">
            <i class="fas fa-file-excel mr-1"></i>
             ייצא מוצרים {{(!SelectedProducts || SelectedProducts.GetSelectedItemCount() == 0)? "" : "מסומנים"}} <b>לא</b> פעילים בלבד
          </button>
          -->
          <ng-container *ngIf="SelectedStoreView">
            <button class="dropdown-item btn btn-sm btn-block rounded-0" (click)="ExportPrices()">
              <i class="fas fa-file-excel mr-1"></i>
              ייצא מחירון {{(!SelectedProducts || SelectedProducts.GetSelectedItemCount() == 0)? "" : "מסומנים"}}
            </button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item btn btn-sm btn-block rounded-0" [disabled]="!SelectedProducts || SelectedProducts.GetSelectedItemCount() == 0" (click)="open(contentActiveProduct,null,null,null)">
              <i class="fas fa-check mr-1"></i>
              הפעל מוצרים {{(!SelectedProducts || SelectedProducts.GetSelectedItemCount() == 0)? "" : "מסומנים"}} (בערוץ הנוכחי)
            </button>
            <button class="dropdown-item btn btn-sm btn-block rounded-0" [disabled]="!SelectedProducts || SelectedProducts.GetSelectedItemCount() == 0" (click)="open(contentDisactiveProduct,null,null,null)">
              <i class="fas fa-pause mr-1"></i>
              השהה מוצרים {{(!SelectedProducts || SelectedProducts.GetSelectedItemCount() == 0)? "" : "מסומנים"}} (בערוץ הנוכחי)
            </button>
          </ng-container>
          <div class="dropdown-divider"></div>
          <button class="dropdown-item btn btn-danger btn-sm btn-block rounded-0" [disabled]="!SelectedProducts || SelectedProducts.GetSelectedItemCount() == 0" (click)="open(contentDeleteProduct,null,null,null)">
            <i class="fas fa-trash-alt mr-1"></i>
            מחק מוצרים {{(!SelectedProducts || SelectedProducts.GetSelectedItemCount() == 0)? "" : "מסומנים"}}
          </button>
        </div>
      </div>


    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <input type="text" class="form-control  border-primary" placeholder="" aria-label="" aria-describedby="basic-addon1" [(ngModel)]="FreeTextSearch" (keydown.enter)="RunFreeSearch()">
        <div class="btn-group  pr-2" ngbDropdown>
          <button type="button" class="btn btn-outline-primary left-radius-only" (click)="RunFreeSearch();">
            <span>חיפוש מוצרים</span>
            <i class="fas fa-search ml-2"></i>
          </button>
        </div>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-2">
      <select *ngIf="ActiveStores" [autofocus] class="form-control font-weight-bold" [ngModel]="SelectedStoreView" (ngModelChange)="SelectedStoreView=$event ; reloadPage(false,false);" tabindex="0">
        <option [ngClass]="{'h5 font-weight-bold' : !SelectedStoreView  , 'h5 font-italic text-secondary' : SelectedStoreView}" [value]="null">ברירת מחדל</option>
        <option [ngClass]="{'h5' : SelectedStoreView == store.Type , 'h6 text-secondary' : SelectedStoreView !=  store.Type}" [value]="store.Type" *ngFor="let store of ActiveStores">{{store.Description}}</option>
      </select>
    </div>
    <div class="col-4"></div>
    <div class="col-6" *ngIf="!SelectedStoreView">
      <div style="position: relative;top: -1rem;">
        <checkbox [(model)]="IncludeAllProductTypes"></checkbox>
        <span class="ml-2">כלול מוצרים מדומים</span>
      </div>

    </div>
  </div>


  <div class="row">
    <div class="col-3 d-flex align-items-center">
      <h2 class="pt-2 d-inline">נמצאו <span class="badge badge-pill badge-light">{{products?.TotalItemsCount}}</span></h2>
      <div class="d-inline-block ml-4 " style="line-height: 80%;">
        <button type="button" class="btn btn-link m-0 p-0" style="line-height: 50%;" *ngIf="SelectedProducts" (click)="SelectedProducts.ToggleSelectAll()">{{SelectedProducts?.SelectAllItems ? "הסר" : "סמן"}} הכול</button> <br />
        <button type="button" class="btn btn-link m-0 p-0" style="line-height: 50%;" *ngIf="SelectedProducts" (click)="toggleSelectPage()">{{isFullPageSelected ? "הסר" : "סמן"}} עמוד</button>
      </div>

    </div>
    <div class="col-5">
      <pager *ngIf="products && products.PageSize < products.TotalItemsCount" [PageSize]="Query.PageSize" [PageIndex]="Query.PageIndex" (PageIndexChange)="Query.PageIndex = $event; reloadPage(false,false);" [TotalItemCount]="products.TotalItemsCount"></pager>
    </div>
    <div class="col-4">

    </div>

  </div>

  <div class="row" *ngIf="products && SelectedProducts">
    <div class="col-12">
      <table class="table table-hover table-sm">
        <thead>
          <tr>
            <th scope="col" [style.height.%]="HTMLHelper.TableColumnWidths[SelectedStoreView? 'StoreSelected' : 'Default'][0]">סמן</th>
            <th scope="col" [style.height.%]="HTMLHelper.TableColumnWidths[SelectedStoreView? 'StoreSelected' : 'Default'][1]" *ngIf="SelectedStoreView">סטטוס</th>
            <th scope="col" [style.height.%]="HTMLHelper.TableColumnWidths[SelectedStoreView? 'StoreSelected' : 'Default'][2]" class="product-list-header" (click)="SortListBy('SKU')">מק"ט <i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'SKU', 'fa-angle-down': Query?.OrderByFields[0] ==  '-SKU'}"></i></th>
            <th scope="col" [style.height.%]="HTMLHelper.TableColumnWidths[SelectedStoreView? 'StoreSelected' : 'Default'][3]" class="product-list-header" (click)="SortListBy('Model')">מודל <i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'Model', 'fa-angle-down': Query?.OrderByFields[0] ==  '-Model'}"></i></th>
            <th scope="col" [style.height.%]="HTMLHelper.TableColumnWidths[SelectedStoreView? 'StoreSelected' : 'Default'][4]" class="product-list-header" (click)="SortListBy('Name')">תיאור <i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'Name', 'fa-angle-down': Query?.OrderByFields[0] ==  '-Name'}"></i></th>
            <th scope="col" [style.height.%]="HTMLHelper.TableColumnWidths[SelectedStoreView? 'StoreSelected' : 'Default'][5]" *ngIf="SelectedStoreView" class="product-list-header" (click)="SortListBy('PriceList.RecommendedPrice')">מחירון <i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'PriceList.RecommendedPrice', 'fa-angle-down': Query?.OrderByFields[0] ==  '-PriceList.RecommendedPrice'}"></i></th>
            <th scope="col" [style.height.%]="HTMLHelper.TableColumnWidths[SelectedStoreView? 'StoreSelected' : 'Default'][6]" *ngIf="SelectedStoreView && IsStockEnabled" class="product-list-header" (click)="SortListBy('Stock.Amount')">מלאי <i class="fas" [ngClass]="{'fa-angle-up': Query?.OrderByFields[0] ==  'Stock.Amount', 'fa-angle-down': Query?.OrderByFields[0] ==  '-Stock.Amount'}"></i></th>
          </tr>
        </thead>
        <tbody>
          <tr (mouseenter)="MouseOverOrder= product" *ngFor="let product of products.Items; index as i">
            <td>
              <checkbox [model]="SelectedProducts.IsItemSelected(product.Id)" (modelChange)="SelectedProducts.SelectItem(product.Id,$event)"></checkbox>
            </td>
            <td *ngIf="SelectedStoreView">
              <button [disabled]="SaveInProcessProducts.includes(product.Id)" type="button" class="badge badge-pill border-0" [ngClass]="{'badge-success' : product.ActiveInStore,  'badge-danger' : !product.ActiveInStore}" (click)="ToggleActiveStoreProduct(product)">
                {{(product.ActiveInStore)? "פעיל" : "לא פעיל"}}
              </button>
            </td>
            <td scope="row">
              <a [routerLink]="['/product', product.Id]" [queryParams]="{store:SelectedStoreView}">
                <ng-container *ngIf="product.ItemType=='AliasProduct'">
                  <i class="fas fa-project-diagram" title="מוצר מדומה"></i>
                </ng-container>
                <ng-container *ngIf="product.ItemType=='MasterProduct'">
                  <i class="fas fa-cube" title="מוצר מסטר"></i>
                </ng-container>
                {{product.SKU}}
              </a>
            </td>
            <td>{{product.Model}}</td>
            <td>{{product.Name}}</td>

            <td *ngIf="SelectedStoreView">{{product.PriceList?.RecommendedPrice | number:'1.2-2'}}</td>
            <td *ngIf="SelectedStoreView && IsStockEnabled">{{product.Stock?.Amount | number:'1.0'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>


<ng-template #contentDeleteProduct let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">אישור מחיקה</h4>
    <button type="button" class="close" aria-label="Close" [disabled]="IsProductSaving" (click)=" c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="SelectedProducts && SelectedProducts.GetSelectedItemCount() <= 1;else contentDeleteProductGetSelectedProductsCountelse">
      האם אתה בטוח שאתה רוצה למחוק את המוצר ?
    </div>
    <ng-template #contentDeleteProductGetSelectedProductsCountelse>
      האם אתה בטוח שאתה רוצה למחוק <span class="badge badge-pill badge-light">{{SelectedProducts?.GetSelectedItemCount()}}</span> מוצרים ?
    </ng-template>
  </div>

  <div class="modal-footer d-block">
    <button type="button" class="btn btn-outline-danger float-right" [disabled]="IsProductSaving" (click)="DeleteSelectedProducts(c)">אשר <i class="fa" [ngClass]="{'fa-spinner fa-pulse' : IsProductSaving , 'fa-trash-alt' : !IsProductSaving}"></i></button>
    <button type="button" class="btn btn-outline-dark float-left" [disabled]="IsProductSaving" (click)="c('cancel')">בטל</button>
  </div>
</ng-template>


<ng-template #contentActiveProduct let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">אישור הפעלת מוצר</h4>
    <button type="button" class="close" aria-label="Close" [disabled]="IsProductSaving" (click)=" c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    האם אתה בטוח שאתה רוצה להפעיל <span class="badge badge-pill badge-light">{{SelectedProducts?.GetSelectedItemCount()}}</span> מוצרים ?
  </div>

  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-outline-success" [disabled]="IsProductSaving" (click)="SetSelectedActive(true,c)">אשר <i class="fa" [ngClass]="{'fa-spinner fa-pulse' : IsProductSaving , 'fa-check' : !IsProductSaving}"></i></button>
    <span *ngIf="SelectedProducts?.GetSelectedItemCount() > 100 && !IsProductSaving" class="badge badge-warning"> פעולה זו עשוייה לקחת מספר דקות <i class="fas fa-exclamation ml-1"></i> </span>
    <span *ngIf="SelectedProducts?.GetSelectedItemCount() > 100 && IsProductSaving" class="text-info px-2"> מבצע פעולה אנא המתן...<i class="h3 fa-spin fas fa-hourglass-half ml-4 "></i> </span>
    <button type="button" class="btn btn-outline-dark" [disabled]="IsProductSaving" (click)="c('cancel')">בטל</button>
  </div>
</ng-template>


<ng-template #contentDisactiveProduct let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">אישור השהייה מוצר</h4>
    <button type="button" class="close" aria-label="Close" [disabled]="IsProductSaving" (click)=" c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    האם אתה בטוח שאתה רוצה להשהות <span class="badge badge-pill badge-light">{{SelectedProducts?.GetSelectedItemCount()}}</span> מוצרים ?

  </div>

  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-outline-success" [disabled]="IsProductSaving" (click)="SetSelectedActive(false,c)">אשר <i class="fa" [ngClass]="{'fa-spinner fa-pulse' : IsProductSaving , 'fa-pause' : !IsProductSaving}"></i></button>
    <span *ngIf="SelectedProducts?.GetSelectedItemCount() > 100 && !IsProductSaving" class="badge badge-warning"> פעולה זו עשוייה לקחת מספר דקות <i class="fas fa-exclamation ml-1"></i> </span>
    <span *ngIf="SelectedProducts?.GetSelectedItemCount() > 100 && IsProductSaving" class="text-info px-2"> מבצע פעולה אנא המתן...<i class="h3 fa-spin fas fa-hourglass-half ml-4 "></i> </span>
    <button type="button" class="btn btn-outline-dark" [disabled]="IsProductSaving" (click)="c('cancel')">בטל</button>
  </div>
</ng-template>




<ng-template #contentExportOptions let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">אפשרויות ייצוא</h4>
    <button type="button" class="close" aria-label="Close" [disabled]="IsProductSaving" (click)=" c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="container">
      <div class="row">
        <div class="col-3 pt-2"> סינון :</div>
        <div class="col-9">
          <select class="form-control font-weight-bold" [(ngModel)]="exportProductOptions.selector">
            <option [value]="'all'">כול המוצרים {{(!SelectedProducts || SelectedProducts.GetSelectedItemCount() == 0)? "" : "מסומנים"}}</option>
            <option [value]="'activeOnly'">רק מוצרים {{(!SelectedProducts || SelectedProducts.GetSelectedItemCount() == 0)? "" : "מסומנים"}} פעילים לחנות</option>
            <option [value]="'disabledOnly'">רק מוצרים {{(!SelectedProducts || SelectedProducts.GetSelectedItemCount() == 0)? "" : "מסומנים"}} <b>לא</b> פעילים לחנות</option>
          </select>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-3 pt-2"> פורמט :</div>
        <div class="col-9">
          <select class="form-control font-weight-bold" [(ngModel)]="exportProductOptions.type">
            <option [value]="'default'">ברירת מחדל לחנות</option>
            <option *ngFor="let format of ProductFileFormats" [value]="format.exportType">{{format.name}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer d-block">
    <button type="button" class="btn btn-outline-success float-right" [disabled]="IsProductSaving" (click)="c('save')">ייצא</button>
    <button type="button" class="btn btn-outline-dark float-left" [disabled]="IsProductSaving" (click)="c('cancel')">בטל</button>
  </div>
</ng-template>