import { createFeature, createReducer, on } from "@ngrx/store";
import { StatisticsUpdateDraftOrderCountSuccess, StatisticsUpdateERPErrorOrderCountSuccess, StatisticsUpdateInvalidShipmentOrderCountSuccess, StatisticsUpdateOutOfStockOrderCountSuccess } from "./statistics.actions";
import { state } from "@angular/animations";

export const initialState: IStatisticsState =
{
    draftOrderCount: 0,
    erpErrorOrderCount: 0,
    invalidShipmentOrderCount: 0,
    outOfStockOrderCount: 0

}


export interface IStatisticsState {
    draftOrderCount: number,
    erpErrorOrderCount: number,
    invalidShipmentOrderCount: number,
    outOfStockOrderCount: number
}



export const statisticsStateFeature = createFeature({
    name: 'statistics',
    reducer: createReducer(
        initialState,
        on(StatisticsUpdateDraftOrderCountSuccess, (state, payload) => ({ ...state, draftOrderCount: payload.draftOrderCount })),
        on(StatisticsUpdateERPErrorOrderCountSuccess, (state, payload) => ({ ...state, erpErrorOrderCount: payload.erpErrorOrderCount })),
        on(StatisticsUpdateInvalidShipmentOrderCountSuccess, (state, payload) => ({ ...state, invalidShipmentOrderCount: payload.invalidShipmentOrderCount })),
        on(StatisticsUpdateOutOfStockOrderCountSuccess, (state, payload) => ({ ...state, outOfStockOrderCount: payload.outOfStockOrderCount }))
    )

});



