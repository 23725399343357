<div *ngIf="Shipment.ErrorMessage" class="d-flex align-items-center mb-4 font-weight-bold">
  <span> תיאור שגיאה : </span>
  <span class="text-danger">"{{Shipment.ErrorMessage}}"</span>
  <button class="btn btn-outline-primary btn-sm ml-auto" (click)="ResetError()"> אפס שגיאה <i class="fas fa-eraser"></i></button>
</div>

<ng-container [ngSwitch]="Shipment.ItemType">
  <droppoint-shipment-editor *ngSwitchCase="'BoxItShipment'" (ValidationFromChange)="BindFromControl('DropPointEditor',$event)" [Mode]="Mode" [Shipment]="Shipment" (ShipmentChange)="Shipment = $event; ShipmentChange.emit($event)">
  </droppoint-shipment-editor>
  <droppoint-shipment-editor *ngSwitchCase="'ChitaShopsShipment'" (ValidationFromChange)="BindFromControl('DropPointEditor',$event)" [Mode]="Mode" [Shipment]="Shipment" (ShipmentChange)="Shipment = $event; ShipmentChange.emit($event)">
  </droppoint-shipment-editor>
  <droppoint-shipment-editor *ngSwitchCase="'YDMShopsShipment'" (ValidationFromChange)="BindFromControl('DropPointEditor',$event)" [Mode]="Mode" [Shipment]="Shipment" (ShipmentChange)="Shipment = $event; ShipmentChange.emit($event)">
  </droppoint-shipment-editor>
  <droppoint-shipment-editor *ngSwitchCase="'CargoShopsDeliveryShipment'" (ValidationFromChange)="BindFromControl('DropPointEditor',$event)" [Mode]="Mode" [Shipment]="Shipment" (ShipmentChange)="Shipment = $event; ShipmentChange.emit($event)">
  </droppoint-shipment-editor>
  <droppoint-shipment-editor *ngSwitchCase="'OrionShopsShipment'" (ValidationFromChange)="BindFromControl('DropPointEditor',$event)" [Mode]="Mode" [Shipment]="Shipment" (ShipmentChange)="Shipment = $event; ShipmentChange.emit($event)">
  </droppoint-shipment-editor>
  <droppoint-shipment-editor *ngSwitchCase="'UPSPickUpShipment'" (ValidationFromChange)="BindFromControl('DropPointEditor',$event)" [Mode]="Mode" [Shipment]="Shipment" (ShipmentChange)="Shipment = $event; ShipmentChange.emit($event)">
  </droppoint-shipment-editor>
  <droppoint-shipment-editor *ngSwitchCase="'SosnaShopsShipment'" (ValidationFromChange)="BindFromControl('DropPointEditor',$event)" [Mode]="Mode" [Shipment]="Shipment" (ShipmentChange)="Shipment = $event; ShipmentChange.emit($event)">
  </droppoint-shipment-editor>
  <droppoint-shipment-editor *ngSwitchCase="'EPostShipment'" (ValidationFromChange)="BindFromControl('DropPointEditor',$event)" [Mode]="Mode" [Shipment]="Shipment" (ShipmentChange)="Shipment = $event; ShipmentChange.emit($event)">
  </droppoint-shipment-editor>
  <ng-container *ngSwitchDefault>

    <delivery-shipment-editor *ngIf="Controls.DeliveryEditor.Enable" (ValidationFromChange)="BindFromControl('DeliveryEditor',$event)"  [Mode]="Mode" [Shipment]="Shipment" (ShipmentChange)="Shipment = $event; ShipmentChange.emit($event)">

    </delivery-shipment-editor>

  </ng-container>
</ng-container>
<div *ngIf="Shipment.ComputedRemarks" class="text-secondary font-weight-bold">
  {{Shipment.ComputedRemarks}}
</div>
<div *ngIf="ValidationFrom.invalid" class="mt-2">
  <span class="mr-2">התעלם משגיאת משלוח</span>
  <checkbox [(model)]="IgnoreValidation"> </checkbox>
</div>